/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { addSalesReturn, deleteSalesReturn, editSalesReturn, getSalesReturn, getSalesReturnByDate, getSalesReturnById } from "../../services/salesReturn.service"


export enum SalesPaymentModesDropDown {
    CASH = "Cash",
    BANK = "Bank",
    CREDIT = "Credit",
}
export type SalesPaymentModeTypes = SalesPaymentModesDropDown.CASH | SalesPaymentModesDropDown.BANK | SalesPaymentModesDropDown.CREDIT

export enum SalesReturnActionList {
    ADD_SALESRETURN = 'ADD_SALESRETURN',
    FETCH_SALESRETURN = 'FETCH_SALESRETURN',
    UPDATE_SALESRETURN = 'UPDATE_SALESRETURN',
    DELETE_SALESRETURN = 'DELETE_SALESRETURN',
}

export interface SalesReturnItems {
    salesReturnId?: number
    entryNumber: number
    productId?: number
    productGroupCode?: string
    productCode: string
    batchNumber: number
    expiryDate: string
    quantity: string
    freeQuantity: string
    totalQuantity: string
    priceWogst: number
    priceGst: number
    wRate: number
    // rRate: number
    // lRate: number
    mrp: number
    gstValue: number
    sDiscount: number
    pDiscountpercent: number
    pDiscountamount: number
    cDiscountpercent: number
    cDiscountamount: number
    taxableAmount: number
    igstAmount: number
    cgstAmount: number
    sgstAmount: number
    totalAmount: number
    unitCost: number
    profitPercent: number
    profitAmount: number
}

export interface SalesReturnType {
    // id?: number
    // invoiceDate: string
    // invoiceNo: string
    // SalesEntryNo: string
    // supplierId: number
    // finalAmount: string
    id?: number
    financeYear: string
    branchId: number
    invoiceType: string
    invoiceTitle: string
    invoiceDate?: string
    invoiceHour?: string
    // goodsReceivedDate: string
    invoiceDay?: string
    invoiceWeekNumber?: number
    invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    packNo: string
    salesReturnEntryNumber: number
    systemNo: string
    repId?: number
    customerId: number
    customerName: string
    salesReturnItems: SalesReturnItems[]
    /////new
    pointsEarned?: string
    bankName: string
    bankNumber: number
    paymentMode: SalesPaymentModeTypes
    billAmount?: string
    disPercent?: string
    disAmount?: string
    add?: string
    less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    deliveryMode?: string
    deliveryDetails?: string
    remarks?: string,
    tenderAmt?: string
    tenderReturn?: string
    netTaxable?: string
    netIgst?: string
    netSgst?: string
    netCgst?: string
    netProfit?: string
    netProfitPercent?: string
    AValue?: string
    ATaxable?: string
    BValue?: string
    BTaxable?: string
    CValue?: string
    CTaxable?: string
    DValue?: string
    DTaxable?: string
    EValue?: string
    ETaxable?: string
    FValue?: string
    FTaxable?: string
    GValue?: string
    GTaxable?: string
}

export interface AddSalesReturnAction {
    type: SalesReturnActionList.ADD_SALESRETURN
    data: SalesReturnType
}

export interface FetchSalesReturnAction {
    type: SalesReturnActionList.FETCH_SALESRETURN
    data: Array<SalesReturnType>
}

export interface UpdateSalesReturnAction {
    type: SalesReturnActionList.UPDATE_SALESRETURN
    data: SalesReturnType
    id: number
}

export interface DeleteSalesReturnAction {
    type: SalesReturnActionList.DELETE_SALESRETURN
    data: number
}

export type SalesReturnActions = AddSalesReturnAction | FetchSalesReturnAction | UpdateSalesReturnAction | DeleteSalesReturnAction

export const addSalesReturnAction = (data: SalesReturnType) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return addSalesReturn(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddSalesReturnAction>({
                    type: SalesReturnActionList.ADD_SALESRETURN,
                    data: data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'SalesReturn Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
    // dispatch<AddSalesReturnAction>({
    //     type: SalesReturnActionList.ADD_SALESRETURN,
    //     data: {
    //         ...data,
    //         id: parseInt(nanoid(5))
    //     }
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Sales Added")
    // return api().post<APIResponse<SalesReturnType>>('Sales/', data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {

    //         dispatch<AddSalesReturnAction>({
    //             type: SalesReturnActionList.ADD_SALESRETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Sales Added')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to add'
    //         : 'Unable to add')
    // })
    // }
}

export const updateSalesReturnAction = (data: SalesReturnType, id: number) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return editSalesReturn(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<UpdateSalesReturnAction>({
                    type: SalesReturnActionList.UPDATE_SALESRETURN,
                    data: data,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Sales Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })

    }

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Sales Updated")
    // return api().put<APIResponse<SalesReturnType>>(`Sales/?id=${id}`, data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<UpdateSalesReturnAction>({
    //             type: SalesReturnActionList.UPDATE_SALESRETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Sales Updated')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to update'
    //         : 'Unable to update')
    // })
}

export const fetchSalesReturnByIdAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        const response = await getSalesReturnById(id)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        return data
        // dispatch<FetchSalesReturnAction>({
        //     type: SalesReturnActionList.FETCH_SALESRETURN,
        //     data: data
        // })
    }
}

export const fetchSalesReturnAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getSalesReturn()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchSalesReturnAction>({
            type: SalesReturnActionList.FETCH_SALESRETURN,
            data: data
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<FetchSalesReturnAction>({
    //     type: SalesReturnActionList.FETCH_SALESRETURN,
    //     data: SalesSampleData
    // })
    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // return api().get<APIResponse<SalesReturnType[]>>('Sales/', config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<FetchSalesReturnAction>({
    //             type: SalesReturnActionList.FETCH_SALESRETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Fetch Successfull')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to fetch'
    //         : 'Unable to fetch')
    // })
    // }
}


export const fetchSalesReturnByDateAction = (startDate: string, endDate: string) => {
    return async (dispatch: Dispatch) => {
        const response = await getSalesReturnByDate(startDate, endDate)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchSalesReturnAction>({
            type: SalesReturnActionList.FETCH_SALESRETURN,
            data: data
        })
    }
}

export const deleteSalesReturnAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        return deleteSalesReturn(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteSalesReturnAction>({
                    type: SalesReturnActionList.DELETE_SALESRETURN,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Sales Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<DeleteSalesReturnAction>({
    //     type: SalesReturnActionList.DELETE_SALESRETURN,
    //     data: id
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Sales Deleted")
    // return api().delete<APIResponse<SalesReturnType>>(`Sales/?id=${id}`, config).then(response => {
    //     if (response.status === 200) {
    //         dispatch<DeleteSalesReturnAction>({
    //             type: SalesReturnActionList.DELETE_SALESRETURN,
    //             data: id
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Sales Deleted')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to delete'
    //         : 'Unable to delete')
    // })
    // }
}

