import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import LineChartForCard from '../components/custom/components/charts/apex/LineChartForCards'
import UserLayout from '../components/custom/layouts/UserLayout'
import { getDate } from '../config/functions'
import { SalesPaymentModesDropDown } from '../redux/actions/salesActions'
import { SalesReturnType, fetchSalesReturnAction } from '../redux/actions/salesReturnAction'
import { AppDispatch, StoreState } from '../redux/store'
import SalesReturnHourlyReport from './SalesReturnHourlyReport'
import SalesReturnMonthlyReport from './SalesReturnMonthlyReport'
import SalesReturnWeeklyReport from './SalesReturnWeeklyReport'
import SalesReturnYearlyReport from './SalesReturnYearlyReport'

const SalesReturnDashboard = () => {
    const dispatch = useDispatch<AppDispatch>()
    const salesReturnList = useSelector<StoreState, SalesReturnType[]>(state => state.salesReturn)
    let d = new Date();
    const currDate = d;

    const todaysCashSalesReturn = useMemo<number>(() => {
        let todayData = salesReturnList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.CASH)
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [salesReturnList, currDate])

    const todaysCardSalesReturn = useMemo<number>(() => {
        let todayData = salesReturnList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.BANK)
        if (todayData.length > 0) {
            let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            // setCardData(cardArr)
        }
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [salesReturnList, currDate])

    const cashData = useMemo<number[]>(() => {
        let todayData = salesReturnList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.CASH)
        if (todayData.length > 0) {
            let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
            // setCashData(cashArr)
            return cashArr
        } else return []

    }, [salesReturnList, currDate])

    const cardData = useMemo<number[]>(() => {
        let todayData = salesReturnList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.BANK)
        if (todayData.length > 0) {
            let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            // setCardData(cardArr)
            return cardArr
        } else return []

    }, [salesReturnList, currDate])

    const todaysOverallSalesReturn = useMemo<number[]>(() => {
        let todayData = salesReturnList?.filter(f => f.invoiceDate === getDate(currDate))
        console.log(salesReturnList);
        console.log(todayData);

        if (todayData.length > 0) {
            let OverallArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            return OverallArr
        } else return []
    }, [salesReturnList, currDate])

    useEffect(() => {
        dispatch(fetchSalesReturnAction())
    }, [])

    return <UserLayout>

        <PageTitle
            title='Sales Return Analysis'
        />
        <div className='row mx-3'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Cash Sales Return</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashSalesReturn}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Cash',
                                    data: cashData,
                                    // data: [5, 18, 8, 12, 9, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#008FFB']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4 '>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Bank Sales Return</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCardSalesReturn}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Card',
                                    data: cardData,
                                    // data: [6, 20, 8, 12, 9, 18, 11, 18, 15],
                                }]
                            }
                            colors={['#00E396']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Overall Sales Return</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashSalesReturn + todaysCardSalesReturn || 0}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Overall',
                                    data: todaysOverallSalesReturn,
                                    // data: [8, 10, 8, 12, 20, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#FEB019']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <SalesReturnYearlyReport />
        <SalesReturnMonthlyReport />
        <SalesReturnWeeklyReport />
        <SalesReturnHourlyReport />

    </UserLayout>

}

export default SalesReturnDashboard
