import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import LineChartForCard from '../components/custom/components/charts/apex/LineChartForCards'
import UserLayout from '../components/custom/layouts/UserLayout'
import { getDate } from '../config/functions'
import { PurchasePaymentModesDropDown, PurchaseType, fetchPurchaseAction } from '../redux/actions/purchaseActions'
import { AppDispatch, StoreState } from '../redux/store'
import PurchaseHourlyReport from './PurchaseHourlyReport'
import PurchaseMonthlyReport from './PurchaseMonthlyReport'
import PurchaseWeeklyReport from './PurchaseWeeklyReport'
import PurchaseYearlyReport from './PurchaseYearlyReport'

const PurchaseDashboard = () => {
    const dispatch = useDispatch<AppDispatch>()
    const purchaseList = useSelector<StoreState, PurchaseType[]>(state => state.purchase)
    let d = new Date();
    const currDate = d;

    const cashData = useMemo<number[]>(() => {
        let todayData = purchaseList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchasePaymentModesDropDown.CASH)
        if (todayData.length > 0) {
            let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
            // setCashData(cashArr)
            return cashArr
        } else return []

    }, [purchaseList, currDate])

    const cardData = useMemo<number[]>(() => {
        let todayData = purchaseList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchasePaymentModesDropDown.BANK)
        if (todayData.length > 0) {
            let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            // setCardData(cardArr)
            return cardArr
        } else return []

    }, [purchaseList, currDate])

    const todaysCashPurchase = useMemo<number>(() => {
        let todayData = purchaseList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchasePaymentModesDropDown.CASH)
        // if (todayData.length > 0) {
        //     let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
        //     setCashData(cashArr)
        // }
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [purchaseList, currDate])

    const todaysCardPurchase = useMemo<number>(() => {
        let todayData = purchaseList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === PurchasePaymentModesDropDown.BANK)
        // if (todayData.length > 0) {
        //     let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
        //     setCardData(cardArr)
        // }
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [purchaseList, currDate])

    const todaysOverallPurchase = useMemo<number[]>(() => {
        let todayData = purchaseList?.filter(f => f.invoiceDate === getDate(currDate))
        let OverallArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
        return OverallArr
    }, [purchaseList, currDate])

    useEffect(() => {
        dispatch(fetchPurchaseAction())
    }, [])

    return <UserLayout>

        <PageTitle
            title='Purchase Analysis'
        />

        <div className='row mx-3'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Cash Purchase</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashPurchase}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Cash',
                                    data: cashData,
                                    // data: [5, 18, 8, 12, 9, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#008FFB']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4 '>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Bank Purchase</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCardPurchase}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Card',
                                    data: cardData,
                                    // data: [6, 20, 8, 12, 9, 18, 11, 18, 15],
                                }]
                            }
                            colors={['#00E396']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Overall Purchase</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashPurchase + todaysCardPurchase || 0}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Overall',
                                    data: todaysOverallPurchase,
                                    // data: [8, 10, 8, 12, 20, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#FEB019']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <PurchaseYearlyReport />
        <PurchaseMonthlyReport />
        <PurchaseWeeklyReport />
        <PurchaseHourlyReport />

    </UserLayout>

}

export default PurchaseDashboard
