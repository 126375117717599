import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { BrandType, addBrandAction, deleteBrandAction, editBrandAction, fetchBrandAction } from "../redux/actions/brandAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    brandName: string | undefined,
    // viewImage: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: BrandType) => void
type HandleDelete = (value: BrandType) => void

const Brand = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const BrandList = useSelector<StoreState, BrandType[]>(state => state.brand)

    useEffect(() => {
        dispatch(fetchBrandAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<BrandType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()


    const [brandName, setBrandName] = useState<string>('')
    const [brandNameError, setBrandNameError] = useState<string>('')
    const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    const [imageUrl, setImageUrl] = useState<any>('')
    const [imageFlag, setImageFlag] = useState<boolean>(false)


    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrandName(e.target.value)
        setBrandNameError('')
    }

    const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setImageUpload(e.target.files[0])
        setImageFlag(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setBrandName('')
        setImageUpload({ filename: '' })
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        // const addData: BrandType = {
        //     id: id,
        //     name: brandName
        // }

        const edata: BrandType = {
            id: id,
            name: brandName,
            imageUrl: imageUrl
        }


        if (brandName.trim() === '') {
            setBrandNameError('Brand required')
            error = true
        } else if ((brandName.trim() || '').length > 30) {
            setBrandNameError('Brand should be within 30 characters')
            error = true
        }

        if (!error) {
            const data = new FormData()
            data.append('name', brandName)
            data.append('imageUrl', imageUpload)
            if (imageFlag) {
                data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            }
            if (formType === FormTypeList.ADD) {
                dispatch(addBrandAction(data)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editBrandAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData('' || null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            // setLoginid(editData.loginid !== undefined ? editData.loginid : '')
            setBrandName(editData.name || '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteBrandAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }

    return <>
        <Modal
            headerText={'Delete Brand'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Brand Master</h4>
            <div className="container">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8">
                        <div className='hstack justify-content-center gap-3'>
                            <label htmlFor="" className="mb-2">Name</label>
                            <FormInput
                                name='brandName'
                                labelClassName="required"
                                value={brandName}
                                onChange={handleName}
                                errorText={brandNameError}
                                containerClass="mb-2"
                            />
                            {/* <FormInput
                                name='imageUpload'
                                value={imageUpload.filename}
                                onChange={handleImageUpload}
                                // errorText={imageUploadError}
                                type="file"
                                className="mb-2"
                            /> */}
                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) &&
                                <>
                                    < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                    <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                                </>
                            }

                        </div>
                    </div>
                    <div className="col-2"></div>
                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="card mb-0 p-1">
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Name</th>
                                            {/* <th className='text-truncate align-middle'>Image</th> */}
                                            <th className='text-truncate align-middle'>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {BrandList.map((bl, i) => {
                                            return <GroupBody
                                                sno={i + 1}
                                                brandName={bl.name}
                                                // viewImage={bl.imageUrl}
                                                key={bl.id}
                                                onEdit={() => handleEdit(bl)}
                                                onDelete={() => handleDelete(bl)}

                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </UserLayout>
    </>
}

const GroupBody = ({ sno, brandName, onEdit, onDelete }: TableBody) => {
    const [image, setImage] = React.useState<any>('')

    // React.useEffect(() => {
    //     const config = store.getState().authUser?.['auth-token']!
    //     fetch(apiUrl + '' + viewImage, {
    //         headers: {
    //             'auth-token': store.getState().authUser?.['auth-token']!
    //         }
    //     }).then(async (res) => {
    //         const blob = await res.blob()
    //         const fileURL = window.URL.createObjectURL(blob);
    //         setImage(fileURL)
    //     });
    // }, [viewImage]);

    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{brandName}</td>
        {/* <td className='text-capitalize align-middle'><img src={image} style={{ margin: "3px" }} className="img-thumbnail w-100px" width={100} height={100} /></td> */}
        <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default Brand

