import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { GetState } from "../store"
import { camelize } from "../../config/functions"
import { addBranch, deleteBranch, editBranch, getBranch } from "../../services/branch.service"

// export enum ActiveDropDown {
//     Active = "active",
//     InActive = "inactive",
// }
// export type ActiveTypes = ActiveDropDown.Active | ActiveDropDown.InActive

// export const branchData = [
//     {
//         id: 1,
//         // loginid: "admin",
//         name: "Mannargudi",
//         createdBy: 1,
//         updatedBy: 1,
//         createdAt: "2023-06-12T12:51:46.825Z",
//         updatedAt: "2023-06-12T12:51:46.825Z"
//     },
//     {
//         id: 2,
//         // loginid: "super",
//         name: "Namakkal",
//         createdBy: 2,
//         updatedBy: 2,
//         createdAt: "2023-06-15T09:48:31.741Z",
//         updatedAt: "2023-06-15T09:49:08.967Z"
//     },
// ]

export interface BranchType {
    id?: number
    loginid?: string
    name?: string
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: number | null,
}

export enum BranchActionsList {
    ADD_BRANCH = 'ADD_BRANCH',
    EDIT_BRANCH = 'EDIT_BRANCH',
    FETCH_BRANCH = 'FETCH_BRANCH',
    DELETE_BRANCH = 'DELETE_BRANCH'
}

export interface DeleteBranch {
    id: number | undefined
}

export interface AddBranchAction {
    type: BranchActionsList.ADD_BRANCH
    data: BranchType
}

export interface EditBranchAction {
    type: BranchActionsList.EDIT_BRANCH
    data: BranchType
    id: number | undefined
}

export interface FetchBranchAction {
    type: BranchActionsList.FETCH_BRANCH
    data: BranchType[]

}

export interface DeleteBranchAction {
    type: BranchActionsList.DELETE_BRANCH
    data: number|undefined
}

export type BranchActions = AddBranchAction | EditBranchAction | FetchBranchAction | DeleteBranchAction

export const addBranchAction = (data: BranchType) => {
    return async (dispatch: Dispatch) => {
        return addBranch(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddBranchAction>({
                        type: BranchActionsList.ADD_BRANCH,
                        data: addData
                    })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })


        // return dispatch<AddBranchAction>({
        //     type: BranchActionsList.ADD_BRANCH,
        //     data: {
        //         ...data,
        //         id: Math.round(Math.random() * 100000)
        //     }
        // })
        // })
    }
}

export const editBranchAction = (data: BranchType, id: number | undefined) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return editBranch(data,id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
            dispatch<EditBranchAction>({
                    type: BranchActionsList.EDIT_BRANCH,
                    data: editData,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
        // return dispatch<EditBranchAction>({
        //     type: BranchActionsList.EDIT_BRANCH,
        //     data: data,
        //     id: id
        // })
       
    }
}

export const fetchBranchAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getBranch()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchBranchAction>({
            type: BranchActionsList.FETCH_BRANCH,
            data: data
        })
    }
  
}

export const deleteBranchAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteBranch(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteBranchAction>({
                    type: BranchActionsList.DELETE_BRANCH,
                    data:id 
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })

        // return dispatch<DeleteBranchAction>({
        //     type: BranchActionsList.DELETE_BRANCH,
        //     data: { id }
        // })
        
    }
}