import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import 'react-select/dist/react-select.css'
import Select from 'react-select'
// import 'react-virtualized-select/styles.css'
// import 'react-virtualized/styles.css'
import { FormType, FormTypeList } from "../config"
import { today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { BranchType } from "../redux/actions/branchAction"
import { AppDispatch, StoreState } from "../redux/store"
import Option from "./Option"
import { FormInput } from "./custom/components"
import { useToast } from "./toast/ToastProvider"

const ACTIVE_DROPS = [
    {
        text: 'Active',
        value: true
    },
    {
        text: 'InActive',
        value: false
    },
]

const PURCHASE_TYPE_DROPS = [
    {
        text: 'PURCHASE TAX INVOICE',
        value: 'PURCHASE TAX INVOICE'
    },
    {
        text: 'PURCHASE ESTIMATE',
        value: 'PURCHASE ESTIMATE'
    },
    {
        text: 'OPENING STOCK',
        value: 'OPENING STOCK'
    },
]

interface PurchaseReturnFormProps {
    setInvoiceType: React.Dispatch<React.SetStateAction<string>>
    setInvoiceTitle: React.Dispatch<React.SetStateAction<string>>
    setInvDate: React.Dispatch<React.SetStateAction<string>>
    setInvNo: React.Dispatch<React.SetStateAction<string>>
    setSupName: React.Dispatch<React.SetStateAction<string>>
    setSupAddress: React.Dispatch<React.SetStateAction<string>>
    setSupContact: React.Dispatch<React.SetStateAction<string>>
    setGST: React.Dispatch<React.SetStateAction<string>>
    setSupAadhar: React.Dispatch<React.SetStateAction<string>>
    setBilledBy: React.Dispatch<React.SetStateAction<string>>
    setSysNo: React.Dispatch<React.SetStateAction<string>>
    setSupPlace: React.Dispatch<React.SetStateAction<string>>
    setRefBy: React.Dispatch<React.SetStateAction<string>>
    setBranch: React.Dispatch<React.SetStateAction<string>>
    setInvoiceAmount: React.Dispatch<React.SetStateAction<string>>
    setPurchaseReturnEntryNo: React.Dispatch<React.SetStateAction<string>>
    setSupplier: React.Dispatch<React.SetStateAction<string>>
    setGudsRecdDate: React.Dispatch<React.SetStateAction<string>>
    formType: FormType
    onSave: () => void
    gudsRecdDate: string
    invoiceDate: string
    invoiceNo: string
    invoiceAmount: string
    purchaseReturnEntryNo: string
    supplier: string
    invoiceType: string
    refBy: string
    // showViewForm: boolean
}

export interface Dropdown {
    label: string
    value: string
}

const PurchaseReturnForm = (props: PurchaseReturnFormProps) => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    console.log(authUser);

    const [purchaseReturnType, setPurchaseReturnType] = useState<string>(PURCHASE_TYPE_DROPS[0].value)
    const [purchaseReturnTypeError, setPurchaseReturnTypeError] = useState<string>('')
    const [purchaseReturnEntryNo, setPurchaseReturnEntryNo] = useState<string>((Math.floor(Math.random() * 90000) + 10000).toString())
    const [purchaseReturnEntryNoError, setPurchaseReturnEntryNoError] = useState<string>('')
    const [goodsRcvdDate, setGoodsRcvdDate] = useState<string>(today())
    const [goodsRcvdDateError, setGoodsRcvdDateError] = useState<string>('')
    const [invoiceDate, setInvoiceDate] = useState<string>(today())
    const [invoiceDateError, setInvoiceDateError] = useState<string>('')
    const [invoiceNo, setInvoiceNo] = useState<string>('')
    const [invoiceNoError, setInvoiceNoError] = useState<string>('')
    const [invoiceAmt, setInvoiceAmt] = useState<string>('')
    const [invoiceAmtError, setInvoiceAmtError] = useState<string>('')
    const [supplier, setSupplier] = useState<Dropdown | null>(null)
    const [supplierError, setSupplierError] = useState<string>('')
    const [refBy, setRefBy] = useState<string>('')
    const [refByError, setRefByError] = useState<string>('')

    // const [supplierSearchTerm, setSupplierSearchTerm] = useState<string>('')

    const supplierList = AccountLists.filter(a => a.groupName === 'Supplier' || a.groupName === 'supplier')

    // const filteredSuppliers = useMemo<SupplierType[]>(() => {

    //     if (supplier?.value !== '') {
    //         // let obj = supplierList.find(sl => sl.id?.toString() === supplier.toString())

    //         return supplierList.filter(s => {
    //             return s?.name?.includes(supplierSearchTerm)
    //         })
    //     } else {
    //         return supplierList
    //     }
    // }, [supplier])

    const resetStates = () => {
        setPurchaseReturnType('')
        setPurchaseReturnTypeError('')
        setPurchaseReturnEntryNo('')
        setPurchaseReturnEntryNoError('')
        setGoodsRcvdDate(today())
        setGoodsRcvdDateError('')
        setInvoiceDate(today())
        setInvoiceDateError('')
        setSupplier(null)
        setSupplierError('')
        setInvoiceNo('')
        setInvoiceNoError('')
    }
    const handlePurchaseType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseReturnType(e.target.value)
        setPurchaseReturnTypeError('')
    }

    const handleInvoiceDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceDate(e.target.value)
        setInvoiceDateError('')
    }

    const handleGoodsRcvdDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGoodsRcvdDate(e.target.value)
        setGoodsRcvdDateError('')
    }

    const handleInvoiceNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceNo(e.target.value)
        setInvoiceNoError('')
    }
    const handleInvoiceAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceAmt(e.target.value)
        setInvoiceAmtError('')
    }
    const handleRefBy: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRefBy(e.target.value)
        setRefByError('')
    }

    // const handleSupplier = (value: string) => {
    //     setSupplier(value)
    //     setSupplierError('')
    // }

    const handlePurchaseEntryNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseReturnEntryNo(e.target.value)
        setPurchaseReturnEntryNoError('')
    }

    const handleSave = () => {
        let error = false
        const data = {
            purchaseReturnType: purchaseReturnType,
            purchaseReturnEntryNo: purchaseReturnEntryNo,
            invoiceDate: invoiceDate,
            goodsRcvdDate: goodsRcvdDate,
            invoiceNo: invoiceNo,
            invoiceAmt: invoiceAmt,
            supplier: supplier
        }

        if (data.purchaseReturnType === '') {
            setPurchaseReturnTypeError('PurchaseReturn Type required')
            error = true
        }
        // else if ((data.purchaseType).length > 15) {
        //     setPurchaseTypeError('PurchaseType should be within 30 characters')
        //     error = true
        // }

        if (data.supplier?.value === "") {
            setSupplierError('Supplier required')
            error = true
        }

        if (data.invoiceNo === '') {
            setInvoiceNoError('Invoice No required')
            error = true
        }

        if (data.invoiceAmt === '') {
            setInvoiceAmtError('Invoice Amount required')
            error = true
        }
        // else if ((data.invoiceNo) > 15) {
        //     setInvoiceNoError('Invoice No should be within 15 digits')
        //     error = true
        // }

        if (data.invoiceDate === '') {
            setInvoiceDateError('Invoice date required')
            error = true
        } else if ((data.invoiceDate).length > 10) {
            setInvoiceDateError('Invoice date should be within 10 digits')
            error = true
        }

        if (data.goodsRcvdDate === '') {
            setGoodsRcvdDateError('Goods Received Date required')
            error = true
        } else if ((data.goodsRcvdDate)?.length > 10) {
            setGoodsRcvdDateError('Goods Received Date should be within 10 digits')
            error = true
        }

        if (data.purchaseReturnEntryNo === '') {
            setPurchaseReturnEntryNoError('Purchase Entry No required')
            error = true
        } else if ((data.purchaseReturnEntryNo).length > 10) {
            setPurchaseReturnEntryNoError('Purchase Entry No should be within 10 digits')
            error = true
        }

        if (!error) {
            // console.log(authUser);

            const sobj = AccountLists.find(a => a.accountNumber?.toString() === supplier?.value)
            let sname = sobj?.accountName
            let sadd = sobj?.address1! + sobj?.address2
            let scont = sobj?.contactNumber1
            let saadhar = sobj?.aadharNumber
            let sgst = sobj?.gstNumber
            let splace = sobj?.supplierPlace

            let bobj = branchList.find(b => b.id?.toString() === authUser?.branchId?.toString())
            // console.log(bobj);
            let bname = bobj?.name

            props.setInvDate(invoiceDate)
            props.setGudsRecdDate(goodsRcvdDate)
            props.setInvNo(invoiceNo)
            props.setInvoiceTitle('WHOLESALE')
            props.setInvoiceType(purchaseReturnType)

            props.setSupName(sname ? sname : '')
            props.setSupContact(scont ? scont.toString() : '')
            props.setSupAddress(sadd ? sadd : '')
            props.setSupAadhar(saadhar ? saadhar.toString() : '')
            props.setGST(sgst ? sgst : '')

            props.setBilledBy(authUser?.name ? authUser?.name : '')
            props.setSysNo('SYS 1')
            props.setSupPlace(splace ? splace : '')
            props.setRefBy(refBy)
            props.setBranch(bname ? bname : '')
            props.setInvoiceAmount(invoiceAmt)
            props.setPurchaseReturnEntryNo(purchaseReturnEntryNo)
            props.setSupplier(supplier?.value ? supplier?.value : '')

            props.onSave()
        }
        // if (!error) {
        //     if (formType === FormTypeList.ADD) {
        //         dispatch(addBrand(data)).then(text => {
        //             toast(text, ToastTypes.SUCCESS)
        //             onSave()
        //         }).catch(text => {
        //             toast(text, ToastTypes.ERROR)
        //         })
        //     } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
        //         dispatch(editBrand(data, editData.id)).then(text => {
        //             toast(text, ToastTypes.SUCCESS)
        //             onSave()
        //         }).catch(text => {
        //             toast(text, ToastTypes.ERROR)
        //         })
        //     }
        // }
    }

    useEffect(() => {
        if ((props.formType === FormTypeList.EDIT) && props !== undefined) {
            let sobj = supplierList.find(s => s.accountNumber?.toString() === props.supplier?.toString())

            setPurchaseReturnType(props.invoiceType)
            setPurchaseReturnEntryNo(props.purchaseReturnEntryNo)
            setGoodsRcvdDate(props.gudsRecdDate)
            setInvoiceDate(props.invoiceDate)
            setSupplier({
                label: sobj?.accountName ? sobj?.accountName : '',
                value: sobj?.accountNumber ? sobj?.accountNumber.toString() : ''
            })
            setInvoiceNo(props.invoiceNo)
            setRefBy(props.refBy)
            setInvoiceAmt(props.invoiceAmount)
        } else if (props.formType === FormTypeList.ADD && props !== undefined && props.invoiceType !== '') {
            let sobj = supplierList.find(s => s.accountNumber?.toString() === props.supplier?.toString())

            setPurchaseReturnType(props.invoiceType)
            setPurchaseReturnEntryNo(props.purchaseReturnEntryNo)
            setGoodsRcvdDate(props.gudsRecdDate)
            setInvoiceDate(props.invoiceDate)
            setSupplier({
                label: sobj?.accountName ? sobj?.accountName : '',
                value: sobj?.accountNumber ? sobj?.accountNumber.toString() : ''
            })
            setInvoiceNo(props.invoiceNo)
            setRefBy(props.refBy)
            setInvoiceAmt(props.invoiceAmount)
        }

    }, [props.formType])

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: '25px',
            height: '25px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 5px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    return <div className='container' style={{ backgroundColor: '#B1D7E9' }}>
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-8'>
                <FormInput
                    name='Purchase Return Type'
                    label='Purchase Return Type'
                    labelClassName="required"
                    value={purchaseReturnType}
                    onChange={handlePurchaseType}
                    placeholder='Purchase Return Type'
                    errorText={purchaseReturnTypeError}
                    containerClass="mb-2"
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {PURCHASE_TYPE_DROPS.map((a, i) => {
                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Invoice Date'
                    label='Invoice Date'
                    labelClassName="required"
                    value={invoiceDate}
                    onChange={handleInvoiceDate}
                    placeholder='Invoice Date'
                    errorText={invoiceDateError}
                    containerClass="mb-2"
                    type="date"
                // disabled={showViewForm}
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-8'>
                <FormInput
                    name='Referred By'
                    label='Referred By'
                    labelClassName="required"
                    value={refBy}
                    onChange={handleRefBy}
                    placeholder='Referred By'
                    errorText={refByError}
                    containerClass="mb-2"
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {AccountLists.filter((al) => al.groupName === 'representer' || al.groupName === 'Representer').map((al, i) => {
                        return <option value={al.accountNumber} key={i}>{al.accountName}</option>
                    })
                    }
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Goods Received Date'
                    label='Goods Received Date'
                    labelClassName="required"
                    value={goodsRcvdDate}
                    onChange={handleGoodsRcvdDate}
                    placeholder='Goods Received Date'
                    errorText={goodsRcvdDateError}
                    containerClass="mb-2"
                    type="date"
                // disabled={showViewForm}
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-8 mb-1'>
                <label className="" style={{ marginBottom: '0.2rem' }}>Supplier</label>
                <Select
                    options={supplierList.map(f => {
                        return {
                            label: f?.accountName ? f?.accountName : '',
                            value: f?.accountNumber?.toString() || ''
                        }
                    })}
                    isSearchable={true}
                    isClearable={true}
                    onChange={v => setSupplier(v)}
                    value={supplier}
                    styles={customStyles}
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 d-none'>
                <FormInput
                    name='Purchase Return Entry No'
                    label='Purchase Return Entry No'
                    labelClassName="required"
                    value={purchaseReturnEntryNo}
                    onChange={handlePurchaseEntryNo}
                    placeholder='PurchaseReturn Entry No'
                    errorText={purchaseReturnEntryNoError}
                    containerClass="mb-2"
                // disabled={showViewForm}
                />
            </div>


            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Invoice No'
                    label='Invoice No'
                    labelClassName="required"
                    value={invoiceNo}
                    onChange={handleInvoiceNo}
                    placeholder='Invoice No'
                    errorText={invoiceNoError}
                    containerClass="mb-2"
                // disabled={showViewForm}
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Invoice Grand Total'
                    label='Invoice Grand Total'
                    labelClassName="required"
                    value={invoiceAmt}
                    onChange={handleInvoiceAmt}
                    placeholder='Invoice Grand Total'
                    errorText={invoiceAmtError}
                    containerClass="mb-2"
                    type="number"
                // disabled={showViewForm}
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Supplier'
                    label='Supplier'
                    labelClassName="required"
                    value={supplierSearchTerm}
                    onChange={handleSupplier}
                    placeholder='Supplier'
                    errorText={supplierError}
                    containerClass="mb-2"
                    // disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {filteredSuppliers.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="form-control border-0">
                    <label className="pb-2">Brand Logo</label>
                    <div className="border rounded border-2 border-secondray logo-lg pt-0 pb-1 m-0 w-75">
                        <img src={LogoDark} alt="" height="22" />
                    </div>
                    <div className="hstack gap-1 mt-1">
                        <button className="btn btn-secondary">Browser</button>
                        <button className="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div> */}
        </div>

        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-secondary" onClick={() => resetStates()}>Add New</button>
            <button className="btn btn-secondary" onClick={handleSave}>Save</button>
        </div>
    </div >
}
export default PurchaseReturnForm