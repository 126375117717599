import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { onlyUnique } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { PurchaseType, fetchPurchaseByDateAction } from "../redux/actions/purchaseActions"
import { AppDispatch, StoreState, } from "../redux/store"


interface TableBody {
    sno: number
    financeYear: string
    invoiceType: string
    invoiceTitle: string
    invoiceDate: string
    goodsReceivedDate: string
    invoiceNumber: number
    purchaseEntryNumber: number
    systemNo: string
    repId: number
    supplierId: number | string
    supplierName: string
    billAmount: string | undefined
    disPercent: string | undefined
    disAmount: string | undefined
    grandTotal: string | undefined
    amtPaid: string | undefined
    paymentMode: string | undefined
    deliveryMode: string | undefined
    deliveryDetails: string | undefined
    netTaxable: string | undefined
    netIgst: string | undefined
    netSgst: string | undefined
    netCgst: string | undefined
    netProfit: string | undefined
    netProfitPercent: string | undefined
    totalAmount?: number
}



const PurchaseReport = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const PurchaseList = useSelector<StoreState, PurchaseType[]>(state => state.purchase)
    const AccountList = useSelector<StoreState, AccountHolderType[]>(state => state.account)

    // console.log(PurchaseList);

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [editData, setEditData] = useState<DropDownType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()


    const [fromDate, setFromDate] = useState<string>('')
    const [fromDateError, setFromDateError] = useState<string>('')

    const [toDate, setToDate] = useState<string>('')
    const [toDateError, setToDateError] = useState<string>('')

    const [supplier, setSupplier] = useState<string>('')
    const [supplierError, setSupplierError] = useState<string>('')

    const [paymentMode, setPaymentMode] = useState<string>('')
    const [paymentModeError, setPaymentModeError] = useState<string>('')

    const [purchaseReport, setPurchaseReport] = useState<PurchaseType[]>(PurchaseList)

    const [gtotal, setGTotal] = useState<number>(0)

    console.log(purchaseReport);

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }

    const handleSupplier: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSupplier(e.target.value)
        setSupplierError('')
    }

    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value)
        setPaymentModeError('')
    }


    const pdfGenerate = () => {
        // Create a new jsPDF instance
        // const doc = new jsPDF();

        // // Define the table headers and data
        const headers: any = [["S.No", "Finance Year", "Invoice Type", "Invoice Title", "Invoice Date", "Goods Received Date", "Invoice Number", "Purchase Entry Number", "System No", "RepId", "Supplier Id", "Bill Amount", "Discount Percent", "Discount Amount", "Grand Total", "Amount Paid", "Payment Mode", "Delivery Mode", "Delivery Details", "Net Taxable", "Net IGST", "Net SGST", "Net CGST", "Net Profit", "Net Profit Percent"]];
        const data: any = PurchaseList.map((pl, i) => {
            return [i + 1, pl.financeYear, pl.invoiceType, pl.invoiceTitle, pl.invoiceDate, pl.goodsReceivedDate, pl.invoiceNumber, pl.purchaseEntryNumber, pl.systemNo, pl.repId, pl.supplierId, pl.billAmount, pl.disPercent, pl.disAmount, pl.grandTotal, pl.amtPaid, pl.paymentMode, pl.deliveryMode, pl.deliveryDetails, pl.netTaxable, pl.netIgst, pl.netSgst, pl.netCgst, pl.netProfit, pl.netProfitPercent]
        })
        const foot: any = [['', 'Grand total', gtotal, '']];
        // Define the table headers and data
        // const headers = ["S.No", "Finance Year", "Invoice Type","Invoice Type","Invoice Type","Invoice Type","Invoice Type"];
        // const data = [
        //   ["John Doe", "30", "USA","USA","USA","30","30"]
        // ];
        const title = "Purchase Report"
        const doc = new jsPDF('l', 'mm', [460, 210])
        doc.setFontSize(18)
        doc.text(title, 180, 13)
        autoTable(doc, {
            head: headers,
            body: data,
            foot: foot,
            startY: 20,
            didDrawPage: function () {
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 360, 10);
            }
        })
        doc.save(title + ".pdf")
    }
    // useEffect(() => {
    //     dispatch(fetchAccountHolderAction())
    // }, [dispatch])

    useEffect(() => {
        dispatch(fetchPurchaseByDateAction(fromDate, toDate))
    }, [dispatch, fromDate, toDate])

    useEffect(() => {
        if (supplier) {
            const filterLists = PurchaseList.filter((pl) => pl.supplierId.toString() === supplier.toString())
            setPurchaseReport(filterLists)
        } else if (paymentMode) {
            const paymentList = PurchaseList.filter((pm) => pm.paymentMode === paymentMode)
            setPurchaseReport(paymentList)
        } else {
            setPurchaseReport(PurchaseList)
        }
    }, [supplier, paymentMode, PurchaseList])

    const supplierList = AccountList.filter((al: AccountHolderType) => al.groupName === "Supplier" || al.groupName === "supplier")

    useEffect(() => {

        if (purchaseReport) {
            const gtot = purchaseReport.reduce((previous, current) => {
                return previous + (current.grandTotal ? parseFloat(current.grandTotal) : 0)
            }, 0)
            setGTotal(gtot)
        }
    }, [purchaseReport])

    // const supplierObj = supplierList.find(s => s.accountNumber?.toString() === pl.supplierId?.toString())

    // const sname = supplierObj?.accountName
    //  PurchaseList.map(pl => pl.supplierId === supplierNumber.filter((sn) => sn.accountNumber))

    // console.log(supplierObj);

    return <>
        <UserLayout>
            <h4>Purchase Report</h4>
            <div className="row mb-2">
                <div className="col-3">
                    <FormInput
                        name='fromDate'
                        label='From Date'
                        labelClassName="required"
                        value={fromDate}
                        className="mt-2"
                        onChange={handleFromDate}
                        // errorText={tdateError}
                        type="date"
                    />
                </div>
                <div className="col-3">
                    <FormInput
                        name='toDate'
                        label='To Date'
                        labelClassName="required"
                        value={toDate}
                        className="mt-2"
                        onChange={handleToDate}
                        // errorText={tdateError}
                        type="date"
                    />
                </div>

                <div className="col-3">
                    <FormInput
                        name='supplier'
                        label='Supplier'
                        labelClassName="required mb-2"
                        value={supplier}
                        className="my-4"
                        onChange={handleSupplier}
                        // errorText={tdateError}
                        type="select"
                    >
                        <option value="">All</option>
                        {
                            supplierList.filter(onlyUnique).map((sl) => {
                                return <option value={sl.accountNumber}>{sl.accountName}</option>
                            })
                        }
                    </FormInput>
                </div>

                <div className="col-3">
                    <FormInput
                        name='paymentMode'
                        label='Payment Mode'
                        labelClassName="required mb-2"
                        value={paymentMode}
                        className="fs-5 mx-2"
                        onChange={handlePaymentMode}
                        type="select"
                    >
                        <option value="">Select</option>
                        <option value={"Cash"}>Cash</option>
                        <option value={"Bank"}>Bank</option>
                        <option value={"Credit"}>Credit</option>
                    </FormInput>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <div className="card p-2 w-100">
                        <div className='table-wrapper'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Finance Year</th>
                                        <th className='text-truncate align-middle'>Invoice Type</th>
                                        <th className='text-truncate align-middle'>Invoice Title</th>
                                        <th className='text-truncate align-middle'>Invoice Date</th>
                                        <th className='text-truncate align-middle'>Good Received Date</th>
                                        <th className='text-truncate align-middle'>Invoice Number</th>
                                        <th className='text-truncate align-middle'>Purchase Entry Number</th>
                                        <th className='text-truncate align-middle'>Sytem No</th>
                                        <th className='text-truncate align-middle'>Rep Id</th>
                                        <th className='text-truncate align-middle'>Supplier Id</th>
                                        <th className='text-truncate align-middle'>Bill Amount</th>
                                        <th className='text-truncate align-middle'>Discount Percent</th>
                                        <th className='text-truncate align-middle'>Discount Amount</th>
                                        <th className='text-truncate align-middle'>Grand Total</th>
                                        <th className='text-truncate align-middle'>Amount Paid</th>
                                        <th className='text-truncate align-middle'>Payment Mode</th>
                                        <th className='text-truncate align-middle'>Delivery Mode</th>
                                        <th className='text-truncate align-middle'>Delivery Details</th>
                                        <th className='text-truncate align-middle'>Net Taxable</th>
                                        <th className='text-truncate align-middle'>Net IGST</th>
                                        <th className='text-truncate align-middle'>Net SGST</th>
                                        <th className='text-truncate align-middle'>Net CGST</th>
                                        <th className='text-truncate align-middle'>Net Profit</th>
                                        <th className='text-truncate align-middle'>Net Profit Percent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        purchaseReport.map((pl, i) => {
                                            // console.log(pl);
                                            const supplierObj = supplierList.find(s => s.accountNumber?.toString() === pl.supplierId?.toString())
                                            let sname = supplierObj?.accountName

                                            return <PurchaseReportBody
                                                sno={i + 1}
                                                financeYear={pl.financeYear}
                                                invoiceType={pl.invoiceType}
                                                invoiceTitle={pl.invoiceTitle}
                                                invoiceDate={pl.invoiceDate}
                                                goodsReceivedDate={pl.goodsReceivedDate}
                                                invoiceNumber={pl.invoiceNumber}
                                                purchaseEntryNumber={pl.purchaseEntryNumber}
                                                systemNo={pl.systemNo}
                                                repId={pl.repId}
                                                supplierId={pl.supplierId}
                                                supplierName={sname ? sname : ''}
                                                billAmount={pl.billAmount}
                                                disPercent={pl.disPercent}
                                                disAmount={pl.disAmount}
                                                grandTotal={pl.grandTotal}
                                                amtPaid={pl.amtPaid}
                                                paymentMode={pl.paymentMode}
                                                deliveryMode={pl.deliveryMode}
                                                deliveryDetails={pl.deliveryDetails}
                                                netTaxable={pl.netTaxable}
                                                netIgst={pl.netIgst}
                                                netSgst={pl.netSgst}
                                                netCgst={pl.netCgst}
                                                netProfit={pl.netProfit}
                                                netProfitPercent={pl.netProfitPercent}
                                                totalAmount={gtotal}
                                            />
                                        })
                                    }

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="fs-4 fw-bold text-center">Grand Total :</td>
                                        <td className="fs-4 fw-bold">{gtotal}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>

                        <div className="text-end mt-1 mx-4">
                            <button className="btn btn-primary justify-content-end" onClick={pdfGenerate}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout >
    </>

}
const PurchaseReportBody = ({ sno, financeYear, supplierName, invoiceType, invoiceTitle, invoiceDate, goodsReceivedDate, invoiceNumber, purchaseEntryNumber, systemNo, repId, supplierId, billAmount, disPercent, disAmount, grandTotal, amtPaid, paymentMode, deliveryMode, deliveryDetails, netTaxable, netIgst, netSgst, netCgst, netProfit, netProfitPercent, totalAmount }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle text-truncate'>{financeYear}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceType}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceTitle}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceDate}</td>
        <td className='text-capitalize align-middle'>{goodsReceivedDate}</td>
        <td className='text-capitalize align-middle'>{invoiceNumber}</td>
        <td className='text-capitalize align-middle'>{purchaseEntryNumber}</td>
        <td className='text-capitalize align-middle'>{systemNo}</td>
        <td className='text-capitalize align-middle'>{repId}</td>
        <td className='text-capitalize align-middle'>{supplierName}</td>
        <td className='text-capitalize align-middle'>{billAmount}</td>
        <td className='text-capitalize align-middle'>{disPercent}</td>
        <td className='text-capitalize align-middle'>{disAmount}</td>
        <td className='text-capitalize align-middle'>{grandTotal}</td>
        <td className='text-capitalize align-middle'>{amtPaid}</td>
        <td className='text-capitalize align-middle'>{paymentMode}</td>
        <td className='text-capitalize align-middle'>{deliveryMode}</td>
        <td className='text-capitalize align-middle'>{deliveryDetails}</td>
        <td className='text-capitalize align-middle'>{netTaxable}</td>
        <td className='text-capitalize align-middle'>{netIgst}</td>
        <td className='text-capitalize align-middle'>{netSgst}</td>
        <td className='text-capitalize align-middle'>{netCgst}</td>
        <td className='text-capitalize align-middle'>{netProfit}</td>
        <td className='text-capitalize align-middle'>{netProfitPercent}</td>
    </tr>
}

export default PurchaseReport

