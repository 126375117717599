import { SalesReturnItemActionList, SalesReturnItems } from "../actions/salesReturnItemAction";
import { Action } from "../actions/types";

const initialValue: Array<SalesReturnItems> = []

export const salesReturnItemReducer = (state: SalesReturnItems[] = initialValue, action: Action) => {

    switch (action.type) {
        case SalesReturnItemActionList.FETCH_SALES_RETURN_ITEM:
            return action.data
        default:
            return state
    }
}