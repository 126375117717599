import { useDispatch } from "react-redux"
import { AppDispatch } from "../redux/store"
import { useToast } from "./toast/ToastProvider"


interface Props {
    id?: number
    value?: string
    onDelete: () => void
    onClose: () => void
    // onDelete: () => void
}


const DeleteConfirmationForm = ({ id, value, onClose, onDelete }: Props) => {

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const conformLabel = () => {
        if (value)
            return <div className="col-lg-12 col-md-8 col-sm-8 my-1">
                <label className="fs-4 text-black">Delete not allowed! </label><br />
                <label className="fs-4 text-black">As {value} - already used in transaction </label>
            </div>
        else
            return <div className="col-lg-12 col-md-8 col-sm-8 my-1">
                <label className="fs-4 text-black">Do You Really Want To Delete this record ?</label>
            </div>
    }

    return <>
        <div className="container">
            <div className="row m-0">
                {
                    conformLabel()
                }

                <div className="hstack gap-1 d-flex justify-content-end ">
                    <button className="btn btn-sm fw-bold btn-danger" onClick={onDelete} disabled={value ? true : false}>Delete</button>
                    <button className="btn btn-sm fw-bold btn-primary" onClick={onClose}>Cancel</button>

                </div>
            </div>
        </div>
    </>
}
export default DeleteConfirmationForm