import { PurchaseActionList, PurchaseType } from "../actions/purchaseActions"
import { Action } from "../actions/types"

const initialValue: Array<PurchaseType> = []

const purchaseReducer = (state: Array<PurchaseType> = initialValue, action: Action) => {
    switch (action.type) {

        case PurchaseActionList.ADD_PURCHASE:
            return [
                ...state,
                action.data,
            ]

        case PurchaseActionList.UPDATE_PURCHASE:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case PurchaseActionList.FETCH_PURCHASE:
            return action.data

        case PurchaseActionList.DELETE_PURCHASE:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default purchaseReducer