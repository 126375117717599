import axios from 'axios'
import { apiUrl } from '.'
import { store } from '../redux/store'

export interface APIResponse<D = undefined> {
    data?: D
    message?: string
    error?: string
}


export const api = () => {
    return axios.create({
        baseURL: apiUrl,
        headers: {
            Authorization: store.getState().authUser?.['auth-token'] || ''
        }
    })
}

// const api = axios.create({ baseURL: apiUrl })

// export const getAxiosRequestConfig = (token: string): AxiosRequestConfig => {
//     return {
//         headers: {
//             Authorization: token
//         }
//     }
// }

export const errorHandler = (error: any, errorText?: string) => {
    const defaultText = 'Unable to process request'
    if (error.code && error.code === 'ERR_NETWORK') {
        return String(error.message
            ? error.message
            : errorText ?
                errorText
                : defaultText)
    } else if (error.response) {
        return String(error.response.data
            ? error.response.data.message
            : errorText ?
                errorText
                : defaultText)
    } else {
        return String(error.message
            ? error.message
            : errorText ?
                errorText
                : defaultText)
    }
}

export default api