import React, { ChangeEventHandler, useEffect, useState } from "react"
import { Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { AppDispatch, StoreState } from "../redux/store"
import { SalesType, fetchSalesByDateAction } from "../redux/actions/salesActions"
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { CustomerType, fetchCustomerAction } from "../redux/actions/customerAction"
import { onlyUnique } from "../config/functions"

interface TableBody {
    sno?: number
    financeYear: string
    invoiceType: string
    invoiceTitle: string
    invoiceDate: string
    invoiceDay?: string
    invoiceWeekNumber?: number
    invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    packNo: string
    salesEntryNumber: number
    systemNo: string
    repId?: number
    customerId: number
    customerName: string | undefined
    paymentMode: string
    billAmount?: string
    disPercent?: string
    disAmount?: string
    add?: string
    less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    deliveryMode?: string
    deliveryDetails?: string
    tenderAmt?: string
    tenderReturn?: string
    netTaxable?: string
    netIgst?: string
    netSgst?: string
    netCgst?: string
    netProfit?: string
    netProfitPercent?: string
    AValue?: string
    ATaxable?: string
    BValue?: string
    BTaxable?: string
    CValue?: string
    CTaxable?: string
    DValue?: string
    DTaxable?: string
    EValue?: string
    ETaxable?: string
    FValue?: string
    FTaxable?: string
    GValue?: string
    GTaxable?: string
    totalAmount?:number
}



const SalesReport = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const SalesList = useSelector<StoreState, SalesType[]>(state => state.sales)
    const CustomerList = useSelector<StoreState, CustomerType[]>(state => state.customer)

    // console.log(CustomerList);

    const [fromDate, setFromDate] = useState<string>('')
    const [fromDateError, setFromDateError] = useState<string>('')

    const [toDate, setToDate] = useState<string>('')
    const [toDateError, setToDateError] = useState<string>('')

    const [customer, setCustomer] = useState<string>('')
    const [customerError, setCustomerError] = useState<string>('')

    const [paymentMode, setPaymentMode] = useState<string>('')
    const [paymentModeError, setPaymentModeError] = useState<string>('')

    const [salesReport, setSalesReport] = useState<SalesType[]>(SalesList)

    const [gtotal, setGTotal] = useState<number>(0)


    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }

    const handleCustomer: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCustomer(e.target.value)
        setCustomerError('')
    }

    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value)
        setPaymentModeError('')
    }

    const generatePdf = () => {

        const headers: any = [["S.No", "Finance Year", "Invoice Type", "Invoice Title", "Invoice Date", "Invoice Number", "Pack No", "Sales Entry Number", "System No", "RepId", "Customer Id", "Bill Amount", "Discount Percent", "Discount Amount", "Grand Total", "Amount Paid", "Payment Mode", "Delivery Mode", "Delivery Details", "Net Taxable", "Net IGST", "Net SGST", "Net CGST", "Net Profit", "Net Profit Percent"]];
        const data: any = SalesList.map((sl, i) => {
            return [i + 1, sl.financeYear, sl.invoiceType, sl.invoiceTitle, sl.invoiceDate, sl.invoiceNumber, sl.packNo, sl.salesEntryNumber, sl.systemNo, sl.repId, sl.customerId, sl.billAmount, sl.disPercent, sl.disAmount, sl.grandTotal, sl.amtPaid, sl.paymentMode, sl.deliveryMode, sl.deliveryDetails, sl.netTaxable, sl.netIgst, sl.netSgst, sl.netCgst, sl.netProfit, sl.netProfitPercent]
        })
        const foot: any = [['', 'Grand Total', gtotal, '']];
        // Define the table headers and data
        // const headers = ["S.No", "Finance Year", "Invoice Type","Invoice Type","Invoice Type","Invoice Type","Invoice Type"];
        // const data = [
        //   ["John Doe", "30", "USA","USA","USA","30","30"]
        // ];
        const title = "Sales Report"
        const doc = new jsPDF('l', 'mm', [460, 210])
        doc.setFontSize(18)
        doc.text(title, 180, 13)
        autoTable(doc, {
            head: headers,
            body: data,
            foot:foot,
            startY: 20,
            didDrawPage: function () {
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 360, 10);
            }
        })
        doc.save(title + ".pdf")
    }

    useEffect(() => {
        dispatch(fetchCustomerAction())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchSalesByDateAction(fromDate, toDate))
    }, [dispatch, fromDate, toDate])

    useEffect(() => {
        if (customer) {
            const filterLists = SalesList.filter((sl) => sl.customerId.toString() === customer.toString())
            setSalesReport(filterLists)
        }else if(paymentMode){
            const filterLists = SalesList.filter((sl) => sl.paymentMode===paymentMode)
            setSalesReport(filterLists)
        } else {
            setSalesReport(SalesList)
        }
    }, [customer,paymentMode, SalesList])

    useEffect(() => {

        if (salesReport) {
            const gtot = salesReport.reduce((previous, current) => {
                return previous + (current.grandTotal ? parseFloat(current.grandTotal) : 0)
            }, 0)
            setGTotal(gtot)
        }
    }, [salesReport])

    return <>
        <UserLayout>
            <h4>Sales Report</h4>
            <div className="row mb-2">
                <div className="col-3">
                    <FormInput
                        name='fromDate'
                        label='From Date'
                        labelClassName="required"
                        value={fromDate}
                        className="mt-2 fs-5"
                        onChange={handleFromDate}
                        errorText={fromDateError}
                        type="date"
                    />
                </div>
                <div className="col-3">
                    <FormInput
                        name='toDate'
                        label='To Date'
                        labelClassName="required"
                        value={toDate}
                        className="mt-2 fs-5"
                        onChange={handleToDate}
                        errorText={toDateError}
                        type="date"
                    />
                </div>
                <div className="col-3">
                    <FormInput
                        name='customer'
                        label='Customer'
                        labelClassName="required mb-2"
                        value={customer}
                        className="mt-2 fs-5"
                        onChange={handleCustomer}
                        errorText={customerError}
                        type="select"
                    >
                        <option value="">All</option>
                        {
                            CustomerList.filter(onlyUnique).map((cl) => {
                                return <option value={cl.accountNumber}>{cl.accountName}</option>
                            })
                        }
                    </FormInput>
                </div>
                <div className="col-3">
                    <FormInput
                        name='paymentMode'
                        label='Payment Mode'
                        labelClassName="required mb-2"
                        value={paymentMode}
                        className="mt-2 fs-5"
                        onChange={handlePaymentMode}
                        errorText={paymentModeError}
                        type="select"
                    >
                        <option value="">Select</option>
                        <option value={"Cash"}>Cash</option>
                        <option value={"Bank"}>Bank</option>
                        <option value={"Credit"}>Credit</option>

                    </FormInput>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card p-2 w-100">
                        <div className='table-wrapper'>
                            {/* <FormInput
                                onChange={searchTermChangeHandler}
                                name=""
                                placeholder='Search...'
                                value={searchTerm}
                                onFocus={() => {
                                    mainContentRef?.current?.scrollTo({
                                        behavior: 'smooth',
                                        top: 270
                                    })
                                }}
                                innerRef={searchRef}
                                className="container my-2"
                            /> */}

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Finance Year</th>
                                        <th className='text-truncate align-middle'>Invoice Type</th>
                                        <th className='text-truncate align-middle'>Invoice Title</th>
                                        <th className='text-truncate align-middle'>Invoice Date</th>
                                        <th className='text-truncate align-middle'>Invoice Number</th>
                                        <th className='text-truncate align-middle'>Pack No</th>
                                        <th className='text-truncate align-middle'>Sales Entry Number</th>
                                        <th className='text-truncate align-middle'>Sytem No</th>
                                        <th className='text-truncate align-middle'>Rep Id</th>
                                        <th className='text-truncate align-middle'>Customer</th>
                                        <th className='text-truncate align-middle'>Bill Amount</th>
                                        <th className='text-truncate align-middle'>Discount Percent</th>
                                        <th className='text-truncate align-middle'>Discount Amount</th>
                                        <th className='text-truncate align-middle'>Grand Total</th>
                                        <th className='text-truncate align-middle'>Particulars</th>
                                        <th className='text-truncate align-middle'>Amount Paid</th>
                                        <th className='text-truncate align-middle'>Payment Mode</th>
                                        <th className='text-truncate align-middle'>Delivery Mode</th>
                                        <th className='text-truncate align-middle'>Delivery Details</th>
                                        <th className='text-truncate align-middle'>Tender Amount</th>
                                        <th className='text-truncate align-middle'>Tender Return</th>
                                        <th className='text-truncate align-middle'>Net Taxable</th>
                                        <th className='text-truncate align-middle'>Net IGST</th>
                                        <th className='text-truncate align-middle'>Net SGST</th>
                                        <th className='text-truncate align-middle'>Net CGST</th>
                                        <th className='text-truncate align-middle'>Net Profit</th>
                                        <th className='text-truncate align-middle'>Net Profit Percent</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        salesReport.map((sl, i) => {

                                            const custNo = CustomerList.filter((cl) => cl.accountNumber === sl.customerId).map((cn) => {
                                                return (cn.accountName)
                                            })

                                            // console.log(custNo);

                                            return <SalesReportBody
                                                sno={i + 1}
                                                financeYear={sl.financeYear}
                                                invoiceType={sl.invoiceType}
                                                invoiceTitle={sl.invoiceTitle}
                                                invoiceDate={sl.invoiceDate}
                                                invoiceNumber={sl.invoiceNumber}
                                                packNo={sl.packNo}
                                                salesEntryNumber={sl.salesEntryNumber}
                                                systemNo={sl.systemNo}
                                                repId={sl.repId}
                                                customerId={sl.customerId}
                                                customerName={custNo.toString()}
                                                billAmount={sl.billAmount}
                                                disPercent={sl.disPercent}
                                                disAmount={sl.disAmount}
                                                grandTotal={sl.grandTotal}
                                                particulars={sl.particulars}
                                                amtPaid={sl.amtPaid}
                                                paymentMode={sl.paymentMode}
                                                deliveryMode={sl.deliveryMode}
                                                deliveryDetails={sl.deliveryDetails}
                                                tenderAmt={sl.tenderAmt}
                                                tenderReturn={sl.tenderReturn}
                                                netTaxable={sl.netTaxable}
                                                netIgst={sl.netIgst}
                                                netSgst={sl.netSgst}
                                                netCgst={sl.netCgst}
                                                netProfit={sl.netProfit}
                                                netProfitPercent={sl.netProfitPercent}
                                                totalAmount={gtotal }
                                            />
                                        })
                                    }

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="fs-4 fw-bold text-center">Grand Total :</td>
                                        <td className="fs-4 fw-bold">{gtotal}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className="text-end mt-1">
                            <button className="btn btn-primary" onClick={generatePdf}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    </>

}
const SalesReportBody = ({ sno, financeYear, customerName, invoiceType, invoiceTitle, invoiceDate, invoiceNumber, packNo, salesEntryNumber, systemNo, repId, customerId, billAmount, disPercent, disAmount, grandTotal, particulars, amtPaid, paymentMode, deliveryMode, deliveryDetails, tenderAmt, tenderReturn, netTaxable, netIgst, netSgst, netCgst, netProfit, netProfitPercent,totalAmount }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle text-truncate'>{financeYear}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceType}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceTitle}</td>
        <td className='text-capitalize align-middle text-truncate'>{invoiceDate}</td>
        <td className='text-capitalize align-middle'>{invoiceNumber}</td>
        <td className='text-capitalize align-middle'>{packNo}</td>
        <td className='text-capitalize align-middle'>{salesEntryNumber}</td>
        <td className='text-capitalize align-middle'>{systemNo}</td>
        <td className='text-capitalize align-middle'>{repId}</td>
        <td className='text-capitalize align-middle'>{customerName}</td>
        <td className='text-capitalize align-middle'>{billAmount}</td>
        <td className='text-capitalize align-middle'>{disPercent}</td>
        <td className='text-capitalize align-middle'>{disAmount}</td>
        <td className='text-capitalize align-middle'>{grandTotal}</td>
        <td className='text-capitalize align-middle'>{particulars}</td>
        <td className='text-capitalize align-middle'>{amtPaid}</td>
        <td className='text-capitalize align-middle'>{paymentMode}</td>
        <td className='text-capitalize align-middle'>{deliveryMode}</td>
        <td className='text-capitalize align-middle'>{deliveryDetails}</td>
        <td className='text-capitalize align-middle'>{tenderAmt}</td>
        <td className='text-capitalize align-middle'>{tenderReturn}</td>
        <td className='text-capitalize align-middle'>{netTaxable}</td>
        <td className='text-capitalize align-middle'>{netIgst}</td>
        <td className='text-capitalize align-middle'>{netSgst}</td>
        <td className='text-capitalize align-middle'>{netCgst}</td>
        <td className='text-capitalize align-middle'>{netProfit}</td>
        <td className='text-capitalize align-middle'>{netProfitPercent}</td>
    </tr>
}

export default SalesReport

