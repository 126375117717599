import { Dispatch } from "redux"
import { toCamelize } from "../../config/functions"
import { addTransaction, deleteTransaction, editTransaction, getTransaction } from "../../services/transaction.service"
import { GetState } from "../store"

export interface TransactionType {
    id?: number,
    financialYear: string,
    date: string,
    branch: string,
    paymentMode: string,
    voucherType: string,
    bankName: string,
    bankNumber: number,
    refNo: number,
    source?: string,
    sourceId?: number,
    number?: string,
    accountGroupName: string,
    accountName: string,
    accountNumber: number,
    particulars: string,
    amount: string,
    debitAmount?: number | undefined,
    creditAmount?: number | undefined
}

export enum TransactionActionsList {
    ADD_TRANSACTION = 'ADD_TRANSACTION',
    EDIT_TRANSACTION = 'EDIT_TRANSACTION',
    FETCH_TRANSACTION = 'FETCH_TRANSACTION',
    DELETE_TRANSACTION = 'DELETE_TRANSACTION'
}


export interface AddTransactionAction {
    type: TransactionActionsList.ADD_TRANSACTION
    data: TransactionType
}

export interface EditTransactionAction {
    type: TransactionActionsList.EDIT_TRANSACTION
    data: TransactionType
    id: number | undefined
}

export interface FetchTransactionAction {
    type: TransactionActionsList.FETCH_TRANSACTION
    data: TransactionType[]

}

export interface DeleteTransactionAction {
    type: TransactionActionsList.DELETE_TRANSACTION
    data: number | undefined
}

export type TransactionActions = AddTransactionAction | EditTransactionAction | FetchTransactionAction | DeleteTransactionAction

export const addTransactionAction = (data: TransactionType) => {
    return async (dispatch: Dispatch) => {
        return addTransaction(data).then(response => {
            if (response.status === 200 && response.data.data) {
                const addData = response.data.data
                dispatch<AddTransactionAction>({
                    type: TransactionActionsList.ADD_TRANSACTION,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
}

export const editTransactionAction = (data: TransactionType, number: number) => {
    return async (dispatch: Dispatch) => {
        return editTransaction(data, number).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = toCamelize<TransactionType>(response.data.data)
                dispatch<EditTransactionAction>({
                    type: TransactionActionsList.EDIT_TRANSACTION,
                    data: response.data.data,
                    id: number
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTransactionAction = (date: string) => {
    return async (dispatch: Dispatch) => {
        const response = await getTransaction(date)

        dispatch<FetchTransactionAction>({
            type: TransactionActionsList.FETCH_TRANSACTION,
            data: response.data.data
        })
    }
}



export const deleteTransactionAction = (number: number | undefined) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return deleteTransaction(number).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTransactionAction>({
                    type: TransactionActionsList.DELETE_TRANSACTION,
                    data: number
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })


    }
}