import { useDispatch } from "react-redux"
// import { UsersType, deleteUsers } from "../redux/actions/usersActions"
import { PurchaseReturnType, deletePurchaseReturnAction } from "../redux/actions/purchaseReturnAction"
import { AppDispatch } from "../redux/store"
import { ToastTypes, useToast } from "./toast/ToastProvider"


interface Props {
    editData?: PurchaseReturnType
    onClose: () => void
    // onDelete: () => void
}

// type HandleDelete = (value: TransactionType) => void

const DeleteConfirmPurchaseReturn = ({ editData, onClose }: Props) => {

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const handleDeleteYes = () => {
        if (editData !== undefined && editData.id !== undefined) {

            dispatch(deletePurchaseReturnAction(editData.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                onClose()
            }).catch(text => {

            })
        }
    }

    return <>
        <div className="container">
            <div className="row m-0">
                <div className="col-lg-12 col-md-8 col-sm-8 my-1">
                    <label className="fs-4 text-black">Do You Really Want To Delete this record ?</label>
                </div>
                <div className="hstack gap-1 d-flex justify-content-end ">
                    <button className="btn btn-sm fw-bold btn-danger" onClick={handleDeleteYes}>Delete</button>
                    <button className="btn btn-sm fw-bold btn-primary" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    </>
}
export default DeleteConfirmPurchaseReturn