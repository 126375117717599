import { AccountActionsList, AccountHolderType } from "../actions/accountHolder";
import { Action } from "../actions/types";

const initialValue: Array<AccountHolderType> = []

export const accountReducer = (state: AccountHolderType[] = initialValue, action: Action) => {

    switch (action.type) {

        case AccountActionsList.ADD_ACCOUNTHOLDER:
            console.log(action.data);

            return [...state, action.data]


        case AccountActionsList.EDIT_ACCOUNTHOLDER: {
            console.log(action.data);
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.accountNumber === action.data.accountNumber
            })
            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }
            return updated
        }

        case AccountActionsList.FETCH_ACCOUNTHOLDER:
            return action.data



        case AccountActionsList.DELETE_ACCOUNTHOLDER: {
            console.log(action.data);

            return state.filter(i => i.accountNumber !== action.data)

        }

        default: return state
    }

}