import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { showLeftSideBar } from '../config';
import { LayoutTypes } from '../constants';
import { changeBodyAttribute } from '../utils';
import UserTopNavbar from './UserTopNavbar';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const UserLeftSidebar = React.lazy(() => import('./UserLeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

interface UserLayoutProps {
    children?: any;
}

const UserLayout = ({ children }: UserLayoutProps) => {

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

    /*
    layout defaults
    */
    useEffect(() => {
        changeBodyAttribute('data-layout-mode', LayoutTypes.LAYOUT_VERTICAL);
    }, []);

    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        setIsMenuOpened((prevState) => !prevState);

        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };

    return (
        <>
            <div id="wrapper">
                <Suspense fallback={loading()}>
                    <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} hideFilter={false} />
                    <UserTopNavbar />
                </Suspense>
                {!showLeftSideBar && <Suspense fallback={loading()}>
                    <UserLeftSidebar />
                </Suspense>}
                <div className={`content-page ${!showLeftSideBar ? 'mx-0 container-fluid mx-auto' : 'mx-0 container-fluid mx-auto'}`}>
                    <div className="content mt-5" >
                        <Container fluid mx-auto>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div >
        </>
    );
};
export default UserLayout;
