import React, { ChangeEventHandler, useEffect, useState } from "react"
import { Edit, Trash2 } from 'react-feather'
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { AttributeType, addAttribute, deleteAttribute, editAttribute, getAttribute } from '../services/attribute.service'
import { AttributeItemType, addAttributeItem, deleteAttributeItem, editAttributeItem, getAttributeItem } from '../services/attributeItem.service'

const Attribute = () => {
    const toast = useToast()
    const [attributeList, setAttributeList] = useState<AttributeType[]>([]);
    const [attributeItemList, setAttributeItemList] = useState<AttributeItemType[]>([]);

    const fetchData = async () => {
        const attList = await getAttribute();
        setAttributeList(attList);
        const attItemList = await getAttributeItem();
        setAttributeItemList(attItemList);
    }
    useEffect(() => {
        fetchData()
    }, [])

    const [id, setId] = useState<number | null>(null);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    let errorFlag: boolean = false;


    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }


    const deleteHandler = (id: number) => {
        setId(id);
        setShowDeleteForm(true);
    }

    const editHandler = (attributeItem: AttributeItemType) => {
        setId(attributeItem?.id ? attributeItem.id : null)
        setName(attributeItem?.name);
    }
    const deleteConnfirmHandler = () => {
        if (id) {

            deleteAttribute(id).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setId(null)
                fetchData()
            }).catch(text => {
            })
        }
    }

    const clearAll = () => {
        setId(null);
        setName('');
        setNameError('');
    }
    const saveHandler = () => {

        errorFlag = false;
        if (name === '') {
            setNameError('name is Required')
            errorFlag = true;
        }

        const addData: AttributeType = {
            // id: id,
            name: name
        }

        const editData: AttributeType = {
            id: id,
            name: name
        }
        if (id && !errorFlag) {
            //update
            editAttribute(editData, id).then(text => {
                toast('Updated', ToastTypes.SUCCESS)
                clearAll()
                fetchData()
            }).catch(text => {

            })

        }
        else if (!errorFlag) {
            //add
            addAttribute(addData).then(text => {
                toast('Added', ToastTypes.SUCCESS)
                clearAll()
                fetchData()
            }).catch(text => {
            })

        }
    }

    return <>
        <Modal
            headerText={'Delete Manufacture'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={deleteConnfirmHandler}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Attribute Master</h4>
            <div className="container">
                <div className="row">

                    <div className="col-9">
                        <div className="hstack gap-2">
                            <label htmlFor="" className="mb-2">Name</label>
                            <FormInput
                                name='attributeName'
                                // label='Attribute'
                                labelClassName="required"
                                value={name}
                                onChange={handleName}
                                errorText={nameError}
                                containerClass="mb-2"
                            />


                            <>
                                < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => clearAll()}>Add New</button>
                                <button className="btn btn-sm fw-bold btn-success mb-2" onClick={saveHandler}>{id ? "Update" : "Add"}</button>
                            </>


                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
            <div className="row">

                <div className="col-9">

                    {attributeList.map((attribute, i) => {
                        const filteredItemList = attributeItemList.filter(item => item.attributeId === attribute.id);
                        return (
                            <AttributeItem fetchData={fetchData} attribute={attribute} attributeItemList={filteredItemList} editAttributeHandler={editHandler} deleteAttributeHandler={deleteHandler} />
                        )

                    })}

                </div>
            </div>
        </UserLayout>
    </>
}

export default Attribute
interface AttributeItemProp {
    fetchData: any
    attribute: AttributeType
    attributeItemList: AttributeItemType[]
    editAttributeHandler: any
    deleteAttributeHandler: any
}
const AttributeItem: React.FC<AttributeItemProp> = (props: AttributeItemProp) => {
    const attribute = props.attribute;
    const attributeItemList = props.attributeItemList;
    const editAttributeHandler = props.editAttributeHandler;
    const deleteAttributeHandler = props.deleteAttributeHandler;
    const [id, setId] = useState<number | null>(null);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    let errorFlag: boolean = false;
    const toast = useToast()

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }
    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const deleteHandler = (id: number) => {
        setId(id);
        setShowDeleteForm(true);
    }

    const editHandler = (attributeItem: AttributeItemType) => {
        setId(attributeItem?.id ? attributeItem.id : null)
        setCode(attributeItem?.code);
        setName(attributeItem?.name);
    }
    const deleteConnfirmHandler = () => {
        if (id) {
            // toast('Deleted', ToastTypes.SUCCESS)
            // setShowDeleteForm(false)
            deleteAttributeItem(id).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setId(null)
                props.fetchData()
            }).catch(text => {
            })
        }
    }

    const clearAll = () => {
        setId(null);
        setCode('');
        setName('');
        setCodeError('');
        setNameError('');
    }

    const saveHandler = () => {

        errorFlag = false;
        if (name === '') {
            setNameError('name is Required')
            errorFlag = true;
        }
        else if (code === '') {
            setNameError('code is Required')
            errorFlag = true;
        }


        const addAtt: AttributeItemType = {
            attributeId: attribute.id,
            name: name,
            code: code
        }

        const editAtt: AttributeItemType = {
            id: id,
            attributeId: attribute.id,
            name: name,
            code: code
        }



        if (id && !errorFlag) {
            //update
            editAttributeItem(editAtt, id).then(text => {
                toast('Updated', ToastTypes.SUCCESS)
                clearAll()
                props.fetchData()
            }).catch(text => {
            })

        }
        else if (!errorFlag) {
            //add
            addAttributeItem(addAtt).then(text => {
                toast('Added', ToastTypes.SUCCESS)
                clearAll()
                props.fetchData()
            }).catch(text => {
            })
        }
    }
    return (
        <>
            <Modal
                headerText={'Delete Attribute Item'}
                visible={showDeleteForm}
                onClose={handleDeleteFormClose}
                centered
                size='lg'
            >
                <DeleteConfirmationForm
                    onDelete={deleteConnfirmHandler}
                    onClose={handleDeleteFormClose}
                />
            </Modal>
            <div className="card">
                <div className="card-body">
                    {/* */}
                    <div className='hstack justify-content-between '>
                        <h5 className="card-title">{attribute.name}</h5>
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm fw-bold btn-success" onClick={() => editAttributeHandler(attribute)}><Edit /></button>
                            <button type="button" className="btn btn-sm fw-bold btn-danger" onClick={() => deleteAttributeHandler(attribute?.id || null)}><Trash2 /></button>
                        </div>
                    </div>
                    <hr />
                    <div className='hstack  gap-2'>

                        <label htmlFor="" className="mb-2 ms-2">Code</label>
                        <FormInput
                            name='code'
                            labelClassName="required"
                            value={code}
                            onChange={handleCode}
                            errorText={codeError}
                            containerClass="mb-2"
                        />

                        <label htmlFor="" className="mb-2">Name</label>
                        <FormInput
                            name='Name'
                            labelClassName="required"
                            value={name}
                            onChange={handleName}
                            errorText={nameError}
                            containerClass="mb-2"
                        />


                        <button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => clearAll()}>Add New</button>
                        <button className="btn btn-sm fw-bold btn-success mb-2" onClick={saveHandler}>{id ? "Update" : "Add"}</button>

                    </div>

                    <div className='hstack  gap-2'>
                        {
                            attributeItemList.map((attributeItem, i) => {
                                return (
                                    <div className="btn-group m-2">
                                        <div className="input-group-text" id="btnGroupAddon2">
                                            {attributeItem.code}-{attributeItem.name}
                                        </div>
                                        <button type="button" className="btn btn-sm fw-bold btn-success" onClick={() => editHandler(attributeItem)}><Edit /></button>
                                        <button type="button" className="btn btn-sm fw-bold btn-danger" onClick={() => deleteHandler(attributeItem?.id || 0)}><Trash2 /></button>
                                    </div>


                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}


