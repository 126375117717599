import { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import BarChart from "../components/custom/components/charts/apex/BarChart"
import DonutChart from "../components/custom/components/charts/apex/DonutChart"
import api, { APIResponse } from "../config/api"
import { CategoryType, fetchCategoryAction } from "../redux/actions/categoryAction"
import { PurchasePaymentModeTypes } from "../redux/actions/purchaseActions"
import { AppDispatch, StoreState } from "../redux/store"


export interface ReportPurchaseReturnItemType {
    id?: number
    purchaseReturnId?: number
    productId: number
    productCode: string
    categoryId: number
    categoryName: string
    totalQuantity: string
    mrp: number
    totalAmount: string
    profitAmount: string
}

interface YearlyPurchaseReturnData {
    id?: number
    // financeYear: string
    // branchId: number
    // invoiceType: string
    // invoiceTitle: string
    invoiceDate: string
    // invoiceDay?: string
    // invoiceWeekNumber?: number
    // invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    // packNo: string
    purchaseReturnEntryNumber: number
    systemNo: string
    // repId?: number
    customerId: number
    // customerName: string
    // pointsEarned?: string
    // bankName: string
    // bankNumber: number
    paymentMode: PurchasePaymentModeTypes
    billAmount?: string
    // disPercent?: string
    // disAmount?: string
    // add?: string
    // less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    purchaseReturnItems: ReportPurchaseReturnItemType[]
}

const PurchaseReturnYearlyReport = () => {
    const dispatch = useDispatch<AppDispatch>()
    const d = new Date()
    const [fromYear, setFromYear] = useState<Date>(new Date(d))
    // const [fromYearError, setFromYearError] = useState<string>('')
    const [toYear, setToYear] = useState<Date>(new Date())
    // const [toYearError, setToYearError] = useState<string>('')

    const categories = useSelector<StoreState, CategoryType[]>((state) => state.category)

    const [YearwisePurchaseReturnData, setYearwisePurchaseReturnData] = useState<YearlyPurchaseReturnData[]>([])
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

    const CategorywiseFromYearPurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = YearwisePurchaseReturnData?.filter(y => y.invoiceYear?.toString() === fromYear?.getFullYear()?.toString())?.map(m => m.purchaseReturnItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [YearwisePurchaseReturnData, fromYear, categories])

    const CategorywiseToYearPurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = YearwisePurchaseReturnData?.filter(y => y.invoiceYear?.toString() === toYear?.getFullYear()?.toString())?.map(m => m.purchaseReturnItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString())?.reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        console.log(data);

        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [YearwisePurchaseReturnData, toYear, categories])

    useEffect(() => {

        if (fromYear !== undefined && toYear !== undefined) {
            api().get<APIResponse<YearlyPurchaseReturnData[]>>(`purchaseReturn/dashboard/?fromYear=${fromYear?.getFullYear()?.toString()}&toYear=${toYear?.getFullYear()?.toString()}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    console.log(result);
                    setYearwisePurchaseReturnData(result)
                }
            }).catch((error) => { })
        } else {
            setYearwisePurchaseReturnData([])
        }
    }, [fromYear, toYear])

    useEffect(() => {
        dispatch(fetchCategoryAction)
    }, [])

    console.log(CategorywiseToYearPurchase);
    console.log(CategorywiseFromYearPurchase);

    // console.log([{ name: fromYear?.getFullYear()?.toString(), data: [FromYearPurchase] }, { name: toYear?.getFullYear()?.toString(), data: [ToYearPurchase] }])
    return <>
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Yearly Purchase</h4>
                        </div>
                        <div className="col-lg-4 hstack gap-3 text-end">
                            <div className="col-lg-3 ms-5">
                                <span className="dateInput">
                                    {/* <span><Feather.Calendar size={14} /></span> */}
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={fromYear}
                                        onChange={(date: any) => setFromYear(date)}
                                        showYearPicker
                                        calendarClassName=""
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                                {/* <FormInput
                                name='fromYear'
                                label='From Year'
                                labelClassName="required"
                                value={fromYear}
                                className="mt-2 fs-5"
                                onChange={handleFromYear}
                                errorText={fromYearError}
                                type="date"
                            /> */}
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={toYear}
                                        onChange={(date: any) => setToYear(date)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">

                            <BarChart
                                // datasets={[
                                //     { name: fromYear?.getFullYear()?.toString(), data: [FromYearPurchase, ToYearPurchase] },
                                //     // { name: toYear?.getFullYear()?.toString(), data: [ToYearPurchase] }
                                // ]}
                                datasets={(() => {
                                    let fyearData: { name: string, data: number[] }[] = []
                                    let years = [fromYear, toYear]
                                    years.forEach(year => {

                                        const fdata = YearwisePurchaseReturnData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString())
                                        console.log(fdata);
                                        let data: number[] = []
                                        months.forEach(m => {

                                            const val = fdata?.filter(f => f.invoiceMonth?.toString() === m)?.reduce((p, c) => {
                                                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
                                            }, 0)
                                            data.push(val)
                                        })
                                        fyearData.push({
                                            name: year?.getFullYear()?.toString(),
                                            data: data
                                        })
                                    })

                                    return fyearData
                                })()}
                                labels={months}
                                // labels={[fromYear?.getFullYear()?.toString(), toYear?.getFullYear()?.toString()]}
                                colors={['#00B9E3', '#00E396']}
                                // colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title=""
                            />
                        </div>

                        <div className="col-lg-3">

                            {/* <PieChart
                                datasets={(() => {
                                    let ydata: { name: string, data: [] } = []

                                    return ydata
                                })()}
                                labels={[]}
                                colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title="" /> */}
                            <DonutChart
                                // datasets={[24, 45, 45, 56, 88]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseFromYearPurchase?.map(f => f.purchase)}
                                labels={CategorywiseFromYearPurchase?.map(n => n.cname)}
                                colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#DC2AAC']}
                                title={fromYear?.getFullYear()?.toString()}
                            />
                        </div>
                        <div className="col-lg-3">
                            <DonutChart
                                // datasets={[65, 75, 72, 21, 89]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseToYearPurchase?.map(f => f.purchase)}
                                labels={CategorywiseToYearPurchase?.map(n => n.cname)}
                                colors={['#FC89AC', '#7ED957', '#FF914D', '#00B9E3', '#E192FF', '#8CA5F7']}
                                title={toYear?.getFullYear()?.toString()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >

    </>
}
export default PurchaseReturnYearlyReport