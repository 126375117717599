import { StockMasterType, StockMasterActionsList } from "../actions/stockMasterAction";
import { Action } from "../actions/types";

const initialValue: Array<StockMasterType> = []

export const stockMasterReducer = (state: StockMasterType[] = initialValue, action: Action) => {

    switch (action.type) { 
        case StockMasterActionsList.FETCH_STOCKMASTER:
            return action.data
        default :
            return state
    }
}