import { ChangeEventHandler, useEffect, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import Select from 'react-select'
import Modal from "../components/Modal"
import Option from "../components/Option"
import useOverlayLoader from "../components/OverlayLoader/useOverlayLoader"
import { Dropdown } from "../components/PurchaseForm"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import api from "../config/api"
import { convertAmtToWords, getDate, rupeeFormat, today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { BranchType, fetchBranchAction } from "../redux/actions/branchAction"
import { CustomerType, addCustomerAction, fetchCustomerAction } from "../redux/actions/customerAction"
import { DropDownType, addDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { ProductType } from "../redux/actions/productAction"
// import { SalesPaymentModeTypes, SalesPaymentModesDropDown, SalesType, addSalesAction, fetchSalesAction, updateSalesAction } from "../redux/actions/salesActions"
// import { StockMasterType, fetchStockMasterAction } from "../redux/actions/stockMasterAction"
import { SalesPaymentModeTypes, SalesPaymentModesDropDown, SalesReturnType, addSalesReturnAction, fetchSalesReturnAction, updateSalesReturnAction } from "../redux/actions/salesReturnAction"
import { StockMasterType, fetchStockMasterAction } from "../redux/actions/stockMasterAction"
import { UsersType } from "../redux/actions/usersActions"
import { AppDispatch, StoreState } from "../redux/store"
import { getSalesReturnById } from "../services/salesReturn.service"
import { getTransactionCurrentBalanceByAcNo } from "../services/transaction.service"
import SalesReturn from "./SalesReturn"

interface SalesReturnItem {
    id: number
    productCode: string
    productGroupCode: string
    productName: Dropdown | null
    gstPercent?: string
    qty: string
    mrp?: string
    rate?: string
    disAmt?: string
    gstValue?: string
    taxable?: string
    totalAmt?: string
    hsn?: string
    batchNo?: string
    expiryDt?: Date | null
    pwog?: string
    pwg?: string
    // sRate1?: string
    // sRate2?: string
    // sRate3?: string
    // pdRs?: string
    // cdPercent?: string
    // cdRs?: string
    igst?: string
    cgst?: string
    sgst?: string
    // netAmt?: string
    // unitProfit?: string
    // totalProfit?: string
    profitPercent?: string
}

const SALSE_PAYMENT_MODES = [
    {
        text: 'CASH',
        value: 'Cash'
    },
    {
        text: 'CREDIT',
        value: 'Credit'
    },
    {
        text: 'BANK',
        value: 'Bank'
    }
]
const SALES_TYPE_DROPS = [
    {
        text: 'SALES TAX INVOICE',
        value: 'SALES TAX INVOICE'
    },
    {
        text: 'SALES ESTIMATE',
        value: 'SALES ESTIMATE'
    },
]
const getDefaultSalesItems = () => {
    const salesReturnItems: SalesReturnItem[] = []

    for (let i = 0; i < 100; i++) {
        salesReturnItems.push({
            id: i + 1,
            productName: null,
            productGroupCode: '',
            productCode: '',
            batchNo: '',
            expiryDt: null,
            gstPercent: '',
            qty: '',
            mrp: '0',
            rate: '0',
            disAmt: '0',
            gstValue: '0',
            taxable: '0',
            totalAmt: '0',
            hsn: '',
            igst: '',
            cgst: '',
            sgst: '',
            pwg: '',
            pwog: '',
            profitPercent: '0'

        })

    }
    return salesReturnItems
}

export interface SalesReturnItemEditAttributes {
    id: number,
    sales_return_id: number
    entry_number: number
    product_id: number
    product_code: string
    product_group_code: string
    batch_number: number
    expiry_date: string
    quantity: number
    free_quantity: number
    total_quantity: number
    price_wogst: number
    price_gst: number
    hsn: string
    w_rate: number
    r_rate: number
    l_rate: number
    mrp: number
    gst_value: number
    s_discount: number
    p_discount_percent: number
    p_discount_amount: number
    c_discount_percent: number
    c_discount_amount: number
    taxable_amount: number
    igst_amount: number
    cgst_amount: number
    sgst_amount: number
    total_amount: number
    unit_cost: number
    profit_percent: number
    profit_amount: number
}

interface SalesReturnEditAttributes {
    id: number
    finance_year: string
    branch_id: number
    invoice_type: string
    invoice_title: string
    invoice_hour?: string
    invoice_date: string
    invoice_day: number
    invoice_week_number: number
    invoice_week_day: number
    invoice_month: number
    invoice_year: number
    invoice_number: number
    sales_return_entry_number: number
    system_no: string
    rep_id: number
    customer_id: number
    bill_amount: string
    dis_percent: string
    dis_amount: string
    add: string
    less: string
    grand_total: string
    amt_in_words: string
    particulars: string
    amt_paid: string
    payment_mode: string
    delivery_mode: string
    delivery_details: string
    remarks: string
    tender_return: number
    tender_amount: number
    net_taxable: string
    net_igst: string
    net_sgst: string
    net_cgst: string
    net_profit: string
    net_profit_percent: string
    A_value: number
    A_taxable: number
    B_value: number
    B_taxable: number
    C_value: number
    C_taxable: number
    D_value: number
    D_taxable: number
    E_value: number
    E_taxable: number
    F_value: number
    F_taxable: number
    G_value: number
    G_taxable: number
    createdAt?: string
    created_by: number
    salesReturnItems: SalesReturnItemEditAttributes[]
    Transactions?: any[]
}

export interface CDropdown {
    label: string
    name: string
    value: number
    score: string
    conNo: string
}

const SalesReturnMaster = () => {
    const toast = useToast()
    const [setShowOverlayLoader, setHideOverlayLoader] = useOverlayLoader()
    const dispatch = useDispatch<AppDispatch>()
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const StockList = useSelector<StoreState, StockMasterType[]>(state => state.stockMaster)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    const userList = useSelector<StoreState, UsersType[]>(state => state.user)
    const BranchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const DropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    const CustomerList = useSelector<StoreState, CustomerType[]>(state => state.customer)
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)

    useEffect(() => {
        dispatch(fetchBranchAction())
        dispatch(fetchDropDownAction())
        dispatch(fetchCustomerAction())
        dispatch(fetchStockMasterAction())
    }, [])

    const [financialYear, setFinancialYear] = useState<string>('')
    // const [financialYearError, setFinancialYearError] = useState<string>('')
    const [billNo, setBillNo] = useState<string>()
    // const [billNoError, setBillNoError] = useState<string>('')
    const [billTime, setBillTime] = useState<string>('')
    // const [billTimeError, setBillTimeError] = useState<string>('')
    const [gudsRecdDate, setGudsRecdDate] = useState<string>('')
    const [billDate, setBillDate] = useState<string>(today())
    // const [billDateError, setBillDateError] = useState<string>('')
    const [customerNo, setCustomerNo] = useState<string>('2')
    const [invoiceType, setInvoiceType] = useState<string>(SALES_TYPE_DROPS[0].value)
    const [invoiceTypeError, setInvoiceTypeError] = useState<string>('')
    const [invoiceTitle, setInvoiceTitle] = useState<string>('RETAIL')
    // const [invoiceTitleError, setInvoiceTitleError] = useState<string>('')

    const [invoiceAmount, setInvoiceAmount] = useState<string>('')
    const [salesReturnEntryNo, setSalesReturnEntryNo] = useState<string>((Math.floor(Math.random() * 60000) + 10000).toString())
    const [customer, setCustomer] = useState<CDropdown | null>(null)

    const [customerName, setCustomerName] = useState<string>('')
    const [customerNameError, setCustomerNameError] = useState<string>('')
    const [customerAddress, setCustomerAddress] = useState<string>('')
    const [customerAddressError, setCustomerAddressError] = useState<string>('')
    const [customerContact, setCustomerContact] = useState<string>('')
    const [customerContactError, setCustomerContactError] = useState<string>('')
    const [gst, setGST] = useState<string>('')
    const [gstError, setGSTError] = useState<string>('')

    const [refBy, setRefBy] = useState<string>('0')
    // const [refByError, setRefByError] = useState<string>('')
    const [customerPlace, setCustomerPlace] = useState<string>('')
    // const [customerPlaceError, setCustomerPlaceError] = useState<string>('')
    const [branch, setBranch] = useState<string>('')
    const [branchError, setBranchError] = useState<string>('')
    const [billedBy, setBilledBy] = useState<string>(AuthUser?.name ? AuthUser.name : '')
    // const [billedByError, setBilledByError] = useState<string>('')
    const [sysNo, setSysNo] = useState<string>(window.location.hostname)
    // const [sysNoError, setSysNoError] = useState<string>('')

    const [productName, setProductName] = useState<string>('')
    const [productNameError, setProductNameError] = useState<string>('')

    const [salesReturnItems, setSalesReturnItems] = useState<SalesReturnItem[]>(getDefaultSalesItems())
    // const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    // const [showSalesForm, setShowSalesForm] = useState<boolean>(false)
    const [showSalesView, setShowSalesView] = useState<boolean>(false)

    const [billAmount, setBillAmount] = useState<string>('')
    const [billAmountError, setBillAmountError] = useState<string>('')
    const [disPercent, setDisPercent] = useState<string>('')
    const [disPercentError, setDisPercentError] = useState<string>('')
    const [disAmt, setDisAmt] = useState<string>('0')
    const [disAmtError, setDisAmtError] = useState<string>('')
    const [totAmt, setTotAmt] = useState<string>('')
    const [totAmtError, setTotAmtError] = useState<string>('')
    const [roundOff, setRoundOff] = useState<string>('')
    const [roundOffError, setRoundOffError] = useState<string>('')
    const [billNetAmt, setBillNetAmt] = useState<string>('')
    const [billNetAmtError, setBillNetAmtError] = useState<string>('')
    const [add, setAdd] = useState<string>('0')
    const [addError, setAddError] = useState<string>('')
    const [less, setLess] = useState<string>('0')
    const [lessError, setLessError] = useState<string>('')
    const [gtotal, setGTotal] = useState<string>('')
    const [gtotalError, setGTotalError] = useState<string>('')
    const [amtInWords, setAmtInWords] = useState<string>('')
    const [amtInWordsError, setAmtInWordsError] = useState<string>('')
    const [paymentMode, setPaymentMode] = useState<SalesPaymentModeTypes>(SalesPaymentModesDropDown.CASH)
    const [paymentModeError, setPaymentModeError] = useState<string>('')
    const [paymentNo, setPaymentNo] = useState<string>('')
    const [paymentNoError, setPaymentNoError] = useState<string>('')
    const [amtPaid, setAmtPaid] = useState<string>('')
    const [amtPaidError, setAmtPaidError] = useState<string>('')
    const [balance, setBalance] = useState<string>('')
    const [balanceError, setBalanceError] = useState<string>('')
    const [deliveryMode, setDeliveryMode] = useState<string>('')
    // const [deliveryModeError, setDeliveryModeError] = useState<string>('')
    const [deliveryDetails, setDeliveryDetails] = useState<string>('')
    // const [deliveryDetailsError, setDeliveryDetailsError] = useState<string>('')
    const [currentBal, setCurrentBal] = useState<string>('0')
    const [currentBalError, setCurrentBalError] = useState<string>('')
    const [previousBal, setPreviousBal] = useState<string>('0')
    const [previousBalError, setPreviousBalError] = useState<string>('')
    const [overAllBal, setOverAllBal] = useState<string>('0')
    const [overAllBalError, setOverAllBalError] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    const [bankNumber, setBankNumber] = useState<string>('')

    const [totalItems, setTotalItems] = useState<string>('')
    const [totalItemsError, setTotalItemsError] = useState<string>('')
    const [totalQty, setTotalQty] = useState<string>('')
    const [totalQtyError, setTotalQtyError] = useState<string>('')
    const [packNo, setPackNo] = useState<string>('')
    const [packNoError, setPackNoError] = useState<string>('')
    const [yourSavings, setYourSavings] = useState<string>('')
    const [yourSavingsError, setYourSavingsError] = useState<string>('')
    const [netIgst, setNetIgst] = useState<string>('0')
    const [netIgstError, setNetIgstError] = useState<string>('')
    const [netSgst, setNetSgst] = useState<string>('')
    const [netSgstError, setNetSgstError] = useState<string>('')
    const [netCgst, setNetCgst] = useState<string>('')
    const [netCgstError, setNetCgstError] = useState<string>('')
    const [netTaxable, setNetTaxable] = useState<string>('')
    const [netTaxableError, setNetTaxableError] = useState<string>('')
    const [netGSTValue, setNetGSTValue] = useState<string>('')
    const [netGSTValueError, setNetGSTValueError] = useState<string>('')

    const [tenderedAmt, setTenderedAmt] = useState<string>('')
    const [tenderedAmtError, setTenderedAmtError] = useState<string>('')
    const [tenderedReturn, setTenderedReturn] = useState<string>('')
    const [tenderedReturnError, setTenderedReturnError] = useState<string>('')
    const [tenderedBal, setTenderedBal] = useState<string>('')
    const [tenderedBalError, setTenderedBalError] = useState<string>('')

    const [finalAmount, setFinalAmount] = useState<string>('0')
    const [totalProfit, setTotalProfit] = useState<string>('0')

    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [id, setId] = useState<number>()

    const [modal, setModal] = useState(false)

    const [branchId, setBranchId] = useState<string>('')
    const [branchIdError, setBranchIdError] = useState<string>('')
    const [nameTag, setNameTag] = useState<string>('')
    const [nameTagError, setNameTagError] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')
    const [accountNameError, setAccountNameError] = useState<string>('')
    const [address1, setAddress1] = useState<string>('')
    const [address1Error, setAddress1Error] = useState<string>('')
    const [address2, setAddress2] = useState<string>('')
    const [address2Error, setAddress2Error] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const [areaError, setAreaError] = useState<string>('')
    const [place, setPlace] = useState<string>('')
    const [placeError, setPlaceError] = useState<string>('')
    const [district, setDistrict] = useState<string>('')
    const [districtError, setDistrictError] = useState<string>('')
    const [pincode, setPincode] = useState<string | undefined>('')
    const [pincodeError, setPincodeError] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [stateError, setStateError] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [countryError, setCountryError] = useState<string>('')
    const [route, setRoute] = useState<string>('')
    const [routeError, setRouteError] = useState<string>('')
    const [contactNumber1, setContactNumber1] = useState<string | undefined>('')
    const [contactNumber1Error, setContactNumber1Error] = useState<string>('')
    const [contactNumber2, setContactNumber2] = useState<string | undefined>('')
    const [contactNumber2Error, setContactNumber2Error] = useState<string>('')
    const [aadharNumber, setAadharNumber] = useState<string | undefined>('')
    const [aadharNumberError, setAadharNumberError] = useState<string>('')

    const [hiddenGst, setHiddenGst] = useState<boolean>(true)

    const [areaName, setAreaName] = useState<string>('')
    const [areaModal, setAreaModal] = useState(false)

    const [placeName, setPlaceName] = useState<string>('')
    const [placeModal, setPlaceModal] = useState(false)

    const [routeName, setRouteName] = useState<string>('')
    const [routeModal, setRouteModal] = useState(false)

    const [districtName, setDistrictName] = useState<string>('')
    const [districtModal, setDistrictModal] = useState(false)

    const [stateName, setStateName] = useState<string>('')
    const [stateModal, setStateModal] = useState(false)

    const [countryName, setCountryName] = useState<string>('')
    const [countryModal, setCountryModal] = useState(false)


    const [previousPoints, setPreviousPoints] = useState<number>(0)
    const [pointsEarned, setPointsEarned] = useState<number>(Math.ceil(parseInt(finalAmount) / 100))
    const [totalpoints, setTotalPoints] = useState<number>(0)
    const [remarks, setRemarks] = useState<string>('')
    const [remarksError, setRemarksError] = useState<string>('')

    const resetStates = () => {
        setRemarks('')
        setRemarksError('')
        setFormType(FormTypeList.ADD)
        setSalesReturnItems(getDefaultSalesItems())
        setPreviousPoints(0)
        setPointsEarned(0)
        setTotalPoints(0)
        // setPackNo()
        setInvoiceType(SALES_TYPE_DROPS[0].value)
        setTenderedAmt('')
        setTenderedReturn('')
        setTenderedBal('')
        //top
        setGudsRecdDate('')
        setBillNo('')
        setBillTime('')
        setBillDate(today())
        setCustomerNo('')
        setInvoiceAmount('')
        setSalesReturnEntryNo('')
        setCustomer(null)
        setCustomerName('')
        setCustomerAddress('')
        setCustomerContact('')
        setGST('')
        setRefBy('')
        setCustomerPlace('')
        setBranch('')
        setBilledBy('')
        setSysNo(window.location.hostname)
        //
        setBillAmount('')
        setBillAmountError('')
        setDisPercent('')
        setDisPercentError('')
        setDisAmt('')
        setDisAmtError('')
        setTotAmt('')
        setTotAmtError('')
        setRoundOff('')
        setRoundOffError('')
        setBillNetAmt('')
        setBillNetAmtError('')
        setAdd('')
        setAddError('')
        setLess('')
        setLessError('')
        setGTotal('')
        setGTotalError('')
        setAmtInWords('')
        setAmtInWordsError('')
        setPaymentMode(SalesPaymentModesDropDown.CASH)
        setPaymentModeError('')
        setPaymentNo('')
        setPaymentNoError('')
        setAmtPaid('')
        setAmtPaidError('')
        setCurrentBal('')
        setCurrentBalError('')
        setPreviousBal('')
        setPreviousBalError('')
        setOverAllBal('')
        setOverAllBalError('')
        setBankName('')
        setBankNumber('')
        setTenderedAmt('')
        setTenderedAmtError('')
        setTenderedBal('')
        setTenderedBalError('')
        setTotalItems('')
        setTotalItemsError('')
        setTotalQty('')
        setTotalQtyError('')
        setFinalAmount('0')
        setBranchId('')
        setNameTag('')
        setAccountName('')
        setContactNumber1('')
        setContactNumber2('')
        setAddress1('')
        setAddress2('')
        setArea('')
        setPlace('')
        setDistrict('')
        setState('')
        setCountry('')
        setRoute('')
        setAadharNumber('')
        setAreaName('')
        setPlaceName('')
        setRouteName('')
        setDistrictName('')
        setStateName('')
        setCountryName('')
    }

    const handleInvoiceType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceType(e.target.value)
        setInvoiceTypeError('')
    }


    const handleProductName = (val: Dropdown | null, id: string) => {
        console.log(val);
        console.log(id);
        // const prodObj = ProductList.find(pd => pd.id?.toString() === val?.value?.toString()
        const stockObj = StockList.find(pd => pd.id?.toString() === val?.value?.toString()
        )

        const values: string[] | undefined = val?.label.split("-") || [''];


        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        const existSalesItem = updated.findIndex(pi => pi.productName?.value === val?.value)
        if (existSalesItem > -1 && val !== null) {
            let qty = parseInt(updated[existSalesItem]?.qty) + 1;
            updated[existSalesItem].qty = qty.toString();
            let gval = (((qty ? parseFloat(qty.toString()) : 0) * (updated[existSalesItem]?.rate ? parseFloat(updated[existSalesItem]?.rate!) : 0)) - (updated[existSalesItem].disAmt ? parseFloat(updated[existSalesItem]?.disAmt!) : 0)) * ((updated[existSalesItem].gstPercent ? parseFloat(updated[existSalesItem]?.gstPercent!) : 0) / (100 + (updated[existSalesItem].gstPercent ? parseFloat(updated[existSalesItem]?.gstPercent!) : 0)))
            updated[existSalesItem].gstValue = gval?.toFixed(2)

            //TAXABLE((qty*rate) - disc) - GST value
            let tax = (((qty ? parseFloat(qty.toString()) : 0) * (updated[existSalesItem].rate ? parseFloat(updated[existSalesItem].rate!) : 0)) - (updated[existSalesItem].disAmt ? parseFloat(updated[existSalesItem].disAmt!) : 0)) - gval
            updated[existSalesItem].taxable = tax?.toFixed(2)

            ///TOTAL gst val+tax
            let tot = gval + tax
            updated[existSalesItem].totalAmt = tot?.toFixed(2)
            setSalesReturnItems(updated)
            return
        }

        if (ind > -1) {
            console.log(stockObj);

            updated[ind].productName = val
            updated[ind].productCode = stockObj?.productCode ? stockObj?.productCode.toString() : ''
            // updated[ind].hsn = stockObj?.sac ? stockObj?.sac.toString() : ''
            updated[ind].productGroupCode = stockObj?.productGroupCode ? stockObj?.productGroupCode.toString() : ''
            updated[ind].gstPercent = stockObj?.gst ? stockObj?.gst.toString() : ''
            updated[ind].qty = '1'
            updated[ind].mrp = stockObj?.mrp ? stockObj?.mrp.toString() : ''
            updated[ind].rate = stockObj?.lRate ? stockObj?.lRate.toString() : ''
            updated[ind].hsn = stockObj?.sac ? stockObj?.sac.toString() : ''

            updated[ind].batchNo = stockObj?.batchNumber ? stockObj?.batchNumber.toString() : ''
            updated[ind].expiryDt = stockObj?.expiryDate ? new Date(stockObj?.expiryDate) : null
            updated[ind].igst = stockObj?.igstAmount ? stockObj?.igstAmount.toString() : ''
            updated[ind].cgst = stockObj?.cgstAmount ? stockObj?.cgstAmount.toString() : '0'
            updated[ind].sgst = stockObj?.sgstAmount ? stockObj?.sgstAmount.toString() : '0'
            updated[ind].pwog = stockObj?.priceWogst ? stockObj?.priceWogst.toString() : ''
            updated[ind].pwg = stockObj?.priceGst ? stockObj?.priceGst.toString() : ''
            console.log(updated[ind].cgst);

            //for unitprofit
            let uprofit = (stockObj?.wRate ? (stockObj?.wRate) : 0) - (stockObj?.priceWogst ? (stockObj?.priceWogst) : 0)
            // updated[ind].unitProfit = uprofit.toString()

            //for profit %
            let profitPercent = ((uprofit ? uprofit : 0) / (stockObj?.priceWogst ? (stockObj?.priceWogst) : 0)) * 100
            updated[ind].profitPercent = profitPercent.toFixed(2)

            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) * ((stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0) / (100 + (stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0)))
            updated[ind].gstValue = gval?.toFixed(2)

            //TAXABLE((qty*rate) - disc) - GST value
            let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) - gval
            updated[ind].taxable = tax?.toFixed(2)

            ///TOTAL gstval +tax
            let tot = gval + tax
            updated[ind].totalAmt = tot?.toFixed(2)
            setSalesReturnItems(updated)

            //Disount =totalAmount-rateAmount
            let dis = ((stockObj?.mrp ? stockObj?.mrp : 0) - (stockObj?.lRate ? stockObj?.lRate : 0))
            updated[ind].disAmt = dis?.toFixed(2)
        }
        if (val === null) {
            // updated[ind].expiryDt = null
            updated[ind].qty = ''
        }
        setSalesReturnItems(updated)
        setProductNameError('')
    }

    const handleProductCode = (value: string, id: string) => {
        // console.log(value);
        // console.log(id);
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].productCode = value
        }
        setSalesReturnItems(updated)
    }
    const handleGst = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].gstPercent = value
        }
        setSalesReturnItems(updated)
    }

    const handleQty = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].qty = value

            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval = (((value ? parseFloat(value) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) * ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) / (100 + (updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0)))
            updated[ind].gstValue = gval?.toFixed(2)

            //TAXABLE((qty*rate) - disc) - GST value
            let tax = (((value ? parseFloat(value) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) - gval
            updated[ind].taxable = tax?.toFixed(2)

            ///TOTAL gst val+tax
            let tot = gval + tax
            updated[ind].totalAmt = tot?.toFixed(2)
        }

        setSalesReturnItems(updated)
    }
    const handleDiscountAmt = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].disAmt = value
            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (value ? parseFloat(value) : 0)) * ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) / (100 + (updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0)))
            updated[ind].gstValue = gval?.toFixed(2)

            //TAXABLE((qty*rate) - disc) - GST value
            let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].rate ? parseFloat(updated[ind].rate!) : 0)) - (value ? parseFloat(value) : 0)) - gval
            updated[ind].taxable = tax?.toFixed(2)

            ///TOTAL gst val+tax
            let tot = gval + tax
            updated[ind].totalAmt = tot?.toFixed(2)
        }
        setSalesReturnItems(updated)
    }

    const handleRate = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].rate = value
            //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
            let gval = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (value ? parseFloat(value) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) * ((updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0) / (100 + (updated[ind].gstPercent ? parseFloat(updated[ind].gstPercent!) : 0)))
            updated[ind].gstValue = gval?.toFixed(2)

            //TAXABLE((qty*rate) - disc) - GST value
            let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (value ? parseFloat(value) : 0)) - (updated[ind].disAmt ? parseFloat(updated[ind].disAmt!) : 0)) - gval
            updated[ind].taxable = tax?.toFixed(2)

            //Disount =totalAmount-rateAmount
            let dis = ((updated[ind].mrp ? parseFloat(updated[ind].mrp!) : 0) - (value ? parseFloat(value) : 0))
            updated[ind].disAmt = dis?.toFixed(2)

            ///TOTAL gst val+tax
            let tot = gval + tax
            updated[ind].totalAmt = tot?.toFixed(2)

        }
        setSalesReturnItems(updated)
    }

    const handleGSTValue = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].gstValue = value
        }
        setSalesReturnItems(updated)
    }

    const handleTaxable = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].taxable = value
        }
        setSalesReturnItems(updated)
    }

    const handleTotAmt = (value: string, id: string) => {
        let updated = [...salesReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].totalAmt = value
        }
        setSalesReturnItems(updated)
    }

    //Bill side
    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value as SalesPaymentModeTypes)
        setPaymentModeError('')
    }

    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(e.target.value)
        if (e.target.value) {
            let obj = AccountLists.find(a => a.accountNumber?.toString() === e.target.value.toString())
            setBankName(obj?.accountName ? obj?.accountName : '')
        }
    }
    const handleAmtPaid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmtPaid(e.target.value)
        setAmtPaidError('')
    }
    const handleBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBalance(e.target.value)
        setBalanceError('')
    }
    const handleDisAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDisAmt(e.target.value)
        setDisAmtError('')
    }

    // const handleCustomerContact: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setCustomer(e.target.value)
    //     // setCustomerError('')
    // }
    const handleTotalAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotAmt(e.target.value)
        setTotAmtError('')
    }
    const handleBillAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillAmount(e.target.value)
        setBillAmountError('')
    }
    const handleTotalItems: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalItems(e.target.value)
        setTotalItemsError('')
    }
    const handlePackNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPackNo(e.target.value)
        setPackNoError('')
    }
    const handleNetTaxable: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetTaxable(e.target.value)
        setNetTaxableError('')
    }
    const handleNetGST: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetGSTValue(e.target.value)
        setNetGSTValueError('')
    }

    const handleYourSavings: ChangeEventHandler<HTMLInputElement> = (e) => {
        setYourSavings(e.target.value)
        setYourSavingsError('')
    }
    const handleNetIgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetIgst(e.target.value)
        setNetIgstError('')
    }
    const handleNetCgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetCgst(e.target.value)
        setNetCgstError('')
    }
    const handleNetSgst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNetSgst(e.target.value)
        setNetSgstError('')
    }
    const handleTotalQty: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalQty(e.target.value)
        setTotalQtyError('')
    }
    const handleBillNetAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillNetAmt(e.target.value)
        setBillNetAmtError('')
    }
    const handleAdd: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAdd(e.target.value)
        setAddError('')
        setFinalAmount(prev => e.target.value ? (parseFloat(prev) + parseFloat(e.target.value)).toString() : prev)
    }
    const handleLess: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLess(e.target.value)
        setLessError('')
        setFinalAmount(prev => e.target.value ? (parseFloat(prev) - parseFloat(e.target.value)).toString() : prev)
    }
    const handleGrandTotal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGTotal(e.target.value)
        setGTotalError('')
    }
    const handleTenderedAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedAmt(e.target.value)
        setTenderedAmtError('')
    }
    const handleTenderedBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedBal(e.target.value)
        setTenderedBalError('')
    }

    const handleTenderedReturn: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTenderedReturn(e.target.value)
        setTenderedReturnError('')
    }
    const handleCurrentBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCurrentBal(e.target.value)
        setCurrentBalError('')
    }
    const handlePreviousBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPreviousBal(e.target.value)
        setPreviousBalError('')
    }
    const handleOverAllBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOverAllBal(e.target.value)
        setOverAllBalError('')
    }
    //
    // const handleInvoiceTypeClick = () => {
    //     setShowSalesForm(true)
    // }
    const handleView = () => {
        setShowSalesView(true)
    }

    const handleModalClose = () => {
        setAreaModal(false)
        setPlaceModal(false)
        setDistrictModal(false)
        setStateModal(false)
        setCountryModal(false)
        setRouteModal(false)

    }

    const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBranchId(e.target.value)
        setBranchIdError('')
    }

    const handleTag: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNameTag(e.target.value)
        setNameTagError('')
    }

    const handleAccountName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountName(e.target.value)
        setAccountNameError('')
    }

    const handleAddress1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress1(e.target.value)
        setAddress1Error('')
    }

    const handleAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress2(e.target.value)
        setAddress2Error('')
    }

    const handleArea: ChangeEventHandler<HTMLInputElement> = (e) => {
        setArea(e.target.value)
        setAreaError('')
    }

    const handlePlace: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlace(e.target.value)
        setPlaceError('')
    }

    const handleDistrict: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrict(e.target.value)
        setDistrictError('')
    }

    const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPincode(e.target.value)
        setPincodeError('')
    }

    const handleState: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const handleCountry: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountry(e.target.value)
        setCountryError('')
    }

    const handleRoute: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoute(e.target.value)
        setRouteError('')
    }

    const handleContactNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber1(e.target.value)
        setContactNumber1Error('')
    }

    const handleContactNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber2(e.target.value)
        setContactNumber2Error('')
    }

    const handleAadharNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNumber(e.target.value)
        setAadharNumberError('')
    }

    const handleHiddenGst: ChangeEventHandler<HTMLInputElement> = (e) => {
        // if (e.target.checked) {
        //     SetViewFrom(false)
        // } else {
        //     SetViewFrom(true)
        // }
        setHiddenGst(!hiddenGst)
    }

    const handleFormClose = () => {
        // setShowSalesForm(false)
        setShowSalesView(false)
        setModal(false)
    }

    const handleAreaName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAreaName(e.target.value)
    }
    const handlePlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlaceName(e.target.value)
    }

    const handleRouteName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteName(e.target.value)
    }
    const handleDistrictName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrictName(e.target.value)
    }
    const handleStateName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStateName(e.target.value)
    }
    const handleCountryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountryName(e.target.value)
    }

    // console.log("fsfssfs");

    // console.log(getDate(new Date()));

    const handleSave = () => {
        let error = false

        const data = salesReturnItems.filter(f => {
            // console.log(f.productName?.label);
            // console.log(f.productName?.value);
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        // console.log(data.length);
        // console.log(financialYear);
        // console.log(parseInt(financialYear));

        if (data.length > 0) {
            data.forEach((d, i) => {
                if (d.productName?.value === '') {
                    toast(`In Row ${d.id}, Product required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.productCode === '') {
                    toast(`In Row ${d.id},Product Code required`, ToastTypes.ERROR)
                    error = true
                }
                // if (d.hsn === '') {
                //     toast(`In Row ${d.id},HSN required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.gstPercent === '') {
                    toast(`In Row ${d.id},gst percent required`, ToastTypes.ERROR)
                    error = true
                }
                // if (d.batchNo === '') {
                //     toast(`In Row ${d.id},Batch required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.expiryDt?.toDateString() === '') {
                //     toast(`In Row ${d.id},Exp date required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.qty === '') {
                    toast(`In Row ${d.id},Quantity required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.gstValue === '') {
                    toast(`In Row ${d.id},gstValue required`, ToastTypes.ERROR)
                    error = true
                }
                // if (d.pwg === '') {
                //     toast(`In Row ${d.id},PWG required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.pwog === '') {
                //     toast(`In Row ${d.id},PWOG required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate1 === '') {
                //     toast(`In Row ${d.id},Wholesale Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate2 === '') {
                //     toast(`In Row ${d.id},Retail Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.sRate3 === '') {
                //     toast(`In Row ${d.id},Sales Price required`, ToastTypes.ERROR)
                //     error = true
                // }
                if (d.rate === '') {
                    toast(`In Row ${d.id},Rate required`, ToastTypes.ERROR)
                    error = true
                }

                // if (parseFloat(d.pwg) < parseFloat(d.pwog)) {
                //     toast(`In Row ${d.id},PWG must be greater than PWOG`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate1) < parseFloat(d.pwg)) {
                //     toast(`In Row ${d.id},Rate 1 must be greater than PWG`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate2) < parseFloat(d.sRate1)) {
                //     toast(`In Row ${d.id},Rate 2 must be greater than Rate 1`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.sRate3) < parseFloat(d.sRate2)) {
                //     toast(`In Row ${d.id},Rate 3 must be greater than Rate 2`, ToastTypes.ERROR)
                //     error = true
                // } else if (parseFloat(d.mrp) < parseFloat(d.sRate3)) {
                //     toast(`In Row ${d.id},MRP must be greater than Rate 3`, ToastTypes.ERROR)
                //     error = true
                // }

                if (d.taxable === '') {
                    toast(`In Row ${d.id},Tax required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.totalAmt === '') {
                    toast(`In Row ${d.id},Total Amt required`, ToastTypes.ERROR)
                    error = true
                }
                // if (d.unitProfit === '') {
                //     toast(`In Row ${d.id},Unit Profit required`, ToastTypes.ERROR)
                //     error = true
                // }
                // if (d.totalProfit === '') {
                //     toast(`In Row ${d.id},Total Profit required`, ToastTypes.ERROR)
                //     error = true
                // }

            })
        } else {
            toast('Data Required', ToastTypes.ERROR)
            error = true
        }
        ///top
        if (financialYear === '') {
            toast(`Financial Year required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceType === '') {
            toast(`Invoice type required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceTitle === '') {
            toast(`Invoice title required`, ToastTypes.ERROR)
            error = true
        }
        if (billNo === '') {
            toast(`bill No required`, ToastTypes.ERROR)
            error = true
        }
        if (billTime === '') {
            toast(`bill Time required`, ToastTypes.ERROR)
            error = true
        }
        if (customer?.value?.toString() === '') {
            toast(`Customer required`, ToastTypes.ERROR)
            error = true
        }
        if (customer?.label?.toString() === '') {
            toast(`Customer required`, ToastTypes.ERROR)
            error = true
        }
        if (customerName === '') {
            toast(`Customer required`, ToastTypes.ERROR)
            error = true
        }
        if (billDate === '') {
            toast(`bill date required`, ToastTypes.ERROR)
            error = true
        }
        if (salesReturnEntryNo === '') {
            toast(`Sales Return EntryNo required`, ToastTypes.ERROR)
            error = true
        }
        // if (supplier === '') {
        //     toast(`Supplier required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (customerPlace === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }

        ///right
        // if (billAmount === '') {
        //     toast(`Bill Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (billNetAmt === '') {
        //     toast(`Net Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (totAmt === '') {
        //     toast(`Total Amt required`, ToastTypes.ERROR)
        //     error = true
        // }
        if (gtotal === '') {
            toast(`Grand total required`, ToastTypes.ERROR)
            error = true
        }
        if ([SalesPaymentModesDropDown.BANK, SalesPaymentModesDropDown.CASH, SalesPaymentModesDropDown.CREDIT,].indexOf(paymentMode as SalesPaymentModeTypes) === -1) {
            toast('Payment Mode must be BANK/CASH/CREDIT')
            error = true
        }
        // if (amtInWords === '') {
        //     toast(`Amt In Words required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (currentBal === '') {
        //     toast(`Current balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (previousBal === '') {
        //     toast(`Previous balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (overAllBal === '') {
        //     toast(`OverAll balance required`, ToastTypes.ERROR)
        //     error = true
        // }
        if (totalQty === '') {
            toast(`total Qty required`, ToastTypes.ERROR)
            error = true
        }
        if (totalItems === '') {
            toast(`total Items required`, ToastTypes.ERROR)
            error = true
        }
        //
        if (data.length > 0 && tenderedBal !== finalAmount) {
            toast('Mismatch in Tender Balance and bill Amt, Kindly Check ', ToastTypes.ERROR)
            error = true
        }

        if (remarks === '') {
            setRemarksError('Remarks required')
            error = true
        } else if (remarks.length > 250) {
            setRemarksError('Remarks must be within 250 characters')
            error = true
        }

        if (error === false) {
            const netTaxable = data.reduce((previous, current) => {
                return previous + parseFloat(current?.taxable!)
            }, 0)
            // const netIgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.igst)
            // }, 0)
            // const netCgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.cgst)
            // }, 0)
            // const netSgst = data.reduce((previous, current) => {
            //     return previous + parseFloat(current.sgst)
            // }, 0)
            const netPercent = data.reduce((previous, current) => {
                return previous + parseFloat(current.profitPercent!)
            }, 0)
            //A
            const AData = data.filter(a => a?.gstPercent?.toString() === '0')
            const AValue = AData.reduce((previous, current) => {
                return previous + parseFloat(current.igst!) + parseFloat(current.cgst!) + parseFloat(current.sgst!)
            }, 0)
            const ATaxable = AData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!)
            }, 0)
            //B
            const BData = data.filter(a => a?.gstPercent?.toString() === '5')
            const BValue = BData.reduce((previous, current) => {
                return previous + parseFloat(current.igst!) + parseFloat(current.cgst!) + parseFloat(current.sgst!)
            }, 0)
            const BTaxable = BData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!)
            }, 0)
            //C
            const CData = data.filter(a => a?.gstPercent?.toString() === '12')
            const CValue = CData.reduce((previous, current) => {
                return previous + parseFloat(current.igst!) + parseFloat(current.cgst!) + parseFloat(current.sgst!)
            }, 0)
            const CTaxable = CData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!)
            }, 0)
            //D
            const DData = data.filter(a => a?.gstPercent?.toString() === '18')
            const DValue = DData.reduce((previous, current) => {
                return previous + parseFloat(current.igst!) + parseFloat(current.cgst!) + parseFloat(current.sgst!)
            }, 0)
            const DTaxable = DData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!)
            }, 0)
            //E
            const EData = data.filter(a => a?.gstPercent?.toString() === '28')
            const EValue = EData.reduce((previous, current) => {
                return previous + parseFloat(current.igst!) + parseFloat(current.cgst!) + parseFloat(current.sgst!)
            }, 0)
            const ETaxable = EData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable!)
            }, 0)

            let idate = new Date(billDate);
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var dayName = days[idate.getDay()];



            let addData: SalesReturnType = {
                financeYear: financialYear,
                branchId: AuthUser?.branchId ? AuthUser.branchId : 0,
                // billNo: billNo,
                // billTime: billTime,
                // billDate: billDate,
                // goodsReceivedDate: gudsRecdDate,
                invoiceDate: getDate(new Date()),
                invoiceType: invoiceType,
                invoiceTitle: invoiceTitle,
                invoiceDay: dayName,
                invoiceWeekNumber: 1,
                invoiceWeekDay: idate.getDay(),
                invoiceMonth: idate.getMonth() + 1,
                invoiceYear: idate.getFullYear(),
                invoiceNumber: parseInt(billNo!),
                packNo: packNo,
                salesReturnEntryNumber: parseInt(salesReturnEntryNo),
                repId: parseInt(refBy),
                customerId: customer?.value!,
                //new
                pointsEarned: pointsEarned.toString(),
                systemNo: sysNo,
                paymentMode: paymentMode,
                customerName: customerName,
                bankName: bankName,
                bankNumber: parseInt(bankNumber),
                billAmount: billAmount,
                disPercent: disPercent,
                disAmount: disAmt,
                add: add,
                less: less,
                grandTotal: gtotal,
                amtInWords: amtInWords,
                particulars: 'sales return-' + packNo,
                amtPaid: amtPaid,
                deliveryMode: deliveryMode,
                deliveryDetails: deliveryDetails,
                remarks: remarks,
                tenderAmt: tenderedAmt,
                tenderReturn: tenderedReturn,
                // totalItems: totalItems,
                // totalQty: totalQty,
                netTaxable: netTaxable.toString(),
                netIgst: netIgst.toString(),
                netSgst: netSgst.toString(),
                netCgst: netCgst.toString(),
                netProfit: totalProfit,
                netProfitPercent: netPercent.toString(),
                AValue: AValue.toString(),
                ATaxable: ATaxable.toString(),
                BValue: BValue.toString(),
                BTaxable: BTaxable.toString(),
                CValue: CValue.toString(),
                CTaxable: CTaxable.toString(),
                DValue: DValue.toString(),
                DTaxable: DTaxable.toString(),
                EValue: EValue.toString(),
                ETaxable: ETaxable.toString(),
                //
                salesReturnItems: data.map(dd => {
                    //tot profit=(q+f*s1)-(q+f*pwog)
                    let tprofit = (((parseFloat(dd.qty) + 0) * parseFloat(dd.rate!)) - ((parseFloat(dd.qty) + 0) * parseFloat(dd.pwog!)))
                    //for unitprofit
                    let unitProfit = (dd.rate ? parseFloat(dd.rate) : 0) - (dd.pwog ? parseFloat(dd.pwog) : 0)
                    //for profit %
                    let profitPercent = ((unitProfit ? unitProfit : 0) / (dd.pwog ? parseFloat(dd.pwog) : 0)) * 100


                    console.log(dd);

                    const obj = {
                        entryNumber: dd.id,
                        productId: dd.productName?.value ? parseInt(dd.productName?.value) : 0,
                        productGroupCode: dd.productGroupCode,
                        productCode: dd.productCode,
                        batchNumber: parseInt(dd.batchNo!),
                        expiryDate: dd.expiryDt ? getDate(dd.expiryDt.toString()) : '',
                        quantity: dd.qty,
                        freeQuantity: '0',
                        totalQuantity: dd.qty,
                        priceWogst: parseFloat(dd.pwog!),
                        priceGst: parseFloat(dd.pwg!),
                        wRate: parseFloat(dd.rate!),
                        // rRate: parseFloat(dd.sRate2),
                        // lRate: parseFloat(dd.sRate3),
                        mrp: parseFloat(dd.mrp!),
                        gstValue: parseFloat(dd.gstValue!),
                        sDiscount: parseFloat(dd.disAmt!),
                        pDiscountpercent: 0,
                        pDiscountamount: 0,
                        cDiscountpercent: 0,
                        cDiscountamount: 0,
                        taxableAmount: parseFloat(dd.taxable!),
                        igstAmount: dd.igst ? parseFloat(dd.igst!) : 0,
                        cgstAmount: dd.cgst ? parseFloat(dd.cgst!) : 0,
                        sgstAmount: dd.sgst ? parseFloat(dd.sgst!) : 0,
                        totalAmount: dd.totalAmt ? parseFloat(dd.totalAmt!) : 0,
                        unitCost: unitProfit,
                        profitAmount: tprofit,
                        profitPercent: profitPercent,
                    }
                    return obj

                })
            }
            // console.log(addData.invoiceDate);


            const printObj = {
                'kotnumber': 1,
                'billnumber': addData.invoiceNumber,
                'tablename': 'table1',
                'customerid': customer,
                'cashierid': AuthUser?.userId,
                'systemid': addData.systemNo,
                'date': addData.invoiceDate,
                'printername': '',
                'time': billTime,
                'productitmes': salesReturnItems.filter((salesReturnItem) => salesReturnItem.productName !== null).map((salesReturnItem) => {
                    return {
                        name: salesReturnItem.productName?.label.split("-")[0],
                        pcode: salesReturnItem.productCode,
                        hsn: salesReturnItem.hsn,
                        qty: salesReturnItem.qty,
                        disc: parseInt(salesReturnItem?.disAmt!).toFixed(2),
                        rate: parseInt(salesReturnItem?.rate!).toFixed(2),
                        mrp: parseInt(salesReturnItem?.mrp!).toFixed(2),
                        total: parseInt(salesReturnItem?.totalAmt!).toFixed(2)
                    }
                }),
                'empname': AuthUser?.name,
                'grandtotal': finalAmount,
                'discount': [{ 'discountname': 'offer 10%', 'amount': 20.00 }],
                'gst': [{ 'taxname': 'gst 8%', 'amount': 16.00 }],
                'oc': [{ 'ocname': 'ocname 5%', 'amount': 10.00 }],
                'finaltotal': parseFloat(addData?.grandTotal!).toFixed(2),
                'totaldisc': parseFloat(addData?.disAmount!).toFixed(2),
                'totalqty': parseFloat(totalQty),
                'paymentamount': parseFloat(finalAmount).toFixed(2),
                'tender': tenderedAmt ? parseFloat(tenderedAmt).toFixed(2) : 0,
                'balance': tenderedReturn ? parseFloat(tenderedReturn).toFixed(2) : 0,
                'pointsearned': pointsEarned > 0 ? pointsEarned : 0,
                'totalpoints': totalpoints > 0 ? totalpoints : 0,
            }
            console.log(printObj);

            console.log(addData);

            if (formType === FormTypeList.ADD) {
                setShowOverlayLoader()
                dispatch(addSalesReturnAction(addData)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    fetch('http://localhost:8080/Print/billprint.jsp', {
                        method: 'POST',
                        body: JSON.stringify(printObj),
                        mode: "no-cors",
                        headers: { 'Content-Type': 'application/json' }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            console.log("Print Successfully");
                        })
                        .catch((err) => {
                            console.log(err.message);
                        });
                    setHideOverlayLoader()
                    resetStates()
                    dispatch(fetchSalesReturnAction())
                    fetchRecentSalesReturnId()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                }).finally(() => {
                    setHideOverlayLoader()
                })

            } else if (formType === FormTypeList.EDIT && id) {
                setShowOverlayLoader()
                dispatch(updateSalesReturnAction(addData, id)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    fetch('http://localhost:8080/Print/billprint.jsp', {
                        method: 'POST',
                        body: JSON.stringify(printObj),
                        mode: "no-cors",
                        headers: { 'Content-Type': 'application/json' }
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            console.log("Print Successfully");
                        })
                        .catch((err) => {
                            console.log(err.message);
                        });
                    resetStates()
                    dispatch(fetchSalesReturnAction())
                    fetchRecentSalesReturnId()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                }).finally(() => {
                    setHideOverlayLoader()
                })
            }
        }
    }

    const fetchRecentSalesReturnId = async () => {
        api().get(`/salesReturn/recent/`).then((res) => {
            const result = (res.data.data !== null ? parseInt(res.data.data) : 0) + 10000 + 1
            if (formType === FormTypeList.ADD) setBillNo(result.toString())
        });
    }

    useEffect(() => {
        fetchRecentSalesReturnId();
    }, [formType])

    useEffect(() => {
        if (paymentMode === SalesPaymentModesDropDown.CREDIT) setAmtPaid('0')
        else setAmtPaid(gtotal)
    }, [gtotal])

    useEffect(() => {
        setTotalPoints(previousPoints + pointsEarned)
    }, [previousPoints, pointsEarned])

    useEffect(() => {
        let d = new Date()
        const date = d.getDate().toString().padStart(2, '0')
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        setPackNo(date + month)
    }, [])

    const onArea = () => {

        const addDynArea: DropDownType = {
            id: id,
            category: "area",
            title: areaName

        }

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynArea)).then(text => {
                toast('Added Division', ToastTypes.SUCCESS)
                setAreaName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setAreaModal(false)
    }
    const onPlace = () => {

        const addDynPlace: DropDownType = {
            id: id,
            category: "place",
            title: placeName
        }
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynPlace)).then(text => {
                toast('Added Place', ToastTypes.SUCCESS)
                setPlaceName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setPlaceModal(false)
    }

    const onDistrict = () => {
        const addDynDistrict: DropDownType = {
            id: id,
            category: 'district',
            title: districtName
        }

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynDistrict)).then(text => {
                toast('Added District', ToastTypes.SUCCESS)
                setDistrictName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setDistrictModal(false)
    }
    const onState = () => {

        const addDynState: DropDownType = {
            id: id,
            category: 'state',
            title: stateName
        }

        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynState)).then(text => {
                toast('Added State', ToastTypes.SUCCESS)
                setStateName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setStateModal(false)
    }
    const onCountry = () => {
        const addDynCountry: DropDownType = {
            id: id,
            category: 'country',
            title: countryName
        }
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynCountry)).then(text => {
                toast('Added Country', ToastTypes.SUCCESS)
                setCountryName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setCountryModal(false)
    }
    const onRoute = () => {

        const addDynRoute: DropDownType = {
            id: id,
            category: 'route',
            title: routeName
        }
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addDynRoute)).then(text => {
                toast('Added Route', ToastTypes.SUCCESS)
                setRouteName('')
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setRouteModal(false)
    }

    useEffect(() => {
        let bal = (gtotal ? parseFloat(gtotal) : 0) - (amtPaid ? parseFloat(amtPaid) : 0)
        setBalance(bal?.toString())
    }, [amtPaid, gtotal])

    useEffect(() => {
        if (parseFloat(tenderedAmt) > parseFloat(gtotal)) {
            let val = (tenderedAmt ? parseFloat(tenderedAmt) : 0) - (gtotal ? parseFloat(gtotal) : 0)
            setTenderedReturn(val?.toString())
        } else if (parseFloat(tenderedAmt) === parseFloat(gtotal)) {
            setTenderedReturn(0?.toString())
        }

    }, [tenderedAmt, gtotal])

    useEffect(() => {
        let val = (tenderedAmt ? parseFloat(tenderedAmt) : 0) - (tenderedReturn ? parseFloat(tenderedReturn) : 0)
        setTenderedBal(val?.toString())

    }, [tenderedReturn, tenderedAmt])

    useEffect(() => {
        const data = salesReturnItems.filter(f => {
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        const igst = data.reduce((previous, current) => {
            return previous + parseFloat(current?.gstValue!)
        }, 0)
        const nqty = data.reduce((previous, current) => {
            return previous + parseFloat(current?.qty!)
        }, 0)
        const ntax = data.reduce((previous, current) => {
            return previous + parseFloat(current?.taxable!)
        }, 0)
        const ngst = data.reduce((previous, current) => {
            return previous + parseFloat(current?.gstValue!)
        }, 0)
        const nsavings = data.reduce((previous, current) => {
            return previous + parseFloat(current?.disAmt!)
        }, 0)
        let val = (igst / 2).toFixed(2)
        console.log(igst);
        console.log(val);
        setNetIgst(0?.toString())
        setNetCgst(val.toString())
        setNetSgst(val.toString())
        setTotalItems(data?.length?.toString())
        setTotalQty(nqty?.toString())
        setNetTaxable(ntax?.toString())
        setNetGSTValue(ngst?.toFixed(2).toString())
        setYourSavings(nsavings?.toString())
    }, [salesReturnItems])

    useEffect(() => {
        const data = salesReturnItems.filter(f => {
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        const tProfit = data.reduce((previous, current) => {
            return previous + parseFloat(current?.totalAmt!)
        }, 0).toFixed(2)
        setBillNetAmt(tProfit.toString())
    }, [salesReturnItems])

    useEffect(() => {
        let gtot = (netTaxable ? parseFloat(netTaxable) : 0) + (netGSTValue ? parseFloat(netGSTValue) : 0) + (add ? parseFloat(add) : 0) - (less ? parseFloat(less) : 0)
        setGTotal(gtot.toString())
    }, [add, less, netGSTValue, netTaxable])

    useEffect(() => {
        let fAmt = parseFloat(gtotal)
        setFinalAmount(fAmt.toString())
        setPointsEarned(Math.ceil(fAmt / 100));
    }, [gtotal])

    // useEffect(() => {
    //     let billnetAmt = Math.round(totAmt ? parseFloat(totAmt) : 0)
    //     setBillNetAmt(billnetAmt.toString())
    // }, [totAmt])

    useEffect(() => {
        console.log('cbal');
        if (parseFloat(previousBal) < 0) {
            let cbal = -((previousBal ? Math.abs(parseFloat(previousBal)) : 0) - parseFloat(amtPaid))
            setCurrentBal(cbal.toString())
        } else {
            let cbal = (previousBal ? parseFloat(previousBal) : 0) - parseFloat(amtPaid)
            setCurrentBal(cbal.toString())
        }
    }, [amtPaid, previousBal])
    useEffect(() => {
        console.log('obal');
        console.log(currentBal);
        console.log(previousBal);
        let obal = (currentBal ? parseFloat(currentBal) : 0) + (previousBal ? parseFloat(previousBal) : 0)
        setOverAllBal(obal.toString())
    }, [currentBal, previousBal])

    useEffect(() => {
        if (customer) {
            (async () => {
                const response = await getTransactionCurrentBalanceByAcNo(customer.value?.toString())
                const data: { currentBalance: number, accountName: string } = response.data.data
                setPreviousBal(data.currentBalance.toString())
            })()
        }
    }, [customer])

    // useEffect(() => {
    //     let tamt = (billAmount ? parseFloat(billAmount) : 0) - (disAmt ? parseFloat(disAmt) : 0)
    //     setTotAmt(tamt.toString())
    // }, [disAmt, billAmount])

    // useEffect(() => {
    //     if (disPercent !== '') {
    //         let damt = (billAmount ? parseFloat(billAmount) : 0) * (disPercent ? parseFloat(disPercent) : 0) / 100
    //         setDisAmt(damt.toString())
    //     }
    // }, [billAmount, disPercent])

    useEffect(() => {
        const d = new Date()
        const todayDate = d.getDate()  //27
        const currentMonth = d.getMonth() + 1  //6
        const currentYear = d.getFullYear()  //2022

        if (currentMonth >= 4 && todayDate >= 1) {
            let year = currentYear + '-' + (currentYear + 1)
            setFinancialYear(year)
        } else if (currentMonth < 4) {
            let year = (currentYear - 1) + '-' + currentYear
            setFinancialYear(year)
        }

    }, [])

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: '23px',
            height: '23px',
            boxShadow: state.isFocused ? null : null,
            width: "150px"
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 5px'

        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: 'none'
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '28px',
        }),
        menu: (css: any) => ({
            ...css,
            // 'z-index': 600,
            width: '250px'
        })
    };
    const customStyles2 = {
        control: (provided: any, state: any) => ({
            ...provided,
            minHeight: '23px',
            height: '23px',
            boxShadow: state.isFocused ? null : null,
            // zIndex: 500,
            width: "250px"
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 6px'
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: 'none'
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '28px',
        }),
        menu: (css: any) => ({
            ...css,
            zIndex: 555,
            width: '250px'
        })
    };
    //     // console.log(23);
    //     // console.log(AmtToWords(23));
    useEffect(() => {
        setAmtInWords(convertAmtToWords(parseFloat(gtotal), 'India'))
    }, [gtotal])

    useEffect(() => {
        if (customer) {
            (async () => {
                const response = await getTransactionCurrentBalanceByAcNo(customer.value?.toString())
                const data: [{ currentBalance: string, accountNumber: number }] = response.data.data
                setPreviousBal(data[0]?.currentBalance)
            })()
        }
    }, [customer])

    useEffect(() => {
        if (formType === FormTypeList.EDIT && id) {
            (async () => {
                // const response = dispatch(fetchSalesByIdAction(id))
                // const data = response
                try {
                    setShowOverlayLoader()
                    // console.log('hi2');
                    const response = await getSalesReturnById(id)
                    const data: SalesReturnEditAttributes = response.data.data
                    // console.log(data);
                    console.log(data.remarks);

                    const custobj = CustomerList?.find(a => a.accountNumber?.toString() === data.customer_id?.toString())
                    let custname = custobj?.accountName
                    let custadd = custobj?.address1! + custobj?.address2
                    let custcont = custobj?.contactNumber1
                    let cScore = custobj?.score
                    // let sgst = custobj?.gstNumber
                    let custplace = custobj?.supplierPlace

                    const userObj = userList.find(a => a.id?.toString() === data.created_by?.toString())
                    let uname = userObj?.name ? userObj?.name : ''
                    //****top****
                    setInvoiceType(data.invoice_type)
                    setInvoiceTitle(data.invoice_title)
                    setBillNo(data.invoice_number.toString())
                    // setBillTime(data.invoice_title)
                    setBillDate(data.invoice_date)
                    setInvoiceAmount(data.grand_total)
                    setSalesReturnEntryNo(data.sales_return_entry_number?.toString())
                    setCustomer({
                        value: data.customer_id,
                        label: custname ? custname : "",
                        name: custname ? custname : "",
                        conNo: custcont ? custcont.toString() : '',
                        score: cScore ? cScore : ''
                    })
                    // setCustomerNo('2')
                    setCustomerName(custname!)
                    setCustomerAddress(custadd)
                    setCustomerContact(custcont?.toString()!)
                    setCustomerPlace(custplace ? custplace : '')
                    // setGST(sgst ? sgst : '')
                    // setSupAadhar(saadhar ? saadhar?.toString() : '')
                    setRefBy(data.rep_id?.toString())
                    setBranch(data.branch_id?.toString())
                    setBilledBy(uname)
                    setSysNo(data.system_no)
                    //****Right****
                    setTotalItems(data.salesReturnItems?.length?.toString())
                    setNetTaxable(data.net_taxable)
                    setNetIgst(data.net_igst)
                    setNetSgst(data.net_sgst)
                    setNetCgst(data.net_cgst)
                    setBillAmount(data.bill_amount)
                    setDisPercent(data.dis_percent?.toString())
                    setDisAmt(data.dis_amount?.toString())
                    setRemarks(data.remarks)
                    setTenderedAmt(data.tender_amount?.toString())
                    setTenderedReturn(data.tender_return?.toString())
                    // setBillNetAmt(data.)
                    setAdd(data.add)
                    setLess(data.less)
                    setGTotal(data.grand_total)
                    setAmtInWords(data.amt_in_words)
                    if (data.payment_mode === SalesPaymentModesDropDown.CASH) {
                        setPaymentMode(SalesPaymentModesDropDown.CASH)
                    } else if (data.payment_mode === SalesPaymentModesDropDown.BANK) {
                        setPaymentMode(SalesPaymentModesDropDown.BANK)
                        setBankName(data?.Transactions ? data?.Transactions[2]?.account_name : '')
                        setBankNumber(data?.Transactions ? data?.Transactions[2]?.account_number?.toString() : '')
                    } else if (data.payment_mode === SalesPaymentModesDropDown.CREDIT) {
                        setPaymentMode(SalesPaymentModesDropDown.CREDIT)
                    }
                    setPaymentNo(data.particulars)
                    setAmtPaid(data.amt_paid)
                    // setCurrentBal('')
                    // setPreviousBal('-3800')
                    // setOverAllBal('')
                    // setTotalItems(data.delivery_mode)
                    // setTotalQty(data.delivery_details)
                    setFinalAmount(data.grand_total)

                    //****table****
                    const salesReturnItemEditData = getDefaultSalesItems();
                    data.salesReturnItems.forEach((pItem, pindex) => {
                        // const prodObj = ProductList.find(pd => pd.id?.toString() === pItem.product_id.toString())
                        const stockObj = StockList.find(pd => pd.productCode?.toString() === pItem.product_code.toString())
                        console.log(stockObj);

                        //Disount =totalAmount-rateAmount
                        let dis = ((stockObj?.mrp ? stockObj?.mrp : 0) - (stockObj?.lRate ? stockObj?.lRate : 0))

                        //GST VALUE ((qty  rate) - disc )   GST/(100 + GST)
                        let gval = (((pItem.quantity ? (pItem.quantity) : 0) * (stockObj?.lRate ? stockObj?.lRate! : 0)) - (dis ? (dis!) : 0)) * ((stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0) / (100 + (stockObj?.gst ? parseFloat(stockObj?.gst.toString()) : 0)))

                        salesReturnItemEditData[pindex] = {

                            id: pindex + 1,
                            productName: {
                                label: stockObj?.productName ? stockObj?.productName : '',
                                value: pItem.product_id.toString() ? pItem.product_id.toString() : '',
                            },
                            productCode: pItem.product_code.toString() ? pItem.product_code.toString() : '',
                            productGroupCode: stockObj?.productGroupCode.toString() ? stockObj?.productGroupCode.toString() : '',
                            gstPercent: stockObj?.gst ? stockObj?.gst.toString() : '',
                            batchNo: stockObj?.batchNumber ? stockObj?.batchNumber.toString() : '',
                            expiryDt: stockObj?.expiryDate ? new Date(stockObj?.expiryDate) : null,
                            hsn: stockObj?.sac ? stockObj?.sac.toString() : '',
                            qty: pItem.quantity.toString(),
                            // pwog: stockObj?.priceWogst ? stockObj?.priceWogst.toString() : '',
                            // pwg: stockObj?.priceGst ? stockObj?.priceGst.toString() : '',
                            // rate: stockObj?.lRate ? stockObj?.lRate.toString() : '',
                            // mrp: stockObj?.mrp ? stockObj?.mrp.toString() : '',
                            pwog: pItem.price_wogst ? pItem.price_wogst.toString() : '',
                            pwg: pItem.price_gst ? pItem.price_gst.toString() : '',
                            rate: pItem.w_rate ? pItem.w_rate.toString() : '',
                            mrp: pItem.mrp ? pItem.mrp.toString() : '',
                            igst: pItem?.igst_amount ? pItem?.igst_amount.toString() : '0',
                            cgst: pItem?.cgst_amount ? pItem?.cgst_amount.toString() : '0',
                            sgst: pItem?.sgst_amount ? pItem?.sgst_amount.toString() : '0',
                            disAmt: pItem.s_discount.toString(),
                            gstValue: pItem.gst_value.toString(),
                            taxable: pItem.taxable_amount.toString(),
                            totalAmt: pItem.total_amount.toString(),
                            profitPercent: pItem.profit_percent.toString(),
                        }
                    })
                    console.log(salesReturnItemEditData);

                    setSalesReturnItems(salesReturnItemEditData)
                } catch (error) {

                } finally {
                    setHideOverlayLoader()
                }
            })()
        }

        // console.log('hi');

    }, [formType, id])

    //     /*
    //     {
    //       id: 2,
    //       productId: 1,
    //       productName: 'Ramraj Shirt',
    //       productCode: 100001,
    //       batchNumber: 1,
    //       expiryDate: '2024-09-23',
    //       brand: '',
    //       size: '',
    //       colour: '',
    //       pattern: '',
    //       material: '',
    //       type: '',
    //       smell: '',
    //       quantity: 20,
    //       freeQuantity: 0,
    //       totalQuantity: 20,
    //       priceWogst: 80,
    //       priceGst: 84,
    //       wRate: 100,
    //       rRate: 110,
    //       lRate: 120,
    //       mrp: 130,
    //       taxableAmount: 800,
    //       igstAmount: 40,
    //       cgstAmount: 0,
    //       sgstAmount: 0,
    //       totalAmount: 840,
    //       unitCost: 20,
    //       createdAt: 'Sep 23, 2023'
    //     },

    //     */

    //     // String(date.getMinutes()).padStart(2, '0')
    const printTime = () => {
        const date = new Date();
        const time = String(date.getHours()).padStart(2, '0') + " : " + String(date.getMinutes()).padStart(2, '0') + " ";
        let h = date.getHours();
        let m: string | number = date.getMinutes();
        let ampm = h >= 12 ? "PM" : "AM";

        h = h % 12; //reminder
        h = h ? h : 12;
        m = m.toString().padStart(2, "0");
        const formatedTimeString = h + ":" + m + " " + ampm;
        setBillTime(formatedTimeString);
    }
    setInterval(printTime, 1000);

    // console.log(getDate(new Date()));

    const onCustomer = () => {

        const addData: CustomerType = {
            branchId: parseInt(branchId),
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(contactNumber1 !== undefined ? contactNumber1 : ''),
            contactNumber2: parseInt(contactNumber2 !== undefined ? contactNumber2 : ''),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ''),
            state: state,
            country: country,
            route: route,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ''),
            // branch: `${AuthUser?.branchId}`
        }

        if (formType === FormTypeList.ADD) {
            dispatch(addCustomerAction(addData)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                api().get(`/customer/recent/`).then((res) => {
                    const result: CustomerType = res.data.data
                    setCustomer({
                        value: result?.accountNumber!,
                        label: result?.accountName!.toString(),
                        name: result?.accountName!.toString(),
                        conNo: result?.contactNumber1!.toString(),
                        score: result?.score!.toString()
                    })
                    setCustomerName(result?.accountName!.toString())
                    setCustomerContact(result?.contactNumber1!.toString())
                    setPreviousPoints(parseInt(result?.score!))
                });
                setBranch('')
                setNameTag('')
                setAccountName('')
                setContactNumber1('')
                setContactNumber2('')
                setAddress1('')
                setAddress2('')
                setArea('')
                setPlace('')
                setDistrict('')
                setPincode('')
                setState('')
                setCountry('')
                setRoute('')
                setModal(false)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    useEffect(() => {
        if (CustomerList) {
            let DefaultCustomer = CustomerList?.find(c => c.accountName?.toLocaleLowerCase() === 'cash sales')
            if (DefaultCustomer) {
                setCustomer(DefaultCustomer
                    ?
                    {
                        label: DefaultCustomer?.accountName!.toString() + '-' + DefaultCustomer?.contactNumber1,
                        name: DefaultCustomer?.accountName!.toString(),
                        value: DefaultCustomer?.accountNumber ? DefaultCustomer?.accountNumber : 0,
                        conNo: DefaultCustomer ? DefaultCustomer?.contactNumber1!.toString() : '',
                        score: DefaultCustomer?.score ? DefaultCustomer?.score : '0'
                    }
                    : null
                )
                setCustomerName(DefaultCustomer ? DefaultCustomer?.accountName!.toString() : '')
                setCustomerContact(DefaultCustomer ? DefaultCustomer?.contactNumber1!.toString() : '')
                setPreviousPoints(DefaultCustomer?.score ? parseInt(DefaultCustomer?.score) : 0)
            }
        }
    }, [CustomerList])

    //     // useEffect(() => {
    //     //     if (customerContact) {
    //     //         api().get(`/customer/?contactNumber1=${customerContact}`).then((res) => {
    //     //             const result: CustomerType = res.data.data
    //     //             setCustomer(result?.accountNumber!.toString())
    //     //             setCustomerName(result?.accountName!.toString())
    //     //             setCustomerContact(result?.contactNumber1!.toString())
    //     //             setPreviousPoints(parseInt(result?.score!))
    //     //         });
    //     //     }
    //     // }, [customerContact])


    return <>

        <Modal
            headerText="SalesReturn"
            onClose={handleFormClose}
            centered
            size="xl"
            visible={showSalesView}
        >
            <SalesReturn
                setFormType={setFormType}
                setId={setId}
                onclose={handleFormClose}
            />
        </Modal>
        <Modal
            headerText={'Add Area'}
            visible={areaModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="areaName"
                value={areaName}
                onChange={handleAreaName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onArea}>save</button>
        </Modal>
        <Modal
            headerText={'Add Place'}
            visible={placeModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="placeName"
                value={placeName}
                onChange={handlePlaceName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onPlace}>save</button>
        </Modal>
        <Modal
            headerText={'Add District'}
            visible={districtModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="districtName"
                value={districtName}
                onChange={handleDistrictName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onDistrict}>save</button>
        </Modal>
        <Modal
            headerText={'Add State'}
            visible={stateModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="stateName"
                value={stateName}
                onChange={handleStateName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onState}>save</button>
        </Modal>
        <Modal
            headerText={'Add Country'}
            visible={countryModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="countryName"
                value={countryName}
                onChange={handleCountryName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onCountry}>save</button>
        </Modal>
        <Modal
            headerText={'Add Route'}
            visible={routeModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="routeName"
                value={routeName}
                onChange={handleRouteName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onRoute}>save</button>
        </Modal>
        <Modal
            headerText={'Customer'}
            visible={modal}
            onClose={handleFormClose}
            centered
            size='lg'
        >
            <div className="row">
                <div className="col-4">
                    <FormInput
                        name='branchId'
                        label='Branch Name'
                        labelClassName="required"
                        required={true}
                        value={branchId}
                        onChange={handleBranch}
                        errorText={branchIdError}
                        type="select"
                    >
                        <option value="">Select Branch</option>
                        {BranchList.map((br: BranchType) => {
                            return <option value={br.id}>{br.name}</option>
                        })}
                    </FormInput>
                </div>
                <div className='col-2'>
                    <FormInput
                        name='nameTag'
                        label='Name Tag'
                        labelClassName="required"
                        required={true}
                        value={nameTag}
                        onChange={handleTag}
                        errorText={nameTagError}

                        type="select"
                    >
                        <option value="">Select Tag</option>
                        {["Mr.", "Mrs.", "Miss.", "Master.", "Baby.", "Dr.", "Er."].map((tag) => {
                            return <option value={tag}>{tag}</option>
                        })}
                    </FormInput>
                </div>

                <div className='col-6'>
                    <FormInput
                        name='accountName'
                        label='Account Name'
                        labelClassName="required"
                        required={true}
                        value={accountName}
                        onChange={handleAccountName}
                        errorText={accountNameError}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormInput
                        name='contactNumber1'
                        label='Primary Contact Number'
                        labelClassName="required"
                        required={true}
                        value={contactNumber1}
                        onChange={handleContactNumber1}
                        errorText={contactNumber1Error}
                    />
                </div>
                <div className="col-6">
                    <FormInput
                        name='contactNumber2'
                        label='Secondary Contact Number'
                        labelClassName="required"
                        value={contactNumber2}
                        onChange={handleContactNumber2}
                        errorText={contactNumber2Error}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormInput
                        name='address1'
                        label='Address 1'
                        labelClassName="required"
                        value={address1}
                        onChange={handleAddress1}
                        errorText={address1Error}

                    />
                </div>
                <div className="col-6">
                    <FormInput
                        name='address2'
                        label='Address 2'
                        labelClassName="required"
                        value={address2}
                        onChange={handleAddress2}
                        errorText={address2Error}

                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <FormInput
                        name='area'
                        label='Area'
                        labelClassName="required"
                        value={area}
                        onChange={handleArea}
                        errorText={areaError}
                        type="select"
                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setAreaModal(true)}>
                            <div className="modal fade"></div>
                        </i>}
                    >
                        <option value="">select</option>
                        {DropDownList.filter((ddl) => ddl.category === 'area').map((ddl, i) => {
                            return <option value={ddl.title}>{ddl.title}</option>
                        })}
                    </FormInput>
                </div>
                <div className="col-4">
                    <FormInput
                        name='place'
                        label='Place'
                        labelClassName="required"
                        value={place}
                        onChange={handlePlace}
                        errorText={placeError}
                        type="select"
                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1 cursor-pointer`} onClick={() => setPlaceModal(true)}>
                            <div className="modal fade"></div>
                        </i>}
                    >
                        <option value="">select</option>
                        {
                            DropDownList.filter((ddl: DropDownType) => ddl.category === 'place').map((ddl) => {
                                return <option value={ddl.title}>{ddl.title}</option>
                            })
                        }
                    </FormInput>
                </div>
                <div className="col-4">
                    <FormInput
                        name='district'
                        label='District'
                        labelClassName="required"
                        value={district}
                        onChange={handleDistrict}
                        errorText={districtError}
                        type="select"
                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setDistrictModal(true)}>
                            <div className="modal fade"></div>
                        </i>}
                    >
                        <option value="">select</option>
                        {
                            DropDownList.filter((ddl: DropDownType) => ddl.category === 'district').map((ddl) => {
                                return <option value={ddl.title}>{ddl.title}</option>
                            })
                        }
                    </FormInput>
                </div>
            </div>
            <div className="col">
                <FormInput
                    name='state'
                    label='State'
                    labelClassName="required"
                    value={state}
                    onChange={handleState}
                    errorText={stateError}
                    type="select"
                    icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setStateModal(true)}>
                    </i>}
                >
                    <option value="">select</option>
                    {
                        DropDownList.filter((ddl: DropDownType) => ddl.category === 'state').map((ddl) => {
                            return <option value={ddl.title}>{ddl.title}</option>
                        })
                    }
                </FormInput>
            </div>
            <div className="col">
                <FormInput
                    name='country'
                    label='Country'
                    labelClassName="required"
                    value={country}
                    onChange={handleCountry}
                    errorText={countryError}
                    type="select"
                    icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setCountryModal(true)}>
                        <div className="modal fade"></div>
                    </i>}
                >
                    <option value="">select</option>
                    {DropDownList.filter((ddl: DropDownType) => ddl.category === 'country').map((ddl) => {
                        return <option value={ddl.title}>{ddl.title}</option>
                    })}
                </FormInput>
            </div>

            <div className="col">
                <FormInput
                    name='route'
                    label='Route'
                    labelClassName="required"
                    value={route}
                    onChange={handleRoute}
                    errorText={routeError}
                    type="select"
                    icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setRouteModal(true)}>
                        <div className="modal fade"></div>
                    </i>}
                >
                    <option value="">select</option>
                    {
                        DropDownList.filter((ddl: DropDownType) => ddl.category === 'route').map((ddl) => {
                            return <option value={ddl.title}>{ddl.title}</option>
                        })
                    }

                </FormInput>
            </div>

            <div className="col">
                <FormInput
                    name='aadharNumber'
                    label='Aadhar Number'
                    labelClassName="required"
                    value={aadharNumber}
                    onChange={handleAadharNumber}
                    errorText={aadharNumberError}
                    type="text"
                />
            </div>

            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onCustomer}>save</button>
        </Modal>



        <UserLayout>
            {/* <h4>Sales Master</h4> */}
            <div className="row">
                <div className="col-lg-9">
                    {/* <div className="col-lg-12"> */}
                    <div className="card shadow-lg">
                        <div className="card-body p-1">
                            <div className="row">
                                <div className="col-lg-6">
                                    {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Financial Year </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='year'
                                                label=''
                                                labelClassName="required"
                                                value={financialYear}
                                                // onChange={handleProductName}
                                                errorText={financialYearError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div> */}

                                    {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Bill No </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='billNo'
                                                label=''
                                                labelClassName="required"
                                                value={billNo}
                                                // onChange={handleInvoiceNo}
                                                errorText={billNoError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Bill Date </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='billDate'
                                                label=''
                                                labelClassName="required"
                                                value={billDate}
                                                // onChange={handleInvoiceDate}
                                                errorText={billDateError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Bill Time </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='billTime'
                                                label=''
                                                labelClassName="required"
                                                value={billTime}
                                                // onChange={handleInvoiceType}
                                                onClick={handleInvoiceTypeClick}
                                                errorText={billTimeError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Billed By</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='billedBy'
                                                label=''
                                                labelClassName="required"
                                                value={billedBy}
                                                // onChange={handlebilledBy}
                                                errorText={billedByError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Floor Name</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='systemNo'
                                                label=''
                                                labelClassName="required"
                                                value={sysNo}
                                                // onChange={handlesystemNo}
                                                errorText={sysNoError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div> */}

                                    <div className="col-lg-12 hstack">
                                        <div className="col-3">
                                            <label htmlFor="">Customer Name</label>
                                        </div>
                                        <div className="col-9 mt-1">
                                            <FormInput
                                                name='customerName'
                                                label=''
                                                labelClassName="required"
                                                value={customerName}
                                                // onChange={handleSupName}
                                                errorText={customerNameError}
                                                containerClass="mb-1 text-end"

                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-3">
                                            <label htmlFor="">Sales Return Type</label>
                                        </div>
                                        <div className="col-9 mt-1">
                                            <FormInput
                                                name='Sales Type'
                                                label=''
                                                labelClassName="required"
                                                value={invoiceType}
                                                onChange={handleInvoiceType}
                                                placeholder='Sales Type'
                                                errorText={invoiceTypeError}
                                                containerClass="mb-1"
                                                // disabled={showViewForm}
                                                type="select"
                                            >
                                                <option value="">Select</option>
                                                {SALES_TYPE_DROPS.map((a, i) => {
                                                    return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                                                })}
                                            </FormInput>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-6">
                                    {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Customer No </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerNo'
                                                label=''
                                                labelClassName="required"
                                                value={customerNo}
                                                // onChange={handleInvoiceNo}
                                                errorText={customerNoError}
                                                containerClass="mb-1 text-end"
                                                width={50}

                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12 hstack">
                                        {/* <div className="col-3">
                                            <label htmlFor="">Contact Number</label>
                                        </div>
                                        <div className="col-8 mt-1">
                                            <FormInput
                                                name='customerContact'
                                                label=''
                                                labelClassName="required"
                                                value={customerContact}
                                                onChange={handleCustomerContact}
                                                errorText={customerContactError}
                                                containerClass="mb-1"
                                                width={50}

                                            />
                                        </div> */}
                                        <div className="col-3">
                                            <label className="" >Contact Number</label>
                                        </div>
                                        <div className="col-8 mt-1">
                                            <Select
                                                options={CustomerList?.map(f => {
                                                    return {
                                                        label: f?.accountName! + '-' + f?.contactNumber1 || '',
                                                        name: f?.accountName ? f?.accountName : '',
                                                        value: f?.accountNumber || 0,
                                                        score: f?.score ? f?.score?.toString() : '0',
                                                        conNo: f?.contactNumber1 ? f?.contactNumber1?.toString() : ''
                                                    }
                                                })}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={v => {
                                                    setCustomer(v)
                                                    setCustomerName(v?.name ? v?.name : '')
                                                    setCustomerContact(v?.conNo?.toString()!)
                                                    setPreviousPoints(v?.score ? parseInt(v?.score!) : 0)
                                                }}
                                                value={customer}
                                                styles={customStyles2}
                                            />
                                        </div>

                                        <div className="col-1">
                                            {<i data-toggle="modal" className={`fe-plus-circle me-1 p-1 cursor-pointer`} onClick={() => setModal(true)}>
                                                <div className="modal fade"></div>
                                            </i>}
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        {/* <div className="col-10"> */}
                                        <h4 className=" fs-4 badge bg-info">Customer Points : {previousPoints}</h4>
                                        {/* </div> */}
                                    </div>
                                    {/* <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Cust.Address</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerAddress'
                                                label=''
                                                labelClassName="required"
                                                value={customerAddress}
                                                // onChange={handleSupAddress}
                                                errorText={customerAddressError}
                                                containerClass="mb-1"
                                                width={50}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Place</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='customerPlace'
                                                label=''
                                                labelClassName="required"
                                                value={customerPlace}
                                                // onChange={handleSupPlace}
                                                errorText={customerPlaceError}
                                                containerClass="p-0 mb-1"
                                                width={50}

                                            />
                                        </div>
                                    </div> */}
                                </div>

                                {/* <div className="col-lg-4">
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Referred By</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='refBy'
                                                label=''
                                                labelClassName="required"
                                                value={refBy}
                                                // onChange={handlerefBy}
                                                errorText={refByError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                                disabled={true}
                                                type="select"
                                            >
                                                <option value=""></option>
                                                {AccountLists.filter((al) => al.groupName === 'representer' || al.groupName === 'Representer').map((al) => {
                                                    return <option value={al.accountNumber}>{al.accountName}</option>
                                                })
                                                }
                                            </FormInput>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Branch</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='branch'
                                                label=''
                                                labelClassName="required"
                                                value={branch}
                                                // onChange={handlebranch}
                                                errorText={branchError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>

                                   
                                </div> */}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    {/* <span className="mt-2 d-n">
                        <FormInput
                            name="hiddenGst"
                            label="Hidden GST"
                            value={hiddenGst.toString()}
                            type="checkbox"
                            onChange={handleHiddenGst}
                            onClick={() => setHiddenGst(false)}
                        />
                    </span> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <div className='table-wrapper'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className='text-truncate align-middle'>S.No</th>
                                                    <th className='text-truncate align-middle'>Product Name</th>
                                                    <th className='text-truncate align-middle'>Product Code</th>
                                                    {!hiddenGst &&
                                                        <th className='text-truncate align-middle'>GST%</th>
                                                    }
                                                    <th className='text-truncate align-middle'>M.R.P</th>
                                                    <th className='text-truncate align-middle'>RATE</th>
                                                    <th className='text-truncate align-middle'>QTY</th>
                                                    <th className='text-truncate align-middle'>DISCOUNT</th>
                                                    {!hiddenGst &&
                                                        <>
                                                            <th className='text-truncate align-middle'>GST VALUE</th>

                                                            <th className='text-truncate align-middle'>Taxable</th>
                                                        </>
                                                    }
                                                    <th className='text-truncate align-middle'>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {salesReturnItems.map((pi, i) => {
                                                    return <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td className="p-0">
                                                            <Select
                                                                // options={ProductList?.map(p => {
                                                                //     return {
                                                                //         label: (p?.productName + '-' + p?.productCode + '-' + p.sac + '-' + p.gst) || '',
                                                                //         value: p.id?.toString() || ''
                                                                //     }
                                                                // })}
                                                                options={StockList?.map(p => {
                                                                    return {
                                                                        label: (p?.productName + '-' + p?.productCode + '-' + p?.sac + '-' + p?.gst) || '',
                                                                        value: p.id?.toString() || ''
                                                                    }
                                                                })}
                                                                placeholder={false}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                onChange={(val) => handleProductName(val, pi.id.toString())}
                                                                value={pi.productName}
                                                                styles={customStyles}
                                                            // menuIsOpen={true}
                                                            // maxMenuHeight={180}
                                                            // classNames={{
                                                            //     container: (state) =>
                                                            //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                            //     control: (state) =>
                                                            //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                            //     indicatorsContainer: (state) =>
                                                            //         state.className ? 'react-select mt-1' : 'react-select mt-1',
                                                            // }}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='productCode'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.productCode}
                                                                onChange={(e) => handleProductCode(e.target.value, pi.id.toString())}
                                                                errorText={productNameError}
                                                                readOnly={true}
                                                                containerClass="w-100 read-only-field"
                                                            />
                                                        </td>
                                                        {!hiddenGst &&
                                                            <td className="p-0">
                                                                <FormInput
                                                                    name='gst'
                                                                    label=''
                                                                    labelClassName="required"
                                                                    value={pi.gstPercent}
                                                                    onChange={(e) => handleGst(e.target.value, pi.id.toString())}
                                                                    errorText={gstError}
                                                                    readOnly={true}
                                                                    containerClass="read-only-field"
                                                                />
                                                            </td>
                                                        }
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='mrp'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.mrp}
                                                                onChange={(e) => handleRate(e.target.value, pi.id.toString())}
                                                                containerClass="read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='Rate'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.rate}
                                                                onChange={(e) => handleRate(e.target.value, pi.id.toString())}
                                                                containerClass="read-only-field"
                                                                type="number"
                                                                readOnly={true}
                                                            />
                                                        </td>

                                                        <td className="p-0">
                                                            <FormInput
                                                                name='qty'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.qty}
                                                                onChange={(e) => handleQty(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                            // type="number"

                                                            />
                                                        </td>

                                                        <td className="p-0">
                                                            <FormInput
                                                                name='Discount'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.disAmt}
                                                                onChange={(e) => handleDiscountAmt(e.target.value, pi.id.toString())}
                                                                containerClass="read-only-field"
                                                                type="number"
                                                                readOnly={true}

                                                            />
                                                        </td>
                                                        {!hiddenGst &&
                                                            <>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name='GST VALUE'
                                                                        label=''
                                                                        labelClassName="required"
                                                                        value={pi.gstValue}
                                                                        onChange={(e) => handleGSTValue(e.target.value, pi.id.toString())}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                                <td className="p-0">
                                                                    <FormInput
                                                                        name='taxable'
                                                                        label=''
                                                                        labelClassName="required"
                                                                        value={parseFloat(pi.taxable!).toFixed(2)}
                                                                        onChange={(e) => handleTaxable(e.target.value, pi.id.toString())}
                                                                        errorText={gstError}
                                                                        containerClass=" read-only-field"
                                                                        readOnly={true}
                                                                    />
                                                                </td>
                                                            </>
                                                        }
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='TotalAmt'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.totalAmt!).toFixed(2)}
                                                                onChange={(e) => handleTotAmt(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="w-100 read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>

                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-8 mt-2">
                                        <FormInput
                                            name='Remarks'
                                            label='Remarks'
                                            labelClassName="required"
                                            value={remarks}
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                                setRemarksError('')
                                            }}
                                            errorText={remarksError}
                                            containerClass="h-100"
                                            type="textarea"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                        {/* <button className="btn btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                        <button className="btn btn-primary" onClick={handleView}>View</button>
                                        <button className="btn btn-primary" onClick={handleSave}>{formType === FormTypeList.ADD ? 'Save' : 'Update'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="col-lg-3">
                    {/* <div className="card shadow-lg ">
                        <div className="card-body"> */}
                    <p>
                        <span className="fs-4 badge bg-primary">{billDate}</span>
                        <span className="fs-4 badge bg-primary ms-1">{billTime}</span>
                    </p>
                    <p>
                        <span className="fs-3 badge bg-danger">Bill No: {billNo}</span>
                        <span className="fs-3 badge bg-danger ms-2">{ }</span>
                    </p>
                    {/* </div>
                </div> */}
                    <div className="card shadow-lg ">
                        <div className="card-body">
                            <div className="row">
                                <h4 className="text-center">Grand Total</h4>
                                <h2 className="text-center text-white " style={{ backgroundColor: '#032566' }}>{rupeeFormat(finalAmount)}</h2>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><label>Total Items</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='totalItems'
                                                    label=''
                                                    labelClassName="required"
                                                    value={totalItems}
                                                    onChange={handleTotalItems}
                                                    errorText={totalItemsError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Total Quantity</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='totalQty'
                                                    label=''
                                                    labelClassName="required"
                                                    value={totalQty}
                                                    onChange={handleTotalQty}
                                                    errorText={totalQtyError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Pack No</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='packNo'
                                                    label=''
                                                    labelClassName="required"
                                                    value={packNo}
                                                    onChange={handlePackNo}
                                                    errorText={packNoError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Your Savings</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='yourSavings'
                                                    label=''
                                                    labelClassName="required"
                                                    value={yourSavings}
                                                    onChange={handleYourSavings}
                                                    errorText={yourSavingsError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        {!hiddenGst &&
                                            <>
                                                <tr>
                                                    <td><label>NET IGST</label></td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name='netIgst'
                                                            label=''
                                                            labelClassName="required"
                                                            value={netIgst}
                                                            onChange={handleNetIgst}
                                                            errorText={netIgstError}
                                                            containerClass="w-100 read-only-field"
                                                            type="number"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><label>NET CGST</label></td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name='netCgst'
                                                            label=''
                                                            labelClassName="required"
                                                            value={netCgst}
                                                            onChange={handleNetCgst}
                                                            errorText={netCgstError}
                                                            containerClass="w-100 read-only-field"
                                                            type="number"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><label>NET SGST</label></td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name='netSgst'
                                                            label=''
                                                            labelClassName="required"
                                                            value={netSgst}
                                                            onChange={handleNetSgst}
                                                            errorText={netSgstError}
                                                            containerClass="w-100 read-only-field"
                                                            type="number"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td><label>Net Taxable</label></td>
                                                    <td className="p-0">
                                                        <FormInput
                                                            name='netTaxable'
                                                            label=''
                                                            labelClassName="required"
                                                            value={netTaxable}
                                                            onChange={handleNetTaxable}
                                                            errorText={netTaxableError}
                                                            containerClass="read-only-field"
                                                            type="number"
                                                            readOnly={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                        {!hiddenGst &&
                                            <tr>
                                                <td><label>Net GST Value</label></td>
                                                <td className="p-0">
                                                    <FormInput
                                                        name='netGSTValue'
                                                        label=''
                                                        labelClassName="required"
                                                        value={netGSTValue}
                                                        onChange={handleNetGST}
                                                        errorText={netGSTValueError}
                                                        containerClass="read-only-field"
                                                        type="number"
                                                        readOnly={true}
                                                    />
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <td><label>Add</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Add'
                                                    label=''
                                                    labelClassName="required"
                                                    value={add}
                                                    onChange={handleAdd}
                                                    errorText={addError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Less</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Less'
                                                    label=''
                                                    labelClassName="required"
                                                    value={less}
                                                    onChange={handleLess}
                                                    errorText={lessError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Grand Total</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='grandTotal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={gtotal}
                                                    onChange={handleGrandTotal}
                                                    errorText={gtotalError}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                        </tr>

                                        {/* <tr>
                                            <td><label>Amt In Words</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='AmtInWords'
                                                    label=''
                                                    labelClassName="required"
                                                    value={amtInWords}
                                                    onChange={handleAmtInWords}
                                                    errorText={amtInWordsError}
                                                    containerClass="w-100"
                                                // type="number"
                                                />
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td><label>Payment Mode</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='paymentMode'
                                                    label=''
                                                    labelClassName="required"
                                                    value={paymentMode}
                                                    onChange={handlePaymentMode}
                                                    errorText={paymentModeError}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {SALSE_PAYMENT_MODES.map((a, i) => {
                                                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                                                    })}
                                                </FormInput>
                                            </td>
                                        </tr>
                                        {paymentMode === SalesPaymentModesDropDown.BANK && <tr>
                                            <td><label>Bank</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='bankNumber'
                                                    label=''
                                                    labelClassName="required"
                                                    value={bankNumber}
                                                    onChange={handleBank}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {AccountLists.filter((al) => al.groupName === 'main' && al.accountName?.toLowerCase().includes('bank')).map((al) => {
                                                        return <option value={al.accountNumber}>{al.accountName}</option>
                                                    })
                                                    }
                                                </FormInput>
                                            </td>
                                        </tr>}
                                        <tr>
                                            <td><label>Amt Paid</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='AmtPaid'
                                                    label=''
                                                    labelClassName="required"
                                                    value={amtPaid}
                                                    onChange={handleAmtPaid}
                                                    errorText={amtPaidError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Balance</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Balance'
                                                    label=''
                                                    labelClassName="required"
                                                    value={balance}
                                                    onChange={handleBalance}
                                                    errorText={balanceError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Ten.Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedAmt}
                                                    onChange={handleTenderedAmt}
                                                    errorText={tenderedAmtError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Ten.Return</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedReturn'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedReturn}
                                                    onChange={handleTenderedReturn}
                                                    errorText={tenderedReturnError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Tendered Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedBal}
                                                    onChange={handleTenderedBal}
                                                    errorText={tenderedBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Current Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='currentBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={currentBal}
                                                    onChange={handleCurrentBal}
                                                    errorText={currentBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Previous Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='previousBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={previousBal}
                                                    onChange={handlePreviousBal}
                                                    errorText={previousBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>OverAll Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='overAllBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={overAllBal}
                                                    onChange={handleOverAllBal}
                                                    errorText={overAllBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Previous Points</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='previouspoints'
                                                    label=''
                                                    labelClassName="required"
                                                    value={previousPoints}
                                                    onChange={handleTenderedBal}
                                                    // errorText={previouspointsError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Points Earned</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='pointsEarned'
                                                    label=''
                                                    labelClassName="required"
                                                    value={pointsEarned}
                                                    onChange={handleTenderedBal}
                                                    // errorText={pointsEarnedError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <p>
                        <span className="fs-4 badge bg-primary">User: {billedBy}</span>
                        <span className="fs-4 badge bg-primary ms-1">Sys: {sysNo}</span>
                    </p>
                </div>
            </div >


        </UserLayout >
    </>
}
export default SalesReturnMaster