import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import LineChartForCard from '../components/custom/components/charts/apex/LineChartForCards'
import UserLayout from '../components/custom/layouts/UserLayout'
import { getDate } from '../config/functions'
import { SalesPaymentModesDropDown, SalesType } from '../redux/actions/salesActions'
import { StoreState } from '../redux/store'
import HourlySalesReport from './HourlySalesReport'
import MonthwiseSalesReport from './MonthwiseSalesReport'
import WeeklySalesReport from './WeeklySalesReport'
import YearwiseSalesReport from './YearwiseSalesReport'

const Home = () => {
    const salesList = useSelector<StoreState, SalesType[]>(state => state.sales)
    let d = new Date();
    const currDate = d;

    const cashData = useMemo<number[]>(() => {
        let todayData = salesList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.CASH)
        if (todayData.length > 0) {
            let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
            // setCashData(cashArr)
            return cashArr
        } else return []

    }, [salesList, currDate])

    const cardData = useMemo<number[]>(() => {
        let todayData = salesList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.BANK)
        if (todayData.length > 0) {
            let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
            // setCardData(cardArr)
            return cardArr
        } else return []

    }, [salesList, currDate])

    const todaysCashSales = useMemo<number>(() => {
        let todayData = salesList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.CASH)
        // if (todayData.length > 0) {
        //     let cashArr = todayData?.map(cash => cash?.grandTotal ? parseFloat(cash?.grandTotal) : 0)
        //     setCashData(cashArr)
        // }
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [salesList, currDate])

    const todaysCardSales = useMemo<number>(() => {
        let todayData = salesList?.filter(f => f.invoiceDate === getDate(currDate)).filter(pm => pm.paymentMode === SalesPaymentModesDropDown.BANK)
        // if (todayData.length > 0) {
        //     let cardArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
        //     setCardData(cardArr)
        // }
        return todayData.reduce((prev, curr) => {
            return prev + (curr?.grandTotal ? parseFloat(curr?.grandTotal) : 0)
        }, 0)

    }, [salesList, currDate])

    const todaysOverallSales = useMemo<number[]>(() => {
        let todayData = salesList?.filter(f => f.invoiceDate === getDate(currDate))
        let OverallArr = todayData?.map(card => card?.grandTotal ? parseFloat(card?.grandTotal) : 0)
        return OverallArr
    }, [salesList, currDate])

    return <UserLayout>

        <PageTitle
            title='Sales Analysis'
        // title='Home'
        />

        {/* <div className="row">
            {portfolioDashboardView
                ? <PortfolioDashboard />
                : CenterDashboardView
                    ? <CenterDashboard />
                    : <CommonDashboard />
            }
        </div> */}
        <div className='row mx-3'>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Cash Sales</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashSales}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Cash',
                                    data: cashData,
                                    // data: [5, 18, 8, 12, 9, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#008FFB']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4 '>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Bank Sales</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCardSales}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Card',
                                    data: cardData,
                                    // data: [6, 20, 8, 12, 9, 18, 11, 18, 15],
                                }]
                            }
                            colors={['#00E396']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
            <div className='col-sm-12 col-md-4 col-lg-4'>
                <div className='card' style={{ width: '360px' }}>
                    <div className='card-body p-1'>
                        <h4 className='fs-3 fw-bold ms-3 my-0'>Overall Sales</h4>
                        <h4 className='fs-4 fw-bold ms-3 my-0'>₹ {todaysCashSales + todaysCardSales || 0}</h4>
                        <LineChartForCard
                            datasets={
                                [{
                                    name: 'Overall',
                                    data: todaysOverallSales,
                                    // data: [8, 10, 8, 12, 20, 13, 11, 18, 15],
                                }]
                            }
                            colors={['#FEB019']}
                            labels={['1', '2', '3', '4', '5', '6', '7', '8', '9']}
                            title=''
                            height={60}
                            xyLabelShow={false}
                        />
                    </div>
                </div>
            </div>
        </div>
        <YearwiseSalesReport />
        <MonthwiseSalesReport />
        <WeeklySalesReport />
        <HourlySalesReport />

    </UserLayout>

}

export default Home
