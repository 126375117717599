import api from "../config/api"
import { TransactionType } from "../redux/actions/transactionAction"

export const addTransaction = (data: TransactionType) => {
    return api().post('/transaction/', data)
}

export const editTransaction = async (data: TransactionType, number: number | undefined) => {
    // return await api.put(`/transaction/${id}`, data)
    return await api().put('/transaction/?number=' + number, data)
}

export const getTransaction = async (date: string) => {
    // return await api().get('/transaction/' + date)
    return await api().get('/transaction/tabletransaction/?date=' + date)
}

export const getTransactionBalance = async () => {
    // return await api().get(`/transaction/all/currentBalance`)
    return await api().get(`/transaction/currentbalance/`)
}

export const deleteTransaction = async (number: number | undefined) => {
    // return await api.delete(`/transaction/${id}`)
    return await api().delete(`/transaction/?number=` + number)
}

export const getTransactionCurrentBalanceByAcNo = async (accountNumber: string) => {
    return await api().get(`/transaction/currentbalance/?accountNumber=${accountNumber}`)
}