import api from "../config/api"
import { AccountHolderType } from "../redux/actions/accountHolder"

export const addAccountHolder = async (data: AccountHolderType) => {
    return await api().post('/accountHolder/', data)
}

export const editAccountHolder = async (data: AccountHolderType, account_number: number | undefined) => {
    // return await api.put(`/accountHolder/${account_number}`, data)
    return await api().put('/accountHolder/?accountNumber=' + account_number, data)
}

export const getAccountHolder = async () => {

    return await api().get('/accountHolder/')
}

export const deleteAccountHolder = async (account_number: number | undefined) => {
    // return await api.delete(`/accountHolder/${account_number}`)
    return await api().delete(`/accountHolder/?accountNumber=` + account_number)
}