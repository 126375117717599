import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import api from "../config/api"
import { BrandType, addBrandAction, fetchBrandAction } from "../redux/actions/brandAction"
import { CategoryType, addCategoryAction, fetchCategoryAction } from "../redux/actions/categoryAction"
import { DepartmentType, addDepartmentAction, fetchDepartmentAction } from "../redux/actions/departmentAction"
import { DropDownType, addDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { ProductType, addProductAction, deleteProductAction, editProductAction, fetchProductAction } from "../redux/actions/productAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number
    productName: string
    productCode: number
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ProductType) => void
type HandleDelete = (value: ProductType) => void

const ProductMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()

    const productList = useSelector<StoreState, ProductType[]>(state => state.product)
    const brandList = useSelector<StoreState, BrandType[]>(state => state.brand)
    const departmentList = useSelector<StoreState, DepartmentType[]>(state => state.department)
    const cateList = useSelector<StoreState, CategoryType[]>(state => state.category)
    const unitLists = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    const [recentProductId, setRecentProductId] = useState('')

    useEffect(() => {
        dispatch(fetchProductAction())
        dispatch(fetchBrandAction())
        dispatch(fetchDepartmentAction())
        dispatch(fetchCategoryAction())
        dispatch(fetchDropDownAction())
    }, [dispatch])


    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ProductType | null>(null)

    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()

    const [loginid, setLoginid] = useState<string>('')
    const [loginidError, setLoginidError] = useState<string>('')
    // const [productNo, setProductNo] = useState<string>('')
    // const [productNoError, setProductNoError] = useState<string>('')
    const [productName, setProductName] = useState<string | undefined>('')
    const [productNameError, setProductNameError] = useState<string>('')
    const [productCode, setProductCode] = useState<string>('')
    const [productCodeError, setProductCodeError] = useState<string>('')
    const [brandId, setBrandId] = useState<string | undefined>('')
    const [brandIdError, setBrandIdError] = useState<string>('')
    const [departmentId, setDepartmentId] = useState<string>('')
    const [departmentIdError, setDepartmentIdError] = useState<string>('')
    const [categoryId, setCategoryId] = useState<string>()
    const [categoryIdError, setCategoryIdError] = useState<string>('')
    const [unit, setUnit] = useState<string>('')
    const [unitErrorInModel, setUnitErrorInModel] = useState<string>('')
    const [unitError, setUnitError] = useState<string>('')
    const [uom, setUom] = useState<string>('1')
    const [uomError, setUomError] = useState<string>('')
    const [sac, setSac] = useState<string>('')
    const [sacError, setSacError] = useState<string>('')
    const [gst, setGst] = useState<string>('')
    const [gstError, setGstError] = useState<string>('')
    const [rackNumber, setRackNumber] = useState<string>('')
    const [rackNumberError, setRackNumberError] = useState<string>('')
    const [reOrder, setReOrder] = useState<string>('1')
    const [reOrderError, setReOrderError] = useState<string>('')
    const [taxable, setTaxable] = useState<string>('')
    const [taxableError, setTaxableError] = useState<string>('')
    const [modelNumber, setModelNumber] = useState<string>('')
    const [modelNumberError, setModelNumberError] = useState<string>('')
    const [modelName, setModelName] = useState<string>('')
    const [modelNameError, setModelNameError] = useState<string>('')
    const [reMarks, setReMarks] = useState<string>('')
    const [reMarksError, setReMarksError] = useState<string>('')

    const [openModal, setOpenModal] = useState(false)
    const [brandName, setBrandName] = useState<string>('')

    const [showModal, setShowModal] = useState(false)
    const [departmentName, setDepartmentName] = useState<string>('')

    const [viewModal, setViewModal] = useState(false)
    const [categoryName, setCategoryName] = useState<string>('')

    const [unitModal, setUnitModal] = useState(false)
    const [unitName, setUnitName] = useState<string>('')



    const [imageUpload, setImageUpload] = useState<any | null>({ filename: '' })
    const [imageUrl, setImageUrl] = useState<any>('')
    const [imageFlag, setImageFlag] = useState<boolean>(false)


    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''

    const mainContentRef = React.useRef<HTMLDivElement>(null)

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    const filterProductHandler = (product: any, searchTerm: any) => {

        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productName) || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productCode)) : true || []
    }

    const fetchRecentProductId = async () => {
        api().get(`/product/recent/`).then((res) => {
            const result = (res.data.data !== null ? ((parseInt(categoryId!) * 10000) + parseInt(res.data.data)) : 100000) + 1
            if (formType === FormTypeList.ADD) setProductCode(result.toString())
            console.log(res.data.data);
            console.log(categoryId)
        });
    }

    useEffect(() => {
        fetchRecentProductId();
    }, [categoryId, formType, editData])

    // useEffect(() => {
    //     let newProdNumber = 0;
    //     if (recentProductId === null && editData === null)
    //         newProdNumber = 100001;
    //     else if (recentProductId!==null&&editData === null)
    //         newProdNumber = parseInt(recentProductId) + 1;
    //     else
    //         newProdNumber = parseInt(productCode)

    //     setProductCode(newProdNumber.toString())
    // }, [recentProductId,editData])


    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])

    // const handleProductNo: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setProductNo(e.target.value)
    //     setProductNoError('')
    // }
    const handleBrand: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrandId(e.target.value)
        setBrandIdError('')
    }
    const handleDepartment: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDepartmentId(e.target.value)
        setDepartmentIdError('')
    }
    const handleCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategoryId(e.target.value)
        setCategoryIdError('')
    }

    const handleProductName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductName(e.target.value)
        setProductNameError('')
    }
    const handleProductCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setProductCode(e.target.value)
        setProductCodeError('')
    }
    const handleUnit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnit(e.target.value)
        setUnitError('')
    }
    const handleUom: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUom(e.target.value)
        setUomError('')
    }
    const handleSac: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSac(e.target.value)
        setSacError('')
    }
    const handleGst: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGst(e.target.value)
        setGstError('')
    }
    const handleRackNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRackNumber(e.target.value)
        setRackNumberError('')
    }
    const handleReOrder: ChangeEventHandler<HTMLInputElement> = (e) => {
        const value = e.target.value;
        setReOrder(value);
        setReOrderError(value < '1' ? 'Reorder level should be greater than or equal to 1' : '');
    }
    const handleBrandName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrandName(e.target.value)
    }
    const handleDepartmentName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDepartmentName(e.target.value)
    }
    const handleCategoryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategoryName(e.target.value)
    }
    const handleUnitName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUnitName(e.target.value)
    }

    const handleTaxable: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTaxable(e.target.value)
    }
    const handleModelNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setModelNumber(e.target.value)
    }
    const handleModelName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setModelName(e.target.value)
    }
    const handleReMarks: ChangeEventHandler<HTMLInputElement> = (e) => {
        setReMarks(e.target.value)
    }

    const handleImageUpload: ChangeEventHandler<HTMLInputElement | HTMLFormElement> = (e) => {
        setImageUpload(e.target.files[0])
        setImageFlag(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)

        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }


    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleModalClose = () => {
        setOpenModal(false)
        setShowModal(false)
        setViewModal(false)
        setUnitModal(false)
    }

    const resetStates = () => {
        setLoginid('')
        setLoginidError('')
        setBrandId('')
        setBrandIdError('')
        setDepartmentId('')
        setDepartmentIdError('')
        setCategoryId('')
        setCategoryIdError('')
        setProductName('')
        setProductNameError('')
        setUnit('')
        setUnitError('')
        setUom('1')
        setUomError('')
        setSac('')
        setSacError('')
        setGst('')
        setGstError('')
        setRackNumber('')
        setRackNumberError('')
        setReOrder('1')
        setReOrderError('')
        setTaxable('')
        setTaxableError('')
        setModelName('')
        setModelNameError('')
        setModelNumber('')
        setModelNumberError('')
        setReMarks('')
        setReMarksError('')
        setBrandName('')
        setDepartmentName('')
        setCategoryName('')
        setImageUpload({ filename: "" })
        setEditData(null)
        setFormType(FormTypeList.ADD)
    }

    const handleSave = () => {
        let error = false

        const editData: ProductType = {
            id: id,
            productName: productName,
            productCode: parseInt(productCode),
            brandId: brandId ? parseInt(brandId) : '',
            departmentId: departmentId ? parseInt(departmentId) : '',
            categoryId: categoryId ? parseInt(categoryId) : '',
            unit: unit,
            uom: parseInt(uom),
            sac: parseInt(sac),
            gst: gst,
            rackNumber: rackNumber,
            reOrder: parseInt(reOrder),
            taxable: taxable,
            modelNumber: parseInt(modelNumber),
            modelName: modelName,
            reMark: reMarks,
            imageUrl: imageUrl

        }



        if (productName === '') {
            setProductNameError('Product name required')
            error = true
        } else if ((productName || '').length > 30) {
            setProductNameError('Product name should be within 30 characters')
            error = true
        }

        if (productCode === '') {
            setProductCodeError('Product code required')
            error = true
        }

        if (departmentId === '') {
            setDepartmentIdError('Department required')
            error = true
        }

        if (categoryId === '') {
            setCategoryIdError('Category required')
            error = true
        }

        if (brandId === '') {
            setBrandIdError('Brand required')
            error = true
        }

        if (unit === '') {
            setUnitError('Unit required')
            error = true
        }

        if (gst === '') {
            setGstError('GST required')
            error = true
        }

        if (reOrder === '') {
            setReOrderError('Re-Order required')
            error = true
        }

        if (taxable === '') {
            setTaxableError('Taxable required')
            error = true
        }

        if (sac?.toString() === '') {
            setSacError('HSN/SAC required')
            error = true
        } else if ((sac?.toString().trim() || '').length > 8) {
            setSacError('HSN/SAC should be within 8 Digits')
            error = true
        }




        if (!error) {
            const data = new FormData()
            data.append('productName', productName ? productName : '')
            data.append('productCode', productCode ? productCode : '')
            data.append('brandId', brandId ? brandId : '')
            data.append('departmentId', departmentId ? departmentId : '')
            data.append('categoryId', categoryId ? categoryId : '')
            data.append('unit', unit ? unit : '')
            data.append('uom', uom ? uom : '')
            data.append('sac', sac ? sac : '')
            data.append('gst', gst ? gst : '')
            data.append('rackNumber', rackNumber ? rackNumber : '')
            data.append('reOrder', reOrder ? reOrder : '')
            data.append('taxable', taxable ? taxable : '')
            data.append('modelNumber', modelNumber ? modelNumber : '')
            data.append('modelName', modelName ? modelName : '')
            data.append('reMark', reMarks ? reMarks : '')
            data.append('imageUrl', imageUpload)
            if (imageFlag) {
                data.append('oldImageUrl', editData?.imageUrl ? imageUrl : '')
            }



            if (formType === FormTypeList.ADD) {

                dispatch(addProductAction(data)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                    fetchRecentProductId();
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.id !== undefined) {
                dispatch(editProductAction(data, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData(null)
                    setFormType(FormTypeList.ADD)
                    fetchRecentProductId();
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {

        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {

            setId(editData.id)
            // setProductNo(editData.productNo?.toString() || '')
            setCategoryId(editData.categoryId?.toString() || '')
            setDepartmentId(editData.departmentId?.toString() || '')
            setBrandId(editData.brandId?.toString() || '')
            setProductName(editData.productName || '')
            setProductCode(editData.productCode ? editData.productCode.toString() : '')
            setUnit(editData.unit || '')
            setUom(editData.uom?.toString() || '')
            setSac(editData.sac?.toString() || '')
            setGst(editData.gst || '')
            setRackNumber(editData.rackNumber || '')
            setReOrder(editData.reOrder?.toString() || '')
            setTaxable(editData.taxable || '')
            setModelNumber(editData.modelNumber?.toString() || '')
            setModelName(editData.modelName || '')
            setReMarks(editData.reMark || '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    //add brand
    const onManufacture = () => {
        const data = new FormData()
        data.append('name', brandName)
        if (formType === FormTypeList.ADD) {
            dispatch(addBrandAction(data)).then(text => {
                toast('Added Brand', ToastTypes.SUCCESS)
                setBrandName('')

            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setOpenModal(false)
    }

    //add department
    const onDepartment = () => {
        const data = new FormData()
        data.append('name', departmentName)
        if (formType === FormTypeList.ADD) {
            dispatch(addDepartmentAction(data)).then(text => {
                toast('Added Department', ToastTypes.SUCCESS)
                setDepartmentName('')

            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setShowModal(false)
    }

    //add category
    const onCategory = () => {
        const data = new FormData()
        data.append('name', categoryName)
        data.append('departmentId', departmentId ? departmentId.toString() : '')
        // {
        //     productList.map((pl:ProductType)=>{
        // console.log(pl.departmentId);

        //        return data.append('departmentId',pl.departmentId?pl.departmentId.toString():"")
        //     })

        // }

        if (formType === FormTypeList.ADD) {
            dispatch(addCategoryAction(data)).then(text => {
                toast('Added Category', ToastTypes.SUCCESS)
                setCategoryName('')
                setEditData(null)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setViewModal(false)
    }
    const onUnit = () => {

        if (unitName.length > 3) {
            setUnitErrorInModel('Unit should be 3 characters or below');
            return;
        }

        const addUnitData: DropDownType = {
            id: id,
            category: "unit",
            title: unitName
        }
        if (formType === FormTypeList.ADD) {
            dispatch(addDropDownAction(addUnitData)).then(text => {
                toast('Added Unit', ToastTypes.SUCCESS)
                setUnitName('')
                setEditData(null)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
        setUnitModal(false)
    }
    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteProductAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }

    const filterCategoriesByDepartment = (categories: CategoryType[], selectedDepartmentId: string): CategoryType[] => {
        return categories.filter((category) => category.departmentId === selectedDepartmentId);
    };

    console.log(categoryId);

    return <>
        <Modal
            headerText={'Delete Product Master'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <Modal
            headerText={'Add Brand'}
            visible={openModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="brandName"
                value={brandName}
                onChange={handleBrandName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onManufacture}>save</button>
        </Modal >
        <Modal
            headerText={'Add Department'}
            visible={showModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="departmentName"
                value={departmentName}
                onChange={handleDepartmentName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onDepartment}>save</button>
        </Modal >
        <Modal
            headerText={'Add Category'}
            visible={viewModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="categoryName"
                value={categoryName}
                onChange={handleCategoryName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onCategory}>save</button>
        </Modal >
        <Modal
            headerText={'Add Unit'}
            visible={unitModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="unit"
                value={unitName}
                onChange={handleUnitName}
                errorText={unitErrorInModel}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => resetStates()}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onUnit}>save</button>
        </Modal >
        <UserLayout>
            <h4>Product Master</h4>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card shadow-lg p-1">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className='col-sm-6 col-md-6 col-lg-5'>
                                    <FormInput
                                        name='productName'
                                        label='Product Name'
                                        labelClassName='required'
                                        required={true}
                                        value={productName}
                                        onChange={handleProductName}
                                        errorText={productNameError}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-5'>
                                    <FormInput
                                        name='productCode'
                                        label='Product Code'
                                        labelClassName="required"
                                        required={true}
                                        value={productCode}
                                        onChange={handleProductCode}
                                        errorText={productCodeError}
                                        type="number"
                                        readOnly
                                    />
                                </div>
                                <div className="col-2 mt-2">
                                    <button className="btn btn-sm fw-bold btn-secondary" style={{ float: "right" }} onClick={() => resetStates()}>Add New</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='departmentId'
                                        label='Department'
                                        labelClassName="required"
                                        required={true}
                                        value={departmentId}
                                        onChange={handleDepartment}
                                        errorText={departmentIdError}
                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setShowModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {departmentList.map((dl: DepartmentType) => {
                                            return <option value={dl.id}>{dl.name}</option>
                                        })
                                        }
                                    </FormInput>
                                </div>

                                <div className='col-sm-6 col-md-8 col-lg-8'>
                                    <FormInput
                                        name='categoryId'
                                        label='Category'
                                        labelClassName="required"
                                        required={true}
                                        value={categoryId}
                                        onChange={handleCategory}
                                        errorText={categoryIdError}
                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setViewModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {cateList.filter((cl: CategoryType) => cl.departmentId?.toString() === (departmentId !== "" ? departmentId?.toString() : cl.departmentId?.toString()))
                                            .map((cl: CategoryType) => {
                                                return <option value={cl.id} selected={cl.id === categoryId ? true : false}>{cl.name}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>

                                {/* <div className='col-sm-6 col-md-8 col-lg-8'>
    <FormInput
        name='categoryId'
        label='Category'
        labelClassName="required"
        required={true}
        value={categoryId}
        onChange={(e) => setCategoryId(e.target.value)}
        errorText={categoryIdError}
        type="select"
        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setViewModal(true)}>
            <div className="modal fade"></div>
        </i>}
    >
        <option value="">select</option>
        {filterCategoriesByDepartment(cateList, departmentId!).map((cl: CategoryType) => (
            <option key={cl.id} value={cl.id} selected={cl.id === categoryId}>
                {cl.name}
            </option>
        ))}
    </FormInput>
</div> */}
                            </div>
                            <div className="row">
                                <div className='col-sm-6 col-md-12 col-lg-12'>
                                    <FormInput
                                        name='brandId'
                                        label='Brand'
                                        labelClassName="required"
                                        required={true}
                                        value={brandId}
                                        onChange={handleBrand}
                                        errorText={brandIdError}
                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setOpenModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {brandList.map((bd: BrandType) => {
                                            return <option value={bd.id} selected={bd.id === brandId ? true : false}>{bd.name}</option>
                                        })
                                        }
                                    </FormInput>
                                </div>
                            </div>

                            {/* <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='productTamil'
                                        label='Product Tamil'
                                        labelClassName="required"
                                        required={true}
                                        value={productTamil}
                                        onChange={handleProductTamil}
                                        errorText={productTamilError}
                                        type="number"
                                    />
                                </div> */}
                            <div className="row">
                                <div className='col-sm-4 col-md-3 col-lg-3'>
                                    <FormInput
                                        name='unit'
                                        label='Unit'
                                        labelClassName="required"
                                        required={true}
                                        value={unit}
                                        onChange={handleUnit}
                                        errorText={unitError}
                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setUnitModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >

                                        <option value="">select</option>
                                        {
                                            unitLists.filter((unit: DropDownType) => unit.category === "unit").map((unit) => {
                                                return <option value={unit.title}>{unit.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                                <div className='col-sm-4 col-md-3 col-lg-3'>
                                    <FormInput
                                        name='uom'
                                        label='UOM'
                                        labelClassName="required"
                                        value={uom}
                                        onChange={handleUom}
                                        errorText={uomError}
                                        type="number"
                                    />
                                </div>
                                <div className=' col-sm-4 col-md-3 col-lg-3'>
                                    <FormInput
                                        name='sac'
                                        label='H.S.N/SAC'
                                        labelClassName="required"
                                        required={true}
                                        value={sac}
                                        onChange={handleSac}
                                        errorText={sacError}
                                        type="number"
                                    />
                                </div>
                                <div className='col-sm-4 col-md-3 col-lg-3'>
                                    <FormInput
                                        name='gst'
                                        label='GST %'
                                        labelClassName="required"
                                        required={true}
                                        value={gst}
                                        onChange={handleGst}
                                        errorText={gstError}
                                        type="select"
                                    >
                                        <option value="">select</option>
                                        {
                                            [0, 5, 12, 18, 28].map((gst) => {
                                                return <option value={gst}>{gst}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                            </div>
                            <div className="row">

                                <div className='col-sm-4 col-md-5 col-lg-4'>
                                    <FormInput
                                        name='rackNumber'
                                        label='Rack Number'
                                        labelClassName="required"
                                        value={rackNumber}
                                        onChange={handleRackNumber}
                                        errorText={rackNumberError}
                                    />
                                </div>
                                <div className='col-sm-4 col-md-5 col-lg-5'>
                                    <FormInput
                                        name='reOrder'
                                        label='Re-Order Level'
                                        labelClassName="required"
                                        required={true}
                                        value={reOrder}
                                        onChange={handleReOrder}
                                        errorText={reOrderError}
                                        type="number"
                                    />
                                </div>
                                <div className="col-sm-4 col-md-5 col-lg-3">
                                    <FormInput
                                        name='taxable'
                                        label='Taxable'
                                        labelClassName="required"
                                        required={true}
                                        value={taxable}
                                        onChange={handleTaxable}
                                        errorText={taxableError}
                                        type="select"
                                    >
                                        <option value="">select</option>
                                        {
                                            ["yes", "no"].map((tax) => {
                                                return <option value={tax}>{tax}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                            </div>

                            {/* <div className="row">

                            </div> */}
                            <div className="row">
                                <div className="col-6">
                                    <FormInput
                                        name='modelNumber'
                                        label='Model Number'
                                        labelClassName="required"
                                        value={modelNumber}
                                        onChange={handleModelNumber}
                                        errorText={modelNumberError}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormInput
                                        name='modelName'
                                        label='Model Name'
                                        labelClassName="required"
                                        value={modelName}
                                        onChange={handleModelName}
                                        errorText={modelNumberError}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <FormInput
                                    name='reMarks'
                                    label='Re-Marks'
                                    labelClassName="required"
                                    value={reMarks}
                                    onChange={handleReMarks}
                                    errorText={reMarksError}
                                    type="textarea"
                                />
                            </div>
                            {/* <div className="row mt-1">
                                <div className="col-7">
                                    <FormInput
                                        name='imageUpload'
                                        label="Image Upload"
                                        value={imageUpload.filename}
                                        onChange={handleImageUpload}
                                        type="file"
                                    />
                                </div>
                            </div> */}


                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                <button className="btn btn-sm fw-bold btn-success m-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card shadow-lg p-1" style={{ height: '410px', overflow: "auto" }}>
                        {/* <div className="card-body"> */}
                        <div className='table-wrapper'>
                            <div>
                                <FormInput
                                    onChange={searchTermChangeHandler}
                                    name=""
                                    placeholder='Search...'
                                    value={searchTerm}
                                    onFocus={() => {
                                        mainContentRef?.current?.scrollTo({
                                            behavior: 'smooth',
                                            top: 270
                                        })
                                    }}
                                    innerRef={searchRef}
                                    className="container my-2"
                                />

                            </div>
                            {/* <div style={{ height: "43vh", overflowY: "auto" }}> */}
                            <table className="table my-2">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Product Name</th>
                                        <th className='text-truncate align-middle'>Product Code</th>
                                        <th className='text-truncate align-middle'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productList.filter((product) => filterProductHandler(product, searchTerm)).map((product, i) => {
                                        return <ProductBody
                                            sno={i + 1}
                                            productName={product.productName || ''}
                                            productCode={product.productCode || 0}
                                            onEdit={() => handleEdit(product)}
                                            onDelete={() => handleDelete(product)}

                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </UserLayout >
    </>
}

const ProductBody = ({ sno, productName, productCode, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{productName}</td>
        <td className='text-capitalize align-middle'>{productCode}</td>

        <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default ProductMaster;