import React from 'react'
import { OverlayLoaderContext } from "./OverlayLoaderProvider"

const useOverlayLoader = () => {
    const context = React.useContext(OverlayLoaderContext)

    return [context?.onShow!, context?.onHide!]

}

export default useOverlayLoader