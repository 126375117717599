import api from "../config/api"

export interface AppSettings {
    id?: number | undefined | null
    setting_group: string
    setting_key: string
    setting_value: string
}

export const addAppSettings = async (data: AppSettings) => {
    return await api().post('/appSettings', data)
}

export const editAppSettings = async (data: AppSettings, id: number | undefined) => {
    return await api().put(`/appSettings/?id=${id}`, data);
}


export const getSettings = async () => {
    const res = await api().get('/appSettings/')
    return res.data.data;
}

export const deleteAppSettings = async (id: number | undefined) => {
    return await api().delete(`/appSettings/?id=${id}/`)
}