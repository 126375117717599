import { BranchActionsList, BranchType} from "../actions/branchAction";
import { Action } from "../actions/types";

const initialValue: Array<BranchType> = []

export const branchReducer = (state: BranchType[] = initialValue, action: Action) => {

    switch (action.type) {
        case BranchActionsList.ADD_BRANCH:
            return [...state, action.data];

        case BranchActionsList.FETCH_BRANCH:
            return action.data

        case BranchActionsList.EDIT_BRANCH: {
            const updated = [...state]
            const index = updated.findIndex((u) => {
                return u.id === action.data.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated;
        }
        case BranchActionsList.DELETE_BRANCH:
            return state.filter(i => i.id !== action.data)
        default:
            return state;
    }
}