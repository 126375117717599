/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { getPurchaseCategory } from "../../services/purchaseCategory.service"

export enum PurchaseCategoryReportActionList {
    // ADD_PURCHASEITEM = 'ADD_PURCHASE',
    FETCH_PURCHASECATEGORY = 'FETCH_PURCHASECATEGORY',
    // UPDATE_PURCHASE = 'UPDATE_PURCHASE',
    // DELETE_PURCHASE = 'DELETE_PURCHASE',
}



export interface PurchaseCategoryType {
   categoryName:string,
   amount:number
}

export interface FetchPurchaseCategoryAction {
    type: PurchaseCategoryReportActionList.FETCH_PURCHASECATEGORY
    data: Array<PurchaseCategoryType>
}



export type PurchaseCategoryActions = FetchPurchaseCategoryAction


export const fetchPurchaseCategoryAction = (startDate:string,endDate:string,categoryId:string) => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseCategory(startDate,endDate,categoryId)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseCategoryAction>({
            type: PurchaseCategoryReportActionList.FETCH_PURCHASECATEGORY,
            data: data
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<FetchPurchaseAction>({
    //     type: PurchaseActionList.FETCH_PURCHASE,
    //     data: PurchaseSampleData
    // })
    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // return api().get<APIResponse<PurchaseType[]>>('Purchase/', config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<FetchPurchaseAction>({
    //             type: PurchaseActionList.FETCH_PURCHASE,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Fetch Successfull')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to fetch'
    //         : 'Unable to fetch')
    // })
    // }
}



