import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { AccountHolderType, addAccountHolderAction, deleteAccountHolderAction, editAccountHolderAction, fetchAccountHolderAction } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { BranchType, fetchBranchAction } from "../redux/actions/branchAction"
import { fetchCategoryAction } from "../redux/actions/categoryAction"
import { DropDownType, addDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { ActiveTypes } from "../redux/actions/usersActions"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    accountName: string | undefined,
    place: string | undefined,
    contactNumber1: number | undefined | string,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: AccountHolderType) => void
type HandleDelete = (value: AccountHolderType) => void


const AccountHolder = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()

    const date = new Date()
    const unixTimestamp = Math.floor(date.getTime() / 1000);

    const AccountList = useSelector<StoreState, AccountHolderType[]>(state => state.account.filter(account => account.groupName !== 'main'))
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    const DropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    const BranchList = useSelector<StoreState, BranchType[]>(state => state.branch)

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<AccountHolderType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [deleteName, setDeleteName] = useState<string | undefined>('')
    const [accountNumber, setAccountNumber] = useState<number>()
    const [id, setId] = useState<number>()


    useEffect(() => {
        dispatch(fetchAccountHolderAction())
        dispatch(fetchBranchAction())
        dispatch(fetchDropDownAction())
        dispatch(fetchCategoryAction())
    }, [dispatch])


    const [branchId, setBranchId] = useState<string>('')
    const [branchIdError, setBranchIdError] = useState<string>('')

    const [groupName, setGroupName] = useState<string>('')
    const [groupNameError, setGroupNameError] = useState<string>('')

    const [nameTag, setNameTag] = useState<string>('')
    const [nameTagError, setNameTagError] = useState<string>('')

    const [accountName, setAccountName] = useState<string>('')
    const [accountNameError, setAccountNameError] = useState<string>('')

    // const [accountNumber, setAccountNumber] = useState<string>('')
    // const [accountNumberError, setAccountNumberError] = useState<string>('')


    const [address1, setAddress1] = useState<string>('')
    const [address1Error, setAddress1Error] = useState<string>('')

    const [address2, setAddress2] = useState<string>('')
    const [address2Error, setAddress2Error] = useState<string>('')

    // const [doorNumber, setdoorNumber] = useState<string>('')
    // const [doorNumberError, setDoorNumberError] = useState<string>('')

    const [area, setArea] = useState<string>('')
    const [areaError, setAreaError] = useState<string>('')

    const [place, setPlace] = useState<string>('')
    const [placeError, setPlaceError] = useState<string>('')

    const [supplierPlace, setSupplierPlace] = useState<string>('')
    const [supplierPlaceError, setSupplierPlaceError] = useState<string>('')

    const [district, setDistrict] = useState<string>('')
    const [districtError, setDistrictError] = useState<string>('')

    const [pincode, setPincode] = useState<string | undefined>('')
    const [pincodeError, setPincodeError] = useState<string>('')

    const [state, setState] = useState<string>('')
    const [stateError, setStateError] = useState<string>('')

    const [country, setCountry] = useState<string>('')
    const [countryError, setCountryError] = useState<string>('')

    const [route, setRoute] = useState<string>('')
    const [routeError, setRouteError] = useState<string>('')

    const [contactNumber1, setContactNumber1] = useState<string | undefined>('')
    const [contactNumber1Error, setContactNumber1Error] = useState<string>('')

    const [contactNumber2, setContactNumber2] = useState<string | undefined>('')
    const [contactNumber2Error, setContactNumber2Error] = useState<string>('')

    const [oldBalance, setOldBalance] = useState<string>('')
    const [oldBalanceError, setOldBalanceError] = useState<string>('')

    const [asOnDate, setAsOnDate] = useState<string>('')
    const [asOnDateError, setAsOnDateError] = useState<string>('')

    const [gstNumber, setGstNumber] = useState<string | undefined>('')
    const [gstNumberError, setGstNumberError] = useState<string>('')

    const [aadharNumber, setAadharNumber] = useState<string | undefined>('')
    const [aadharNumberError, setAadharNumberError] = useState<string>('')

    const [contactPerson, setContactPerson] = useState<string>('')
    const [contactPersonError, setContactPersonError] = useState<string>('')

    const [contactPersonNumber, setContactPersonNumber] = useState<string | undefined>('')
    const [contactPersonNumberError, setContactPersonNumberError] = useState<string>('')


    const [bankAccountNumber, setBankAccountNumber] = useState<string | undefined>('')
    const [bankAccountNumberError, setBankAccountNumberError] = useState<string>('')

    const [bankName, setBankName] = useState<string>('')
    const [bankNameError, setBankNameError] = useState<string>('')

    const [bankBranchName, setBankBranchName] = useState<string>('')
    const [bankBranchNameError, setBankBranchNameError] = useState<string>('')

    const [ifscCode, setIfscCode] = useState<string>('')
    const [ifscCodeError, setIfscCodeError] = useState<string>('')


    const [areaName, setAreaName] = useState<string>('')
    const [areaNameError, setAreaNameError] = useState<string>('')
    const [openModal, setOpenModal] = useState(false)

    const [placeName, setPlaceName] = useState<string>('')
    const [placeNameError, setPlaceNameError] = useState<string>('')
    const [placeModal, setPlaceModal] = useState(false)

    const [supplierPlaceName, setSupplierPlaceName] = useState<string>('')
    const [supplierPlaceNameError, setSupplierPlaceNameError] = useState<string>('')
    const [showSupplierPlaceModal, setShowSupplierPlaceModal] = useState(false)

    const [routeName, setRouteName] = useState<string>('')
    const [routeNameError, setRouteNameError] = useState<string>('')
    const [routeModal, setRouteModal] = useState(false)

    const [districtName, setDistrictName] = useState<string>('')
    const [districtNameError, setDistrictNameError] = useState<string>('')
    const [districtModal, setDistrictModal] = useState(false)

    const [stateName, setStateName] = useState<string>('')
    const [stateNameError, setStateNameError] = useState<string>('')
    const [stateModal, setStateModal] = useState(false)

    const [countryName, setCountryName] = useState<string>('')
    const [countryNameError, setCountryNameError] = useState<string>('')
    const [countryModal, setCountryModal] = useState(false)

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''

    const mainContentRef = React.useRef<HTMLDivElement>(null)


    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    const filterAccountHandler = (account: any, searchTerm: any) => {
        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.accountName)
            // || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.place)
            || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(account.contactNumber1)) : true || []
    }


    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])

    const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBranchId(e.target.value)
        setBranchIdError('')
    }

    const handleGroup: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGroupName(e.target.value as ActiveTypes)
        setGroupNameError('')
    }

    const handleTag: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNameTag(e.target.value)
        setNameTagError('')
    }

    const handleAccountName: ChangeEventHandler<HTMLInputElement> = (e) => {
        const newAccountName = e.target.value.toLowerCase();

        const isDuplicate = AccountList.some(account => account.accountName && account.accountName.toLowerCase() === newAccountName.toLowerCase());

        if (isDuplicate) {
            setAccountNameError('Account Name must be unique');
        } else {
            setAccountName(e.target.value);
            setAccountNameError('');
        }
    };


    const handleAddress1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress1(e.target.value)
        setAddress1Error('')
    }

    const handleAddress2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress2(e.target.value)
        setAddress2Error('')
    }

    const handleArea: ChangeEventHandler<HTMLInputElement> = (e) => {
        setArea(e.target.value)
        setAreaError('')
    }

    const handlePlace: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlace(e.target.value)
        setPlaceError('')
    }

    const handleSupplierPlace: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSupplierPlace(e.target.value)
        setSupplierPlaceError('')
    }

    const handleDistrict: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrict(e.target.value)
        setDistrictError('')
    }

    const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPincode(e.target.value)
        setPincodeError('')
    }

    const handleState: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const handleCountry: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountry(e.target.value)
        setCountryError('')
    }

    const handleRoute: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoute(e.target.value)
        setRouteError('')
    }

    const handleContactNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber1(e.target.value)
        setContactNumber1Error('')
    }

    const handleContactNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNumber2(e.target.value)
        setContactNumber2Error('')
    }

    const handleOldBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalance(e.target.value)
        setOldBalanceError('')
        if (e.target.value === '') setAsOnDate('')
    }

    const handleAsOnDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAsOnDate(e.target.value)
        setAsOnDateError('')
    }

    const handleGstNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGstNumber(e.target.value)
        setGstNumberError('')
    }

    const handleAadharNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNumber(e.target.value)
        setAadharNumberError('')
    }

    const handleContactPerson: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPerson(e.target.value)
        setContactPersonError('')
    }


    const handleContactPersonNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPersonNumber(e.target.value)
        setContactPersonNumberError('')
    }

    const handleBankAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankAccountNumber(e.target.value)
        setBankAccountNumberError('')
    }

    const handleBankName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankName(e.target.value)
        setBankNameError('')
    }

    const handleBranchBankName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankBranchName(e.target.value)
        setBankBranchNameError('')
    }

    const handleIfscCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIfscCode(e.target.value)
        setIfscCodeError('')
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = async (value) => {
        setDeleteId(value.accountNumber)

        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const handleModalClose = () => {
        setOpenModal(false)
        setPlaceModal(false)
        setShowSupplierPlaceModal(false)
        setRouteModal(false)
        setDistrictModal(false)
        setStateModal(false)
        setCountryModal(false)
    }

    const handleAreaName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAreaName(e.target.value)
    }
    const handlePlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPlaceName(e.target.value)
    }
    const handleSupplierPlaceName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSupplierPlaceName(e.target.value)
    }
    const handleRouteName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteName(e.target.value)
    }
    const handleDistrictName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDistrictName(e.target.value)
    }
    const handleStateName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStateName(e.target.value)
    }
    const handleCountryName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCountryName(e.target.value)
    }
    const resetStates = () => {
        setBranchId('')
        setGroupName('')
        setNameTag('')
        setAccountName('')
        setBankName('')
        setBankAccountNumber('')
        setBankBranchName('')
        setIfscCode('')
        setAddress1('')
        setAddress2('')
        setArea('')
        setPlace('')
        setSupplierPlace('')
        setDistrict('')
        setPincode('')
        setState('')
        setCountry('')
        setRoute('')
        setContactNumber1('')
        setContactNumber2('')
        setContactNumber2Error('')
        setOldBalance('')
        setAsOnDate('')
        setAsOnDateError('')
        setGstNumber('')
        setGstNumberError('')
        setAadharNumber('')
        setAadharNumberError('')
        setContactPerson('')
        setContactPersonNumber('')
        setAreaName('')
        setPlaceName('')
        setRouteName('')
        setDistrictName('')
        setStateName('')
        setCountryName('')
        setEditData(null)
    }


    const handleSave = () => {
        let error = false

        const addData: AccountHolderType = {
            // accountNumber: id,
            branchId: parseInt(branchId),
            groupName: groupName,
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(contactNumber1 !== undefined ? contactNumber1 : ''),
            contactNumber2: parseInt(contactNumber2 !== undefined ? contactNumber2 : ''),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            supplierPlace: supplierPlace,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ''),
            state: state,
            country: country,
            route: route,
            oldBalance: parseInt(oldBalance),
            asOnDate: asOnDate,
            gstNumber: gstNumber,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ''),
            contactPerson: contactPerson,
            contactPersonNumber: parseInt(contactPersonNumber !== undefined ? contactPersonNumber : ''),
            bankAccountNumber: parseInt(bankAccountNumber !== undefined ? bankAccountNumber : ''),
            bankName: bankName,
            bankBranchName: bankBranchName,
            ifscCode: ifscCode,
            branch: `${AuthUser?.branchId}`
        }


        const editdata: AccountHolderType = {
            accountNumber: accountNumber,
            branchId: parseInt(branchId),
            groupName: groupName,
            nameTag: nameTag,
            accountName: accountName,
            contactNumber1: parseInt(contactNumber1 !== undefined ? contactNumber1 : ''),
            contactNumber2: parseInt(contactNumber2 !== undefined ? contactNumber2 : ''),
            address1: address1,
            address2: address2,
            area: area,
            place: place,
            supplierPlace: supplierPlace,
            district: district,
            pincode: parseInt(pincode !== undefined ? pincode : ''),
            state: state,
            country: country,
            route: route,
            oldBalance: parseInt(oldBalance),
            asOnDate: asOnDate,
            gstNumber: gstNumber,
            aadharNumber: parseInt(aadharNumber !== undefined ? aadharNumber : ''),
            contactPerson: contactPerson,
            contactPersonNumber: parseInt(contactPersonNumber !== undefined ? contactPersonNumber : ''),
            bankAccountNumber: parseInt(bankAccountNumber !== undefined ? bankAccountNumber : ''),
            bankName: bankName,
            bankBranchName: bankBranchName,
            ifscCode: ifscCode,
            branch: `${AuthUser?.branchId}`

        }

        if (branchId?.toString() === '') {
            setBranchIdError('Branch name required')
            error = true
        }

        if (groupName === '') {
            setGroupNameError('Group name required')
            error = true
        }

        if (nameTag === '') {
            setNameTagError('Name tag required')
            error = true
        }

        if (accountName === '') {
            setAccountNameError('Account name required')
            error = true
        }

        if (contactNumber1?.toString() === '') {
            setContactNumber1Error('Primary contact number required')
            error = true
        } else if ((contactNumber1?.toString() || '').length > 10 || (contactNumber1?.toString() || '').length < 10) {
            setContactNumber1Error('Enter your 10 digits primary contact number')
            error = true
        }

        if (contactNumber2 && contactNumber2.toString().trim().length > 0) {
            if ((contactNumber2?.toString() || '').length > 10 || (contactNumber2?.toString() || '').length < 10) {
                setContactNumber2Error('Enter your 10 digits secondary contact number')
                error = true
            }
        }

        if (groupName.toLocaleLowerCase() === 'supplier') {
            if (supplierPlace === '') {
                setSupplierPlaceError('Supplier place required')
                error = true
            }
        }

        if (pincode && pincode.toString().trim().length > 0) {
            if ((pincode?.toString() || '').length > 6 || (pincode?.toString() || '').length < 6) {
                setPincodeError('Enter your 6 digits pincode')
                error = true
            }
        }


        if (gstNumber && gstNumber.toString().trim().length > 0) {
            if ((gstNumber || '').length > 15 || (gstNumber || '').length < 15) {
                setGstNumberError('Enter your valid GST number')
                error = true
            }
        }

        if (aadharNumber && aadharNumber.toString().trim().length > 0) {
            if ((aadharNumber?.toString() || '').length > 12 || (aadharNumber?.toString() || '').length < 12) {
                setAadharNumberError('Enter your valid 12 digits aadhar number')
                error = true
            }
        }

        if (contactPersonNumber && contactPersonNumber.toString().trim().length > 0) {
            if ((contactPersonNumber?.toString() || '').length > 10 || (contactPersonNumber?.toString() || '').length < 10) {
                setContactPersonNumberError('Enter your 10 digits contact number')
                error = true
            }
        }

        if (oldBalance !== '' && parseFloat(oldBalance) > 0) {
            if (asOnDate === '') {
                setAsOnDateError('Till Date Required')
                error = true
            }
        }

        if (!error) {

            if (formType === FormTypeList.ADD) {
                dispatch(addAccountHolderAction(addData)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editAccountHolderAction(editdata, editData?.accountNumber)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                    setEditData('' || null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            const dateObj = new Date(editData.asOnDate || '')

            const d = dateObj.getDate().toString().padStart(2, '0')
            const m = (dateObj.getMonth() + 1).toString().padStart(2, '0')
            const y = dateObj.getFullYear().toString()

            setAccountNumber(editData.accountNumber)
            setBranchId(editData.branchId?.toString() || '')
            setGroupName(editData.groupName || '')
            setNameTag(editData.nameTag || '')
            setAccountName(editData.accountName || '')
            setAddress1(editData.address1 || '')
            setAddress2(editData.address2 || '')
            setArea(editData.area || '')
            setPlace(editData.place || '')
            setSupplierPlace(editData.supplierPlace || '')
            setDistrict(editData.district || '')
            setPincode(editData.pincode !== 0 ? editData.pincode?.toString() : '')
            setState(editData.state || '')
            setCountry(editData.country || '')
            setRoute(editData.route || '')
            setContactNumber1(editData.contactNumber1 !== 0 ? editData.contactNumber1?.toString() : '')
            setContactNumber2(editData.contactNumber2 !== 0 ? editData.contactNumber2?.toString() : '')
            setOldBalance(editData.oldBalance?.toString() || '')
            setAsOnDate(y + '-' + m + '-' + d)
            setGstNumber(editData.gstNumber || '')
            setAadharNumber(editData.aadharNumber !== 0 ? editData.aadharNumber?.toString() : '')
            setContactPerson(editData.contactPerson || '')
            setContactPersonNumber(editData.contactPersonNumber !== 0 ? editData.contactPersonNumber?.toString() : '')
            setBankAccountNumber(editData.bankAccountNumber !== 0 ? editData.bankAccountNumber?.toString() : '')
            setBankName(editData.bankName || '')
            setBankBranchName(editData.bankBranchName?.toString() || '')
            setIfscCode(editData.ifscCode || '')
        } else {
            resetStates()
        }
    }, [formType, editData])

    const onArea = () => {
        let error = false

        if (areaName === '') {
            setAreaNameError('Division required')
            error = true
        }


        const addDynArea: DropDownType = {
            id: id,
            category: "area",
            title: areaName
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynArea)).then(text => {
                    toast('Added Division', ToastTypes.SUCCESS)
                    setAreaName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setOpenModal(false)
        }

    }
    const onPlace = () => {

        let error = false

        if (placeName === '') {
            setPlaceNameError('Place required')
            error = true
        }

        const addDynPlace: DropDownType = {
            id: id,
            category: "place",
            title: placeName
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynPlace)).then(text => {
                    toast('Added Place', ToastTypes.SUCCESS)
                    setPlaceName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setPlaceModal(false)
        }

    }
    const onSupplierPlace = () => {
        let error = false
        if (supplierPlaceName === '') {
            setSupplierPlaceNameError('Supplier Place required')
            error = true
        }

        const addDynSupplierPlace: DropDownType = {
            id: id,
            category: "supplier place",
            title: supplierPlaceName
        }
        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynSupplierPlace)).then(text => {
                    toast('Supplier Place Added', ToastTypes.SUCCESS)
                    setSupplierPlaceName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setShowSupplierPlaceModal(false)
        }

    }
    const onRoute = () => {
        let error = false
        if (routeName === '') {
            setRouteNameError('Route required')
            error = true
        }


        const addDynRoute: DropDownType = {
            id: id,
            category: 'route',
            title: routeName
        }
        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynRoute)).then(text => {
                    toast('Added Route', ToastTypes.SUCCESS)
                    setRouteName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setRouteModal(false)
        }

    }

    const onDistrict = () => {
        let error = false
        if (districtName === '') {
            setDistrictNameError('District required')
            error = true
        }


        const addDynDistrict: DropDownType = {
            id: id,
            category: 'district',
            title: districtName
        }
        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynDistrict)).then(text => {
                    toast('Added District', ToastTypes.SUCCESS)
                    setDistrictName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setDistrictModal(false)
        }
    }

    const onState = () => {
        let error = false
        if (stateName === '') {
            setStateNameError('State required')
            error = true
        }

        const addDynState: DropDownType = {
            id: id,
            category: 'state',
            title: stateName
        }
        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynState)).then(text => {
                    toast('Added State', ToastTypes.SUCCESS)
                    setStateName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setStateModal(false)
        }

    }
    const onCountry = () => {
        let error = false
        if (countryName === '') {
            setCountryNameError('Country required')
            error = true
        }


        const addDynCountry: DropDownType = {
            id: id,
            category: 'country',
            title: countryName
        }
        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addDynCountry)).then(text => {
                    toast('Added Country', ToastTypes.SUCCESS)
                    setCountryName('')
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
            setCountryModal(false)
        }

    }
    const onDelete = () => {

        if (deleteId) {
            dispatch(deleteAccountHolderAction(deleteId)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }
    return <>
        <Modal
            headerText={'Delete AccountHolder'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                value={deleteName}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <Modal
            headerText={'Add Division'}
            visible={openModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="areaName"
                value={areaName}
                errorText={areaNameError}
                required={true}
                onChange={handleAreaName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setAreaName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onArea}>save</button>
        </Modal>
        <Modal
            headerText={'Add Place'}
            visible={placeModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="placeName"
                value={placeName}
                errorText={placeNameError}
                required={true}
                onChange={handlePlaceName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setPlaceName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onPlace}>save</button>
        </Modal>
        <Modal
            headerText={'Add Supplier Place'}
            visible={showSupplierPlaceModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="supplierPlaceName"
                value={supplierPlaceName}
                errorText={supplierPlaceNameError}
                required={true}
                onChange={handleSupplierPlaceName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setSupplierPlaceName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onSupplierPlace}>save</button>
        </Modal>
        <Modal
            headerText={'Add Route'}
            visible={routeModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="routeName"
                value={routeName}
                errorText={routeNameError}
                required={true}
                onChange={handleRouteName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setRouteName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onRoute}>save</button>
        </Modal>

        <Modal
            headerText={'Add District'}
            visible={districtModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="districtName"
                value={districtName}
                errorText={districtNameError}
                required={true}
                onChange={handleDistrictName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setDistrictName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onDistrict}>save</button>
        </Modal>
        <Modal
            headerText={'Add State'}
            visible={stateModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="stateName"
                value={stateName}
                errorText={stateNameError}
                required={true}
                onChange={handleStateName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setStateName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onState}>save</button>
        </Modal>
        <Modal
            headerText={'Add Country'}
            visible={countryModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="countryName"
                value={countryName}
                errorText={countryNameError}
                required={true}
                onChange={handleCountryName}
                className="mb-3"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={() => setCountryName('')}>Add New</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={onCountry}>save</button>
        </Modal>

        <UserLayout>
            <h4>Account Holder</h4>
            <div className="row">
                <div className="col-lg-7">
                    <div className="card shadow-lg p-1">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className='col-sm-6 col-md-6 col-lg-5'>
                                    <FormInput
                                        name='branchId'
                                        label='Branch Name'
                                        labelClassName="required"
                                        required={true}
                                        value={branchId}
                                        onChange={handleBranch}
                                        errorText={branchIdError}
                                        type="select"
                                    >
                                        <option value="">Select Branch</option>
                                        {BranchList.map((br: BranchType) => {
                                            return <option value={br.id}>{br.name}</option>
                                        })}
                                    </FormInput>
                                </div>

                                <div className='col-sm-6 col-md-6 col-lg-5
                                '>
                                    <FormInput
                                        name='groupName'
                                        label='Group Name'
                                        labelClassName="required"
                                        required={true}
                                        value={groupName}
                                        onChange={handleGroup}
                                        errorText={groupNameError}

                                        type="select"
                                    >
                                        <option value="">Select Group</option>
                                        {DropDownList.filter((ddl: DropDownType) => ddl.category === 'groupmaster').map((ddl) => {
                                            return <option value={ddl.title}>{ddl.title}</option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-2 mt-2">
                                    <button className="btn btn-sm fw-bold btn-secondary" style={{ float: "right" }} onClick={() => resetStates()}>Add New</button>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='nameTag'
                                        label='Name Tag'
                                        labelClassName="required"
                                        required={true}
                                        value={nameTag}
                                        onChange={handleTag}
                                        errorText={nameTagError}

                                        type="select"
                                    >
                                        <option value="">Select Tag</option>
                                        {["Mr.", "Mrs.", "Miss.", "Master.", "Baby.", "Dr.", "Er."].map((tag, i) => {
                                            return <option value={tag} key={i}>{tag}</option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className='col-sm-6 col-md-8 col-lg-8'>
                                    <FormInput
                                        name='accountName'
                                        label='Account Name'
                                        labelClassName="required"
                                        required={true}
                                        value={accountName}
                                        onChange={handleAccountName}
                                        errorText={accountNameError}
                                    />
                                </div>


                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='contactNumber1'
                                        label='Primary Contact Number'
                                        labelClassName="required"
                                        required={true}
                                        value={contactNumber1}
                                        onChange={handleContactNumber1}
                                        errorText={contactNumber1Error}
                                        type="text"
                                    />
                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='contactNumber2'
                                        label='Secondary Contact Number'
                                        labelClassName="required"
                                        value={contactNumber2}
                                        onChange={handleContactNumber2}
                                        errorText={contactNumber2Error}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='address1'
                                        label='Address 1'
                                        labelClassName="required"
                                        value={address1}
                                        onChange={handleAddress1}
                                        errorText={address1Error}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='address2'
                                        label='Address 2'
                                        labelClassName="required"
                                        value={address2}
                                        onChange={handleAddress2}
                                        errorText={address2Error}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='area'
                                        label='Division'
                                        labelClassName="required"
                                        value={area}
                                        onChange={handleArea}
                                        errorText={areaError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setOpenModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {DropDownList.filter((ddl) => ddl.category === 'area').map((ddl, i) => {
                                            return <option value={ddl.title}>{ddl.title}</option>
                                        })}
                                    </FormInput>
                                </div>
                                {/* </div> */}
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='place'
                                        label='Place'
                                        labelClassName="required"
                                        value={place}
                                        onChange={handlePlace}
                                        errorText={placeError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1 cursor-pointer`} onClick={() => setPlaceModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {
                                            DropDownList.filter((ddl: DropDownType) => ddl.category === 'place').map((ddl) => {
                                                return <option value={ddl.title}>{ddl.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                                {groupName.toLocaleLowerCase() === "supplier" ?
                                    <>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <FormInput
                                                name='supplierPlace'
                                                label=' Supplier Place'
                                                labelClassName="required"
                                                value={supplierPlace}
                                                onChange={handleSupplierPlace}
                                                errorText={supplierPlaceError}
                                                required={true}
                                                type="select"
                                                icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1 cursor-pointer`} onClick={() => setShowSupplierPlaceModal(true)}>
                                                    <div className="modal fade"></div>
                                                </i>}
                                            >
                                                <option value="">select</option>
                                                {
                                                    DropDownList.filter((ddl: DropDownType) => ddl.category.toLocaleLowerCase() === 'supplier place').map((ddl) => {
                                                        return <option value={ddl.title}>{ddl.title}</option>
                                                    })
                                                }
                                            </FormInput>
                                        </div>
                                    </> : ''
                                }
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='district'
                                        label='District'
                                        labelClassName="required"
                                        value={district}
                                        onChange={handleDistrict}
                                        errorText={districtError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setDistrictModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {
                                            DropDownList.filter((ddl: DropDownType) => ddl.category === 'district').map((ddl) => {
                                                return <option value={ddl.title}>{ddl.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='pincode'
                                        label='Pincode'
                                        labelClassName="required"
                                        value={pincode}
                                        onChange={handlePincode}
                                        errorText={pincodeError}
                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='state'
                                        label='State'
                                        labelClassName="required"
                                        value={state}
                                        onChange={handleState}
                                        errorText={stateError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setStateModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {
                                            DropDownList.filter((ddl: DropDownType) => ddl.category === 'state').map((ddl) => {
                                                return <option value={ddl.title}>{ddl.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='country'
                                        label='Country'
                                        labelClassName="required"
                                        value={country}
                                        onChange={handleCountry}
                                        errorText={countryError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setCountryModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {DropDownList.filter((ddl: DropDownType) => ddl.category === 'country').map((ddl) => {
                                            return <option value={ddl.title}>{ddl.title}</option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='route'
                                        label='Route'
                                        labelClassName="required"
                                        value={route}
                                        onChange={handleRoute}
                                        errorText={routeError}

                                        type="select"
                                        icon={<i data-toggle="modal" className={`fe-plus-circle me-1 p-1`} onClick={() => setRouteModal(true)}>
                                            <div className="modal fade"></div>
                                        </i>}
                                    >
                                        <option value="">select</option>
                                        {
                                            DropDownList.filter((ddl: DropDownType) => ddl.category.toLocaleLowerCase() === 'route').map((ddl) => {
                                                return <option value={ddl.title}>{ddl.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>


                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='gstNumber'
                                        label='GST Number'
                                        labelClassName="required"
                                        value={gstNumber}
                                        onChange={handleGstNumber}
                                        errorText={gstNumberError}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='aadharNumber'
                                        label='Aadhar Number'
                                        labelClassName="required"
                                        value={aadharNumber}
                                        onChange={handleAadharNumber}
                                        errorText={aadharNumberError}
                                        type="text"
                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='contactPerson'
                                        label='Contact Person'
                                        labelClassName="required"
                                        value={contactPerson}
                                        onChange={handleContactPerson}
                                        errorText={contactPersonError}

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='contactPersonNumber'
                                        label='Contact Person Number'
                                        labelClassName="required"
                                        value={contactPersonNumber}
                                        onChange={handleContactPersonNumber}
                                        errorText={contactPersonNumberError}
                                        type="text"

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='oldBalance'
                                        label='Old Balance'
                                        labelClassName="required"
                                        value={oldBalance}
                                        onChange={handleOldBalance}
                                        errorText={oldBalanceError}
                                        type="text"

                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='asOnDate'
                                        label='Till Date'
                                        labelClassName="required"
                                        value={asOnDate}
                                        onChange={handleAsOnDate}
                                        errorText={asOnDateError}
                                        type="date"
                                        disabled={oldBalance ? false : true}
                                    />
                                </div>
                                <label htmlFor="" className="fs-5">Bank Details :</label>

                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='bankAccountNumber'
                                        label='Bank Account Number'
                                        labelClassName="required"
                                        value={bankAccountNumber}
                                        onChange={handleBankAccountNumber}
                                        errorText={bankAccountNumberError}
                                        type="text"
                                    >
                                    </FormInput>
                                </div>
                                <div className='col-sm-4 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='bankName'
                                        label='Bank Name'
                                        labelClassName="required"
                                        value={bankName}
                                        onChange={handleBankName}
                                        errorText={bankNameError}
                                    />
                                </div>
                                <div className='col-sm-4 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='bankBranchName'
                                        label='Branch Name'
                                        labelClassName="required"
                                        value={bankBranchName}
                                        onChange={handleBranchBankName}
                                        errorText={bankBranchNameError}
                                    >
                                    </FormInput>
                                </div>
                                <div className='col-sm-4 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='ifscCode'
                                        label='IFSC Code'
                                        labelClassName="required"
                                        value={ifscCode}
                                        onChange={handleIfscCode}
                                        errorText={ifscCodeError}

                                    >
                                    </FormInput>
                                </div>
                                {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT || formType === FormTypeList.DELETE) && <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                    {/* <button className="btn btn-sm fw-bold btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                    <button className="btn btn-sm fw-bold btn-success" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="card shadow-lg p-1" style={{ height: '515px', overflow: "auto" }}>
                        {/* <div className="card-body"> */}
                        <div>
                            <FormInput
                                onChange={searchTermChangeHandler}
                                name=""
                                placeholder='Search...'
                                value={searchTerm}
                                onFocus={() => {
                                    mainContentRef?.current?.scrollTo({
                                        behavior: 'smooth',
                                        top: 270
                                    })
                                }}
                                innerRef={searchRef}
                                className="container my-2"
                            />

                        </div>
                        <div className='table-wrapper'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Account Name</th>
                                        <th className='text-truncate align-middle'>Place</th>
                                        <th className='text-truncate align-middle'>Contact Number</th>
                                        <th className='text-truncate align-middle'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {AccountList?.filter((account) => filterAccountHandler(account, searchTerm))?.map((al, i) => {
                                        return <AccountBody
                                            sno={i + 1}
                                            accountName={al.accountName}
                                            place={al.place}
                                            contactNumber1={al.contactNumber1}
                                            onEdit={() => handleEdit(al)}
                                            onDelete={() => handleDelete(al)}

                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </UserLayout >
    </>
}

const AccountBody = ({ sno, accountName, place, contactNumber1, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{accountName}</td>
        <td className='align-middle'>{place}</td>
        <td className='text-capitalize align-middle'>{contactNumber1}</td>
        <td className="align-middle">
            <div className='hstack gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default AccountHolder