import api from "../config/api"
import { CustomerType } from "../redux/actions/customerAction"

export const addCustomer = async (data: CustomerType) => {
    return await api().post('/customer/', data)
}

export const editCustomer = async (data: CustomerType, account_number: number | undefined) => {
    return await api().put(`/customer/?accountNumber=${account_number}`, data)
}

export const getCustomer = async () => {

    return await api().get('/customer/')
}

export const deleteCustomer = async (account_number: number | undefined) => {
    return await api().delete(`/customer/?accountNumber=${account_number}`)
}