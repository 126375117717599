import api from "../config/api"
import { PurchaseReturnType } from "../redux/actions/purchaseReturnAction"

export const addPurchaseReturn = async (data: PurchaseReturnType) => {
    return await api().post('/purchaseReturn/', data)
}

export const editPurchaseReturn = async (data: PurchaseReturnType, id: number | undefined) => {
    return await api().put(`/purchaseReturn/?id=${id}`, data)
}

export const getPurchaseReturn = async () => {
    return await api().get('/purchaseReturn/')
}

export const getPurchaseReturnById = (id: number) => {
    return api().get(`/purchaseReturn/?id=${id}`)
}

export const getPurchaseReturnByDate = (startDate:string,endDate:string) => {
    return api().get(`/purchaseReturn/?startDate=${startDate}&endDate=${endDate}`)
}


export const getPurchaseReturnItemsByPurchaseDate = (date: string) => {
    return api().get(`/purchaseReturnItems/?date=${date}`)
}

export const deletePurchaseReturn = (id: number | undefined) => {
    return api().delete(`/purchaseReturn/?id=${id}`)

}