import { MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmPurchaseReturn from "../components/DeleteConfirmationPurchaseReturn"
import Modal from "../components/Modal"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { LocalFormatDate } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { PurchaseReturnType, fetchPurchaseReturnAction } from "../redux/actions/purchaseReturnAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number
    invoiceDate: string
    invoiceNo: string
    purchaseReturnEntryNo: string
    supplier: string
    finalAmount: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: PurchaseReturnType) => void
type HandleDelete = (value: PurchaseReturnType) => void

interface Props {
    setFormType: React.Dispatch<React.SetStateAction<FormType>>
    setId: React.Dispatch<React.SetStateAction<number | undefined>>
    onclose: () => void
}

const PurchaseReturn = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const purchaseList = useSelector<StoreState, PurchaseReturnType[]>(state => state.purchaseReturn)
    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [id, setId] = useState<number>()

    const [editData, setEditData] = useState<PurchaseReturnType>()

    const handleEdit: HandleEdit = (value) => {
        props.setFormType(FormTypeList.EDIT)
        props.setId(value.id)
        setEditData(value)
        console.log('edit');
        props.onclose()
    }

    const handleDelete: HandleDelete = (value) => {
        props.setFormType(FormTypeList.DELETE)
        setEditData(value)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    useEffect(() => {
        dispatch(fetchPurchaseReturnAction())
    }, [])

    return <>
        <Modal
            headerText={'Delete PurchaseReturn'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='sm'
        >
            <DeleteConfirmPurchaseReturn
                editData={editData}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow-lg">
                    {/* <div className="card-body"> */}
                    <div className='table-wrapper'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Purchase Return Entry No</th>
                                    <th className='text-truncate align-middle'>Invoice Date</th>
                                    <th className='text-truncate align-middle'>Invoice No</th>
                                    <th className='text-truncate align-middle'>Supplier</th>
                                    <th className='text-truncate align-middle'>Grand Total</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchaseList.map((tr, i) => {
                                    let sobj = AccountLists.find(s => s?.accountNumber === tr?.supplierId)
                                    let sname = sobj?.accountName ? sobj?.accountName : ''
                                    // console.log(tr.purchaseReturnEntryNumber);
                                    return <UserBody
                                        sno={i + 1}
                                        invoiceNo={tr.invoiceNumber?.toString()}
                                        invoiceDate={tr.invoiceDate?.toString()}
                                        purchaseReturnEntryNo={tr.purchaseReturnEntryNumber?.toString()}
                                        finalAmount={tr.grandTotal ? tr.grandTotal?.toString() : '0'}
                                        supplier={sname}
                                        onEdit={() => handleEdit(tr)}
                                        onDelete={() => handleDelete(tr)}
                                        key={i}

                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    </>
}

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, purchaseReturnEntryNo, supplier, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{purchaseReturnEntryNo}</td>
        <td className='text-capitalize align-middle'>{LocalFormatDate(invoiceDate)}</td>
        <td className='text-capitalize align-middle'>{invoiceNo}</td>
        <td className='text-capitalize align-middle'>{supplier}</td>
        <td className='text-capitalize align-middle'>{finalAmount}</td>
        <td className="align-middle">
            <div className='hstack gap-1'>
                <button className='btn btn-sm fw-bold btn-info p-1' onClick={onEdit}><i className="fe-edit noti-icon"></i></button>
                <button className='btn btn-sm fw-bold btn-danger p-1' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></button>
            </div>
        </td>
    </tr>
}
export default PurchaseReturn