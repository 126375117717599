import { TransactionBalanceActionsList } from "../actions/transactionBalance";
import { Action } from "../actions/types";

const initialValue: Array<any> = []

export const transactionBalanceReducer = (state: any[] = initialValue, action: Action) => {

    switch (action.type) {


        case TransactionBalanceActionsList.FETCH_TRANSACTIONBALANCE:

            return action.data

        default:
            return state
    }

}
