import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { addCustomer, deleteCustomer, editCustomer, getCustomer } from "../../services/customer.services"



export interface CustomerType {
    accountNumber?: number
    branchId?: number
    nameTag?: string
    accountName?: string
    address1?: string
    address2?: string
    area?: string
    place?: string
    supplierPlace?: string
    district?: string
    pincode?: number | undefined | string
    state?: string
    country?: string
    route?: string
    contactNumber1?: number | undefined | string
    contactNumber2?: number
    aadharNumber?: number | undefined | string
    score?: string
    // updatedAt: string
    // updatedBy: number | null
}

export enum CustomerActionsList {
    ADD_CUSTOMER = 'ADD_CUSTOMER',
    EDIT_CUSTOMER = 'EDIT_CUSTOMER',
    FETCH_CUSTOMER = 'FETCH_CUSTOMER',
    DELETE_CUSTOMER = 'DELETE_CUSTOMER'
}

export interface deleteCustomerHolder {
    accountNumber: number | undefined
}

export interface AddCustomerAction {
    type: CustomerActionsList.ADD_CUSTOMER
    data: CustomerType
}

export interface EditCustomerAction {
    type: CustomerActionsList.EDIT_CUSTOMER
    data: CustomerType
    accountNumber: number | undefined
}

export interface FetchCustomerAction {
    type: CustomerActionsList.FETCH_CUSTOMER
    data: CustomerType[]
}

export interface DeleteCustomerAction {
    type: CustomerActionsList.DELETE_CUSTOMER
    data: number | undefined
}

export type CustomerActions = AddCustomerAction | EditCustomerAction | FetchCustomerAction | DeleteCustomerAction

export const addCustomerAction = (data: CustomerType) => {
    return async (dispatch: Dispatch) => {
        return addCustomer(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddCustomerAction>({
                    type: CustomerActionsList.ADD_CUSTOMER,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editCustomerAction = (data: CustomerType, accountNumber: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editCustomer(data, accountNumber).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditCustomerAction>({
                    type: CustomerActionsList.EDIT_CUSTOMER,
                    data: editData,
                    accountNumber: accountNumber
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}


export const fetchCustomerAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getCustomer()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchCustomerAction>({
            type: CustomerActionsList.FETCH_CUSTOMER,
            data: data
        })
    }
}

export const deleteCustomerAction = (accountNumber: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteCustomer(accountNumber).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCustomerAction>({
                    type: CustomerActionsList.DELETE_CUSTOMER,
                    data: accountNumber
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Acoount Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}