import { Dispatch } from "redux"
import { toCamelize } from "../../config/functions"
import { addTransaction, deleteTransaction, editTransaction, getTransactionBalance } from "../../services/transaction.service"
import { GetState } from "../store"



export enum TransactionBalanceActionsList {
    ADD_TRANSACTIONBALANCE = 'ADD_TRANSACTIONBALANCE',
    EDIT_TRANSACTIONBALANCE = 'EDIT_TRANSACTIONBALANCE',
    FETCH_TRANSACTIONBALANCE = 'FETCH_TRANSACTIONBALANCE',
    DELETE_TRANSACTIONBALANCE = 'DELETE_TRANSACTIONBALANCE'
}


export interface AddTransactionBalanceAction {
    type: TransactionBalanceActionsList.ADD_TRANSACTIONBALANCE
    data: any
}

export interface EditTransactionBalanceAction {
    type: TransactionBalanceActionsList.EDIT_TRANSACTIONBALANCE
    data: any
    id: number | undefined
}

export interface FetchTransactionBalanceAction {
    type: TransactionBalanceActionsList.FETCH_TRANSACTIONBALANCE
    data: any[]

}

export interface DeleteTransactionBalanceAction {
    type: TransactionBalanceActionsList.DELETE_TRANSACTIONBALANCE
    data: number | undefined
}

export type TransactionBalanceActions = AddTransactionBalanceAction | EditTransactionBalanceAction | FetchTransactionBalanceAction | DeleteTransactionBalanceAction

export const addTransactionBalanceAction = (data: any) => {
    
    return async (dispatch: Dispatch) => {
        return addTransaction(data).then(response => {
            if (response.status === 200 && response.data.data) {
                const addData = response.data.data
                dispatch<AddTransactionBalanceAction>({
                    type: TransactionBalanceActionsList.ADD_TRANSACTIONBALANCE,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Balance Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
}

export const editTransactionBalanceAction = (data: any, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editTransaction(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = toCamelize<any>(response.data.data)
                dispatch<EditTransactionBalanceAction>({
                    type: TransactionBalanceActionsList.EDIT_TRANSACTIONBALANCE,
                    data: editData,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Balance Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTransactionBalanceAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getTransactionBalance()
        
        dispatch<FetchTransactionBalanceAction>({

            type: TransactionBalanceActionsList.FETCH_TRANSACTIONBALANCE,
            data: response.data.data
        })
    }
}



export const deleteTransactionBalanceAction = (id: number | undefined) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        return deleteTransaction(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTransactionBalanceAction>({
                    type: TransactionBalanceActionsList.DELETE_TRANSACTIONBALANCE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Balance Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })


    }
}