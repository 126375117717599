import { SalesCategoryReportActionList, SalesCategoryType } from "../actions/salesCategoryAction";
import { Action } from "../actions/types";

const initialValue: Array<SalesCategoryType> = []

export const salesCategoryReducer = (state: SalesCategoryType[] = initialValue, action: Action) => {

    switch (action.type) { 
        case SalesCategoryReportActionList.FETCH_SALESCATEGORY:
            return action.data
        default :
            return state
    }
}