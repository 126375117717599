import { PurchaseReturnItems } from "../actions/purchaseReturnAction";
import { PurchaseReturnItemActionList } from "../actions/purchaseReturnItemAction";
import { Action } from "../actions/types";

const initialValue: Array<PurchaseReturnItems> = []

export const purchaseReturnItemReducer = (state: PurchaseReturnItems[] = initialValue, action: Action) => {

    switch (action.type) { 
        case PurchaseReturnItemActionList.FETCH_PURCHASERETURNITEM:
            return action.data
        default :
            return state
    }
}