import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { Badge, Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
// import { FormType, FormTypeList, apiUrl } from "../config"
// import { AppDispatch, StoreState, store } from "../redux/store"
// import { TransactionType, addTransactionAction, deleteTransactionAction, editTransactionAction, fetchTransactionAction } from "../redux/actions/transactionAction"
// import { Badge, Form, FormLabel } from "react-bootstrap"
// import { log } from "console"
// import { timestampToDate, today } from "../config/functions"
// import { DivideCircle } from "react-feather"
// import { GroupMasterType } from "../redux/actions/groupMaster"
// import { DropDownType, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { FormType, FormTypeList, FromList } from "../config"
import { today } from "../config/functions"
import { AccountHolderType, fetchAccountHolderAction } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { DropDownType, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { TransactionType, addTransactionAction, deleteTransactionAction, editTransactionAction, fetchTransactionAction } from "../redux/actions/transactionAction"
import { fetchTransactionBalanceAction } from "../redux/actions/transactionBalance"
// import { UnaryExpression } from "typescript"
// import api from "../config/api"
import TransactionPartyLedgerForm from "../components/TransactionPartyLedgerForm"
import api from "../config/api"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    accountName: string,
    particulars: string,
    debitAmount: number | undefined,
    creditAmount: number | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: TransactionType) => void
type HandleDelete = (value: TransactionType) => void

const Transaction = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const AuthUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const TransactionList = useSelector<StoreState, TransactionType[]>(state => state.transaction)
    const GroupList = useSelector<StoreState, DropDownType[]>(state => state.dropdown)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    const transactionBalance = useSelector<StoreState, any>(state => state.transactionBalance)
    const [tdate, setTDate] = useState<string>(today())
    const [tdateError, setTDateError] = useState<string>('')

    const [showPartyLedgerForm, setShowPartyLedgerForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)

    const [editData, setEditData] = useState<TransactionType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null>(null)
    const [id, setId] = useState<number>()

    const [financialYear, setFinancialYear] = useState<string>('')
    const [financialYearError, setFinancialYearError] = useState<string>('')



    const [branch, setBranch] = useState<string>('')
    const [branchError, setBranchError] = useState<string>('')

    const [paymentMode, setPaymentMode] = useState<string>('Cash')
    const [paymentModeError, setPaymentModeError] = useState<string>('')

    const [voucherType, setVoucherType] = useState<string>('Payment')
    const [voucherTypeError, setVoucherTypeError] = useState<string>('')

    const [bankName, setBank] = useState<string>('')
    const [bankError, setBankError] = useState<string>('')
    const [bankNumber, setBankNumber] = useState<number>()

    const [refNo, setRefNo] = useState<string>('')
    const [refNoError, setRefNoError] = useState<string>('')

    // const [source, setSource] = useState<string>('')
    // const [sourceError, setSourceError] = useState<string>('')

    // const [sourceId, setSourceId] = useState<string>('')
    // const [sourceIdError, setSourceIdError] = useState<string>('')

    const [accountGroupName, setAccountGroupName] = useState<string>('')
    const [accountGroupNameError, setAccountGroupNameError] = useState<string>('')

    const [accountName, setAccountName] = useState<string>('')
    const [accountNameError, setAccountIdError] = useState<string>('')

    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountNumberError, setAccountNumberError] = useState<string>('')

    const [particulars, setParticular] = useState<string>('')
    const [particularError, setParticularError] = useState<string>('')

    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')

    const [selected, setSelected] = useState(true)
    const [viewFrom, SetViewFrom] = useState(false)

    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTDate(e.target.value)
        setTDateError('')
    }

    const handlePaymentMode = (value: string) => {

        setPaymentMode(value)
        setPaymentModeError('')
    }

    const handleVoucher = (value: string) => {
        setVoucherType(value)
        setVoucherTypeError('')
    }

    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(parseInt(e.target.value))
        setBankError('')

        if (e.target.value) {
            let obj = AccountLists.find((al) => al.accountNumber === parseInt(e.target.value))
            setBank(obj?.accountName ? obj?.accountName : '')
        }
    }

    const handleRefNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRefNo(e.target.value)
        setRefNoError('')
    }
    // const handleSource: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setSource(e.target.value)
    //     setSourceError('')
    // }
    // const handleSourceId: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setSourceId(e.target.value)
    //     setSourceIdError('')
    // }

    const handleAccount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountGroupName(e.target.value)
        setAccountGroupNameError('')
    }

    const handleAccountId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
        // setAccountName(e.target.value)
        setAccountNumberError('')
        if (e.target.value) {
            let obj = AccountLists.find((al) => al.accountNumber === parseInt(e.target.value))
            setAccountName(obj?.accountName ? obj?.accountName : '')
        }
    }

    // const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setAccountNumber(e.target.value)
    //     setAccountNumberError('')
    // }

    const handleParticular: ChangeEventHandler<HTMLInputElement> = (e) => {
        setParticular(e.target.value)
        setParticularError('')
    }

    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }


    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.number) {
            setDeleteId(parseInt(value.number))
            setShowDeleteForm(true)
        }
    }

    const handlePartyLedgerForm = () => {
        if (accountName !== '' && accountNumber !== '') {
            setShowPartyLedgerForm(true)
        } else {
            toast('Select Account', ToastTypes.ERROR)
        }
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
        setShowPartyLedgerForm(false)
    }

    const resetStates = () => {
        setFormType(FormTypeList.ADD)
        // setFinancialYear('')
        setTDate(today())
        // setBranch('')
        setPaymentMode('Cash')
        setVoucherType('Payment')
        setBank('')
        setRefNo('')
        // setSource('')
        // setSourceId('')
        setAccountGroupName('')
        setAccountName('')
        setParticular('')
        setAmount('')
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        if (paymentMode === 'Bank') {
            // {
            if (transactionBalance?.filter((tb: any) => tb.accountName === bankName).length == 0) {
                toast('Insufficient Balance,Please Check...', ToastTypes.ERROR)
                error = true
            }
            transactionBalance?.filter((tb: any) => tb.accountName !== null && tb.accountName === bankName).map((tb: any) => {
                if (voucherType === 'Payment') {
                    if (tb.currentBalance <= amount) {
                        toast('Bank Amount insufficient', ToastTypes.ERROR)
                        error = true
                        setFormType(FormTypeList.ADD)
                    }
                }
            })
            // }
        } else if (paymentMode === 'Cash') {
            if (transactionBalance?.filter((tb: any) => tb.accountName === 'Cash Account').length === 0) {
                toast('Insufficient Balance,Please Check...', ToastTypes.ERROR)
                error = true
            }
            transactionBalance?.filter((tb: any) => tb.accountName === 'Cash Account').forEach((tb: any) => {
                if (voucherType === 'Payment') {
                    if (tb.currentBalance <= amount) {
                        toast('Cash Amount insufficient', ToastTypes.ERROR)
                        error = true
                        setFormType(FormTypeList.ADD)
                    }
                }

            })
        }

        const account = AccountLists.find(a => a.accountNumber === parseInt(accountNumber))
        // const account = AccountLists.find(a => a?.accountNumber === parseInt(accountId))

        const addData: TransactionType = {
            financialYear: `${new Date().getFullYear() - 1} -${new Date().getFullYear()} `,
            date: tdate,
            branch: `${AuthUser?.branchId}`,
            paymentMode: paymentMode,
            voucherType: voucherType,
            bankName: bankName,
            bankNumber: bankNumber ? bankNumber : 0,
            refNo: parseInt(refNo),
            // source: source,
            // sourceId: parseInt(sourceId),
            accountGroupName: accountGroupName,
            accountName: accountName || '',
            accountNumber: parseInt(accountNumber) || 0,
            particulars: particulars,
            amount: amount,
        }

        const editObject: TransactionType = {
            id: id,
            financialYear: `${new Date().getFullYear() - 1} -${new Date().getFullYear()} `,
            date: tdate,
            branch: `${AuthUser?.branchId}`,
            paymentMode: paymentMode,
            voucherType: voucherType,
            bankName: bankName,
            bankNumber: bankNumber ? bankNumber : 0,
            refNo: parseInt(refNo),
            // source: source,
            // sourceId: parseInt(sourceId),
            accountGroupName: accountGroupName,
            accountName: accountName || '',
            accountNumber: parseInt(accountNumber) || 0,
            particulars: particulars,
            amount: amount
        }



        if (paymentMode === '') {
            setPaymentModeError('PaymentMode Required')
            error = true
        }

        if (paymentMode === 'Bank' && bankNumber !== undefined) {

            if ((bankNumber).toString() === "") {
                setBankError('Bank Details required')
                error = true
            } else if (bankNumber <= 0) {
                setBankError('Invalid Bank Details')
                error = true
            }
        }

        if (voucherType === '') {
            setVoucherTypeError('VoucherType Required')
            error = true
        }

        if (voucherType === "Payment") {
            if (accountGroupName === '') {
                setAccountGroupNameError('Account Group Required')
                error = true
            } else if ((accountGroupName).length > 30) {
                setAccountGroupNameError('Account Group should be within 30 characters')
                error = true
            }
        }
        if (tdate === '') {
            setTDateError('')
            error = true
        }
        if (accountGroupName === '') {
            setAccountGroupNameError('Account Number Required')
            error = true
        }
        if (accountName === '') {
            setAccountIdError('Account Name Required')
            error = true
        }

        if (particulars === '') {
            setParticularError('Particular Required')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addTransactionAction(addData)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                    dispatch(fetchTransactionAction(tdate))
                    dispatch(fetchTransactionBalanceAction())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null && editData?.number !== undefined) {
                dispatch(editTransactionAction(editObject, parseInt(editData?.number))).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    resetStates()
                    dispatch(fetchTransactionAction(tdate))
                    dispatch(fetchTransactionBalanceAction())
                    setEditData(null)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }

        }
    }

    useEffect(() => {

        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {

            setId(editData.id)
            api().get(`/transaction/?number=${editData.number}`).then((res) => {
                const { data: { data } } = res;
                setPaymentMode(data[0].paymentMode)
                if (data[0].paymentMode === "Bank")
                    SetViewFrom(true);
                setVoucherType(data[0].voucherType)
                // setSource(editData.source)
                // setSourceId(editData.sourceId?.toString())
                if (data[0].voucherType === "Payment") {
                    if (data[0].paymentMode === "Cash") {
                        setPaymentMode('Cash')
                        setAccountName(data[0].accountName)
                    } else if (data[0].paymentMode === "Bank") {
                        setBankNumber(data[1].accountNumber)
                        setRefNo(data[1].refNo?.toString())
                        setAccountName(data[0].accountName)
                    }
                    if (data[1].accountName.toLowerCase().includes('Bank'))
                        setAccountGroupName(data[0].accountGroupName)
                    else if (data[1].accountName === 'Cash Account')
                        setAccountGroupName(data[0].accountGroupName)
                    else
                        setAccountGroupName(data[0].accountGroupName)

                    setAccountNumber(data[0].accountNumber)
                    setBank(data[1].accountName)
                }
                else if (data[0].voucherType === "Receipt") {
                    if (data[0].paymentMode === "Cash") {
                        setPaymentMode('Cash')
                        setAccountName(data[1].accountName)
                    } else if (data[0].paymentMode === "Bank") {
                        setBankNumber(data[0].accountNumber)
                        setRefNo(data[1].refNo?.toString())
                        setAccountName(data[1].accountName)
                    }
                    if (data[0].accountName.toLowerCase().includes('Bank'))
                        setAccountGroupName(data[1].accountGroupName)
                    else if (data[0].accountName === 'Cash Account')
                        setAccountGroupName(data[1].accountGroupName)
                    else
                        setAccountGroupName(data[1].accountGroupName)

                    setAccountNumber(data[1].accountNumber)
                    setBank(data[0].accountName)
                }
                // setAccountGroupName(editData.accountGroupName)
                // setAccountId(editData.accountName)
                setFinancialYear(data[0].financialYear)
                setTDate(data[0].date)
                setBranch(data[0].branch)

                setParticular(data[0].particulars)
                setAmount(editData.debitAmount !== undefined && editData.creditAmount !== undefined ? (editData.debitAmount + editData.creditAmount)?.toString() : '0')
            })

        } else {
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteTransactionAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
            }).catch(text => {

            })
        }
    }


    useEffect(() => {
        dispatch(fetchDropDownAction())
        dispatch(fetchAccountHolderAction())

    }, [dispatch])

    useEffect(() => {
        dispatch(fetchTransactionAction(tdate))
    }, [dispatch, tdate])

    useEffect(() => {
        dispatch(fetchTransactionBalanceAction())
    }, [dispatch, TransactionList])


    console.log(TransactionList);

    return <>
        <Modal
            headerText={'Party Ledger'}
            visible={showPartyLedgerForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <TransactionPartyLedgerForm
                formType={FromList.TRANSACTION}
                acName={accountName}
                acNumber={accountNumber}
                onSave={handleDeleteFormClose}
            />
        </Modal>
        <Modal
            headerText={'Delete Transaction'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Transaction</h4>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card shadow-lg p-1">
                        <div className="card-body">
                            <div className="row">
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    {/* <Form.Group className='mb-3'>
                                        <Form.Label>Financial Year:</Form.Label>{" "}
                                        <Badge className="bg-dark p-2 fs-6">{`${new Date().getFullYear() - 1} -${new Date().getFullYear()} `}</Badge>
                                    </Form.Group> */}

                                    <label htmlFor="">Financial Year:</label>
                                    <label className="ms-2 fs-5 badge rounded-pill bg-dark">{`${new Date().getFullYear() - 1} -${new Date().getFullYear()} `}</label>

                                </div>
                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='date'
                                        label='Date'
                                        labelClassName="required"
                                        value={tdate}
                                        className="mt-2"
                                        onChange={handleDate}
                                        errorText={tdateError}
                                        type="date"
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="">Branch:</label>
                                        <label className=" ms-2 px-2 fs-6 badge rounded-pill bg-info">{AuthUser?.branchId}</label>
                                    </div>
                                </div>


                                <div className='col-sm-6 col-md-6 col-lg-6 my-2'>
                                    <label htmlFor="Payment">Payment Mode</label>
                                    <div className="hstack gap-1">
                                        <FormInput
                                            name='Payment'
                                            label='Cash'
                                            labelClassName="required"
                                            required={true}
                                            value='Cash'
                                            onClick={() => SetViewFrom(false)}
                                            onChange={() => handlePaymentMode("Cash")}
                                            checked={paymentMode === "Cash"}
                                            errorText={paymentModeError}
                                            type="radio"
                                        />

                                        <FormInput
                                            name='Payment'
                                            label='Bank'
                                            labelClassName="required"
                                            value='Bank'
                                            onClick={() => SetViewFrom(true)}
                                            onChange={() => handlePaymentMode("Bank")}
                                            checked={paymentMode === "Bank"}
                                            errorText={paymentModeError}
                                            type="radio"
                                        />
                                    </div>
                                </div>


                                <div className='col-sm-6 col-md- col-lg-6 my-2'>
                                    <label htmlFor="voucher">Voucher type</label>
                                    <div className="hstack gap-1">
                                        <FormInput
                                            name='voucher'
                                            label='Receipt'
                                            labelClassName="required"
                                            value='Receipt'
                                            onChange={() => handleVoucher("Receipt")}
                                            errorText={voucherTypeError}
                                            checked={voucherType === "Receipt"}
                                            type="radio" />
                                        <FormInput
                                            name='voucher'
                                            label='Payment'
                                            labelClassName="required"
                                            value='Payment'
                                            defaultChecked
                                            onChange={() => handleVoucher("Payment")}
                                            checked={voucherType === "Payment"}
                                            errorText={voucherTypeError}
                                            type="radio" />
                                    </div>
                                </div>
                            </div>

                            {viewFrom &&
                                < FormInput
                                    className="mb-3"
                                    name='bankName'
                                    label='Bank'
                                    required={true}
                                    value={bankNumber}
                                    onChange={handleBank}
                                    errorText={bankError}
                                    type="select"
                                >
                                    <option value="">Select Bank</option>
                                    {
                                        AccountLists.filter((al) => al.groupName === 'main' && al.accountName?.toLowerCase().includes('bank')).map((al) => {
                                            return <option value={al.accountNumber} selected={al.accountName === bankName ? true : false}>{al.accountName}</option>
                                        })
                                    }
                                </FormInput>
                            }
                            <div>{
                                paymentMode === 'Cash' ?
                                    <Form.Group className='mt-1'>
                                        {transactionBalance?.filter((tb: any) => tb.accountName === 'Cash Account').map((tb: any) => (

                                            <Badge className="bg-warning p-1 text-dark fs-5">Cash Balance:{tb.currentBalance}</Badge>
                                        ))}
                                    </Form.Group>
                                    :
                                    <Form.Group className='mt-1'>
                                        {transactionBalance?.filter((tb: any) => tb.accountName !== null && tb.accountName === bankName).map((tb: any) => {
                                            return <Badge className="bg-warning p-1 text-dark fs-5">Bank Balance:{tb.currentBalance}</Badge>
                                        })}
                                    </Form.Group>
                            }
                            </div>
                            {/* <div className="hstack gap-1">
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='source'
                                        label='Source'
                                        labelClassName="required"
                                        value={source}
                                        onChange={handleSource}
                                        errorText={sourceError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className='col-sm-6 col-md-4 col-lg-4'>
                                    <FormInput
                                        name='sourceId'
                                        label='Source Id'
                                        labelClassName="required"
                                        value={sourceId}
                                        onChange={handleSourceId}
                                        errorText={sourceIdError}
                                        containerClass="mb-2"
                                    />
                                </div>
                            </div> */}

                            <div className="row">
                                <label htmlFor="" className="fs-4">{voucherType === "Receipt" ? "From" : "To"}</label>
                                < div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='accountGroup'
                                        label='Account Group'
                                        labelClassName="required"
                                        required={true}
                                        value={accountGroupName}
                                        onChange={handleAccount}
                                        errorText={accountGroupNameError}
                                        type="select"
                                    >
                                        <option value="">Select</option>
                                        {
                                            GroupList.filter((gl) => gl.category === 'groupmaster').map((gl) => {
                                                return <option value={gl.title} selected={gl.title === accountGroupName ? true : false}>{gl.title}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>

                                <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        name='accountName'
                                        label='Account Name'
                                        labelClassName="required"
                                        required={true}
                                        value={accountNumber}
                                        onChange={handleAccountId}
                                        errorText={accountNumberError}
                                        type="select"
                                    >
                                        <option value="">Select</option>
                                        {
                                            AccountLists.filter((al) => al.groupName === accountGroupName).map((al) => {
                                                return <option value={al.accountNumber}>{al.accountName}</option>
                                                // return <option value={al?.accountNumber}>{al.accountName}</option>
                                            })
                                        }
                                    </FormInput>
                                </div>

                                <div className="my-1">
                                    <button className="btn btn-sm btn-danger" onClick={handlePartyLedgerForm}>Party Ledger</button>
                                </div>

                            </div>
                            <div className='col'>
                                <FormInput
                                    name='particulars'
                                    label='Particulars/Narration'
                                    labelClassName="required"
                                    required={true}
                                    value={particulars}
                                    onChange={handleParticular}
                                    errorText={particularError}
                                    containerClass="mb-2"
                                    type="textarea"
                                />
                            </div>
                            {viewFrom &&
                                < FormInput
                                    className="mb-2"
                                    name='refNo'
                                    label='Ref.No'
                                    value={refNo}
                                    onChange={handleRefNo}
                                />
                            }
                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <FormInput
                                    name='amount'
                                    label='Amount'
                                    labelClassName="required"
                                    required={true}
                                    value={amount}
                                    onChange={handleAmount}
                                    errorText={amountError}
                                />
                            </div>

                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT || formType === FormTypeList.DELETE) && <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                <button className="btn btn-sm fw-bold btn-secondary" onClick={() => resetStates()}>Add New</button>
                                <button className="btn btn-sm fw-bold btn-success" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card shadow-lg p-1">
                        <div className="card-body">
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>Account Name</th>
                                            <th className='text-truncate align-middle'>Current Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionBalance?.filter((tb: any) => tb.accountName === 'Cash Account').map((tb: any) => (
                                            <tr>
                                                <td>{tb.accountName}</td>
                                                <td>{tb.currentBalance}</td>
                                            </tr>
                                        ))}

                                        {transactionBalance?.filter((tb: any) => tb.accountName !== null && tb.accountName.toLowerCase().includes('Bank')).map((tb: any) => (

                                            <tr>
                                                <td>{tb.accountName}</td>
                                                <td>{tb.currentBalance}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow-lg p-1">
                        <div className="card-body">
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Account Name</th>
                                            <th className='text-truncate align-middle'>Particular</th>
                                            <th className='text-truncate align-middle'>Debit</th>
                                            <th className='text-truncate align-middle'>Credit</th>
                                            <th className='text-truncate align-middle'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TransactionList?.filter((tr) => tr.particulars !== 'OLD BALANCE' && tr.source === 'Transaction').map((tr, i) => {
                                            return <TransactionBody
                                                sno={i + 1}
                                                accountName={tr.accountName}
                                                particulars={tr.particulars}
                                                debitAmount={tr.debitAmount}
                                                creditAmount={tr.creditAmount}
                                                key={tr.id}
                                                onEdit={() => handleEdit(tr)}
                                                onDelete={() => handleDelete(tr)}

                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </UserLayout >
    </>
}

const TransactionBody = ({ sno, accountName, particulars, debitAmount, creditAmount, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{accountName}</td>
        <td className='text-capitalize align-middle'>{particulars}</td>
        <td className='text-capitalize align-middle'>{debitAmount}</td>
        <td className='text-capitalize align-middle'>{creditAmount}</td>

        <td className="align-middle">
            <div className='hstack gap-1'>
                <button className='btn btn-sm fw-bold btn-info' onClick={onEdit}><i className="fe-edit noti-icon"></i></button>
                <button className='btn btn-sm fw-bold btn-danger' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></button>
            </div>
        </td>
    </tr>
}
export default Transaction