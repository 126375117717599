/* eslint-disable no-throw-literal */
import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { GetState } from "../store"

export enum SupplierActionList {
    ADD_SUPPLIER = 'ADD_SUPPLIER',
    FETCH_SUPPLIER = 'FETCH_SUPPLIER',
    UPDATE_SUPPLIER = 'UPDATE_SUPPLIER',
    DELETE_SUPPLIER = 'DELETE_SUPPLIER',
}

export const SupplierSampleData = [
    {
        id: 1,
        code: 'sup 1',
        name: 'AUTO SUPPLIES',
        address: 'no:789,ff nagar,kumbakonam',
        supplyPlace: 'Tamilnadu',
        contactPerson: 'Pradeep',
        mobileNumber: '7324687436',
        gstNumber: 'GSTTN53344',
        aadharNumber: '1232 6767 4657',
        panNumber: "7545 6767 7637",

    },
    {
        id: 2,
        code: "sup 2",
        name: "Honda",
        address: 'no:789,ff nagar,kumbakonam',
        supplyPlace: 'Karnataka',
        contactPerson: "Nobita",
        mobileNumber: "6837839374",
        gstNumber: "587HJGBN75798",
        aadharNumber: "3545 6767 7637",
        panNumber: "3545 6767 7637"
    },
    {
        id: 3,
        code: "sup 3",
        name: "Tata motors",
        address: 'no:789,ff nagar,kumbakonam',
        supplyPlace: 'Kerala',
        contactPerson: "Ben",
        mobileNumber: "8797685643",
        gstNumber: "5658FYNM64768",
        aadharNumber: "6718 7334 5667",
        panNumber: "6718 7334 5667"
    },
]

export interface SupplierType {
    id?: number
    code?: string
    name: string
    address?: string
    supplyPlace?: string
    contactPerson: string
    mobileNumber: string
    gstNumber?: string
    aadharNumber?: string
    panNumber?: string
}

export interface AddSupplierAction {
    type: SupplierActionList.ADD_SUPPLIER
    data: SupplierType
}

export interface FetchSupplierAction {
    type: SupplierActionList.FETCH_SUPPLIER
    data: Array<SupplierType>
}

export interface UpdateSupplierAction {
    type: SupplierActionList.UPDATE_SUPPLIER
    data: SupplierType
    id: number
}

export interface DeleteSupplierAction {
    type: SupplierActionList.DELETE_SUPPLIER
    data: number
}

export type SupplierActions = AddSupplierAction | FetchSupplierAction | UpdateSupplierAction | DeleteSupplierAction

export const addSupplier = (data: SupplierType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        dispatch<AddSupplierAction>({
            type: SupplierActionList.ADD_SUPPLIER,
            data: {
                ...data,
                id: parseInt(nanoid(5))
            }
        })
        // const token = getState().authUser.token!
        // const config = getAxiosRequestConfig(token)

        // // return Promise.resolve("Supplier Added")
        // return api().post<APIResponse<SupplierType>>('supplier/', data, config).then(response => {
        //     if (response.status === 200 && response.data.data) {

        //         dispatch<AddSupplierAction>({
        //             type: SupplierActionList.ADD_SUPPLIER,
        //             data: response.data.data
        //         })
        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Supplier Added')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to add'
        //         : 'Unable to add')
        // })
    }
}

export const updateSupplier = (data: SupplierType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        dispatch<UpdateSupplierAction>({
            type: SupplierActionList.UPDATE_SUPPLIER,
            data: data,
            id: id
        })
        // const token = getState().authUser.token!
        // const config = getAxiosRequestConfig(token)

        // // return Promise.resolve("Supplier Updated")
        // return api().put<APIResponse<SupplierType>>(`supplier/?id=${id}`, data, config).then(response => {
        //     if (response.status === 200 && response.data.data) {
        //         dispatch<UpdateSupplierAction>({
        //             type: SupplierActionList.UPDATE_SUPPLIER,
        //             data: response.data.data
        //         })
        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Supplier Updated')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to update'
        //         : 'Unable to update')
        // })
    }
}

export const fetchSupplier = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        dispatch<FetchSupplierAction>({
            type: SupplierActionList.FETCH_SUPPLIER,
            data: SupplierSampleData
        })
        // const token = getState().authUser.token!
        // const config = getAxiosRequestConfig(token)

        // return api().get<APIResponse<SupplierType[]>>('supplier/', config).then(response => {
        //     if (response.status === 200 && response.data.data) {
        //         dispatch<FetchSupplierAction>({
        //             type: SupplierActionList.FETCH_SUPPLIER,
        //             data: response.data.data
        //         })
        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Fetch Successfull')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to fetch'
        //         : 'Unable to fetch')
        // })
    }
}

export const deleteSupplier = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        dispatch<DeleteSupplierAction>({
            type: SupplierActionList.DELETE_SUPPLIER,
            data: id
        })

        // const token = getState().authUser.token!
        // const config = getAxiosRequestConfig(token)

        // // return Promise.resolve("Supplier Deleted")
        // return api().delete<APIResponse<SupplierType>>(`supplier/?id=${id}`, config).then(response => {
        //     if (response.status === 200) {
        //         dispatch<DeleteSupplierAction>({
        //             type: SupplierActionList.DELETE_SUPPLIER,
        //             data: id
        //         })
        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Supplier Deleted')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to delete'
        //         : 'Unable to delete')
        // })
    }
}

