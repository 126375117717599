/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { authLogin } from "../../services/user.service"
export interface LoginDataType {
    userId: string
    password: string
}

interface RoleType {
    id?: string
    role: string
    page_title: string
    page_url: string
    add: string
    view: string
    edit: string
    delete: string
    createdBy?: string | null,
    createdAt?: string,
    updatedAt?: string
}

export interface AuthUserType {
    id?: number
    "auth-token"?: string
    userId: string
    password?: string
    name: string
    branchId?: number
    email: string
    mobileNumber?: string
    status?: string
    roles?: RoleType[]
    createdAt?: string
    updatedAt?: string
}

export enum UserActionList {
    LOGIN_USER = 'LOGIN_USER',
    LOGOUT_USER = 'LOGOUT_USER',
}


export interface LoginAction {
    type: UserActionList.LOGIN_USER
    data: AuthUserType
}

export interface LogoutAction {
    type: UserActionList.LOGOUT_USER
    data: null
}

export type AuthUserActions = LoginAction | LogoutAction

export const signupUser = (data: AuthUserType) => {
    // api call
}
// const role = [{
//     id: "4442618f3cc",
//     role: "admin",
//     pagetitle: "Role",
//     pageurl: "/admin/role",
//     add: true,
//     view: true,
//     edit: true,
//     delete: true,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "59c4120db20",
//     role: "admin",
//     pagetitle: "Portfolio Dashboard",
//     pageurl: "/",
//     add: false,
//     view: false,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "9c4120db208",
//     role: "admin",
//     pagetitle: "Center Dashboard",
//     pageurl: "/center/dashboard",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "c4120db2083",
//     role: "admin",
//     pagetitle: "Sales Performance",
//     pageurl: "/portfolio/salesPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "4120db2083c",
//     role: "admin",
//     pagetitle: "People Count Performance",
//     pageurl: "/portfolio/peopleCountPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "120db2083c0",
//     role: "admin",
//     pagetitle: "Trading Density",
//     pageurl: "/portfolio/tradingDensity",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "20db2083c01",
//     role: "admin",
//     pagetitle: "Trade Category Performance",
//     pageurl: "/portfolio/tradeCategoryPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "0db2083c014",
//     role: "admin",
//     pagetitle: "Sales Performance",
//     pageurl: "/center/salesPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "db2083c0146",
//     role: "admin",
//     pagetitle: "People Count Performance",
//     pageurl: "/center/peopleCountPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "b2083c01460",
//     role: "admin",
//     pagetitle: "Trading Density",
//     pageurl: "/center/tradingDensity",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "2083c014602",
//     role: "admin",
//     pagetitle: "Trade Category Performance",
//     pageurl: "/center/tradeCategoryPerformance",
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },
// {
//     id: "083c0146027",
//     role: "admin",
//     pagetitle: "User",
//     pageurl: '/userManagement',
//     add: false,
//     view: true,
//     edit: false,
//     delete: false,
//     createdBy: "2",
//     createdAt: "2023-08-09T11:53:20.809Z",
//     updatedAt: "2023-08-09T11:53:20.809Z"
// },]

export const loginUser = (data: LoginDataType) => {
    return async (dispatch: Dispatch) => {
        const response = await authLogin(data)
        dispatch<AuthUserActions>({
            type: UserActionList.LOGIN_USER,
            data: response.data.data
        })
    }
}

export const logoutUser = () => {
    return (dispatch: Dispatch) => {
        dispatch<LogoutAction>({
            type: UserActionList.LOGOUT_USER,
            data: null
        })
    }
}