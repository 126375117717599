import api from "../config/api"
import { BranchType } from "../redux/actions/branchAction"

export const addBranch = async (data: BranchType) => {
    return await api().post('/branch/', data)
}

export const editBranch = async (data: BranchType, id: number | undefined) => {
    return await api().put(`/branch/?id=${id}`, data)
}

export const getBranch = async () => {

    return await api().get('/branch/')
}

export const deleteBranch = async (id: number | undefined) => {
    return await api().delete(`/branch/?id=${id}`)
}