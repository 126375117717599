import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import GenerateBarCode from "../components/GenerateBarCode"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { today } from "../config/functions"
import { ProductType } from "../redux/actions/productAction"
import { StoreState } from "../redux/store"
import { getPurchaseItemsByPurchaseDate } from "../services/purchase.service"

interface TableBody {
    sno: number
    productName: string
    productCode: string
    batch: number
    qty: number
    Lrate: number
    mrp: number
    onGenerate: MouseEventHandler<HTMLButtonElement>
    // onDelete: MouseEventHandler<HTMLButtonElement>
}

interface PurchaseItemForBarcodePrinting {
    productCode: number
    productName: string
    totalQuantity: number
    batch: number
    uRate: number
    mrp: number
}

type HandleGenerate = (value: PurchaseItemForBarcodePrinting) => void

const BarcodeSticker = () => {
    const toast = useToast()
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)

    const [filterDate, setFilterDate] = useState<string>(today())
    const [filterDateError, setFilterDateError] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [pcode, setPcode] = useState<string>('')
    const [count, setCount] = useState<number>(0)
    const [mrp, setMrp] = useState<number>(0)
    const [price, setPrice] = useState<number>(0)
    console.log(filterDate);

    const [purchaseItemList, setPurchaseItemList] = useState<PurchaseItemForBarcodePrinting[]>([])

    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''

    const mainContentRef = React.useRef<HTMLDivElement>(null)

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    const filterProductHandler = (product: any, searchTerm: any) => {

        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productName) || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(product.productCode)) : true || []
    }


    const handlefilterDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFilterDate(e.target.value)
        setFilterDateError('')
    }

    const handleGenerateSticker: HandleGenerate = (value) => {

        const prodObj = ProductList.find(pd => pd.productCode?.toString() === value.productCode?.toString())
        let pname = value?.productName ? value?.productName : ''
        let pcode = value?.productCode ? value?.productCode?.toString() || '' : ''
        setCount(value.totalQuantity)
        setName(pname)
        setPcode(pcode)
        setMrp(value.mrp)
        setPrice(value.uRate)
        setShowModal(true)
    }

    const handleModalClose = () => {
        setShowModal(false)
        setShowGenerateModal(false)
    }

    const handleGenerateModal = () => {
        if (count > 0) {
            setShowGenerateModal(true)
        } else toast('Invalid Count', ToastTypes.ERROR)
    }


    useEffect(() => {
        if (filterDate) {
            (async () => {
                const response = await getPurchaseItemsByPurchaseDate(filterDate)
                const data: PurchaseItemForBarcodePrinting[] = response.data.data
                console.log(data);
                setPurchaseItemList(data)
            })()
        }
    }, [filterDate])

    return <>
        <Modal
            headerText={''}
            visible={showGenerateModal}
            onClose={handleModalClose}
            centered
            size='lg'
        >
            <GenerateBarCode
                name={name}
                productCode={pcode}
                count={count}
                price={price}
                mrp={mrp}
                onClose={handleModalClose}
            />
        </Modal>
        <Modal
            headerText={'Count'}
            visible={showModal}
            onClose={handleModalClose}
            centered
            size='sm'
        >
            <FormInput
                name="count"
                value={count}
                onChange={(e) => setCount(e.target.value ? parseInt(e.target.value) : 0)}
                className="mb-2"
            />
            <button className="btn btn-sm fw-bold btn-danger px-2" onClick={handleModalClose}>Cancel</button>
            <button type="button" className="btn btn-sm fw-bold btn-primary m-2" onClick={handleGenerateModal}>Ok</button>
        </Modal >
        <UserLayout>
            <h4>Barcode Printing</h4>
            <div className="row">
                <div className="col-lg-12">
                    {/* <div className="col-lg-12"> */}
                    <div className="card shadow-lg p-1">
                        <div className="card-body p-1">
                            <div className="row ">
                                <div className="col-6 hstack">
                                    <label htmlFor="">Filter Date :</label>
                                    <div className="col-6 ms-1">
                                        <FormInput
                                            name='filterDate'
                                            label=''
                                            labelClassName="required"
                                            value={filterDate}
                                            onChange={handlefilterDate}
                                            errorText={filterDateError}
                                            containerClass=""
                                            type="date"
                                            width={50}
                                        />
                                    </div>
                                </div>

                                <div className="col-6">
                                    {/* <div className="col-8 ms-1"> */}
                                    <FormInput
                                        onChange={searchTermChangeHandler}
                                        name=""
                                        label="Product"
                                        placeholder='Search...'
                                        value={searchTerm}
                                        onFocus={() => {
                                            mainContentRef?.current?.scrollTo({
                                                behavior: 'smooth',
                                                top: 270
                                            })
                                        }}
                                        innerRef={searchRef}
                                        className="container my-2"
                                    />
                                    {/* </div> */}


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow-lg p-1">
                        <div className="card-body p-1">
                            <table className="table my-2">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Product Name</th>
                                        <th className='text-truncate align-middle'>Product Code</th>
                                        <th className='text-truncate align-middle'>Quantity</th>
                                        <th className='text-truncate align-middle'>Batch</th>
                                        <th className='text-truncate align-middle'>U.Rate</th>
                                        <th className='text-truncate align-middle'>MRP</th>
                                        <th className='text-truncate align-middle'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {purchaseItemList.filter((product) => filterProductHandler(product, searchTerm)).map((pItem, i) => {
                                        const prodObj = ProductList.find(pd => pd.productCode?.toString() === pItem.productCode?.toString())
                                        return <BarcodeBody
                                            sno={i + 1}
                                            productName={pItem?.productName || ''}
                                            productCode={pItem?.productCode ? pItem?.productCode?.toString() : ''}
                                            Lrate={pItem.uRate}
                                            mrp={pItem.mrp}
                                            batch={pItem.batch}
                                            qty={pItem.totalQuantity}
                                            key={i}
                                            onGenerate={() => handleGenerateSticker(pItem)}
                                        // onDelete={() => handleDelete(pItem)}

                                        />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    </>
}
const BarcodeBody = ({ sno, productName, productCode, Lrate, mrp, qty, batch, onGenerate }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{productName}</td>
        <td className='text-capitalize align-middle'>{productCode}</td>
        <td className='align-middle'>{qty}</td>
        <td className='align-middle'>{batch}</td>
        <td className='align-middle'>{Lrate}</td>
        <td className='align-middle'>{mrp}</td>

        <td className="align-middle">
            {/* <div className='hstack justify-content-center gap-1'> */}
            <button className="btn btn-sm fw-bold btn-primary my-1" onClick={onGenerate}>Generate</button>
            {/* </div> */}
        </td>
    </tr>
}
export default BarcodeSticker