import { MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmSalesReturn from "../components/DeleteConfirmationSalesReturn"
import Modal from "../components/Modal"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { LocalFormatDate } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { CustomerType } from "../redux/actions/customerAction"
import { SalesReturnType, fetchSalesReturnAction } from "../redux/actions/salesReturnAction"
import { AppDispatch, StoreState } from "../redux/store"
import ReactDatePicker from "react-datepicker"
import { FormInput } from "../components/custom/components"

interface TableBody {
    sno: number
    invoiceDate?: string
    invoiceNo: string
    salesReturnEntryNo: string
    customer: string
    finalAmount: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: SalesReturnType) => void
type HandleDelete = (value: SalesReturnType) => void

interface Props {
    setFormType: React.Dispatch<React.SetStateAction<FormType>>
    setId: React.Dispatch<React.SetStateAction<number | undefined>>
    onclose: () => void
}

const SalesReturn = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const salesReturnList = useSelector<StoreState, SalesReturnType[]>(state => state.salesReturn)
    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    const CustomerList = useSelector<StoreState, CustomerType[]>(state => state.customer)

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [id, setId] = useState<number>()

    const [editData, setEditData] = useState<SalesReturnType>()
    const [searchDate, setSearchDate] = useState<Date | null>(new Date());
    const [searchCustomer, setSearchCustomer] = useState<string>('');
    const [searchInvoiceNo, setSearchInvoiceNo] = useState<string>('');

    const filteredSalesReturnList = salesReturnList.filter((sr) => {
        const formattedDate = LocalFormatDate(sr.invoiceDate ? sr.invoiceDate?.toString() : '');

        const isDateMatch = searchDate ? formattedDate === LocalFormatDate(searchDate.toISOString()) : true;

        const normalizedSearchSupplier = searchCustomer.toLowerCase();
        const normalizedSupplierName = (AccountLists.find(s => s?.accountNumber === sr?.customerId)?.accountName || '').toLowerCase();

        return (
            isDateMatch &&
            sr.invoiceNumber?.toString().includes(searchInvoiceNo) &&
            normalizedSupplierName.includes(normalizedSearchSupplier)
        );
    });

    const handleDateFilter = (date: Date | null) => {
        setSearchDate(date);
    };

    const handleCustomerFilter = (value: string) => {
        setSearchCustomer(value);
    };

    const handleInvoiceNoFilter = (value: string) => {
        setSearchInvoiceNo(value);
    };

    const handleEdit: HandleEdit = (value) => {
        props.setFormType(FormTypeList.EDIT)
        props.setId(value.id)
        setEditData(value)
        console.log('edit');
        props.onclose()
    }

    const handleDelete: HandleDelete = (value) => {
        props.setFormType(FormTypeList.DELETE)
        setEditData(value)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    useEffect(() => {
        dispatch(fetchSalesReturnAction())
    }, [])

    return <>
        <Modal
            headerText={'Delete SalesReturn'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='sm'
        >
            <DeleteConfirmSalesReturn
                editData={editData}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <div className="row">
            <div className="col-lg-12">
                <div className="hstack gap-4 mb-3">
                    <div>
                        <label htmlFor="searchDate">Bill Date :</label>
                        <ReactDatePicker
                            id="searchDate"
                            selected={searchDate}
                            onChange={(date) => handleDateFilter(date)}
                            // dateFormat="yyyy-MM-dd"
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                    <FormInput
                        name='searchInvoiceNo'
                        label='Bill No :'
                        value={searchInvoiceNo}
                        onChange={(e) => handleInvoiceNoFilter(e.target.value)}
                    />
                    <FormInput
                        name='searchCustomer'
                        label='Customer :'
                        value={searchCustomer}
                        onChange={(e) => handleCustomerFilter(e.target.value)}
                    />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow-lg">
                    {/* <div className="card-body"> */}
                    <div className='table-wrapper'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Sales Return EntryNo</th>
                                    <th className='text-truncate align-middle'>Bill Date</th>
                                    <th className='text-truncate align-middle'>Bill No</th>
                                    <th className='text-truncate align-middle'>Customer</th>
                                    <th className='text-truncate align-middle'>Grand Total</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSalesReturnList.map((tr, i) => {
                                    let sobj = CustomerList.find(s => s?.accountNumber === tr?.customerId)
                                    let cname = sobj?.accountName ? sobj?.accountName : ''
                                    return <UserBody
                                        sno={i + 1}
                                        invoiceNo={tr.invoiceNumber?.toString()}
                                        invoiceDate={tr.invoiceDate?.toString()}
                                        salesReturnEntryNo={tr.salesReturnEntryNumber?.toString()}
                                        finalAmount={tr.grandTotal ? tr.grandTotal?.toString() : '0'}
                                        customer={cname}
                                        onEdit={() => handleEdit(tr)}
                                        onDelete={() => handleDelete(tr)}
                                        key={i}

                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    </>
}

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, salesReturnEntryNo, customer, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{salesReturnEntryNo}</td>
        <td className='text-capitalize align-middle'>{LocalFormatDate(invoiceDate ? invoiceDate : '')}</td>
        <td className='text-capitalize align-middle'>{invoiceNo}</td>
        {/* <td className='text-capitalize align-middle'>Customer 1</td> */}
        <td className='text-capitalize align-middle'>{customer}</td>
        <td className='text-capitalize align-middle'>{finalAmount}</td>
        <td className="align-middle">
            <div className='hstack gap-1'>
                <button className='btn btn-sm fw-bold btn-info p-1' onClick={onEdit}><i className="fe-edit noti-icon"></i></button>
                <button className='btn btn-sm fw-bold btn-danger p-1' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></button>
            </div>
        </td>
    </tr>
}
export default SalesReturn