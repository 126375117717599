import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { Link } from 'react-router-dom';
// import logoDark from '../assets/images/logo-grey.png';
// import CreateNew from '../components/CreateNew';
//import LanguageDropdown from '../components/LanguageDropdown';
import { useSelector } from 'react-redux';
import { AuthUserType } from '../../../redux/actions/authUserActions';
import { StoreState } from '../../../redux/store';
import { getUserMenuItems } from '../helpers/menu';
import { MenuItemTypes } from '../constants/menu';

export interface NotificationItem {
    id: number;
    text: string;
    subText: string;
    icon?: string;
    avatar?: string;
    bgColor?: string;
}


interface UserTopNavbarProps {
    hideLogo?: boolean;
    navCssClasses?: string;
    openLeftMenuCallBack?: () => void;
    topbarDark?: boolean;
}

const UserTopNavbar = ({ hideLogo, navCssClasses, openLeftMenuCallBack, topbarDark }: UserTopNavbarProps) => {
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    const roles = authUser?.roles
    
    // const [isopen, setIsopen] = useState<boolean>(false);

    // const navbarCssClasses: string = navCssClasses || '';
    // const containerCssClasses: string = !hideLogo ? 'container-fluid' : '';
    const menuItems = getUserMenuItems()
    /**
     * Toggle the leftmenu when having mobile screen
     */


    return (
        <React.Fragment>
            {/* <div className={`navbar-custom`}>
                <div className={containerCssClasses}> */}

            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div className="collapse navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav m-0">
                                {/* <AppMenu menuItems={getUserMenuItems()} /> */}
                                {menuItems.map((m, i) => {
                                    let viewArr: string[] = []
                                    return <React.Fragment key={i}>
                                        <>
                                            {/* {m.children && m.children?.length > 0 && m.children?.forEach((c, i2) => {
                                                let obj = roles?.find(r => r.page_url === c.url && r.page_title === c.label)
                                                let view = obj?.view==='active'
                                                viewArr.push(view !== undefined ?'true': '')
                                            })
                                            } */}

                                            {< li className="nav-item dropdown" key={nanoid()}>
                                                <Link className="nav-link dropdown-toggle arrow-none" to={m.url!} id="topnav-apps"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">

                                                    {React.createElement(m.Icon!, { width: 13, height: 13 })} {m.label}

                                                    {m.children && m.children?.length > 0 && <div className="arrow-down"></div>}
                                                </Link>
                                                {m.children && m.children?.length > 0 && < div className="dropdown-menu" aria-labelledby="topnav-apps">
                                                    {m.children.sort((a: MenuItemTypes, b: MenuItemTypes) => {
                                                        if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) return -1
                                                        if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) return 1
                                                        return 0
                                                    }).map((c, i) => {
                                                        // let obj = roles?.find(r => r.page_url === c.url && r.page_title === c.label)
                                                        // let view = obj?.view==='active'
                                                        return <React.Fragment key={i}>
                                                            {
                                                            // view &&<Link to={obj?.page_url!} className="dropdown-item"><i className="fe-chevrons-right me-1" key={nanoid()}></i> {obj?.page_title}</Link>
                                                            <Link to={c?.url!} className="dropdown-item"><i className="fe-chevrons-right me-1" key={nanoid()}></i> {c?.label}</Link>

                                                            }

                                                        </React.Fragment>
                                                    })
                                                        // })
                                                    }
                                                </div>}
                                            </li >}
                                        </>
                                    </React.Fragment>
                                })
                                }
                            </ul>
                        </div>
                    </nav>
                </div>
            </div >

            {/* </div>
            </div> */}
        </React.Fragment >
    );
};

export default UserTopNavbar;
