import { CategoryType, CategoryActionList} from "../actions/categoryAction";
import { Action } from "../actions/types";

const initialValue: Array<CategoryType> = []

export const categoryReducer = (state: CategoryType[] = initialValue, action: Action) => {

    switch (action.type) {
        case CategoryActionList.ADD_CATEGORY:
            return [...state, action.data];
        case CategoryActionList.EDIT_CATEGORY: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.id
            })
            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }
            return updated
        }
        case CategoryActionList.FETCH_CATEGORY:
            return action.data

        case CategoryActionList.DELETE_CATEGORY: {
            return state.filter(i => i.id !== action.data)

        }


        default:
            return state;
    }
}