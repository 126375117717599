import api from "../config/api"
import { RolesType } from "../redux/actions/rolesAction"

export const addRole = async (data: RolesType) => {
    return await api().post('/roles/', data)
}

export const editRole = async (data: RolesType, role: string | undefined) => {
    return await api().put(`/roles/?role=${role}`, data)
}

export const getRole = async () => {
    return await api().get('/roles/')
}

export const deleteRole = async (role: string | undefined) => {
    return await api().delete(`/roles/?role=${role}`)
}