import { Dispatch } from "redux"
import { GetState } from "../store"
import { addCategory, deleteCategory, editCategory, getCategory } from "../../services/category.service";
import { camelize } from "../../config/functions";


export interface CategoryType {
    id?: number
    name?: string
    departmentId?:string
    imageUpload?:File
    imageUrl?:any
}



export enum CategoryActionList {
    ADD_CATEGORY = ' ADD_CATEGORY',
    EDIT_CATEGORY = 'EDIT_CATEGORY',
    FETCH_CATEGORY = ' FETCH_CATEGORY',
    DELETE_CATEGORY = 'DELETE_CATEGORY'
}

export interface AddCategoryAction {
    type: CategoryActionList.ADD_CATEGORY;
    data: CategoryType
}
export interface EditCategoryAction {
    type: CategoryActionList.EDIT_CATEGORY;
    data: CategoryType
    id: number | undefined
}

export interface FetchCategoryAction {
    type: CategoryActionList.FETCH_CATEGORY;
    data: CategoryType[]
}
export interface DeleteCategoryAction {
    type: CategoryActionList.DELETE_CATEGORY;
    data: number|undefined
}

export type CategoryAction = AddCategoryAction | EditCategoryAction | FetchCategoryAction | DeleteCategoryAction;


export const addCategoryAction = (data: FormData) => {
    return async (dispatch: Dispatch) => {
        return addCategory(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddCategoryAction>({
                    type: CategoryActionList.ADD_CATEGORY,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Category Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })    
    }
}
export const editCategoryAction = (data: FormData, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editCategory(data,id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditCategoryAction>({
                    type: CategoryActionList.EDIT_CATEGORY,
                    data: editData,
                    id: id
                })
        
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Category Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}
export const fetchCategoryAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getCategory()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchCategoryAction>({
            type: CategoryActionList.FETCH_CATEGORY,
            data: data
        })
    }
}
export const deleteCategoryAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteCategory(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteCategoryAction>({
                    type: CategoryActionList.DELETE_CATEGORY,
                    data:id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Acoount Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}