import { nanoid } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { GetState } from "../store"
import { addProduct, deleteProduct, editProduct, getProduct } from "../../services/product.service"
import { camelize } from "../../config/functions"

export interface ProductType {
    id?: number
    // loginid?: string
    productNo?: number,
    brandId?:string| number|undefined,
    departmentId?:string| number|undefined,
    categoryId?: string|number|undefined,
    productName?: string,
    productCode?: number,
    unit?: string,
    uom?: number,
    sac?: number,
    gst?: string,
    rackNumber?: string,
    reOrder?: number,
    taxable?: string,
    modelNumber?: number,
    modelName?: string,
    reMark?: string,
    imageUrl?:string,
    purchaseRate?: number,
    mrp?: number
    createdBy?: number | null,
    createdAt?: string,
    updatedAt?: string
    updatedBy?: number | null,
}

export enum ProductActionsList {
    ADD_PRODUCT = 'ADD_PRODUCT',
    EDIT_PRODUCT = 'EDIT_PRODUCT',
    FETCH_PRODUCT = 'FETCH_PRODUCT',
    DELETE_PRODUCT = 'DELETE_PRODUCT'
}

export interface DeleteProduct {
    id: number | undefined
}

export interface AddProductAction {
    type: ProductActionsList.ADD_PRODUCT
    data: ProductType
}

export interface EditProductAction {
    type: ProductActionsList.EDIT_PRODUCT
    data: ProductType
    id: number | undefined
}

export interface FetchProductAction {
    type: ProductActionsList.FETCH_PRODUCT
    data: ProductType[]

}

export interface DeleteProductAction {
    type: ProductActionsList.DELETE_PRODUCT
    data:number|undefined
}

export type ProductActions = AddProductAction | EditProductAction | FetchProductAction | DeleteProductAction

export const addProductAction = (data: FormData) => {
    
    return async (dispatch: Dispatch) => {
      return  addProduct(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddProductAction>({
                    type: ProductActionsList.ADD_PRODUCT,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
        
    }
}

export const editProductAction = (data: FormData, id: number | undefined) => {
    
    return async (dispatch: Dispatch) => {
      return  editProduct(data,id).then(response => {
              if (response.status === 200 && response.data.data) {
                let editData={}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
               dispatch<EditProductAction>({
                type: ProductActionsList.EDIT_PRODUCT,
                data: editData,
                id: id
        })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Product Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchProductAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getProduct()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchProductAction>({
            type: ProductActionsList.FETCH_PRODUCT,
            data: data
        })     
    }
}

export const deleteProductAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteProduct(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteProductAction>({
                    type: ProductActionsList.DELETE_PRODUCT,
                    data:id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Product Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}