import api from "../config/api"

export interface AttributeItemType {
    id?: number | null
    attributeId: number|undefined | null
    name: string
    code: string
}

export const addAttributeItem = async (data: AttributeItemType) => {
    return await api().post('/attributeItem/', data)
}

export const editAttributeItem = async (data: AttributeItemType, id: number | undefined) => {
    // return await api().put(`/attributeItem/${id}`, data)
    return await api().put(`/attributeItem/?id=${id}`, data)
}

export const getAttributeItem = async () => {
    const res = await api().get('/attributeItem/')
    return res.data.data; interface AttributeType {
        id: number | undefined
        name: string
    }
    interface AttributeItemType {
        id: number
        attributeId: number
        name: string
        code: string
    }
}

export const deleteAttributeItem = async (id: number | undefined) => {
    return await api().delete(`/attributeItem/?id=${id}`)
    // return await api().delete(`/attributeItem/${id}`)
}