import React, { useEffect, useState } from "react"
import { Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { ProductType } from "../redux/actions/productAction"
import { StockMasterType, fetchStockMasterAction } from "../redux/actions/stockMasterAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    // departmentName: string|undefined,
    productName: string | undefined,
    productCode: string,
    expiryDate: string,
    productDetails: (string | undefined)[],
    quantity: number,
    reOrder: number,
    priceWithOutGst: number,
    priceWithGst: number,
    wholeSaleRate: number,
    retailRate: number,
    loseRate: number,
    mrp: number
    hsn: string
    // onEdit: MouseEventHandler<HTMLButtonElement>
    // onDelete: MouseEventHandler<HTMLButtonElement>
}



const StockMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const StockList = useSelector<StoreState, StockMasterType[]>(state => state.stockMaster)
    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [editData, setEditData] = useState<DropDownType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()


    const searchRef = React.useRef<HTMLInputElement>(null)
    const [searchQuery, setSearchQuery] = useSearchParams()
    const searchTerm = searchQuery.get('search') || ''

    const mainContentRef = React.useRef<HTMLDivElement>(null)

    const searchTermChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery({ search: e.target.value })
    }
    const filterProductHandler = (stockMaster: any, searchTerm: any) => {

        return searchTerm !== '' ? (new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(stockMaster.productName) || new RegExp(`^${searchTerm}|${searchTerm}`, 'gi').test(stockMaster.productCode)) : true || []
    }

    React.useEffect(() => {
        if (searchTerm !== '') {
            searchRef.current?.focus()
        }
    }, [])

    useEffect(() => {
        dispatch(fetchStockMasterAction())
    }, [dispatch])

    return <>
        <UserLayout>
            <h4>Stock Master</h4>
            <div className="row">
                <div className="col">
                    <div className="card p-2 w-100">
                        <div className='table-wrapper'>
                            <FormInput
                                onChange={searchTermChangeHandler}
                                name=""
                                placeholder='Search...'
                                value={searchTerm}
                                onFocus={() => {
                                    mainContentRef?.current?.scrollTo({
                                        behavior: 'smooth',
                                        top: 270
                                    })
                                }}
                                innerRef={searchRef}
                                className="container my-2"
                            />

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Code</th>
                                        <th className='text-truncate align-middle'>Product Name</th>
                                        <th className='text-truncate align-middle'>Product Details</th>
                                        <th className='text-truncate align-middle'>PWO GST</th>
                                        <th className='text-truncate align-middle'>PW GST</th>
                                        <th className='text-truncate align-middle'>W.Sale Rate</th>
                                        <th className='text-truncate align-middle'>Retail Rate</th>
                                        <th className='text-truncate align-middle'>MRP</th>
                                        <th className='text-truncate align-middle'>Stock</th>
                                        <th className='text-truncate align-middle'>Department</th>
                                        <th className='text-truncate align-middle'>Category</th>
                                        <th className='text-truncate align-middle'>Brand</th>
                                        <th className='text-truncate align-middle'>Vendor</th>
                                        <th className='text-truncate align-middle'>Updated By/Updated On</th>
                                        <th className='text-truncate align-middle'>HSN Code</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        StockList.filter((sl) => filterProductHandler(sl, searchTerm)).map((sl, i) => {
                                            // console.log(StockList)
                                            let name = sl.productName

                                            return <StockBody
                                                sno={i + 1}
                                                productName={sl.productName}
                                                productCode={sl.productCode}
                                                expiryDate={sl.expiryDate}
                                                productDetails={[sl.attribute1, sl.attribute2, sl.attribute3, sl.attribute4, sl.attribute5]}
                                                // quantity={sl.quantity}
                                                quantity={sl.totalQuantity}
                                                reOrder={sl.reOrder}
                                                priceWithOutGst={sl.priceWogst}
                                                priceWithGst={sl.priceGst}
                                                wholeSaleRate={sl.wRate}
                                                retailRate={sl.rRate}
                                                loseRate={sl.lRate}
                                                mrp={sl.mrp}
                                                hsn={sl.sac?.toString()}
                                            />
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    </>
}

const StockBody = ({ sno, productName, productCode, expiryDate, productDetails, quantity, reOrder, priceWithOutGst, priceWithGst, wholeSaleRate, retailRate, loseRate, mrp, hsn }: TableBody) => {

    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle text-truncate'>{productCode}</td>
        <td className='text-capitalize align-middle text-truncate'>{productName} {productDetails.filter(item => item !== '').join('-')}</td>
        <td className='text-capitalize align-middle text-truncate'>{productDetails.filter(item => item !== '').join(',')}</td>
        <td className='text-capitalize align-middle'>{priceWithOutGst}</td>
        <td className='text-capitalize align-middle'>{priceWithGst}</td>
        <td className='text-capitalize align-middle'>{wholeSaleRate}</td>
        <td className='text-capitalize align-middle'>{retailRate}</td>
        <td className='text-capitalize align-middle'>{mrp}</td>
        <td className='text-capitalize align-middle'> {quantity < 50 && <Badge bg="danger" className="badge">{quantity}{" / "}{50}</Badge>}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td className='text-capitalize align-middle'>{hsn}</td>
        {/* 
        <td className='text-capitalize align-middle'>{loseRate}</td>
        <td className='text-capitalize align-middle'>{expiryDate}</td> 
        */}


    </tr>
}
export default StockMaster

