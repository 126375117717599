import { DropDownActionsList, DropDownType } from "../actions/dropdownAction";
import { Action } from "../actions/types";

const initialValue: Array<DropDownType> = []

export const dropdownReducer = (state: DropDownType[] = initialValue, action: Action) => {

    switch (action.type) {

        case DropDownActionsList.ADD_DROPDOWN:

            return [...state, action.data]


        case DropDownActionsList.EDIT_DROPDOWN: {
            console.log(action.data);
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.data.id
            })
            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }
            return updated
        }

        case DropDownActionsList.FETCH_DROPDOWN:
            return action.data



        case DropDownActionsList.DELETE_DROPDOWN: {            
            return state.filter(i => i.id !== action.data)

        }

        default: return state
    }

}