import { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import BarChart from "../components/custom/components/charts/apex/BarChart"
import DonutChart from "../components/custom/components/charts/apex/DonutChart"
import { COLORS } from "../config"
import api, { APIResponse } from "../config/api"
import { CategoryType, fetchCategoryAction } from "../redux/actions/categoryAction"
import { PurchasePaymentModeTypes } from "../redux/actions/purchaseActions"
import { AppDispatch, StoreState } from "../redux/store"

export interface ReportPurchaseItemType {
    id?: number
    purchaseId?: number
    productId: number
    productCode: string
    categoryId: number
    categoryName: string
    totalQuantity: string
    mrp: number
    totalAmount: string
    profitAmount: string
}

// interface YearlySalesData {
//     id?: number
//     // financeYear: string
//     // branchId: number
//     // invoiceType: string
//     // invoiceTitle: string
//     invoiceDate: string
//     // invoiceDay?: string
//     // invoiceWeekNumber?: number
//     // invoiceWeekDay?: number
//     invoiceMonth?: number
//     invoiceYear?: number
//     invoiceNumber: number
//     packNo: string
//     salesEntryNumber: number
//     systemNo: string
//     // repId?: number
//     customerId: number
//     // customerName: string
//     // pointsEarned?: string
//     // bankName: string
//     // bankNumber: number
//     paymentMode: SalesPaymentModeTypes
//     billAmount?: string
//     // disPercent?: string
//     // disAmount?: string
//     // add?: string
//     // less?: string
//     grandTotal?: string
//     amtInWords?: string
//     particulars?: string
//     amtPaid?: string
//     salesItems: ReportSalesItemType[]
// }

interface YearlyPurchaseDataType {
    id?: number
    financeYear: string
    branchId: number
    // invoiceType: string
    // invoiceTitle: string
    invoiceDate: string
    // goodsReceivedDate: string
    // invoiceDay?: string
    // invoiceWeekNumber?: number
    // invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    purchaseEntryNumber: number
    // systemNo: string
    // repId: number
    supplierId: number
    purchaseItems: ReportPurchaseItemType[]
    // stockItems: PurchaseAttribute[]
    bankName: string
    bankNumber: number
    supplierName: string
    paymentMode: PurchasePaymentModeTypes
    billAmount?: string
    // disPercent?: string
    // disAmount?: string
    // add?: string
    // less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    // deliveryMode?: string
    // deliveryDetails?: string
}

const PurchaseYearlyReport = () => {
    const dispatch = useDispatch<AppDispatch>()
    const d = new Date()
    const [fromYear, setFromYear] = useState<Date>(new Date(d))
    // const [fromYearError, setFromYearError] = useState<string>('')
    const [toYear, setToYear] = useState<Date>(new Date())
    // const [toYearError, setToYearError] = useState<string>('')

    const categories = useSelector<StoreState, CategoryType[]>((state) => state.category)

    const [YearwiseSalesData, setYearwiseSalesData] = useState<YearlyPurchaseDataType[]>([])
    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

    const CumulativeFromYearSales = useMemo<number>(() => {
        const fdata = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === fromYear?.getFullYear()?.toString())

        return fdata.reduce((p, c) => {
            return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        }, 0)

    }, [YearwiseSalesData, fromYear])

    const FromYearSales = useMemo<number[]>(() => {
        let fyearData: number[] = []
        const fdata = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === fromYear?.getFullYear()?.toString())

        months.forEach(m => {

            const val = fdata?.filter(f => f.invoiceMonth?.toString() === m)?.reduce((p, c) => {
                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
            }, 0)
            fyearData.push(val)
        })
        return fyearData

    }, [YearwiseSalesData, fromYear, months])

    const CumulativeToYearSales = useMemo<number>(() => {
        const tdata = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === toYear?.getFullYear()?.toString())
        return tdata.reduce((p, c) => {
            return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        }, 0)

    }, [YearwiseSalesData, toYear])

    const ToYearSales = useMemo<number[]>(() => {
        let tyearData: number[] = []

        const tdata = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === toYear?.getFullYear()?.toString())

        months.forEach(m => {
            const val = tdata?.filter(f => f.invoiceMonth?.toString() === m)?.reduce((p, c) => {
                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
            }, 0)
            tyearData.push(val)
        })
        return tyearData

    }, [YearwiseSalesData, toYear, months])

    const CategorywiseFromYearSales = useMemo<{ cid: number, cname: string, sales: number }[]>(() => {
        let data: { cid: number, cname: string, sales: number }[] = [];
        const salesItemsData = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === fromYear?.getFullYear()?.toString())?.map(m => m.purchaseItems).flat(1);
        console.log(salesItemsData);

        categories?.forEach(c => {
            const catSales = salesItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                sales: catSales
            })
        })
        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [YearwiseSalesData, fromYear, categories])

    const CategorywiseToYearSales = useMemo<{ cid: number, cname: string, sales: number }[]>(() => {
        let data: { cid: number, cname: string, sales: number }[] = [];
        const salesItemsData = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === toYear?.getFullYear()?.toString())?.map(m => m.purchaseItems).flat(1);
        console.log(salesItemsData);

        categories?.forEach(c => {
            const catSales = salesItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString())?.reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                sales: catSales
            })
        })
        console.log(data);

        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [YearwiseSalesData, toYear, categories])

    useEffect(() => {

        if (fromYear !== undefined && toYear !== undefined) {
            api().get<APIResponse<YearlyPurchaseDataType[]>>(`purchase/dashboard/?fromYear=${fromYear?.getFullYear()?.toString()}&toYear=${toYear?.getFullYear()?.toString()}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    console.log(result);
                    setYearwiseSalesData(result)
                }
            }).catch((error) => { })
        } else {
            setYearwiseSalesData([])
        }
    }, [fromYear, toYear])

    useEffect(() => {
        dispatch(fetchCategoryAction)
    }, [])

    const colors = [COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3, COLORS.COLOR4, COLORS.COLOR5, COLORS.COLOR6, COLORS.COLOR7, COLORS.COLOR8]

    console.log(CategorywiseToYearSales);
    console.log(CategorywiseFromYearSales);

    // console.log([{ name: fromYear?.getFullYear()?.toString(), data: [FromYearSales] }, { name: toYear?.getFullYear()?.toString(), data: [ToYearSales] }])
    return <>
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Yearly Sales</h4>
                        </div>
                        <div className="col-lg-4 hstack gap-3 text-end">
                            <div className="col-lg-3 ms-5">
                                <span className="dateInput">
                                    {/* <span><Feather.Calendar size={14} /></span> */}
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={fromYear}
                                        onChange={(date: any) => setFromYear(date)}
                                        showYearPicker
                                        calendarClassName=""
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                                {/* <FormInput
                                name='fromYear'
                                label='From Year'
                                labelClassName="required"
                                value={fromYear}
                                className="mt-2 fs-5"
                                onChange={handleFromYear}
                                errorText={fromYearError}
                                type="date"
                            /> */}
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={toYear}
                                        onChange={(date: any) => setToYear(date)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                // <BarChart
                                //     // datasets={[
                                //     //     { label: 'Portfolio 1', data: [11, 10, 34, 56, 12, 44, 55, 56, 89, 35, 78, 67], backgroundColor: COLORS.COLOR1, borderColor: COLORS.COLOR1 },
                                //     //     { label: 'Portfolio 2', data: [51, 70, 84, 16, 56, 78, 12, 45, 56, 89, 34, 56], backgroundColor: COLORS.COLOR2, borderColor: COLORS.COLOR2 }
                                //     // ]}
                                //     datasets={(() => {
                                //         let ydata: { name: string; data: number[] }[] = []

                                //         YearlyFilteredData.forEach((y, i) => {
                                //             const index = portfolioOption.findIndex(p => p.id !== undefined && p.id.toString() === y.portfolio.toString())
                                //             const name = portfolioOption[index]?.name

                                //             // let data = y.record.map(d => parseFloat(d.sales))
                                //             // if (data.length > 0) {
                                //             //     ydata.push({
                                //             //         name: name,
                                //             //         data: data
                                //             //     })
                                //             // }
                                //             let data2: number[] = []
                                //             let d1 = y.record
                                //             yearFilter.map(f => f).sort().forEach(yr => {
                                //                 var yrs = d1.map(d => d.transyear)
                                //                 if (yrs.includes(yr)) {
                                //                     let obj = d1.find(f => f.transyear === yr)
                                //                     data2.push(obj?.sales ? parseFloat(obj.sales) : 0)
                                //                 } else data2.push(0)
                                //             })

                                //             if (data2.length > 0) {
                                //                 ydata.push({
                                //                     name: name,
                                //                     data: data2
                                //                 })
                                //             }
                                //         })
                                //         return ydata
                                //     })()
                                //         //     YearlyFilteredData.map((y, i) => {
                                //         //     const index = portfolioOption.findIndex(p => p.id !== undefined && p.id.toString() === y.portfolio.toString())
                                //         //     const name = portfolioOption[index]?.name

                                //         //     return {
                                //         //         name: name,
                                //         //         data: y.record.map((r) => {
                                //         //             return parseFloat(r?.sales)
                                //         //         }),
                                //         //     }
                                //         // })
                                //     }
                                //     // labels={yearFilter.map(y => y.toString()).sort() || []}
                                //     labels={(() => {
                                //         const years: string[] = []
                                //         YearlyFilteredData?.forEach((yr, i) => {
                                //             yr.record.forEach((r, i) => {
                                //                 years.push(r.transyear.toString())
                                //             })
                                //         })
                                //         return years.filter(onlyUnique)
                                //     })()}

                                //     // labels={yearlyData.at(0)?.record.map(r => r.transyear.toString()) || []}
                                //     colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                //     title={''}
                                // />
                            }

                            <BarChart
                                // datasets={[
                                //     { name: fromYear?.getFullYear()?.toString(), data: [FromYearSales, ToYearSales] },
                                //     // { name: toYear?.getFullYear()?.toString(), data: [ToYearSales] }
                                // ]}
                                datasets={(() => {
                                    let fyearData: { name: string, data: number[] }[] = []
                                    let years = [fromYear, toYear]
                                    years.forEach(year => {

                                        const fdata = YearwiseSalesData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString())
                                        console.log(fdata);
                                        let data: number[] = []
                                        months.forEach(m => {

                                            const val = fdata?.filter(f => f.invoiceMonth?.toString() === m)?.reduce((p, c) => {
                                                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
                                            }, 0)
                                            data.push(val)
                                        })
                                        fyearData.push({
                                            name: year?.getFullYear()?.toString(),
                                            data: data
                                        })
                                    })

                                    return fyearData
                                })()}
                                labels={months}
                                // labels={[fromYear?.getFullYear()?.toString(), toYear?.getFullYear()?.toString()]}
                                colors={['#00B9E3', '#00E396']}
                                // colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title=""
                            />
                        </div>

                        <div className="col-lg-3">

                            {/* <PieChart
                                datasets={(() => {
                                    let ydata: { name: string, data: [] } = []

                                    return ydata
                                })()}
                                labels={[]}
                                colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title="" /> */}
                            <DonutChart
                                // datasets={[24, 45, 45, 56, 88]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseFromYearSales?.map(f => f.sales)}
                                labels={CategorywiseFromYearSales?.map(n => n.cname)}
                                colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#DC2AAC']}
                                title={fromYear?.getFullYear()?.toString()}
                            />
                        </div>
                        <div className="col-lg-3">
                            <DonutChart
                                // datasets={[65, 75, 72, 21, 89]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseToYearSales?.map(f => f.sales)}
                                labels={CategorywiseToYearSales?.map(n => n.cname)}
                                colors={['#FC89AC', '#7ED957', '#FF914D', '#00B9E3', '#E192FF', '#8CA5F7']}
                                title={toYear?.getFullYear()?.toString()}
                            />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="card">
                            <div className="card-body">

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >

    </>
}
export default PurchaseYearlyReport