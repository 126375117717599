import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import api from "../config/api"
import { camelize } from "../config/functions"
import { RoleProperties, RolesType, addRolesAction, deleteRolesAction, editRolesAction, fetchRolesAction } from "../redux/actions/rolesAction"
import { AppDispatch, StoreState } from "../redux/store"
import { getUserMenuItems } from '../components/custom/helpers/menu';
interface TableBody {
    sno: number
    role: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: any) => void
type HandleDelete = (value: any) => void

const STATUS_DROPS = [
    {
        text: 'Active',
        value: 'active'
    },
    {
        text: 'InActive',
        value: 'inactive'
    },
]

// console.log(getUserMenuItems().map(menuItem => menuItem.key && menuItem.url) );


const dynamicModules = getUserMenuItems().map(menuItem => ({
    pageTitle: menuItem.key,
    pageUrl: menuItem.url || "", 
    add: false,
    edit: false,
    view: false,
    delete: false,
}));


const RoleManagement = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const roleList = useSelector<StoreState, RolesType[]>(state => state.role) || [];
    const roleListArray = Array.isArray(roleList) ? roleList : [];


    useEffect(() => {
        dispatch(fetchRolesAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [id, setId] = useState<number>()
    const [loginid, setLoginid] = useState<string>('')
    const [loginidError, setLoginidError] = useState<string>('')
    // const [modules, setModules] = useState<any>(defaultModules)
    const [modules, setModules] = useState<RoleProperties[]>(dynamicModules);
    const [role, setRole] = useState<string>('')
    const [editData, setEditData] = useState<any | null>(null)
    const [deleteId, setDeleteId] = useState<string | null | undefined>(null)
    const [roleError, setRoleError] = useState<string>('')
    const [isUpdate, setIsUpdate] = useState(false);
    
    const uniqueRoles = Array.from(new Set(roleListArray.map((tr) => tr.role)));

    const handleLoginid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLoginid(e.target.value)
        setLoginidError('')
    }
    const handleRole = (e: any, pageTitle: string, action: string) => {
        setModules(
            modules.map((module: any) =>
                module.pageTitle === pageTitle
                    ? { ...module, [action]: e.target.checked }
                    : module
            ))
        setRoleError('')
    }

    const handleRoles: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRole(e.target.value)
        setRoleError('')
    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.role)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setLoginid('')
        setLoginidError('')
        setRole('')
        setRoleError('')
        setModules(dynamicModules)
        setEditData(null)
    }

    const handleSave = () => {
      let error = false;
  
      
      const roleProperties = modules.map((module: RoleProperties) => ({
          pageTitle: module.pageTitle,
          pageUrl: module.pageUrl,
          add: module.add,
          edit: module.edit,
          view: module.view,
          delete: module.delete,
      }));
  
      const addRole: RolesType = {
          id: id,
          role: role,
          roleProperties: roleProperties,
      };
  
      const editData: RolesType = {
        //   id: id,
          role: role,
          roleProperties: roleProperties,
      };
  
      if (addRole.role.trim() === '') {
          setRoleError('Role required');
          error = true;
      }

      if (!error && formType === FormTypeList.ADD && roleList.some((existingRole) => existingRole.role.toLowerCase() === role.toLowerCase())) {
        setRoleError('Role already exists');
        error = true;
    }
  
      if (!error) {
          if (formType === FormTypeList.ADD) {
              dispatch(addRolesAction(addRole))
                  .then(() => {
                      toast('Added', ToastTypes.SUCCESS);
                      dispatch(fetchRolesAction())
                      resetStates();
                  })
                  .catch((text) => {
                      toast(text, ToastTypes.ERROR);
                  });
          } else if (formType === FormTypeList.EDIT && editData !== undefined && editData.role !== undefined) {
              dispatch(editRolesAction(editData, editData?.role))
                  .then(() => {
                      toast('Updated', ToastTypes.SUCCESS);
                      resetStates();
                      dispatch(fetchRolesAction())
                      setEditData('' || null);
                      setFormType(FormTypeList.ADD);
                  })
                  .catch((text) => {
                      toast(text, ToastTypes.ERROR);
                  });
          }
      }
  };
  
  
const handleEdit: HandleEdit = async (value) => {
    setIsUpdate(true);
    setFormType(FormTypeList.EDIT);
    setEditData(value);
    setId(value.id);
    setRole(value.role);
    editDataHandler(value.role);
};

const editDataHandler = async (role: string) => {
    try {
        const { data: { data } } = await api().get(`/roles/?role=${role}`);
        
        let roleProperties: { [key: string]: { add?: boolean; edit?: boolean; view?: boolean; delete?: boolean } } = {};

        data.forEach((item: any) => {
            const action = item.add === 'true' ? 'add' : (item.edit === 'true' ? 'edit' : '');
            roleProperties = { ...roleProperties, [item.page_title]: { [action]: true, view: item.view === 'true' || item.edit === 'true', delete: item.delete === 'true' } };
        });

        setModules((prevModules: any) => {
            return prevModules.map((module: any) => {
                if (roleProperties.hasOwnProperty(module.pageTitle)) {
                    return { ...module, ...roleProperties[module.pageTitle] };
                }
                return module;
            });
        });

    } catch (error) {
        console.error("Error fetching role data:", error);
    }
};



useEffect(() => {
    const fetchData = async () => {
      if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
        setId(editData.id);
        await editDataHandler(editData.role);
        setRole(editData.role);
      } else {
        resetStates();
      }
    };
  
    fetchData();
  }, [formType, editData]);
  
    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteRolesAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                dispatch(fetchRolesAction())
                setShowDeleteForm(false)
                setDeleteId(null)
            }).catch(text => {

            })
        }
    }

    return <>
        <Modal
            headerText={'Delete Roles'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Role Management</h4>
            <div className="row">
                <div className="col-lg-7">
                    <div className="card shadow-lg p-1">
                        <div className="card-body">
                        <FormInput
                            name='role'
                            label='Role'
                            labelClassName="required"
                            value={role}
                            onChange={handleRoles}
                            placeholder='role'
                            errorText={roleError}
                            containerClass="mb-2"
                            readOnly={isUpdate}  // Set isUpdate to true when in update
                        >
                        </FormInput>

                        </div>
                        {modules.map((module: any) => (
                            <div className="current" data-kt-stepper-element="content">
                                <div className="row mx-2">
                                    <div className="col-4">
                                        <div className="form-check">
                                            <div className="w-100">
                                                <div className="fv-row mb-10 fv-plugins-icon-container">
                                                    <input type="checkbox" name="pageTitle" checked={module.view} className="form-check-input" onChange={(e) => handleRole(e, module.pageTitle, 'view')} />
                                                    <label className="d-flex align-items-center fs-5 fw-bold mb-2 form-check-label">
                                                        <span>{module.pageTitle}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {module.view &&
                                        <>
                                            <div className="col-2">
                                                <div className="form-check">
                                                    <div className="fv-row mb-10 px-3 fv-plugins-icon-container">
                                                        <input type="checkbox" name="add" checked={module.add} onChange={(e) => handleRole(e, module.pageTitle, 'add')} className="form-check-input" />
                                                        <label className="d-inline-block align-items-center fs-5 fw-bold mb-2 form-check-label">
                                                            <span>Add</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-2">
                                                <div className="form-check">
                                                    <div className="fv-row mb-10 px-3 fv-plugins-icon-container">
                                                        <input type="checkbox" name="edit" className="form-check-input" checked={module.edit} onChange={(e) => handleRole(e, module.pageTitle, 'edit')} />
                                                        <label className="d-inline-block align-items-center fs-5 fw-bold mb-2 form-check-label">
                                                            <span>Edit</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-2">
                                                <div className="form-check">
                                                    <div className="fv-row mb-10 px-3 fv-plugins-icon-container">
                                                        <input type="checkbox" name="delete" className="form-check-input" checked={module.delete} onChange={(e) => handleRole(e, module.pageTitle, 'delete')} />
                                                        <label className="d-inline-block align-items-center fs-5 fw-bold mb-2 form-check-label">
                                                            <span>Delete</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                        {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT || formType === FormTypeList.DELETE) && <div className="d-flex justify-content-end hstack gap-1 mt-2">
                            <button className="btn btn-sm fw-bold btn-secondary" onClick={() => resetStates()}>Add New</button>
                            <button className="btn btn-sm fw-bold btn-success" onClick={handleSave}>{editData ? "update" : "save"}</button>
                        </div>}
                    </div>
                </div>

                <div className="col-lg-5">
                    <div className="card shadow-lg p-1">
                        {/* <div className="card-body"> */}
                        <div className='table-wrapper'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Roles</th>
                                        <th className='text-truncate align-middle'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {uniqueRoles.map((uniqueRole, index) => {
                                    const matchingRole = roleListArray.find((tr) => tr.role === uniqueRole);
                                    if (matchingRole) {
                                        return (
                                        <RoleBody
                                            key={index}
                                            sno={index + 1}
                                            role={matchingRole.role}
                                            onEdit={() => handleEdit(matchingRole)}
                                            onDelete={() => handleDelete(matchingRole)}
                                        />
                                        );
                                    }
                                    return null;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>


        </UserLayout >
    </>
}

export const RoleBody = ({ sno, role, onEdit, onDelete }: TableBody) => {
  
    return (
      <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{role}</td>
        <td className="align-middle">
          <div className='hstack justify-content-center gap-1'>
            <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
            <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
          </div>
        </td>
      </tr>
    );
  };
  
export default RoleManagement