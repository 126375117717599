import React, { PropsWithChildren } from "react"
import styles from './styles.module.scss'

type IOverlayLoaderContext = {
    onShow?: () => void
    onHide?: () => void
} | null

export const OverlayLoaderContext = React.createContext<IOverlayLoaderContext>(null)

const OverlayLoaderProvider = (props: PropsWithChildren) => {
    const [show, setShow] = React.useState<boolean>(false)

    const onShow = () => {
        setShow(true)
    }
    const onHide = () => {
        setShow(false)
    }

    return <OverlayLoaderContext.Provider value={{ onShow, onHide }}>
        {show && <div className={styles.loader}>
            <div className="spinner-border text-primary" role="status">
                {/* <span className="visually-hidden"></span> */}
            </div>
        </div>}
        {props.children}
    </OverlayLoaderContext.Provider>
}

export default OverlayLoaderProvider