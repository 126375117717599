import api from "../config/api"
import { DropDownType } from "../redux/actions/dropdownAction"



export const addDropDown = async (data: DropDownType) => {
    return await api().post('/dropdown/', data)
}

export const editDropDown = async (data: DropDownType, id: number | undefined) => {
    return await api().put(`/dropdown/?id=${id}`, data)
}

export const getDropDown = async () => {

    return await api().get('/dropdown/')
}

export const deleteDropDown = async (id: number | undefined) => {
    return await api().delete(`/dropdown/?id=${id}`)
}