import { nanoid } from "@reduxjs/toolkit"
import { ChangeEventHandler, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import Select from 'react-select'
import Modal from "../components/Modal"
import Option from "../components/Option"
import useOverlayLoader from "../components/OverlayLoader/useOverlayLoader"
import { Dropdown } from "../components/PurchaseForm"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { convertAmtToWords, getDate, getOnePlusYear, rupeeFormat } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { AuthUserType } from "../redux/actions/authUserActions"
import { ProductType } from "../redux/actions/productAction"
// import { PurchaseItems, PurchasePaymentModeTypes, PurchaseReturnPaymentModesDropDown, PurchaseType, addPurchaseAction, fetchPurchaseAction, updatePurchaseAction } from "../redux/actions/purchaseActions"
import { UsersType } from "../redux/actions/usersActions"
import { AppDispatch, StoreState } from "../redux/store"
// import { getPurchaseById } from "../services/purchase.service"
import PurchaseReturnForm from "../components/PurchaseReturnForm"
import { PurchaseReturnItems, PurchaseReturnPaymentModeTypes, PurchaseReturnPaymentModesDropDown, PurchaseReturnType, addPurchaseReturnAction, fetchPurchaseReturnAction, updatePurchaseReturnAction } from "../redux/actions/purchaseReturnAction"
import { getPurchaseReturnById } from "../services/purchaseReturn.service"
import { getTransactionCurrentBalanceByAcNo } from "../services/transaction.service"
import GroupProducts, { cartesianAttributes } from "./GroupProducts"
import PurchaseReturn from "./PurchaseReturn"

interface PurchaseReturnItem {
    id: number
    productName: Dropdown | null
    productCode: string
    hsn: string
    gst: string
    batchNo: string
    expiryDt: Date | null
    qty: string
    free: string
    pwog: string
    pwg: string
    sRate1: string
    sRate2: string
    sRate3: string
    mrp: string
    sdRs: string
    pdPercent: string
    pdRs: string
    cdPercent: string
    cdRs: string
    taxable: string
    igst: string
    cgst: string
    sgst: string
    netAmt: string
    unitProfit: string
    totalProfit: string
    profitPercent: string
}

const PURCHASE_PAYMENT_MODES = [
    {
        text: 'CASH',
        value: 'Cash'
    },
    {
        text: 'CREDIT',
        value: 'Credit'
    },
    {
        text: 'BANK',
        value: 'Bank'
    }
]

const getDefaultPurchaseReturnItems = () => {
    const purchaseReturnItems: PurchaseReturnItem[] = []

    // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // const d = new Date();
    // const month = months[d.getMonth()];
    // const nextYear = d.getFullYear() + 1;

    // const expDate = month + ' ' + nextYear

    for (let i = 0; i < 100; i++) {
        purchaseReturnItems.push({
            id: i + 1,
            productName: null,
            productCode: '',
            hsn: '',
            gst: '',
            batchNo: '',
            expiryDt: null,
            qty: '',
            free: '',
            pwog: '',
            pwg: '',
            sRate1: '',
            sRate2: '',
            sRate3: '',
            mrp: '',
            sdRs: '0',
            pdPercent: '0',
            pdRs: '0',
            cdPercent: '0',
            cdRs: '0',
            taxable: '0',
            igst: '',
            cgst: '',
            sgst: '',
            netAmt: '0',
            unitProfit: '0',
            totalProfit: '0',
            profitPercent: '0',
        })

    }
    return purchaseReturnItems
}

export interface PurchaseReturnItemEditAttributes {
    id: number,
    purchase_id: number
    entry_number: number
    product_id: number
    batch_number: number
    expiry_date: string
    quantity: number
    free_quantity: number
    totalQuantity: number
    price_wogst: number
    price_gst: number
    w_rate: number
    r_rate: number
    l_rate: number
    mrp: number
    batch: number,
    uRate: number,
    s_discount: number
    p_discount_percent: number
    p_discount_amount: number
    c_discount_percent: number
    c_discount_amount: number
    taxable_amount: number
    igst_amount: number
    cgst_amount: number
    sgst_amount: number
    total_amount: number
    unit_cost: number
    profit_percent: number
    profit_amount: number
    productCode?: number
}

interface PurchaseReturnEditAttributes {
    id: number
    finance_year: string
    branch_id: number
    invoice_type: string
    invoice_title: string
    invoice_date: string
    goods_received_date: string
    invoice_day: number
    invoice_week_number: number
    invoice_week_day: number
    invoice_month: number
    invoice_year: number
    invoice_number: number
    purchase_return_entry_number: number
    system_no: string
    rep_id: number
    supplier_id: number
    bill_amount: string
    dis_percent: string
    dis_amount: string
    add: string
    less: string
    grand_total: string
    amt_in_words: string
    particulars: string
    amt_paid: string
    payment_mode: string
    delivery_mode: string
    delivery_details: string
    remarks: string
    net_taxable: string
    net_igst: string
    net_sgst: string
    net_cgst: string
    net_profit: string
    net_profit_percent: string
    A_value: number
    A_taxable: number
    B_value: number
    B_taxable: number
    C_value: number
    C_taxable: number
    D_value: number
    D_taxable: number
    E_value: number
    E_taxable: number
    F_value: number
    F_taxable: number
    G_value: number
    G_taxable: number
    createdAt?: string
    created_by: number
    PurchaseReturnItems: PurchaseReturnItemEditAttributes[]
    Transactions?: any[]
    PurchaseReturnAttributes?: EditPurchaseReturnAttributeType[]
}
export interface EditPurchaseReturnAttributeType {
    id: number
    attribute1: string
    attribute2: string
    attribute3: number
    attribute4: number
    attribute5: number
    batch_number: number
    brand_id: number
    category_id: number
    cgst_amount: number
    createdAt: string
    expiry_date: string
    free_quantity: number
    igst_amount: number
    l_rate: number
    mrp: number
    price_gst: number
    price_wogst: number
    product_code: string
    item_code: string
    product_group_code: string
    purchase_id: number
    quantity: number
    r_rate: number
    sgst_amount: number
    taxable_amount: number
    total_amount: number
    total_quantity: number
    unit_profit: number
    w_rate: number
    s_discount: number
    p_discount_amount: number
    p_discount_percent: number
    c_discount_amount: number
    c_discount_percent: number
    profit_amount: number
    profit_percent: number
}
export interface PurchaseReturnAttribute {
    productCode: string,
    attribute: cartesianAttributes[]
}

const PurchaseReturnMaster = () => {
    const toast = useToast()
    const [setShowOverlayLoader, setHideOverlayLoader] = useOverlayLoader()
    const dispatch = useDispatch<AppDispatch>()

    const ProductList = useSelector<StoreState, ProductType[]>(state => state.product)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)
    const userList = useSelector<StoreState, UsersType[]>(state => state.user)

    const [cartesianTableData, setCartesianTableData] = useState<PurchaseReturnAttribute[]>([]);

    const [financialYear, setFinancialYear] = useState<string>('')
    const [financialYearError, setFinancialYearError] = useState<string>('')
    const [invoiceType, setInvoiceType] = useState<string>('')
    const [invoiceTypeError, setInvoiceTypeError] = useState<string>('')
    const [invoiceTitle, setInvoiceTitle] = useState<string>('')
    const [invoiceTitleError, setInvoiceTitleError] = useState<string>('')
    const [gudsRecdDate, setGudsRecdDate] = useState<string>('')
    const [invoiceDate, setInvoiceDate] = useState<string>('')
    const [invoiceDateError, setInvoiceDateError] = useState<string>('')
    const [invoiceNo, setInvoiceNo] = useState<string>('')
    const [invoiceNoError, setInvoiceNoError] = useState<string>('')

    const [invoiceAmount, setInvoiceAmount] = useState<string>('')
    const [purchaseReturnEntryNo, setPurchaseReturnEntryNo] = useState<string>('')
    const [supplier, setSupplier] = useState<string>('')

    const [supName, setSupName] = useState<string>('')
    const [supNameError, setSupNameError] = useState<string>('')
    const [supAddress, setSupAddress] = useState<string>('')
    const [supAddressError, setSupAddressError] = useState<string>('')
    const [supContact, setSupContact] = useState<string>('')
    const [supContactError, setSupContactError] = useState<string>('')
    const [gst, setGST] = useState<string>('')
    const [gstError, setGSTError] = useState<string>('')
    const [supAadhar, setSupAadhar] = useState<string>('')
    const [supAadharError, setSupAadharError] = useState<string>('')

    const [refBy, setRefBy] = useState<string>('')
    const [refByError, setRefByError] = useState<string>('')
    const [supPlace, setSupPlace] = useState<string>('')
    const [supPlaceError, setSupPlaceError] = useState<string>('')
    const [branch, setBranch] = useState<string>('')
    const [branchError, setBranchError] = useState<string>('')
    const [billedBy, setBilledBy] = useState<string>('')
    const [billedByError, setBilledByError] = useState<string>('')
    const [sysNo, setSysNo] = useState<string>('')
    const [sysNoError, setSysNoError] = useState<string>('')

    const [productName, setProductName] = useState<string>('')
    const [productNameError, setProductNameError] = useState<string>('')

    const [purchaseReturnItems, setPurchaseReturnItems] = useState<PurchaseReturnItem[]>(getDefaultPurchaseReturnItems())
    const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)

    const [showPurchaseForm, setShowPurchaseForm] = useState<boolean>(false)
    const [showPurchaseView, setShowPurchaseView] = useState<boolean>(false)
    const [showAttributeModal, setShowAttributeModal] = useState<boolean>(false)

    const [billAmount, setBillAmount] = useState<string>('')
    const [billAmountError, setBillAmountError] = useState<string>('')
    const [disPercent, setDisPercent] = useState<string>('')
    const [disPercentError, setDisPercentError] = useState<string>('')
    const [disAmt, setDisAmt] = useState<string>('0')
    const [disAmtError, setDisAmtError] = useState<string>('')
    const [totAmt, setTotAmt] = useState<string>('')
    const [totAmtError, setTotAmtError] = useState<string>('')
    const [roundOff, setRoundOff] = useState<string>('0')
    const [roundOffError, setRoundOffError] = useState<string>('')
    const [billNetAmt, setBillNetAmt] = useState<string>('')
    const [billNetAmtError, setBillNetAmtError] = useState<string>('')
    const [add, setAdd] = useState<string>('0')
    const [addError, setAddError] = useState<string>('')
    const [less, setLess] = useState<string>('0')
    const [lessError, setLessError] = useState<string>('')
    const [gtotal, setGTotal] = useState<string>('')
    const [gtotalError, setGTotalError] = useState<string>('')
    const [amtInWords, setAmtInWords] = useState<string>('')
    const [amtInWordsError, setAmtInWordsError] = useState<string>('')
    const [paymentMode, setPaymentMode] = useState<PurchaseReturnPaymentModeTypes>(PurchaseReturnPaymentModesDropDown.CASH)
    const [paymentModeError, setPaymentModeError] = useState<string>('')
    const [paymentNo, setPaymentNo] = useState<string>('')
    const [paymentNoError, setPaymentNoError] = useState<string>('')
    const [amtPaid, setAmtPaid] = useState<string>('0')
    const [amtPaidError, setAmtPaidError] = useState<string>('')
    const [currentBal, setCurrentBal] = useState<string>('0')
    const [currentBalError, setCurrentBalError] = useState<string>('')
    const [previousBal, setPreviousBal] = useState<string>('0')
    const [previousBalError, setPreviousBalError] = useState<string>('')
    const [overAllBal, setOverAllBal] = useState<string>('0')
    const [overAllBalError, setOverAllBalError] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    const [bankNumber, setBankNumber] = useState<string>('')
    const [tenderedBal, setTenderedBal] = useState<string>('')
    const [tenderedBalError, setTenderedBalError] = useState<string>('')
    const [deliveryMode, setDeliveryMode] = useState<string>('')
    const [deliveryModeError, setDeliveryModeError] = useState<string>('')
    const [deliveryDetails, setDeliveryDetails] = useState<string>('')
    const [deliveryDetailsError, setDeliveryDetailsError] = useState<string>('')

    const [finalAmount, setFinalAmount] = useState<string>('0')
    const [totalProfit, setTotalProfit] = useState<string>('0')

    //variables to check attribute modal totals
    const [pcode, setPcode] = useState<string>('')
    const [pgstPercent, setPgstPercent] = useState<number>(0)
    const [pQty, setPQty] = useState<number>(0)
    const [pFree, setPFree] = useState<number>(0)
    const [pNetAmt, setPNetAmt] = useState<number>(0)
    const [pTotalProfit, setPTotalProfit] = useState<number>(0)
    const [pTotalTaxable, setPTotalTaxable] = useState<number>(0)

    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [id, setId] = useState<number>()
    const [remarks, setRemarks] = useState<string>('')
    const [remarksError, setRemarksError] = useState<string>('')

    const resetStates = () => {
        setRemarks('')
        setRemarksError('')
        setFormType(FormTypeList.ADD)
        setPurchaseReturnItems(getDefaultPurchaseReturnItems())
        //top
        setGudsRecdDate('')
        setInvoiceType('')
        setInvoiceTitle('')
        setInvoiceDate('')
        setInvoiceNo('')
        setInvoiceAmount('')
        setPurchaseReturnEntryNo('')
        setSupplier('')
        setSupName('')
        setSupAddress('')
        setSupContact('')
        setGST('')
        setSupAadhar('')
        setRefBy('')
        setSupPlace('')
        setBranch('')
        setBilledBy('')
        setSysNo('')
        //
        setBillAmount('')
        setBillAmountError('')
        setDisPercent('')
        setDisPercentError('')
        setDisAmt('')
        setDisAmtError('')
        setTotAmt('')
        setTotAmtError('')
        setRoundOff('')
        setRoundOffError('')
        setBillNetAmt('')
        setBillNetAmtError('')
        setAdd('')
        setAddError('')
        setLess('')
        setLessError('')
        setGTotal('')
        setGTotalError('')
        setAmtInWords('')
        setAmtInWordsError('')
        setPaymentMode(PurchaseReturnPaymentModesDropDown.CASH)
        setPaymentModeError('')
        setPaymentNo('')
        setPaymentNoError('')
        setAmtPaid('')
        setAmtPaidError('')
        setCurrentBal('0')
        setCurrentBalError('')
        setPreviousBal('0')
        setPreviousBalError('')
        setOverAllBal('0')
        setOverAllBalError('')
        setBankName('')
        setBankNumber('')
        setDeliveryMode('')
        setDeliveryModeError('')
        setDeliveryDetails('')
        setDeliveryDetailsError('')
        setFinalAmount('0')
    }

    const handleProductName = (val: Dropdown | null, id: string) => {
        // console.log(val);
        // console.log(id);
        const prodObj = ProductList.find(pd => pd.id?.toString() === val?.value?.toString()
        )

        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].productName = val
            updated[ind].productCode = prodObj?.productCode ? prodObj?.productCode.toString() : ''
            updated[ind].hsn = prodObj?.sac ? prodObj?.sac.toString() : ''
            updated[ind].gst = prodObj?.gst ? prodObj?.gst : ''
            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = parseFloat(prodObj?.gst ? prodObj?.gst : '0') / 2

                updated[ind].cgst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
                updated[ind].sgst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
                updated[ind].igst = '0'
            } else {
                let gstPercent = parseFloat(prodObj?.gst ? prodObj?.gst : '0')
                updated[ind].cgst = '0'
                updated[ind].sgst = '0'
                updated[ind].igst = (parseFloat(updated[ind].taxable) * gstPercent / 100).toString()
            }
            //for pwg
            let pwg = (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) + ((updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0) * (prodObj?.gst ? parseFloat(prodObj?.gst) : 0) / 100)
            updated[ind].pwg = pwg.toString()

            //for exp date
            updated[ind].expiryDt = new Date(getOnePlusYear())
            updated[ind].batchNo = '1'
            setPurchaseReturnItems(updated)

        }
        if (val === null) {
            updated[ind].expiryDt = null
            updated[ind].batchNo = ''
        }
        setPurchaseReturnItems(updated)
        setProductNameError('')
    }

    const handleProductCode = (value: string, id: string) => {
        // console.log(value);
        // console.log(id);
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].productCode = value
        }
        setPurchaseReturnItems(updated)
    }

    const handleHsn = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].hsn = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleGst = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].gst = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleBatchNo = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].batchNo = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleExpiryDate = (value: Date, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].expiryDt = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleQty = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].qty = value

            //for taxable
            updated[ind].taxable = (((value ? parseFloat(value) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0)).toString()

            //for igst
            let tax = (((value ? parseFloat(value) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0))

            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                updated[ind].cgst = (tax * gstPercent / 100).toString()
                updated[ind].sgst = (tax * gstPercent / 100).toString()
                updated[ind].igst = '0'

                //for netAMt
                updated[ind].netAmt = (tax
                    + (tax * gstPercent / 100)
                    + (tax * gstPercent / 100)).toString()
            } else {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                updated[ind].igst = (tax * gstPercent / 100).toString()
                updated[ind].cgst = '0'
                updated[ind].sgst = '0'
                //for netAMt
                updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
            }

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((value ? parseFloat(value) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0)) - (((value ? parseFloat(value) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))
            updated[ind].totalProfit = tprofit.toString()
        }

        setPurchaseReturnItems(updated)
    }
    const handleFree = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].free = value

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (value ? parseFloat(value) : 0)) * (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0)) - (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (value ? parseFloat(value) : 0)) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))
            updated[ind].totalProfit = tprofit.toString()
        }
        setPurchaseReturnItems(updated)
    }
    const handlePWOG = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].pwog = value

            //for taxable
            updated[ind].taxable = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (value ? parseFloat(value) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0)).toString()
            //for igst
            let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (value ? parseFloat(value) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0))

            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                updated[ind].cgst = (tax * gstPercent / 100).toString()
                updated[ind].sgst = (tax * gstPercent / 100).toString()
                updated[ind].igst = '0'

                //for netAMt
                updated[ind].netAmt = (tax
                    + (tax * gstPercent / 100)
                    + (tax * gstPercent / 100)).toString()
            } else {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                updated[ind].igst = (tax * gstPercent / 100).toString()
                updated[ind].cgst = '0'
                updated[ind].sgst = '0'
                //for netAMt
                updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
            }
            //for pwg
            let pwg = (value ? parseFloat(value) : 0) + ((value ? parseFloat(value) : 0) * (updated[ind].gst ? parseFloat(updated[ind].gst) : 0) / 100)
            updated[ind].pwg = pwg.toString()

            //for unitprofit
            let uprofit = (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0) - (value ? parseFloat(value) : 0)
            updated[ind].unitProfit = uprofit.toString()

            //for profit %
            let profitPercent = ((uprofit ? uprofit : 0) / (value ? parseFloat(value) : 0)) * 100
            updated[ind].profitPercent = profitPercent.toFixed(2)

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (updated[ind].sRate1 ? parseFloat(updated[ind].sRate1) : 0)) - (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (value ? parseFloat(value) : 0))
            updated[ind].totalProfit = tprofit.toString()
        }
        setPurchaseReturnItems(updated)
    }
    const handlePWG = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].pwg = value

        }
        setPurchaseReturnItems(updated)
    }
    const handleSRate1 = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].sRate1 = value

            //for unitprofit
            let uprofit = (value ? parseFloat(value) : 0) - (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)
            updated[ind].unitProfit = uprofit.toString()

            //for profit %
            let profitPercent = ((uprofit ? uprofit : 0) / (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) * 100
            updated[ind].profitPercent = profitPercent.toFixed(2)

            //tot profit=(q+f*s1)-(q+f*pwog)
            let tprofit = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (value ? parseFloat(value) : 0)) - (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) + (updated[ind].free ? parseFloat(updated[ind].free) : 0)) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0))
            updated[ind].totalProfit = tprofit.toString()
        }

        setPurchaseReturnItems(updated)
    }
    const handleSRate2 = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].sRate2 = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleSRate3 = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].sRate3 = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleMRP = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].mrp = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleSDRs = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].sdRs = value
            //for taxable
            updated[ind].taxable = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (value ? parseFloat(value) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0)).toString()

            let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (value ? parseFloat(value) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0))

            if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                updated[ind].cgst = (tax * gstPercent / 100).toString()
                updated[ind].sgst = (tax * gstPercent / 100).toString()
                updated[ind].igst = '0'
                //for netAMt
                updated[ind].netAmt = (tax
                    + (tax * gstPercent / 100)
                    + (tax * gstPercent / 100)).toString()
            } else {
                let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                updated[ind].igst = (tax * gstPercent / 100).toString()
                updated[ind].cgst = '0'
                updated[ind].sgst = '0'
                //for netAMt
                updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
            }
        }
        setPurchaseReturnItems(updated)
    }
    const handlePDPercent = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].pdPercent = value
            if (updated[ind].pwg !== '') {
                updated[ind].pdRs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()

                let pdrs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()

                //for taxable
                updated[ind].taxable = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (pdrs ? parseFloat(pdrs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0)).toString()
                //for igst
                let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (pdrs ? parseFloat(pdrs) : 0) - (updated[ind].cdRs ? parseFloat(updated[ind].cdRs) : 0))

                if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                    let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                    updated[ind].cgst = (tax * gstPercent / 100).toString()
                    updated[ind].sgst = (tax * gstPercent / 100).toString()
                    updated[ind].igst = '0'

                    //for netAMt
                    updated[ind].netAmt = (tax
                        + (tax * gstPercent / 100)
                        + (tax * gstPercent / 100)).toString()
                } else {
                    let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                    updated[ind].igst = (tax * gstPercent / 100).toString()
                    updated[ind].cgst = '0'
                    updated[ind].sgst = '0'
                    //for netAMt
                    updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
                }
            }
        }
        setPurchaseReturnItems(updated)
    }
    const handlePDRs = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].pdRs = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleCDPercent = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].cdPercent = value
            if (updated[ind].pwg !== '') {
                updated[ind].cdRs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()

                let cdrs = (parseFloat(updated[ind].pwg) * parseFloat(value) / 100).toString()

                //for taxable
                updated[ind].taxable = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (cdrs ? parseFloat(cdrs) : 0)).toString()

                //for igst
                let tax = (((updated[ind].qty ? parseFloat(updated[ind].qty) : 0) * (updated[ind].pwog ? parseFloat(updated[ind].pwog) : 0)) - (updated[ind].sdRs ? parseFloat(updated[ind].sdRs) : 0) - (updated[ind].pdRs ? parseFloat(updated[ind].pdRs) : 0) - (cdrs ? parseFloat(cdrs) : 0))

                if (supPlace.includes('Tamilnadu' || 'tamilnadu' || 'TamilNadu')) {
                    let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0') / 2

                    updated[ind].cgst = (tax * gstPercent / 100).toString()
                    updated[ind].sgst = (tax * gstPercent / 100).toString()
                    updated[ind].igst = '0'

                    //for netAMt
                    updated[ind].netAmt = (tax
                        + (tax * gstPercent / 100)
                        + (tax * gstPercent / 100)).toString()
                } else {
                    let gstPercent = parseFloat(updated[ind]?.gst ? updated[ind]?.gst : '0')
                    updated[ind].igst = (tax * gstPercent / 100).toString()
                    updated[ind].cgst = '0'
                    updated[ind].sgst = '0'
                    //for netAMt
                    updated[ind].netAmt = (tax + (tax * gstPercent / 100)).toString()
                }

            }
        }
        setPurchaseReturnItems(updated)
    }
    const handleCDRs = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].cdRs = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleTaxable = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].taxable = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleIGST = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].igst = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleCGST = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].cgst = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleSGST = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].sgst = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleNetAmt = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].netAmt = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleUnitProfit = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].unitProfit = value
        }
        setPurchaseReturnItems(updated)
    }
    const handleTotalProfit = (value: string, id: string) => {
        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            updated[ind].totalProfit = value
        }
        setPurchaseReturnItems(updated)
    }

    const handleAttributeClick = (id: string) => {
        let error = false

        let updated = [...purchaseReturnItems]
        let ind = updated.findIndex(pi => pi.id.toString() === id)
        if (ind > -1) {
            setPcode(updated[ind].productCode)
            setPgstPercent(parseInt(updated[ind].gst))
            setPQty(parseInt(updated[ind].qty))
            setPFree(parseInt(updated[ind].free))
            setPNetAmt(parseInt(updated[ind].netAmt))
            setPTotalTaxable(parseInt(updated[ind].taxable))
            setPTotalProfit(parseInt(updated[ind].totalProfit))

        }
        if (updated[ind].productCode !== '' &&
            updated[ind].gst !== '' &&
            updated[ind].qty !== '' &&
            updated[ind].free !== '' &&
            updated[ind].taxable !== '' &&
            updated[ind].netAmt !== '') {


            if (supPlace === '') {
                toast('Please select supply place', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].productCode === '') {
                toast('Please select product code', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].gst === '') {
                toast('Please select gst', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].qty === '') {
                toast('Please fill product qty', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].free === '') {
                toast('Please fill product free qty', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].taxable === '') {
                toast('Please fill product taxable', ToastTypes.ERROR)
                error = true
            }
            if (updated[ind].netAmt === '') {
                toast('Please fill product netAmt', ToastTypes.ERROR)
                error = true
            }


            if (error === false) {
                setShowAttributeModal(true)
            }
        } else toast('Please Fill product details', ToastTypes.ERROR)
        // setPurchaseReturnItems(updated)
    }
    useEffect(() => {

    })

    //Bill side
    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value as PurchaseReturnPaymentModeTypes)
        setPaymentModeError('')
    }

    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(e.target.value)
        if (e.target.value) {
            let obj = AccountLists.find(a => a.accountNumber?.toString() === e.target.value.toString())
            setBankName(obj?.accountName ? obj?.accountName : '')
        }
    }

    const handleDisPercent: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDisPercent(e.target.value)
        setDisPercentError('')
    }
    const handleDisAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDisAmt(e.target.value)
        setDisAmtError('')
    }
    const handleTotalAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotAmt(e.target.value)
        setTotAmtError('')
    }
    const handleBillAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillAmount(e.target.value)
        setBillAmountError('')
    }
    const handleRoundOff: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRoundOff(e.target.value)
        setRoundOffError('')
    }
    const handleBillNetAmt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillNetAmt(e.target.value)
        setBillNetAmtError('')
    }
    const handleAdd: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAdd(e.target.value)
        setAddError('')
        setFinalAmount(prev => e.target.value ? (parseFloat(prev) + parseFloat(e.target.value)).toString() : prev)
    }
    const handleLess: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLess(e.target.value)
        setLessError('')
        setFinalAmount(prev => e.target.value ? (parseFloat(prev) - parseFloat(e.target.value)).toString() : prev)
    }
    const handleGrandTotal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGTotal(e.target.value)
        setGTotalError('')
    }
    const handleAmtInWords: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmtInWords(e.target.value)
        setAmtInWordsError('')
    }
    const handlePaymentNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentNo(e.target.value)
        setPaymentNoError('')
    }

    const handleAmtPaid: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmtPaid(e.target.value)
        setAmtPaidError('')
    }
    const handleCurrentBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCurrentBal(e.target.value)
        setCurrentBalError('')
    }
    const handlePreviousBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPreviousBal(e.target.value)
        setPreviousBalError('')
    }
    const handleOverAllBal: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOverAllBal(e.target.value)
        setOverAllBalError('')
    }
    const handleDeliveryMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryMode(e.target.value)
        setDeliveryModeError('')
    }
    const handleDeliveryDetails: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDeliveryDetails(e.target.value)
        setDeliveryDetailsError('')
    }
    //
    const handleInvoiceTypeClick = () => {
        setShowPurchaseForm(true)
    }
    const handleView = () => {
        setShowPurchaseView(true)
    }
    const handleFormClose = () => {
        setShowPurchaseForm(false)
        setShowPurchaseView(false)
        setShowAttributeModal(false)
    }
    const handleSave = () => {
        let error = false

        const data = purchaseReturnItems.filter(f => {
            // console.log(f.productName?.label);
            // console.log(f.productName?.value);
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        // console.log(data.length);
        // console.log(financialYear);
        // console.log(parseInt(financialYear));

        if (data.length > 0) {
            data.forEach((d, i) => {
                if (d.productName?.value === '') {
                    toast(`In Row ${d.id}, Product required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.productCode === '') {
                    toast(`In Row ${d.id},Product Code required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.hsn === '') {
                    toast(`In Row ${d.id},HSN required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.gst === '') {
                    toast(`In Row ${d.id},HSN required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.batchNo === '') {
                    toast(`In Row ${d.id},Batch required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.expiryDt?.toDateString() === '') {
                    toast(`In Row ${d.id},Exp date required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.qty === '') {
                    toast(`In Row ${d.id},Quantity required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.free === '') {
                    toast(`In Row ${d.id},Free required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.pwg === '') {
                    toast(`In Row ${d.id},PWG required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.pwog === '') {
                    toast(`In Row ${d.id},PWOG required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.sRate1 === '') {
                    toast(`In Row ${d.id},Wholesale Price required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.sRate2 === '') {
                    toast(`In Row ${d.id},Retail Price required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.sRate3 === '') {
                    toast(`In Row ${d.id},Purchase Price required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.mrp === '') {
                    toast(`In Row ${d.id},MRP required`, ToastTypes.ERROR)
                    error = true
                }

                if (parseFloat(d.pwg) < parseFloat(d.pwog)) {
                    toast(`In Row ${d.id},PWG must be greater than PWOG`, ToastTypes.ERROR)
                    error = true
                } else if (parseFloat(d.sRate1) < parseFloat(d.pwg)) {
                    toast(`In Row ${d.id},Rate 1 must be greater than PWG`, ToastTypes.ERROR)
                    error = true
                } else if (parseFloat(d.sRate2) < parseFloat(d.sRate1)) {
                    toast(`In Row ${d.id},Rate 2 must be greater than Rate 1`, ToastTypes.ERROR)
                    error = true
                } else if (parseFloat(d.sRate3) < parseFloat(d.sRate2)) {
                    toast(`In Row ${d.id},Rate 3 must be greater than Rate 2`, ToastTypes.ERROR)
                    error = true
                } else if (parseFloat(d.mrp) < parseFloat(d.sRate3)) {
                    toast(`In Row ${d.id},MRP must be greater than Rate 3`, ToastTypes.ERROR)
                    error = true
                }

                if (d.taxable === '') {
                    toast(`In Row ${d.id},Tax required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.netAmt === '') {
                    toast(`In Row ${d.id},NetAmt required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.unitProfit === '') {
                    toast(`In Row ${d.id},Unit Profit required`, ToastTypes.ERROR)
                    error = true
                }
                if (d.totalProfit === '') {
                    toast(`In Row ${d.id},Total Profit required`, ToastTypes.ERROR)
                    error = true
                }

            })
        } else {
            toast('Data Required', ToastTypes.ERROR)
            error = true
        }
        ///top
        if (financialYear === '') {
            toast(`Financial Year required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceType === '') {
            toast(`Invoice type required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceTitle === '') {
            toast(`Invoice title required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceNo === '') {
            toast(`Invoice No required`, ToastTypes.ERROR)
            error = true
        }
        if (invoiceDate === '') {
            toast(`Invoice date required`, ToastTypes.ERROR)
            error = true
        }
        if (purchaseReturnEntryNo === '') {
            toast(`Purchase Entry No required`, ToastTypes.ERROR)
            error = true
        }
        if (supplier === '') {
            toast(`Supplier required`, ToastTypes.ERROR)
            error = true
        }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (supPlace === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }
        // if (branch === '') {
        //     toast(`Branch required`, ToastTypes.ERROR)
        //     error = true
        // }

        ///right
        if (billAmount === '') {
            toast(`Bill Amt required`, ToastTypes.ERROR)
            error = true
        }
        if (billNetAmt === '') {
            toast(`Net Amt required`, ToastTypes.ERROR)
            error = true
        }
        if (totAmt === '') {
            toast(`Total Amt required`, ToastTypes.ERROR)
            error = true
        }
        if (gtotal === '') {
            toast(`Grand total required`, ToastTypes.ERROR)
            error = true
        }
        if ([PurchaseReturnPaymentModesDropDown.BANK, PurchaseReturnPaymentModesDropDown.CASH, PurchaseReturnPaymentModesDropDown.CREDIT,].indexOf(paymentMode as PurchaseReturnPaymentModeTypes) === -1) {
            toast('Payment Mode must be BANK/CASH/CREDIT')
            error = true
        }

        if (paymentMode === PurchaseReturnPaymentModesDropDown.CASH || paymentMode === PurchaseReturnPaymentModesDropDown.BANK) {
            if (parseFloat(amtPaid) <= 0) {
                toast(`Amt Paid value invalid`, ToastTypes.ERROR)
                error = true
            }
        }
        if (amtInWords === '') {
            toast(`Amt In Words required`, ToastTypes.ERROR)
            error = true
        }
        if (currentBal === '') {
            toast(`Current balance required`, ToastTypes.ERROR)
            error = true
        }
        if (previousBal === '') {
            toast(`Previous balance required`, ToastTypes.ERROR)
            error = true
        }
        if (overAllBal === '') {
            toast(`OverAll balance required`, ToastTypes.ERROR)
            error = true
        }
        if (deliveryDetails === '') {
            toast(`Delivery details required`, ToastTypes.ERROR)
            error = true
        }
        if (deliveryMode === '') {
            toast(`Delivery mode required`, ToastTypes.ERROR)
            error = true
        }
        //
        if (data.length > 0 && invoiceAmount !== finalAmount) {
            toast('Mismatch in Invoice and bill Amt, Kindly Check ', ToastTypes.ERROR)
            error = true
        }
        if (remarks === '') {
            setRemarksError('Remarks required')
            error = true
        } else if (remarks.length > 250) {
            setRemarksError('Remarks must be within 250 characters')
            error = true
        }

        if (error === false) {
            const netTaxable = data.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)
            const netIgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.igst)
            }, 0)
            const netCgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.cgst)
            }, 0)
            const netSgst = data.reduce((previous, current) => {
                return previous + parseFloat(current.sgst)
            }, 0)
            const netPercent = data.reduce((previous, current) => {
                return previous + parseFloat(current.profitPercent)
            }, 0)
            //A
            const AData = data.filter(a => a.gst.toString() === '0')
            const AValue = AData.reduce((previous, current) => {
                return previous + parseFloat(current.igst) + parseFloat(current.cgst) + parseFloat(current.sgst)
            }, 0)
            const ATaxable = AData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)
            //B
            const BData = data.filter(a => a.gst.toString() === '5')
            const BValue = BData.reduce((previous, current) => {
                return previous + parseFloat(current.igst) + parseFloat(current.cgst) + parseFloat(current.sgst)
            }, 0)
            const BTaxable = BData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)
            //C
            const CData = data.filter(a => a.gst.toString() === '12')
            const CValue = CData.reduce((previous, current) => {
                return previous + parseFloat(current.igst) + parseFloat(current.cgst) + parseFloat(current.sgst)
            }, 0)
            const CTaxable = CData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)
            //D
            const DData = data.filter(a => a.gst.toString() === '18')
            const DValue = DData.reduce((previous, current) => {
                return previous + parseFloat(current.igst) + parseFloat(current.cgst) + parseFloat(current.sgst)
            }, 0)
            const DTaxable = DData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)
            //E
            const EData = data.filter(a => a.gst.toString() === '28')
            const EValue = EData.reduce((previous, current) => {
                return previous + parseFloat(current.igst) + parseFloat(current.cgst) + parseFloat(current.sgst)
            }, 0)
            const ETaxable = EData.reduce((previous, current) => {
                return previous + parseFloat(current.taxable)
            }, 0)

            let idate = new Date(invoiceDate);
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var dayName = days[idate.getDay()];

            let addData: PurchaseReturnType = {
                financeYear: financialYear,
                branchId: authUser?.branchId ? authUser.branchId : 0,
                invoiceType: invoiceType,
                invoiceTitle: invoiceTitle,
                invoiceDate: invoiceDate,
                goodsReceivedDate: gudsRecdDate,
                invoiceDay: dayName,
                invoiceWeekNumber: 1,
                invoiceWeekDay: idate.getDay(),
                invoiceMonth: idate.getMonth() + 1,
                invoiceYear: idate.getFullYear(),
                invoiceNumber: parseInt(invoiceNo),
                purchaseReturnEntryNumber: parseInt(purchaseReturnEntryNo),
                repId: parseInt(refBy),
                supplierId: parseInt(supplier),
                //new
                systemNo: sysNo,
                paymentMode: paymentMode,
                supplierName: supName,
                bankName: bankName,
                bankNumber: parseInt(bankNumber),
                billAmount: billAmount,
                disPercent: disPercent,
                disAmount: disAmt,
                add: add,
                less: less,
                grandTotal: gtotal,
                amtInWords: amtInWords,
                particulars: paymentNo,
                amtPaid: amtPaid,
                deliveryMode: deliveryMode,
                deliveryDetails: deliveryDetails,
                remarks: remarks,
                netTaxable: netTaxable.toString(),
                netIgst: netIgst.toString(),
                netSgst: netSgst.toString(),
                netCgst: netCgst.toString(),
                netProfit: totalProfit,
                netProfitPercent: netPercent.toString(),
                AValue: AValue.toString(),
                ATaxable: ATaxable.toString(),
                BValue: BValue.toString(),
                BTaxable: BTaxable.toString(),
                CValue: CValue.toString(),
                CTaxable: CTaxable.toString(),
                DValue: DValue.toString(),
                DTaxable: DTaxable.toString(),
                EValue: EValue.toString(),
                ETaxable: ETaxable.toString(),
                //
                stockItems: cartesianTableData,
                purchaseReturnItems: data.map(dd => {
                    const obj: PurchaseReturnItems = {
                        entryNumber: parseInt(purchaseReturnEntryNo),
                        productId: dd.productName?.value ? parseInt(dd.productName?.value) : 0,
                        productCode: dd.productCode,
                        batchNumber: parseInt(dd.batchNo),
                        expiryDate: getDate(dd.expiryDt),
                        quantity: dd.qty,
                        freeQuantity: dd.free,
                        totalQuantity: (parseFloat(dd.qty) + (dd.free ? parseFloat(dd.free) : 0)).toString(),
                        priceWogst: parseFloat(dd.pwog),
                        priceGst: parseFloat(dd.pwg),
                        wRate: parseFloat(dd.sRate1),
                        rRate: parseFloat(dd.sRate2),
                        lRate: parseFloat(dd.sRate3),
                        mrp: parseFloat(dd.mrp),
                        sDiscount: parseFloat(dd.sdRs),
                        pDiscountpercent: parseFloat(dd.pdPercent),
                        pDiscountamount: parseFloat(dd.pdRs),
                        cDiscountpercent: parseFloat(dd.cdPercent),
                        cDiscountamount: parseFloat(dd.cdRs),
                        taxableAmount: parseFloat(dd.taxable),
                        igstAmount: parseFloat(dd.igst),
                        cgstAmount: parseFloat(dd.cgst),
                        sgstAmount: parseFloat(dd.sgst),
                        totalAmount: parseFloat(dd.netAmt),
                        unitCost: parseFloat(dd.unitProfit),
                        profitPercent: parseFloat(dd.profitPercent),
                        profitAmount: parseFloat(dd.totalProfit)
                    }
                    return obj
                })
            }

            if (formType === FormTypeList.ADD) {
                setShowOverlayLoader()
                dispatch(addPurchaseReturnAction(addData)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                    dispatch(fetchPurchaseReturnAction())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                }).finally(() => {
                    setHideOverlayLoader()
                })
            } else if (formType === FormTypeList.EDIT && id) {
                setShowOverlayLoader()
                dispatch(updatePurchaseReturnAction(addData, id)).then(text => {
                    toast('Purchase Return Updated', ToastTypes.SUCCESS)
                    resetStates()
                    dispatch(fetchPurchaseReturnAction())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                }).finally(() => {
                    setHideOverlayLoader()
                })
            }
        }
    }

    useEffect(() => {
        const data = purchaseReturnItems.filter(f => {
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        const bill = data.reduce((previous, current) => {
            return previous + parseFloat(current.netAmt)
        }, 0).toFixed(2)
        setBillAmount(bill.toString())
        setDisAmt('0')
        setDisPercent('0')
    }, [purchaseReturnItems])

    useEffect(() => {
        const data = purchaseReturnItems.filter(f => {
            return f.productName?.label !== '' && f.productName?.label !== undefined && f.productName?.value !== '' && f.productName?.value !== undefined
        })
        const tProfit = data.reduce((previous, current) => {
            return previous + parseFloat(current.totalProfit)
        }, 0).toFixed(2)
        setTotalProfit(tProfit.toString())
    }, [purchaseReturnItems])

    // useEffect(() => {
    //     setDisAmt('0')
    //     setDisPercent('0')
    // }, [billAmount])
    useEffect(() => {
        let fAmt = parseFloat(gtotal)
        setFinalAmount(fAmt.toString())
    }, [gtotal])

    useEffect(() => {
        let billnetAmt = Math.round(totAmt ? parseFloat(totAmt) : 0)
        setBillNetAmt(billnetAmt.toString())
    }, [totAmt])
    useEffect(() => {
        // console.log('cbal');
        if (parseFloat(previousBal) < 0) {
            let cbal = -((previousBal ? Math.abs(parseFloat(previousBal)) : 0) - parseFloat(amtPaid))
            setCurrentBal(cbal.toString())
        } else {
            let cbal = (previousBal ? parseFloat(previousBal) : 0) - parseFloat(amtPaid)
            setCurrentBal(cbal.toString())
        }
    }, [amtPaid, previousBal])
    useEffect(() => {
        // console.log('obal');
        let obal = (currentBal ? parseFloat(currentBal) : 0) + (previousBal ? parseFloat(previousBal) : 0)
        setOverAllBal(obal.toString())
    }, [currentBal, previousBal])

    useEffect(() => {
        let gtot = (billNetAmt ? parseFloat(billNetAmt) : 0) + (add ? parseFloat(add) : 0) - (less ? parseFloat(less) : 0)
        setGTotal(gtot.toString())
    }, [add, less, billNetAmt])

    useEffect(() => {
        let tamt = (billAmount ? parseFloat(billAmount) : 0) - (disAmt ? parseFloat(disAmt) : 0)
        setTotAmt(tamt.toString())
    }, [disAmt, billAmount])

    useEffect(() => {
        if (disPercent !== '') {
            let damt = (billAmount ? parseFloat(billAmount) : 0) * (disPercent ? parseFloat(disPercent) : 0) / 100
            setDisAmt(damt.toString())
        }
    }, [billAmount, disPercent])

    useEffect(() => {
        const d = new Date()
        const todayDate = d.getDate()  //27
        const currentMonth = d.getMonth() + 1  //6
        const currentYear = d.getFullYear()  //2022

        if (currentMonth >= 4 && todayDate >= 1) {
            let year = currentYear + '-' + (currentYear + 1)
            setFinancialYear(year)
        } else if (currentMonth < 4) {
            let year = (currentYear - 1) + '-' + currentYear
            setFinancialYear(year)
        }

    }, [])

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            // background: '#fff',
            // borderColor: '#9e9e9e',
            minHeight: '23px',
            height: '23px',
            boxShadow: state.isFocused ? null : null,
            width: "150px"
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 5px'

        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        dropdownIndicator: (prevStyle: any, state: any) => ({
            // ...prevStyle,
            display: 'none'
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '28px',
        }),
        menu: (css: any) => ({
            ...css,
            // 'z-index': 600,
            width: '250px'
        })
    };
    // console.log(23);
    // console.log(AmtToWords(23));
    useEffect(() => {
        setAmtInWords(convertAmtToWords(parseFloat(gtotal), 'India'))
    }, [gtotal])

    useEffect(() => {
        if (supplier) {
            (async () => {
                const response = await getTransactionCurrentBalanceByAcNo(supplier)
                const data: { currentBalance: number, accountName: string } = response.data.data
                setPreviousBal(data.currentBalance.toString())
                // if (data.currentBalance === 0) {
                //     console.log('balance');
                //     let cbal = data.currentBalance - parseFloat(amtPaid)
                //     setCurrentBal(cbal.toString())
                //     let obal = (currentBal ? parseFloat(currentBal) : 0) + data.currentBalance
                //     setOverAllBal(obal.toString())
                // }
            })()
        }
    }, [supplier])

    console.log(id);

    useEffect(() => {
        console.log("asdafaf");

        if (formType === FormTypeList.EDIT && id) {
            (async () => {
                // const response = dispatch(fetchPurchaseByIdAction(id))
                // const data = response
                try {
                    setShowOverlayLoader()
                    // console.log('hi2');
                    const response = await getPurchaseReturnById(id)
                    const data: PurchaseReturnEditAttributes = response.data.data
                    console.log(data);

                    const sobj = AccountLists.find(a => a.accountNumber?.toString() === data.supplier_id?.toString())
                    let sname = sobj?.accountName
                    let sadd = sobj?.address1! + sobj?.address2
                    let scont = sobj?.contactNumber1
                    let saadhar = sobj?.aadharNumber
                    let sgst = sobj?.gstNumber
                    let splace = sobj?.supplierPlace

                    const userObj = userList.find(a => a.id?.toString() === data.created_by?.toString())
                    let uname = userObj?.name ? userObj?.name : ''
                    //****top****
                    setInvoiceType(data.invoice_type)
                    setInvoiceTitle(data.invoice_title)
                    setInvoiceDate(data.invoice_date)
                    setGudsRecdDate(data.goods_received_date)
                    setInvoiceNo(data.invoice_number.toString())
                    setInvoiceAmount(data.grand_total)
                    setPurchaseReturnEntryNo(data.purchase_return_entry_number.toString())
                    setSupplier(data.supplier_id.toString())
                    setSupName(sname ? sname : '')
                    setSupAddress(sadd)
                    setSupContact(scont ? scont.toString() : '')
                    setGST(sgst ? sgst : '')
                    setSupAadhar(saadhar ? saadhar.toString() : '')
                    setRefBy(data.rep_id.toString())
                    setSupPlace(splace ? splace : '')
                    setBranch(data.branch_id.toString())
                    setBilledBy(uname)
                    setSysNo(data.system_no)
                    //****Right****
                    setBillAmount(data.bill_amount)
                    setDisPercent(data.dis_percent.toString())
                    setDisAmt(data.dis_amount.toString())
                    // setTotAmt(data.)
                    // setRoundOff(data.)
                    // setBillNetAmt(data.)
                    setAdd(data.add)
                    setLess(data.less)
                    setGTotal(data.grand_total)
                    setAmtInWords(data.amt_in_words)
                    if (data.payment_mode === PurchaseReturnPaymentModesDropDown.CASH) {
                        setPaymentMode(PurchaseReturnPaymentModesDropDown.CASH)
                    } else if (data.payment_mode === PurchaseReturnPaymentModesDropDown.BANK) {
                        setPaymentMode(PurchaseReturnPaymentModesDropDown.BANK)
                        setBankName(data?.Transactions ? data?.Transactions[3]?.account_name
                            : '')
                        setBankNumber(data?.Transactions ? data?.Transactions[3]?.account_number.toString() : '')
                    } else if (data.payment_mode === PurchaseReturnPaymentModesDropDown.CREDIT) {
                        setPaymentMode(PurchaseReturnPaymentModesDropDown.CREDIT)
                    }
                    setPaymentNo(data.particulars)
                    setAmtPaid(data.amt_paid)
                    // setCurrentBal('')
                    // setPreviousBal('-3800')
                    // setOverAllBal('')
                    setDeliveryMode(data.delivery_mode)
                    setDeliveryDetails(data.delivery_details)
                    setRemarks(data.remarks)
                    setFinalAmount(data.grand_total)
                    // *****attribute table*****
                    const attributeArray = data.PurchaseReturnAttributes
                    let cdata: PurchaseReturnAttribute[] = []

                    data.PurchaseReturnItems.forEach((pItem, pindex) => {
                        const prodObj = ProductList.find(pd => pd.id?.toString() === pItem.product_id.toString())
                        let darray = attributeArray?.filter(f => f.product_group_code?.toString() === prodObj?.productCode?.toString())
                        cdata.push({
                            productCode: prodObj?.productCode ? prodObj?.productCode?.toString() : '',
                            attribute: darray && darray?.length > 0 ? darray?.map(d => {
                                let arr = [d.attribute1, d.attribute2, d.attribute3, d.attribute4, d.attribute5]
                                let name = '';
                                arr.forEach(a => {
                                    if (a !== '') {
                                        name = name + a
                                    }
                                })
                                return {
                                    id: nanoid(),
                                    item: name,
                                    itemCode: d.item_code,
                                    productCode: d.product_code,
                                    productGroupCode: d.product_group_code,
                                    qty: d.quantity?.toString(),
                                    free: d.free_quantity?.toString(),
                                    pwog: d.price_wogst?.toString(),
                                    pwg: d.price_gst?.toString(),
                                    sRate1: d.w_rate?.toString(),
                                    sRate2: d.r_rate?.toString(),
                                    sRate3: d.l_rate?.toString(),
                                    mrp: d.mrp?.toString(),
                                    sdRs: d.s_discount?.toString(),
                                    pdPercent: d.p_discount_percent?.toString(),
                                    pdRs: d.p_discount_amount?.toString(),
                                    cdPercent: d.c_discount_percent?.toString(),
                                    cdRs: d.c_discount_amount?.toString(),
                                    taxable: d.taxable_amount?.toString(),
                                    igst: d.igst_amount?.toString(),
                                    cgst: d.cgst_amount?.toString(),
                                    sgst: d.sgst_amount?.toString(),
                                    netAmt: d.total_amount?.toString(),
                                    unitProfit: d.unit_profit?.toString(),
                                    totalProfit: d.profit_amount?.toString(),
                                    profitPercent: d.profit_percent?.toString(),
                                }
                            }) : []
                        })
                    })
                    setCartesianTableData(cdata)
                    //
                    //****table****
                    const purchaseItemEditData = getDefaultPurchaseReturnItems();
                    data.PurchaseReturnItems.forEach((pItem, pindex) => {
                        const prodObj = ProductList.find(pd => pd.id?.toString() === pItem.product_id.toString())

                        purchaseItemEditData[pindex] = {
                            id: pindex + 1,
                            productName: {
                                label: prodObj?.productName ? prodObj?.productName : '',
                                value: pItem.product_id.toString() ? pItem.product_id.toString() : '',
                            },
                            productCode: prodObj?.productCode ? prodObj?.productCode.toString() : '',
                            hsn: prodObj?.sac ? prodObj?.sac.toString() : '',
                            gst: prodObj?.gst ? prodObj?.gst : '',
                            batchNo: pItem.batch_number.toString(),
                            expiryDt: new Date(pItem.expiry_date),
                            qty: pItem.quantity.toString(),
                            free: pItem.free_quantity.toString(),
                            pwog: pItem.price_wogst.toString(),
                            pwg: pItem.price_gst.toString(),
                            sRate1: pItem.w_rate.toString(),
                            sRate2: pItem.r_rate.toString(),
                            sRate3: pItem.l_rate.toString(),
                            mrp: pItem.mrp.toString(),
                            sdRs: pItem.s_discount.toString(),
                            pdPercent: pItem.p_discount_percent.toString(),
                            pdRs: pItem.p_discount_amount.toString(),
                            cdPercent: pItem.c_discount_percent.toString(),
                            cdRs: pItem.c_discount_amount.toString(),
                            taxable: pItem.taxable_amount.toString(),
                            igst: pItem.igst_amount.toString(),
                            cgst: pItem.cgst_amount.toString(),
                            sgst: pItem.sgst_amount.toString(),
                            netAmt: pItem.total_amount.toString(),
                            unitProfit: pItem.unit_cost.toString(),
                            totalProfit: pItem.profit_amount.toString(),
                            profitPercent: pItem.profit_percent.toString(),
                        }
                    })
                    setPurchaseReturnItems(purchaseItemEditData)
                } catch (errro) {

                } finally {
                    setHideOverlayLoader()
                }
            })()
        }

        // console.log('hi');

    }, [formType, id])

    useEffect(() => {
        setShowPurchaseForm(true)
    }, [])


    return <>
        <Modal
            headerText="Invoice Details"
            onClose={handleFormClose}
            centered
            size="lg"
            visible={showPurchaseForm}
            bgColor="#B1D7E9"//1587BE
        >
            <PurchaseReturnForm
                setInvoiceType={setInvoiceType}
                setInvoiceTitle={setInvoiceTitle}
                setInvDate={setInvoiceDate}
                setInvNo={setInvoiceNo}
                setSupName={setSupName}
                setSupAddress={setSupAddress}
                setSupContact={setSupContact}
                setGST={setGST}
                setSupAadhar={setSupAadhar}
                setBilledBy={setBilledBy}
                setSysNo={setSysNo}
                setSupPlace={setSupPlace}
                setRefBy={setRefBy}
                setBranch={setBranch}
                setInvoiceAmount={setInvoiceAmount}
                setPurchaseReturnEntryNo={setPurchaseReturnEntryNo}
                setSupplier={setSupplier}
                setGudsRecdDate={setGudsRecdDate}
                onSave={handleFormClose}
                formType={formType}
                gudsRecdDate={gudsRecdDate}
                invoiceDate={invoiceDate}
                invoiceNo={invoiceNo}
                invoiceAmount={invoiceAmount}
                purchaseReturnEntryNo={purchaseReturnEntryNo}
                supplier={supplier}
                invoiceType={invoiceType}
                refBy={refBy}
            />
        </Modal>
        <Modal
            headerText="Purchase Return"
            onClose={handleFormClose}
            centered
            size="xl"
            visible={showPurchaseView}
        >
            <PurchaseReturn
                setFormType={setFormType}
                setId={setId}
                onclose={handleFormClose}
            />
        </Modal>
        <Modal
            headerText="Attribute"
            onClose={handleFormClose}
            centered
            size="xl"
            visible={showAttributeModal}
        >
            <GroupProducts
                pcode={pcode}
                cartesianTableData={cartesianTableData}
                setCartesianTableData={setCartesianTableData}
                supPlace={supPlace}
                GSTPercent={pgstPercent}
                pFree={pFree}
                pNetAmt={pNetAmt}
                pQty={pQty}
                pTotalTaxable={pTotalTaxable}
                pTotalProfit={pTotalProfit}
                onSave={handleFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Purchase Return Master</h4>
            <div className="row">
                <div className="col-lg-9">
                    {/* <div className="col-lg-12"> */}
                    <div className="card shadow-lg p-1 pb-0 mb-1">
                        <div className="card-body p-1">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Financial Year </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='year'
                                                label=''
                                                labelClassName="required"
                                                value={financialYear}
                                                // onChange={handleProductName}
                                                errorText={financialYearError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Invoice Type </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='invoiceType'
                                                label=''
                                                labelClassName="required"
                                                value={invoiceType}
                                                // onChange={handleInvoiceType}
                                                onClick={handleInvoiceTypeClick}
                                                errorText={invoiceTypeError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Invoice Title </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='invoiceTitle'
                                                label=''
                                                labelClassName="required"
                                                value={invoiceTitle}
                                                // onChange={handleInvoiceTitle}
                                                errorText={invoiceTitleError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Invoice Date </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='invoiceDate'
                                                label=''
                                                labelClassName="required"
                                                value={invoiceDate}
                                                // onChange={handleInvoiceDate}
                                                errorText={invoiceDateError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Invoice No </label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='invoiceNo'
                                                label=''
                                                labelClassName="required"
                                                value={invoiceNo}
                                                // onChange={handleInvoiceNo}
                                                errorText={invoiceNoError}
                                                containerClass="mb-1 text-end"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Supplier</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='supName'
                                                label=''
                                                labelClassName="required"
                                                value={supName}
                                                // onChange={handleSupName}
                                                errorText={supNameError}
                                                containerClass="mb-1 text-end"
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Address</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='supAddress'
                                                label=''
                                                labelClassName="required"
                                                value={supAddress}
                                                // onChange={handleSupAddress}
                                                errorText={supAddressError}
                                                containerClass="mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Contact</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='supContact'
                                                label=''
                                                labelClassName="required"
                                                value={supContact}
                                                // onChange={handlesupContact}
                                                errorText={supContactError}
                                                containerClass="mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">GST</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='gst'
                                                label=''
                                                labelClassName="required"
                                                value={gst}
                                                // onChange={handlegst}
                                                errorText={gstError}
                                                containerClass="mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Aadhar/Other</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='supAadhar'
                                                label=''
                                                labelClassName="required"
                                                value={supAadhar}
                                                // onChange={handlesupAadhar}
                                                errorText={supAadharError}
                                                containerClass="mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Referred By</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='refBy'
                                                label=''
                                                labelClassName="required"
                                                value={refBy}
                                                // onChange={handlerefBy}
                                                errorText={refByError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                                disabled={true}
                                                type="select"
                                            >
                                                <option value=""></option>
                                                {AccountLists.filter((al) => al.groupName === 'representer' || al.groupName === 'Representer').map((al) => {
                                                    return <option value={al.accountNumber}>{al.accountName}</option>
                                                })
                                                }
                                            </FormInput>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Supply Place</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='SupPlace'
                                                label=''
                                                labelClassName="required"
                                                value={supPlace}
                                                // onChange={handleSupPlace}
                                                errorText={supPlaceError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Branch</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='branch'
                                                label=''
                                                labelClassName="required"
                                                value={branch}
                                                // onChange={handlebranch}
                                                errorText={branchError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">Billed By</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='billedBy'
                                                label=''
                                                labelClassName="required"
                                                value={billedBy}
                                                // onChange={handlebilledBy}
                                                errorText={billedByError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 hstack">
                                        <div className="col-5">
                                            <label htmlFor="">System No</label>
                                        </div>
                                        <div className="col-7">
                                            <FormInput
                                                name='systemNo'
                                                label=''
                                                labelClassName="required"
                                                value={sysNo}
                                                // onChange={handlesystemNo}
                                                errorText={sysNoError}
                                                containerClass="p-0 mb-1"
                                                width={50}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="row pt-0 mt-0">
                        <div className="col-lg-12">
                            {/* <div className="card shadow-lg p-1">
                                <div className="card-body"> */}
                            <div className="card shadow-lg p-0 mt-0">
                                <div className="card-body pt-1 px-2">
                                    <div className='table-wrapper'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className='text-truncate align-middle'>S.No</th>
                                                    <th className='text-truncate align-middle'>Product Name</th>
                                                    <th className='text-truncate align-middle'>Product Code</th>
                                                    <th className='text-truncate align-middle'>HSN</th>
                                                    <th className='text-truncate align-middle'>GST%</th>
                                                    <th className='text-truncate align-middle'>Batch</th>
                                                    <th className='text-truncate align-middle'>Exp.Dt</th>
                                                    <th className='text-truncate align-middle'>QTY</th>
                                                    <th className='text-truncate align-middle'>Free</th>
                                                    <th className='text-truncate align-middle'>PWOG</th>
                                                    <th className='text-truncate align-middle'>PWG</th>
                                                    {/* <th className='text-truncate align-middle'>S.Rate 1</th>
                                                    <th className='text-truncate align-middle'>S.Rate 2</th>
                                                    <th className='text-truncate align-middle'>S.Rate 3</th> */}
                                                    <th className='text-truncate align-middle'>Wholesale</th>
                                                    <th className='text-truncate align-middle'>Retail</th>
                                                    <th className='text-truncate align-middle'>U.Rate</th>
                                                    <th className='text-truncate align-middle'>MRP</th>
                                                    <th className='text-truncate align-middle'>SD Rs</th>
                                                    <th className='text-truncate align-middle'>PD%</th>
                                                    <th className='text-truncate align-middle'>PD Rs</th>
                                                    <th className='text-truncate align-middle'>CD%</th>
                                                    <th className='text-truncate align-middle'>CD Rs</th>
                                                    <th className='text-truncate align-middle'>Taxable</th>
                                                    <th className='text-truncate align-middle'>IGST</th>
                                                    <th className='text-truncate align-middle'>CGST</th>
                                                    <th className='text-truncate align-middle'>SGST</th>
                                                    <th className='text-truncate align-middle'>Net Amt</th>
                                                    <th className='text-truncate align-middle'>Unit Profit</th>
                                                    <th className='text-truncate align-middle'>Total Profit</th>
                                                    <th className='text-truncate align-middle'>Profit %</th>
                                                    <th className='text-truncate align-middle'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchaseReturnItems.map((pi, i) => {
                                                    return <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td className="p-0">
                                                            <Select
                                                                options={ProductList?.map(p => {
                                                                    return {
                                                                        label: (p?.productName + '-' + p?.productCode + '-' + p.sac + '-' + p.gst) || '',
                                                                        value: p.id?.toString() || ''
                                                                    }
                                                                })}
                                                                placeholder={false}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                onChange={(val) => handleProductName(val, pi.id.toString())}
                                                                value={pi.productName}
                                                                styles={customStyles}
                                                            // menuIsOpen={true}
                                                            // maxMenuHeight={180}
                                                            // classNames={{
                                                            //     container: (state) =>
                                                            //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                            //     control: (state) =>
                                                            //         state.className ? 'form-select react-select' : 'form-select react-select',
                                                            //     indicatorsContainer: (state) =>
                                                            //         state.className ? 'react-select mt-1' : 'react-select mt-1',
                                                            // }}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='productCode'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.productCode}
                                                                onChange={(e) => handleProductCode(e.target.value, pi.id.toString())}
                                                                errorText={productNameError}
                                                                readOnly={true}
                                                                containerClass="w-100 read-only-field"
                                                            />
                                                        </td>
                                                        <td className="p-0 ">
                                                            <FormInput
                                                                name='hsn'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.hsn}
                                                                onChange={(e) => handleHsn(e.target.value, pi.id.toString())}
                                                                errorText={productNameError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                                style={{ width: '105px' }}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='gst'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.gst}
                                                                onChange={(e) => handleGst(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                readOnly={true}
                                                                containerClass="read-only-field"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='batchNo'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.batchNo}
                                                                onChange={(e) => handleBatchNo(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                            />
                                                        </td>
                                                        <td className="p-0 w-90">
                                                            <DatePicker
                                                                id="DatePicker"
                                                                // type="string"
                                                                className="fw-bold form-control"
                                                                selected={pi.expiryDt}
                                                                onChange={(date: any) => handleExpiryDate(date, pi.id.toString())}
                                                                showMonthYearPicker
                                                                dateFormat="MM/yyyy"
                                                                yearItemNumber={8}
                                                            //     required
                                                            />
                                                            {/* <FormInput
                                                                name='expiryDate'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.expiryDt}
                                                                onChange={(e) => handleExpiryDate(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                // width={500}
                                                                // type="date"
                                                                containerClass=""
                                                            /> */}
                                                        </td>

                                                        <td className="p-0">
                                                            <FormInput
                                                                name='qty'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.qty}
                                                                onChange={(e) => handleQty(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='free'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.free}
                                                                onChange={(e) => handleFree(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='pwog'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.pwog}
                                                                onChange={(e) => handlePWOG(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='pwg'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.pwg}
                                                                onChange={(e) => handlePWG(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='sRate1'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.sRate1}
                                                                onChange={(e) => handleSRate1(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='sRate2'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.sRate2}
                                                                onChange={(e) => handleSRate2(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='sRate3'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.sRate3}
                                                                onChange={(e) => handleSRate3(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='MRP'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.mrp}
                                                                onChange={(e) => handleMRP(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='SDRs'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.sdRs}
                                                                onChange={(e) => handleSDRs(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='pdPercent'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.pdPercent}
                                                                onChange={(e) => handlePDPercent(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='pdRs'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.pdRs}
                                                                onChange={(e) => handlePDRs(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='cdPercent'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.cdPercent}
                                                                onChange={(e) => handleCDPercent(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=""
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='cdRs'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.cdRs}
                                                                onChange={(e) => handleCDRs(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=" read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='taxable'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.taxable).toFixed(2)}
                                                                onChange={(e) => handleTaxable(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass=" read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='igst'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.igst}
                                                                onChange={(e) => handleIGST(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="w-100 read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='cgst'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.cgst}
                                                                onChange={(e) => handleCGST(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="w-100 read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='sgst'
                                                                label=''
                                                                labelClassName="required"
                                                                value={pi.sgst}
                                                                onChange={(e) => handleSGST(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="w-100 read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='netAmt'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.netAmt).toFixed(2)}
                                                                onChange={(e) => handleNetAmt(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="w-100 read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='unitProfit'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.unitProfit).toFixed(2)}
                                                                onChange={(e) => handleUnitProfit(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='totalProfit'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.totalProfit).toFixed(2)}
                                                                onChange={(e) => handleTotalProfit(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <FormInput
                                                                name='profitPercent'
                                                                label=''
                                                                labelClassName="required"
                                                                value={parseFloat(pi.profitPercent)}
                                                                // onChange={(e) => handleProfitPercent(e.target.value, pi.id.toString())}
                                                                errorText={gstError}
                                                                containerClass="read-only-field"
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td className="p-0">
                                                            <button className="btn btn-sm fw-bold btn-primary py-0" onClick={() => handleAttributeClick(pi.id.toString())}>+</button>
                                                        </td>
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <h4>Total Profit : {rupeeFormat(totalProfit)}</h4>
                                    <div className="col-8">
                                        <FormInput
                                            name='Remarks'
                                            label='Remarks'
                                            labelClassName="required"
                                            value={remarks}
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                                setRemarksError('')
                                            }}
                                            errorText={remarksError}
                                            containerClass="h-100"
                                            type="textarea"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end hstack gap-1 mt-2">
                                        {/* <button className="btn btn-secondary" onClick={() => resetStates()}>Clear</button> */}
                                        <button className="btn btn-primary" onClick={handleView}>View</button>
                                        <button className="btn btn-primary" onClick={handleSave}>{formType === FormTypeList.ADD ? 'Save' : 'Update'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="col-lg-3">
                    <div className="mb-1">
                        <span className="fs-4 badge bg-danger">Invoice Amt: {invoiceAmount}</span>
                    </div>
                    <div className="card shadow-lg ">
                        <div className="card-body">
                            <div className="row">
                                <h4 className="text-center">Grand Total</h4>
                                <h2 className="text-center text-white " style={{ backgroundColor: '#032566' }}>{rupeeFormat(finalAmount)}</h2>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><label>Bill Amount</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='BillAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={billAmount}
                                                    onChange={handleBillAmt}
                                                    errorText={billAmountError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Discount %</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='DisPercent'
                                                    label=''
                                                    labelClassName="required"
                                                    value={disPercent}
                                                    onChange={handleDisPercent}
                                                    errorText={disPercentError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Discount Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='DisAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={parseFloat(disAmt).toFixed()}
                                                    onChange={handleDisAmt}
                                                    errorText={disAmtError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Total Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='TotalAMt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={totAmt}
                                                    onChange={handleTotalAmt}
                                                    errorText={totAmtError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Round Off</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='RoundOff'
                                                    label=''
                                                    labelClassName="required"
                                                    value={roundOff}
                                                    onChange={handleRoundOff}
                                                    errorText={roundOffError}
                                                    containerClass=""
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Net Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='netAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={billNetAmt}
                                                    onChange={handleBillNetAmt}
                                                    errorText={billNetAmtError}
                                                    containerClass="read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Add</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Add'
                                                    label=''
                                                    labelClassName="required"
                                                    value={add}
                                                    onChange={handleAdd}
                                                    errorText={addError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Less</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='Less'
                                                    label=''
                                                    labelClassName="required"
                                                    value={less}
                                                    onChange={handleLess}
                                                    errorText={lessError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Grand Total</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='grandTotal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={gtotal}
                                                    onChange={handleGrandTotal}
                                                    errorText={gtotalError}
                                                    containerClass="w-100 read-only-field"
                                                    readOnly={true}
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Amt In Words</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='AmtInWords'
                                                    label=''
                                                    labelClassName="required"
                                                    value={amtInWords}
                                                    onChange={handleAmtInWords}
                                                    errorText={amtInWordsError}
                                                    containerClass="w-100"
                                                // type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Payment Mode</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='paymentMode'
                                                    label=''
                                                    labelClassName="required"
                                                    value={paymentMode}
                                                    onChange={handlePaymentMode}
                                                    errorText={paymentModeError}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {PURCHASE_PAYMENT_MODES.map((a, i) => {
                                                        return <Option value={a.value.toString()} key={i}>{a.text}</Option>
                                                    })}
                                                </FormInput>
                                            </td>
                                        </tr>
                                        {paymentMode === PurchaseReturnPaymentModesDropDown.BANK && <tr>
                                            <td><label>Bank</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='bankNumber'
                                                    label=''
                                                    labelClassName="required"
                                                    value={bankNumber}
                                                    onChange={handleBank}
                                                    containerClass="w-100"
                                                    type="select"
                                                >
                                                    <option value="">Select</option>
                                                    {AccountLists.filter((al) => al.groupName === 'main' && al.accountName?.toLowerCase().includes('bank')).map((al) => {
                                                        return <option value={al.accountNumber}>{al.accountName}</option>
                                                    })
                                                    }
                                                </FormInput>
                                            </td>
                                        </tr>}
                                        <tr>
                                            <td><label>Narration</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='paymentNo'
                                                    label=''
                                                    labelClassName="required"
                                                    value={paymentNo}
                                                    onChange={handlePaymentNo}
                                                    errorText={paymentNoError}
                                                    containerClass="w-100"
                                                // type="number"
                                                />
                                            </td>
                                        </tr>
                                        {(paymentMode === PurchaseReturnPaymentModesDropDown.BANK || paymentMode === PurchaseReturnPaymentModesDropDown.CASH) && <tr>
                                            <td><label>Amt Paid</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='AmtPaid'
                                                    label=''
                                                    labelClassName="required"
                                                    value={amtPaid}
                                                    onChange={handleAmtPaid}
                                                    errorText={amtPaidError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>}
                                        <tr>
                                            <td><label>Current Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='currentBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={currentBal}
                                                    onChange={handleCurrentBal}
                                                    errorText={currentBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Previous Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='previousBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={previousBal}
                                                    onChange={handlePreviousBal}
                                                    errorText={previousBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>OverAll Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='overAllBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={overAllBal}
                                                    onChange={handleOverAllBal}
                                                    errorText={overAllBalError}
                                                    containerClass="w-100 read-only-field"
                                                    type="number"
                                                    readOnly={true}
                                                />
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td><label>Tendered Amt</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedAmt'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedAmt}
                                                    onChange={handleTenderedAmt}
                                                    errorText={tenderedAmtError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Tendered Bal</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='tenderedBal'
                                                    label=''
                                                    labelClassName="required"
                                                    value={tenderedBal}
                                                    onChange={handleTenderedBal}
                                                    errorText={tenderedBalError}
                                                    containerClass="w-100"
                                                    type="number"
                                                />
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td><label>Delivery Mode</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='deliveryMode'
                                                    label=''
                                                    labelClassName="required"
                                                    value={deliveryMode}
                                                    onChange={handleDeliveryMode}
                                                    errorText={deliveryModeError}
                                                    containerClass="w-100"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><label>Delivery Details</label></td>
                                            <td className="p-0">
                                                <FormInput
                                                    name='deliverydetails'
                                                    label=''
                                                    labelClassName="required"
                                                    value={deliveryDetails}
                                                    onChange={handleDeliveryDetails}
                                                    errorText={deliveryDetailsError}
                                                    containerClass="w-100"
                                                // type="number"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </UserLayout >
    </>
}
export default PurchaseReturnMaster