import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { DropDownType, addDropDownAction, deleteDropDownAction, editDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    routeName: string | undefined,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: DropDownType) => void
type HandleDelete = (value: DropDownType) => void

const RouteMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const RouteList = useSelector<StoreState, DropDownType[]>(state => state.dropdown)

    useEffect(() => {
        dispatch(fetchDropDownAction())
    }, [dispatch])


    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<DropDownType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()

    const [routeName, setRouteName] = useState<string>('')
    const [routeNameError, setRouteNameError] = useState<string>('')



    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteName(e.target.value)
        setRouteNameError('')
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)

    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setRouteName('')
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        const addData: DropDownType = {
            category: "route",
            title: routeName

        }

        const edata: DropDownType = {
            id: id,
            category: "route",
            title: routeName
        }
        if (addData.category === '') {
            setRouteNameError('Route required')
            error = true
        } else if ((addData.category || '').length > 30) {
            setRouteNameError('Route should be within 30 characters')
            error = true
        }

        if (addData.title === '') {
            setRouteNameError('Route required')
            error = true
        } else if ((addData.title || '').length > 30) {
            setRouteNameError('Route should be within 30 characters')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addData)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editDropDownAction(edata, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData('' || null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setRouteName(editData.title || '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteDropDownAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
                resetStates()
            }).catch(text => {

            })
        }
    }
    return <>
        <Modal
            headerText={'Delete Route'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Route Master</h4>
            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="hstack justify-content-center gap-3">
                            <label htmlFor="" className="mb-2">Name</label>
                            <FormInput
                                name='routeName'
                                labelClassName="required"
                                value={routeName}
                                onChange={handleName}
                                errorText={routeNameError}
                                containerClass="mb-2"
                            />
                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) &&
                                <>
                                    < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                    <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                                </>
                            }

                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="card mb-0 p-1">
                            <div className='table-wrapper'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className='text-truncate align-middle'>S.No</th>
                                            <th className='text-truncate align-middle'>Name</th>
                                            <th className='text-truncate align-middle'>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RouteList.filter((rt) => rt.category === "route").map((rt, i) => {
                                            return <RouteBody
                                                sno={i + 1}
                                                routeName={rt.title}
                                                key={rt.id}
                                                onEdit={() => handleEdit(rt)}
                                                onDelete={() => handleDelete(rt)}

                                            />
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        </UserLayout>
    </>
}

const RouteBody = ({ sno, routeName, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{routeName}</td>
        <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default RouteMaster

