import jsPDF from "jspdf"
import autoTable, { RowInput } from "jspdf-autotable"

export const FormatDateToYear = (date: string) => {
    const dateObj = new Date(date)
    // const day = dateObj.getDate().toString().padStart(2, '0')
    // const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()
    return year
    // return [year, month, day].join('-')//2023-12-02
}
export const onlyUnique = <V, I>(value: V, index: I, self: V[]) => {
    return self.indexOf(value) === index;
}

export const getOnePlusYear = (): string => {
    const d = new Date();
    d.setDate(d.getDate())
    const date = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const year = d.getFullYear() + 1

    return [year, month, date].join('-')
    // return d;
}

// export const AmtToWords = (n: number) => {
//     if (n < 0) return '';
//     if (n === 0) return 'Zero';
//     const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
//     const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
//     const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']

//     const translate = (n: number) => {
//         let word = "";
//         while (n) {
//             if (n < 10) {
//                 word = single_digit[n] + ' '
//             }
//             else if (n < 20) {
//                 word = double_digit[n - 10] + ' '
//             }
//             else if (n < 100) {
//                 let rem = translate(n % 10)
//                 word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
//             }
//             else if (n < 1000) {
//                 word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
//             }
//             else if (n < 1000000) {
//                 word = translate(n / 1000).trim() + ' Thousand ' + translate(n % 1000)
//             }
//             else if (n < 1000000000) {
//                 word = translate(n / 1000000).trim() + ' Million ' + translate(n % 1000000)
//             }
//             else {
//                 word = translate(n / 1000000000).trim() + ' Billion ' + translate(n % 1000000000)
//             }
//         }

//         return word
//     }
//     const result = translate(n)
//     return result.trim() + '.'
// }



var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
var tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
var teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

const convert_millions = (num: number): string => {
    if (num >= 1000000) {
        return convert_millions(Math.floor(num / 1000000)) + " million " + convert_thousands(num % 1000000);
    } else {
        return convert_thousands(num);
    }
}

const convert_crores = (num: number) => {
    if (num >= 10000000) {
        return convert_lakhs(Math.floor(num / 10000000)) + " crores " + convert_lakhs(num % 10000000);
    } else {
        return convert_lakhs(num);
    }
}

const convert_lakhs = (num: number) => {
    if (num >= 100000) {
        return convert_thousands(Math.floor(num / 100000)) + " lakhs " + convert_thousands(num % 100000);
    } else {
        return convert_thousands(num);
    }
}

const convert_thousands = (num: number) => {
    if (num >= 1000) {
        return convert_hundreds(Math.floor(num / 1000)) + " thousand " + convert_hundreds(num % 1000);
    } else {
        return convert_hundreds(num);
    }
}

const convert_hundreds = (num: number) => {
    if (num > 99) {
        return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
    } else {
        return convert_tens(num);
    }
}

const convert_tens = (num: number) => {
    if (num < 10) return ones[num];
    else if (num >= 10 && num < 20) return teens[num - 10];
    else {
        return tens[Math.floor(num / 10)] + " " + ones[num % 10];
    }
}

export const convertAmtToWords = (num: number, country: string) => {
    if (num === 0) return "zero";
    else {
        if (country === 'India') {
            return convert_crores(num)
        } else {
            return convert_millions(num)
        }
    };
}

export const isToday = (date: string) => {
    const currDate = new Date();
    const givenDate = new Date(date);
    if (currDate.toDateString() === givenDate.toDateString()) return true;
    else return false;

}

export const currencyFormat = (currency?: number | string): string => {

    return (currency !== undefined ? parseFloat(currency.toString()) : 0).toLocaleString('en-IN', {
        // style: 'currency',
        maximumFractionDigits: 2,
        currency: 'INR'
    })
}

export const rupeeFormat = (currency?: number | string): string => {

    return (currency !== undefined ? parseFloat(currency.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0')
}

export const today = (): string => {
    const dateObj = new Date()
    const date = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [year, month, date].join('-')
}

export const LocalFormatDate = (date: string) => {
    const dateObj = new Date(date)
    const day = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [day, month, year].join('-')//20-12-2023
}

export const getDate = (date: any) => {

    const dateObj = new Date(date)

    const d = dateObj.getDate().toString().padStart(2, '0')
    const m = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const y = dateObj.getFullYear()
    return [y, m, d].join('-')
}

export const timestampToDate = (timestamp: string): string => {
    const dateObj = new Date(timestamp)
    const date = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [date, month, year].join('-')
}

export const getYesterday = (): string => {
    const d = new Date();
    d.setDate(d.getDate() - 1)
    const date = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const year = d.getFullYear()

    return [date, month, year].join('-')
    // return d;
}

interface PDFProps {
    head: RowInput[] | undefined
    body: RowInput[] | undefined
    fileName: string
    title: string
}

export const handleDownloadPDF = (props: PDFProps) => {
    const doc = new jsPDF('l', 'mm', [460, 210])
    doc.setFontSize(18)
    doc.text(props.title, 180, 13)
    autoTable(doc, {
        head: props.head,
        body: props.body,
        // foot: foot,
        startY: 20,
        didDrawPage: function () {
            // // Header
            var str = doc.getNumberOfPages();
            doc.setFontSize(10);
            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height
            //     ? pageSize.height
            //     : pageSize.getHeight();
            doc.text(str.toString(), 360, 10);
        }
    })
    doc.save(props.fileName + ".pdf")
}

interface CSVProps {
    rows: string[][]
    fileName: string
}

export const CSVdownload = (props: CSVProps) => {

    const rows = props.rows

    let csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", props.fileName + ".csv");
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

}
//3 yrs
export const YearOptions = () => {
    const d = new Date()
    const currentYear = d.getFullYear().toString()

    let a = [parseInt(currentYear)]

    for (let i = 1; i < 3; i++) {
        let year = parseInt(currentYear) - i;
        a.push(year)
    }

    return a
}

// export const convertToInternationalCurrencySystem = (labelValue: string | number) => {

//     // Nine Zeroes for Billions
//     return Math.abs(Number(labelValue)) >= 1.0e+9

//         ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
//         // Six Zeroes for Millions
//         : Math.abs(Number(labelValue)) >= 1.0e+6

//             ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
//             // Three Zeroes for Thousands
//             : Math.abs(Number(labelValue)) >= 1.0e+3

//                 ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

//                 : Math.abs(Number(labelValue));

// }
export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const camelize = (str: string) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
}

export const toCamelize = <R extends object>(obj: object): R => {
    let data: R = Object.create({})
    for (let i = 0; i < Object.keys(obj).length; i++) {
        const key = camelize(Object.keys(obj)[i])
        const value = Object.values(obj)[i]
        data = { ...data, [key]: value }
    }

    return data
}

export const capitalizeFirstLetter = (str:string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };