import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../redux/actions/authUserActions";
import { AppDispatch } from "../redux/store";

const Logout = () => {
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(logoutUser())
    }, [])
    return <>
    </>
}
export default Logout