import api from "../config/api"

export const addDepartment = async (data: FormData) => {
    return await api().post('/department/', data)
}

export const editDepartment = async (data: FormData, id: number | undefined) => {
    // return await api().put(`/department/${id}`, data)
    return await api().post(`/department/?id=${id}`, data)
}

export const getDepartment = async () => {
    return await api().get('/department/')
}

export const deleteDepartment = async (id: number | undefined) => {
    return await api().delete(`/department/?id=${id}`)
    // return await api().delete(`/department/${id}`)
}