import api from "../config/api"
import { SalesReturnType } from "../redux/actions/salesReturnAction"

export const addSalesReturn = async (data: SalesReturnType) => {
    return await api().post('/salesReturn/', data)
}

export const editSalesReturn = async (data: SalesReturnType, id: number | undefined) => {
    return await api().put(`/salesReturn/?id=${id}`, data)
}

export const getSalesReturn = async () => {
    return await api().get('/salesReturn/')
}

export const getSalesReturnById = (id: number) => {
    return api().get(`/salesReturn/?id=${id}`)
}

export const getRecentSalesReturnId = () => {
    return api().get(`/salesReturn/recent/`)
}

export const getSalesReturnItemsBySalesDate = (date: string) => {
    return api().get(`/salesReturnItems/?date=${date}`)
}

export const getSalesReturnByDate = (startDate: string,endDate:string) => {
    return api().get(`/salesReturn/?startDate=${startDate}&endDate=${endDate}`)
}

export const deleteSalesReturn = (id: number | undefined) => {
    return api().delete(`/salesReturn/?id=${id}`)

}