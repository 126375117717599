import { TransactionActionsList,TransactionType } from "../actions/transactionAction";
import { Action } from "../actions/types";

const initialValue: Array<TransactionType> = []

export const transactionReducer = (state: TransactionType[] = initialValue, action: Action) => {

    switch (action.type) {

        case TransactionActionsList.ADD_TRANSACTION:

            return action.data
        case TransactionActionsList.EDIT_TRANSACTION: {
            
            return action.data
        }
        case TransactionActionsList.FETCH_TRANSACTION:
            return action.data

        case TransactionActionsList.DELETE_TRANSACTION: {
            return state.filter(i => i.id !== action.data)

        }

        default:
            return state
    }

}
