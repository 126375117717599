import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { log } from "console"
import { addDropDown, deleteDropDown, editDropDown, getDropDown } from "../../services/dropdown.service"



export interface DropDownType {
    id?: number
    category:string,
    title:string
    // createdAt: string,
    // updatedAt: string
    // updatedBy: number | null,
}

export enum DropDownActionsList {
    ADD_DROPDOWN = 'ADD_DROPDOWN',
    EDIT_DROPDOWN= 'EDIT_DROPDOWN',
    FETCH_DROPDOWN = 'FETCH_DROPDOWN',
    DELETE_DROPDOWN = 'DELETE_DROPDOWN'
}

export interface deleteDropDown {
    id: number | undefined
}

export interface AddDropdownAction {
    type: DropDownActionsList.ADD_DROPDOWN
    data: DropDownType
}

export interface EditDropdownAction {
    type: DropDownActionsList.EDIT_DROPDOWN
    data: DropDownType
    id: number | undefined
}

export interface FetchDropdownAction {
    type: DropDownActionsList.FETCH_DROPDOWN
    data: DropDownType[]

}

export interface DeleteDropdownAction {
    type: DropDownActionsList.DELETE_DROPDOWN
    data: number|undefined
}

export type DropDownActions = AddDropdownAction | EditDropdownAction | FetchDropdownAction | DeleteDropdownAction

export const addDropDownAction = (data: DropDownType) => {
  
    return async (dispatch: Dispatch) => {
       return addDropDown(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData:DropDownType = {category:'',title:''}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddDropdownAction>({
                    type: DropDownActionsList.ADD_DROPDOWN,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Drop Down Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editDropDownAction = (data: DropDownType, id: number | undefined) =>{
    return async (dispatch: Dispatch)=>{
        return editDropDown(data,id).then(response => {
                if (response.status === 200 && response.data.data) {
                    let editData:DropDownType = {category:'',title:''}
                    for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                        const key = camelize(Object.keys(response.data.data)[i])
                        const value = Object.values(response.data.data)[i]
                        editData = { ...editData, [key]: value }
                    }
                    dispatch<EditDropdownAction>({
                        type: DropDownActionsList.EDIT_DROPDOWN,
                        data: editData,
                        id: id
                    })
                    return Promise.resolve(response.data.message
                        ? response.data.message
                        : 'Drop Down Updated')
                } else {
                    throw { response }
                }
            }).catch(error => {
                return Promise.reject(error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to update'
                    : 'Unable to update')
            })        
    }
}
    

export const fetchDropDownAction = () => {
    return async (dispatch: Dispatch) => {
        const response=await getDropDown()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
         dispatch<FetchDropdownAction>({
            type: DropDownActionsList.FETCH_DROPDOWN,
            data: data
        })
    }
}

export const deleteDropDownAction = (id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteDropDown(id).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDropdownAction>({
                    type: DropDownActionsList.DELETE_DROPDOWN,
                    data:id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Drop Down Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}