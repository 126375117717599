import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

interface dataSet {
    name: string,
    data: number[],
}

interface Iprops {
    datasets: dataSet[]
    labels: string[]
    title: string
    colors: string[]
    height?: number
    xyLabelShow?: boolean
}
// simple line chart
const LineChartForCard = (props: Iprops) => {
    // default options
    const { colors, datasets, labels, title, height, xyLabelShow } = props
    const apexLineChartWithLables: ApexOptions = {
        chart: {
            // height: 50,
            height: height ? height : 300,
            type: 'area',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            sparkline: {
                enabled: true
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [4, 1],
            curve: 'straight',//smooth
        },
        // title: {
        //     text: '',
        //     align: 'left',
        //     style: {
        //         fontSize: '14px',
        //     },
        // },
        title: {
            // text: '$235,312',
            offsetX: 30,
            style: {
                fontSize: '24px',
                // cssClass: 'apexcharts-yaxis-title'
            }
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
            borderColor: '#f1f3fa',
        },
        markers: {
            size: 3,
        },
        fill: {
            type: 'gradient',
            opacity: 1,
            colors: colors,
        },
        xaxis: {
            categories: labels,
            title: {
                text: '',
            },
            labels: {
                show: xyLabelShow !== undefined ? xyLabelShow : true,
            },
        },
        yaxis: {
            title: {
                text: '',
            },
            labels: {
                show: xyLabelShow !== undefined ? xyLabelShow : true,
            },
        },
        legend: {
            offsetY: 0,
        },
        responsive: [
            {
                options: {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    stroke: {
                        curve: 'smooth',

                    },

                },
            },
        ],
        ///
        // chart: {
        //     id: 'sparkline3',
        //     group: 'sparklines',
        //     type: 'area',
        //     height: 50,
        //     sparkline: {
        //         enabled: true
        //     },
        // },
        // stroke: {
        //     curve: 'straight'
        // },
        // fill: {
        //     opacity: 1,
        // },
        // series: [{
        //     name: 'Profits',
        //     data: [3, 2, 4, 5, 7]
        // }],
        // labels: labels,
        // xaxis: {
        //     type: 'datetime',
        // },
        // yaxis: {
        //     min: 0
        // },
        // colors: colors,
        // title: {
        //     // text: '$135,965',
        //     offsetX: 30,
        //     style: {
        //         fontSize: '24px',
        //         // cssClass: 'apexcharts-yaxis-title'
        //     }
        // },
        // subtitle: {
        //     // text: 'Profits',
        //     offsetX: 30,
        //     style: {
        //         fontSize: '14px',
        //         // cssClass: 'apexcharts-yaxis-title'
        //     }
        // }
    };

    // chart data
    // const apexLineChartWithLablesData = [
    //     {
    //         name: 'High - 2018',
    //         data: [403540.35, 584287.1, 294573.87, 176220.78, 487164.73, 314826.53, 1995.23],
    //     },
    //     {
    //         name: 'Low - 2018',
    //         data: [584287, 176220, 1995.23, 487164.73, 584287, 314826.53, 1995.23],
    //     },
    // ];
    return (
        <>
            <h4 className="header-title mb-3">{title}</h4>
            <Chart
                options={apexLineChartWithLables}
                series={datasets}
                type="area"
                className="apex-charts"
                height={height}
            />
        </>
    );
};

export default LineChartForCard;
