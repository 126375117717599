import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { addAccountHolder, deleteAccountHolder, editAccountHolder, getAccountHolder } from "../../services/accountHolder.service"



export interface AccountHolderType {
    accountNumber?: number,
    // userId: number
    branchId?: number,
    groupName?: string,
    nameTag?: string,
    accountName?: string,
    // accountNumber?:number,
    customerName?: string,
    address1?: string,
    address2?: string,
    area?: string,
    place?: string,
    supplierPlace?: string,
    district?: string,
    pincode?: number | undefined | string,
    state?: string,
    country?: string,
    route?: string,
    contactNumber1?: number | undefined | string,
    contactNumber2?: number,
    oldBalance?: number,
    asOnDate?: string,
    gstNumber?: string,
    aadharNumber?: number | undefined | string,
    contactPerson?: string,
    contactPersonNumber?: number,
    bankAccountNumber?: number,
    bankName?: string,
    bankBranchName?: string,
    ifscCode?: string
    branch?: string
    // createdAt: string,
    // updatedAt: string
    // updatedBy: number | null,
}

export enum AccountActionsList {
    ADD_ACCOUNTHOLDER = 'ADD_ACCOUNTHOLDER',
    EDIT_ACCOUNTHOLDER = 'EDIT_ACCOUNTHOLDER',
    FETCH_ACCOUNTHOLDER = 'FETCH_ACCOUNTHOLDER',
    DELETE_ACCOUNTHOLDER = 'DELETE_ACCOUNTHOLDER'
}

export interface deleteAccountHolder {
    accountNumber: number | undefined
}

export interface AddAccountAction {
    type: AccountActionsList.ADD_ACCOUNTHOLDER
    data: AccountHolderType
}

export interface EditAccountAction {
    type: AccountActionsList.EDIT_ACCOUNTHOLDER
    data: AccountHolderType
    accountNumber: number | undefined
}

export interface FetchAccountAction {
    type: AccountActionsList.FETCH_ACCOUNTHOLDER
    data: AccountHolderType[]

}

export interface DeleteAccountAction {
    type: AccountActionsList.DELETE_ACCOUNTHOLDER
    data: number | undefined
}

export type AccountActions = AddAccountAction | EditAccountAction | FetchAccountAction | DeleteAccountAction

export const addAccountHolderAction = (data: AccountHolderType) => {
    return async (dispatch: Dispatch) => {
        return addAccountHolder(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddAccountAction>({
                    type: AccountActionsList.ADD_ACCOUNTHOLDER,
                    data: addData
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const editAccountHolderAction = (data: AccountHolderType, accountNumber: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editAccountHolder(data, accountNumber).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditAccountAction>({
                    type: AccountActionsList.EDIT_ACCOUNTHOLDER,
                    data: editData,
                    accountNumber: accountNumber
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}


export const fetchAccountHolderAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getAccountHolder()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchAccountAction>({
            type: AccountActionsList.FETCH_ACCOUNTHOLDER,
            data: data
        })
    }
}

export const deleteAccountHolderAction = (accountNumber: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return deleteAccountHolder(accountNumber).then(response => {
            if (response.status === 200) {
                dispatch<DeleteAccountAction>({
                    type: AccountActionsList.DELETE_ACCOUNTHOLDER,
                    data: accountNumber
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : ' Acoount Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}