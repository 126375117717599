import { RolesActionsList, RolesType } from "../actions/rolesAction";
import { Action } from "../actions/types";

const initialValue: Array<RolesType> = []

export const roleReducer = (state: RolesType[] = initialValue, action: Action) => {

    switch (action.type) {
        case RolesActionsList.ADD_ROLES:
            return action.data;

        case RolesActionsList.FETCH_ROLES:
            return action.data

        case RolesActionsList.EDIT_ROLES: {
            return action.data;
        }
        case RolesActionsList.DELETE_ROLES:
            return action.data
        default:
            return state;
    }
}