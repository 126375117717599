export const apiUrl = process.env.REACT_APP_API_URL

export interface Permission {
    add: boolean
    edit: boolean
    view: boolean
    delete: boolean
}

export enum FormTypeList {
    ADD = 'ADD',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    DELETE = 'DELETE',
}

export type FormType = FormTypeList.ADD | FormTypeList.EDIT | FormTypeList.VIEW | FormTypeList.DELETE;

export enum FromList {
    REPORT = "REPORT",
    TRANSACTION = "TRANSACTION"
}

export type FromTypeForTransaction = FromList.REPORT | FromList.TRANSACTION

export const PAGE_SIZE = 50;

export const WEEK_DAYS = ['6', '7'];

export enum PortfolioDashboardList {
    MOM = 'MOM',
    YOY = 'YOY',
    MTD = 'MTD',
    YTD = 'YTD',
}

export type PortfolioDashboardType = PortfolioDashboardList.MOM | PortfolioDashboardList.YOY | PortfolioDashboardList.MTD | PortfolioDashboardList.YTD

export enum COLORS {
    'COLOR1' = '#763EEC',
    //    'COLOR1'='#286AF3',
    'COLOR2' = '#3CC88A',
    'COLOR3' = '#DC6195',
    'COLOR4' = '#F3AB3B',
    'COLOR5' = '#52CCE5',
    'COLOR6' = '#F2575C',
    'COLOR7' = '#F46FA5',
    'COLOR8' = '#274178',
    'COLOR11' = '#A6E4EF',
    'COLOR12' = '#95FF4B',
    'COLOR13' = '#EEA0EC',
    'ALICE_BLUE' = 'AliceBlue',
    'ANTIQUE_WHITE' = 'AntiqueWhite',
    'AQUA' = 'Aqua',
    'AQUAMARINE' = 'Aquamarine',
    'AZURE' = 'Azure',
    'BEIGE' = 'Beige',
    'BISQUE' = 'Bisque',
    'BLACK' = 'Black',
}