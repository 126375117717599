import { ProductActionsList, ProductType} from "../actions/productAction";
import { Action } from "../actions/types";

const initialValue: Array<ProductType> = []

export const productReducer = (state: ProductType[] = initialValue, action: Action) => {

    switch (action.type) {
        case ProductActionsList.ADD_PRODUCT:
            
            return [...state, action.data];

        case ProductActionsList.FETCH_PRODUCT:
            return action.data

        case ProductActionsList.EDIT_PRODUCT: {
            const updated = [...state]
            const index = updated.findIndex((u) => {
                return u.id === action.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated;
        }
        case ProductActionsList.DELETE_PRODUCT:

            return state.filter(i => i.id !== action.data)
        default:
            return state;
    }
}