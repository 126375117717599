import api from "../config/api"
import { SalesType } from "../redux/actions/salesActions"

export const addSales = async (data: SalesType) => {
    return await api().post('/sales/', data)
}

export const editSales = async (data: SalesType, id: number | undefined) => {
    return await api().put(`/sales/?id=${id}`, data)
}

export const getSales = async () => {
    return await api().get('/sales/')
}

export const getSalesById = (id: number) => {
    return api().get(`/sales/?id=${id}`)
}

export const getRecentSalesId = () => {
    return api().get(`/sales/recent/`)
}

export const getSalesItemsBySalesDate = (date: string) => {
    return api().get(`/salesItems/?date=${date}`)
}

export const getSalesByDate = (startDate: string,endDate:string) => {
    return api().get(`/sales/?startDate=${startDate}&endDate=${endDate}`)
}

export const deleteSales = (id: number | undefined) => {
    return api().delete(`/sales/?id=${id}`)

}