import api from "../config/api"

export const addCategory = async (data: FormData) => {
    return await api().post('/category/', data)
}

export const editCategory = async (data: FormData, id: number | undefined) => {
    // return await api().put(`/category/${id}`, data)
    return await api().post(`/category/?id=${id}`, data)
}

export const getCategory = async () => {
    return await api().get('/category/')
}

export const deleteCategory = async (id: number | undefined) => {
    return await api().delete(`/category/?id=${id}`)
    // return await api().delete(`/category/${id}`)
}