import api from "../config/api"

//for purchase  att form
export const getAttributes = async () => {
    return await api().get('/attribute/')
}
//

export interface AttributeType {
    id?: number | undefined | null
    name: string
}

export const addAttribute = async (data: AttributeType) => {
    return await api().post('/attribute/', data)
}

export const editAttribute = async (data: AttributeType, id: number | undefined) => {
    // return await api().put(`/attribute/${id}`, data)
    return await api().put(`/attribute/?id=${id}`, data)
}

export const getAttribute = async () => {
    const res = await api().get('/attribute/')
    return res.data.data;
}

export const deleteAttribute = async (id: number | undefined) => {
    return await api().delete(`/attribute/?id=${id}`)
    // return await api().delete(`/attribute/${id}`)
}
