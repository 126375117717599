import { DepartmentActionList, DepartmentType } from "../actions/departmentAction";
import { Action } from "../actions/types";

const initialValue: Array<DepartmentType> = []

export const departmentReducer = (state: DepartmentType[] = initialValue, action: Action) => {

    switch (action.type) {
        case DepartmentActionList.ADD_DEPARTMENT:
            return [...state, action.data];
        case DepartmentActionList.EDIT_DEPARTMENT: {

            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case DepartmentActionList.FETCH_DEPARTMENT:
            return action.data

        case DepartmentActionList.DELETE_DEPARTMENT: {
            return state.filter(i => i.id !== action.data)

        }


        default:
            return state;
    }
}