/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { getPurchaseItem } from "../../services/purchaseItem.service"

export enum PurchaseReturnItemActionList {
    // ADD_PURCHASEITEM = 'ADD_PURCHASE',
    FETCH_PURCHASERETURNITEM = 'FETCH_PURCHASERETURNITEM',
    // UPDATE_PURCHASE = 'UPDATE_PURCHASE',
    // DELETE_PURCHASE = 'DELETE_PURCHASE',
}

export const PurchaseSampleData = [
    {
        id: 1,
        invoiceDate: '2023-08-30',
        invoiceNo: '1234',
        purchaseEntryNo: '73246',
        supplierId: 1,
        finalAmount: '3093',
    },

]

export interface PurchaseReturnItems {
    entryNumber: number
    productId: number
    batchNumber: number
    expiryDate: string
    quantity: string
    freeQuantity: string
    totalQuantity: string
    priceWogst: number
    priceGst: number
    wRate: number
    rRate: number
    lRate: number
    mrp: number
    sDiscount: number
    pDiscountpercent: number
    pDiscountamount: number
    cDiscountpercent: number
    cDiscountamount: number
    taxableAmount: number
    igstAmount: number
    cgstAmount: number
    sgstAmount: number
    totalAmount: number
    unitCost: number
    profitPercent: number
    profitAmount: number
}



export interface FetchPurchaseReturnItemAction {
    type: PurchaseReturnItemActionList.FETCH_PURCHASERETURNITEM
    data: Array<PurchaseReturnItems>
}



export type PurchaseReturnItemActions = FetchPurchaseReturnItemAction


export const fetchPurchaseItemAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseItem()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseReturnItemAction>({
            type: PurchaseReturnItemActionList.FETCH_PURCHASERETURNITEM,
            data: []
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<FetchPurchaseAction>({
    //     type: PurchaseActionList.FETCH_PURCHASE,
    //     data: PurchaseSampleData
    // })
    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // return api().get<APIResponse<PurchaseType[]>>('Purchase/', config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<FetchPurchaseAction>({
    //             type: PurchaseActionList.FETCH_PURCHASE,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Fetch Successfull')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to fetch'
    //         : 'Unable to fetch')
    // })
    // }
}



