import api from "../config/api"

export const addBrand = async (data: FormData) => {
    return await api().post('/brand/', data)
}

export const editBrand = async (data: FormData, id: number | undefined) => {
    // return await api().put(`/brand/${id}`, data)
    return await api().post(`/brand/?id=`+id, data)
}

export const getBrand = async () => {
    return await api().get('/brand/')
}

export const deleteBrand = async (id: number | undefined) => {
    // return await api().delete(`/brand/${id}`)
    return await api().delete(`/brand/?id=`+id)
}