import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmationForm from "../components/DeleteConfirmationForm"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { DropDownType, addDropDownAction, deleteDropDownAction, editDropDownAction, fetchDropDownAction } from "../redux/actions/dropdownAction"
import { AppDispatch, StoreState } from "../redux/store"

interface TableBody {
    sno: number,
    Name: string,
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: DropDownType) => void
type HandleDelete = (value: DropDownType) => void



const GroupMaster = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const GroupLists = useSelector<StoreState, DropDownType[]>(state => state.dropdown)

    useEffect(() => {
        dispatch(fetchDropDownAction())
    }, [dispatch])

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<DropDownType | null>(null)
    const [deleteId, setDeleteId] = useState<number | null | undefined>(null)
    const [id, setId] = useState<number>()


    const [Name, setName] = useState<string>('')
    const [NameError, setNameError] = useState<string>('')

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.EDIT)
        setEditData(value)
    }

    const handleDelete: HandleDelete = (value) => {
        setDeleteId(value.id)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    const resetStates = () => {
        setName('')
        setEditData(null)
    }

    const handleSave = () => {
        let error = false
        const addData: DropDownType = {
            id: id,
            category: "groupmaster",
            title: Name
        }

        const edata: DropDownType = {
            id: id,
            category: "groupmaster",
            title: Name
        }

        if (addData.category === '') {
            setNameError('Category is Required')
            error = true
        } else if ((addData.category || '').length > 30) {
            setNameError('Category should be within 30 characters')
            error = true
        }

        if (addData.title === '') {
            setNameError('Group name is required')
            error = true
        } else if ((addData.title || '').length > 30) {
            setNameError('Group name should be within 30 characters')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addDropDownAction(addData)).then(text => {
                    toast('Added', ToastTypes.SUCCESS)
                    resetStates()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.EDIT && editData !== null) {
                dispatch(editDropDownAction(edata, editData.id)).then(text => {
                    toast('Updated', ToastTypes.SUCCESS)
                    resetStates()
                    setEditData('' || null)
                    setFormType(FormTypeList.ADD)
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if ((formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) && editData !== null) {
            setId(editData.id)
            setName(editData.title || '')


        } else {
            resetStates()
        }
    }, [formType, editData])


    const onDelete = () => {
        if (deleteId) {
            dispatch(deleteDropDownAction(deleteId)).then(text => {
                toast('Deleted', ToastTypes.SUCCESS)
                setShowDeleteForm(false)
                setDeleteId(null)
            }).catch(text => {

            })
        }
    }
    return <>
        <Modal
            headerText={'Delete GroupMaster'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='lg'
        >
            <DeleteConfirmationForm
                onDelete={onDelete}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <UserLayout>
            <h4>Group Master</h4>

            <div className="container">
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6 justify-content-center">
                        {/* <div className="card"> */}
                        <div className='hstack justify-content-center gap-3'>
                            <label htmlFor="" className="mb-2">Name</label>
                            <FormInput
                                name='Name'
                                labelClassName="required"
                                value={Name}
                                onChange={handleName}
                                errorText={NameError}
                                containerClass="mb-2"
                            />
                            {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT || formType === FormTypeList.DELETE) &&
                                <>
                                    < button className="btn btn-sm fw-bold btn-secondary mb-2" onClick={() => resetStates()}>Add New</button>
                                    <button className="btn btn-sm fw-bold btn-success mb-2" onClick={handleSave}>{editData ? "Update" : "Save"}</button>
                                </>
                            }

                        </div>

                        {/* </div> */}
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="card mb-0 p-1">
                            {/* <div className="card-body"> */}
                            {/* <div className='table-wrapper'> */}
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Name</th>
                                        <th className='text-truncate align-middle'>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {GroupLists.filter((gl: DropDownType) => gl.category === "groupmaster").map((gl, i) => {
                                        return <GroupBody
                                            sno={i + 1}
                                            Name={gl.title}
                                            key={gl.id}
                                            onEdit={() => handleEdit(gl)}
                                            onDelete={() => handleDelete(gl)}

                                        />
                                    })}
                                </tbody>
                            </table>
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
            {/* </div> */}
        </UserLayout >
    </>
}

const GroupBody = ({ sno, Name, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{Name}</td>
        <td className="align-middle">
            <div className='hstack justify-content-center gap-1'>
                <span className='badge bg-info cursor-pointer' onClick={onEdit}><i className="fe-edit noti-icon"></i></span>
                <span className='badge bg-danger cursor-pointer' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></span>
            </div>
        </td>
    </tr>
}
export default GroupMaster

