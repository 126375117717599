import { PurchaseItems } from "../actions/purchaseActions";
import { PurchaseItemActionList } from "../actions/purchaseItemAction";
import { Action } from "../actions/types";

const initialValue: Array<PurchaseItems> = []

export const purchaseItemReducer = (state: PurchaseItems[] = initialValue, action: Action) => {

    switch (action.type) { 
        case PurchaseItemActionList.FETCH_PURCHASEITEM:
            return action.data
        default :
            return state
    }
}