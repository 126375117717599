/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { PurchaseReturnAttribute } from "../../pages/PurchaseReturnMaster"
import { addPurchaseReturn, deletePurchaseReturn, editPurchaseReturn, getPurchaseReturn, getPurchaseReturnByDate, getPurchaseReturnById } from "../../services/purchaseReturn.service"
// import { PurchaseAttribute } from "../../pages/PurchaseMaster"


export enum PurchaseReturnPaymentModesDropDown {
    CASH = "Cash",
    BANK = "Bank",
    CREDIT = "Credit",
}
export type PurchaseReturnPaymentModeTypes = PurchaseReturnPaymentModesDropDown.CASH | PurchaseReturnPaymentModesDropDown.BANK | PurchaseReturnPaymentModesDropDown.CREDIT

export enum PurchaseReturnActionList {
    ADD_PURCHASERETURN = 'ADD_PURCHASERETURN',
    FETCH_PURCHASERETURN = 'FETCH_PURCHASERETURN',
    UPDATE_PURCHASERETURN = 'UPDATE_PURCHASERETURN',
    DELETE_PURCHASERETURN = 'DELETE_PURCHASERETURN',
}

export interface PurchaseReturnItems {
    entryNumber: number
    productId: number
    productCode: string
    batchNumber: number
    expiryDate: string
    quantity: string
    freeQuantity: string
    totalQuantity: string
    priceWogst: number
    priceGst: number
    wRate: number
    rRate: number
    lRate: number
    mrp: number
    sDiscount: number
    pDiscountpercent: number
    pDiscountamount: number
    cDiscountpercent: number
    cDiscountamount: number
    taxableAmount: number
    igstAmount: number
    cgstAmount: number
    sgstAmount: number
    totalAmount: number
    unitCost: number
    profitPercent: number
    profitAmount: number
}

export interface PurchaseReturnType {
    // id?: number
    // invoiceDate: string
    // invoiceNo: string
    // purchaseEntryNo: string
    // supplierId: number
    // finalAmount: string
    id?: number
    financeYear: string
    branchId: number
    invoiceType: string
    invoiceTitle: string
    invoiceDate: string
    invoiceHour?: string
    goodsReceivedDate: string
    invoiceDay?: string
    invoiceWeekNumber?: number
    invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    purchaseReturnEntryNumber: number
    systemNo: string
    repId: number
    // repName: string
    supplierId: number
    purchaseReturnItems: PurchaseReturnItems[]
    stockItems: PurchaseReturnAttribute[]
    /////new
    bankName: string
    bankNumber: number
    supplierName: string
    paymentMode: PurchaseReturnPaymentModeTypes
    billAmount?: string
    disPercent?: string
    disAmount?: string
    add?: string
    less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    deliveryMode?: string
    deliveryDetails?: string
    remarks?: string
    netTaxable?: string
    netIgst?: string
    netSgst?: string
    netCgst?: string
    netProfit?: string
    netProfitPercent?: string
    AValue?: string
    ATaxable?: string
    BValue?: string
    BTaxable?: string
    CValue?: string
    CTaxable?: string
    DValue?: string
    DTaxable?: string
    EValue?: string
    ETaxable?: string
    FValue?: string
    FTaxable?: string
    GValue?: string
    GTaxable?: string
}

export interface AddPurchaseReturnAction {
    type: PurchaseReturnActionList.ADD_PURCHASERETURN
    data: PurchaseReturnType
}

export interface FetchPurchaseReturnAction {
    type: PurchaseReturnActionList.FETCH_PURCHASERETURN
    data: Array<PurchaseReturnType>
}

export interface UpdatePurchaseReturnAction {
    type: PurchaseReturnActionList.UPDATE_PURCHASERETURN
    data: PurchaseReturnType
    id: number
}

export interface DeletePurchaseReturnAction {
    type: PurchaseReturnActionList.DELETE_PURCHASERETURN
    data: number
}

export type PurchaseReturnActions = AddPurchaseReturnAction | FetchPurchaseReturnAction | UpdatePurchaseReturnAction | DeletePurchaseReturnAction

export const addPurchaseReturnAction = (data: PurchaseReturnType) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return addPurchaseReturn(data).then(response => {
            if (response.status === 200 && response.data.data) {
                let addData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    addData = { ...addData, [key]: value }
                }
                dispatch<AddPurchaseReturnAction>({
                    type: PurchaseReturnActionList.ADD_PURCHASERETURN,
                    data: data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'PurchaseReturn Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }
    // dispatch<AddPurchaseReturnAction>({
    //     type: PurchaseReturnActionList.ADD_PURCHASERETURN,
    //     data: {
    //         ...data,
    //         id: parseInt(nanoid(5))
    //     }
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Added")
    // return api().post<APIResponse<PurchaseReturnType>>('Purchase/', data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {

    //         dispatch<AddPurchaseReturnAction>({
    //             type: PurchaseReturnActionList.ADD_PURCHASERETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Added')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to add'
    //         : 'Unable to add')
    // })
    // }
}

export const updatePurchaseReturnAction = (data: PurchaseReturnType, id: number) => {
    // return async (dispatch: Dispatch, getState: GetState) => {

    return async (dispatch: Dispatch) => {
        return editPurchaseReturn(data, id).then(response => {
            if (response.status === 200 && response.data.data) {
                let editData = {}
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<UpdatePurchaseReturnAction>({
                    type: PurchaseReturnActionList.UPDATE_PURCHASERETURN,
                    data: data,
                    id: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'PurchaseReturn Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })

    }

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Updated")
    // return api().put<APIResponse<PurchaseReturnType>>(`Purchase/?id=${id}`, data, config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<UpdatePurchaseReturnAction>({
    //             type: PurchaseReturnActionList.UPDATE_PURCHASERETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Updated')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to update'
    //         : 'Unable to update')
    // })
}

export const fetchPurchaseReturnByIdAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseReturnById(id)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        return data
        // dispatch<FetchPurchaseReturnAction>({
        //     type: PurchaseReturnActionList.FETCH_PURCHASERETURN,
        //     data: data
        // })
    }
}

export const fetchPurchaseReturnAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseReturn()
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseReturnAction>({
            type: PurchaseReturnActionList.FETCH_PURCHASERETURN,
            data: data
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<FetchPurchaseReturnAction>({
    //     type: PurchaseReturnActionList.FETCH_PURCHASERETURN,
    //     data: PurchaseSampleData
    // })
    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // return api().get<APIResponse<PurchaseReturnType[]>>('Purchase/', config).then(response => {
    //     if (response.status === 200 && response.data.data) {
    //         dispatch<FetchPurchaseReturnAction>({
    //             type: PurchaseReturnActionList.FETCH_PURCHASERETURN,
    //             data: response.data.data
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Fetch Successfull')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to fetch'
    //         : 'Unable to fetch')
    // })
    // }
}



export const fetchPurchaseReturnDateAction = (startDate: string, endDate: string) => {
    return async (dispatch: Dispatch) => {
        const response = await getPurchaseReturnByDate(startDate, endDate)
        const data = response.data.data.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchPurchaseReturnAction>({
            type: PurchaseReturnActionList.FETCH_PURCHASERETURN,
            data: data
        })
    }
}

export const deletePurchaseReturnAction = (id: number) => {
    return async (dispatch: Dispatch) => {
        return deletePurchaseReturn(id).then(response => {
            if (response.status === 200) {
                dispatch<DeletePurchaseReturnAction>({
                    type: PurchaseReturnActionList.DELETE_PURCHASERETURN,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'PurchaseReturn Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
    // return async (dispatch: Dispatch, getState: GetState) => {

    // dispatch<DeletePurchaseReturnAction>({
    //     type: PurchaseReturnActionList.DELETE_PURCHASERETURN,
    //     data: id
    // })

    // const token = getState().authUser.token!
    // const config = getAxiosRequestConfig(token)

    // // return Promise.resolve("Purchase Deleted")
    // return api().delete<APIResponse<PurchaseReturnType>>(`Purchase/?id=${id}`, config).then(response => {
    //     if (response.status === 200) {
    //         dispatch<DeletePurchaseReturnAction>({
    //             type: PurchaseReturnActionList.DELETE_PURCHASERETURN,
    //             data: id
    //         })
    //         return Promise.resolve(response.data.message
    //             ? response.data.message
    //             : 'Purchase Deleted')
    //     } else {
    //         throw { response }
    //     }
    // }).catch(error => {
    //     return Promise.reject(error.response
    //         ? error.response.data.message
    //             ? error.response.data.message
    //             : 'Unable to delete'
    //         : 'Unable to delete')
    // })
    // }
}

