import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { getStockMaster } from "../../services/stockMaster.service"



export interface StockMasterType {
    id: number
    productId: number,
    batchNumber: number
    expiryDate: string
    brandId?: string
    categoryId?: string
    attribute1?: string
    attribute2?: string
    attribute3?: string
    attribute4?: string
    attribute5?: string
    quantity: number
    freeQuantity: number
    totalQuantity: number
    priceWogst: number
    priceGst: number
    wRate: number
    rRate: number
    lRate: number
    mrp: number
    taxableAmount: number
    igstAmount: number
    cgstAmount: number
    sgstAmount: number
    totalAmount: number
    unitCost: number
    productName: string
    productCode: string
    productGroupCode: string
    reOrder: number
    gst: number
    sac: number
}

export enum StockMasterActionsList {
    ADD_STOCKMASTER = 'ADD_STOCKMASTER',
    EDIT_STOCKMASTER = 'EDIT_STOCKMASTER',
    FETCH_STOCKMASTER = 'FETCH_STOCKMASTER',
    DELETE_STOCKMASTER = 'DELETE_STOCKMASTER'
}


export interface FetchStockMasterAction {
    type: StockMasterActionsList.FETCH_STOCKMASTER
    data: StockMasterType[]

}



export type StockMasterActions = FetchStockMasterAction





export const fetchStockMasterAction = () => {
    return async (dispatch: Dispatch) => {
        const response = await getStockMaster()        
        const data = response.data.data?.map((res: any) => {
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchStockMasterAction>({
            type: StockMasterActionsList.FETCH_STOCKMASTER,
            data: data
        })
    }

}

