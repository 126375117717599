import * as Feather from 'react-feather'

export interface MenuItemTypes {
    key: string
    label: string
    isTitle?: boolean
    Icon?: Feather.Icon
    url?: string
    badge?: {
        variant: string
        text: string
    }
    parentKey?: string
    target?: string
    children?: MenuItemTypes[]
}

// const dispatch = useDispatch<AppDispatch>()
// const role = useSelector<StoreState, RolesType[]>(state => state.role)
// const authLogin = useSelector<StoreState,AuthUserType[]>(state => state.authUser)
// const user = useSelector<StoreState, UsersType[]>(state => state.user)
// console.log(user);

// useEffect(() => {
//     dispatch(fetchUsersAction())
// }, [dispatch])



const MENU_ITEMS: MenuItemTypes[] = [
    {
        key: 'apps-chat',
        label: 'Chat',
        isTitle: false,
        Icon: Feather.MessageSquare,
        url: '/apps/chat',
    },

    {
        key: 'apps-ecommerce',
        label: 'Ecommerce',
        isTitle: false,
        Icon: Feather.ShoppingCart,
        children: [
            {
                key: 'ecommerce-dashboard',
                label: 'Dashboard',
                url: '/apps/ecommerce/dashboard',
                parentKey: 'apps-ecommerce',
            },
            {
                key: 'ecommerce-products',
                label: 'Products',
                url: '/apps/ecommerce/products',
                parentKey: 'apps-ecommerce',
            },
        ],
    },
]
const USER_MENU_ITEMS: MenuItemTypes[] = [
    // {
    //     key: 'dashboard',
    //     label: 'Dashboard',
    //     isTitle: false,
    //     Icon: Feather.Home,
    //     children: [
    //         {
    //             key: 'p-dashboard',
    //             label: 'Portfolio Dashboard',
    //             url: '/',
    //             parentKey: 'dashboard',
    //         },
    //         {
    //             key: 'c-dashboard',
    //             label: 'Center Dashboard',
    //             url: '/center/dashboard',
    //             parentKey: 'dashboard',
    //         },
    //     ],
    // },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        Icon: Feather.Airplay,
        url: '/',
        children: [
            {
                key: 's-dashboard',
                label: 'Sales Dashboard',
                url: '/',
                parentKey: 'dashboard',
            },
            {
                key: 'sr-dashboard',
                label: 'Sales Return Dashboard',
                url: '/salesReturn/dashboard',
                parentKey: 'dashboard',
            },
            {
                key: 'p-dashboard',
                label: 'Purchase Dashboard',
                url: '/purchase/dashboard',
                parentKey: 'dashboard',
            },
            {
                key: 'pr-dashboard',
                label: 'Purchase Return Dashboard',
                url: '/purchaseReturn/dashboard',
                parentKey: 'dashboard',
            },
        ]
    },
    {
        key: 'accountHolder',
        label: 'Account Holder',
        isTitle: false,
        Icon: Feather.UserCheck,
        url: '/accountHolder',
    },
    {
        key: 'product',
        label: 'Product',
        isTitle: false,
        Icon: Feather.Package,

        // parentKey: 'master'
        children: [
            {
                key: 'attribute',
                label: 'Attribute',
                isTitle: false,
                Icon: Feather.Users,
                url: '/attributeMaster',
                parentKey: 'product'

            },
            {
                key: 'brand',
                label: 'Brand',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/brand',
                parentKey: 'product'
            },
            {
                key: 'department',
                label: 'Department',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/department',
                parentKey: 'product'
            },
            {
                key: 'category',
                label: 'Category',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/category',
                parentKey: 'product'
            },
            {
                key: 'productMaster',
                label: 'Product Master',
                isTitle: false,
                Icon: Feather.Package,
                url: '/productMaster',
            }
        ]

    },
    {
        key: 'stockMaster',
        label: 'Stock',
        isTitle: false,
        Icon: Feather.Layers,
        url: '/stockMaster',
    },
    {
        key: 'Purchase',
        label: 'Purchase',
        isTitle: false,
        Icon: Feather.Package,
        children: [
            {
                key: 'purchaseMaster',
                label: 'Purchase',
                isTitle: false,
                Icon: Feather.PlusCircle,
                url: '/purchaseMaster',
                parentKey: 'Purchase'
            },
            {
                key: 'purchaseReturn',
                label: 'Purchase Return',
                isTitle: false,
                Icon: Feather.MinusCircle,
                url: '/purchaseReturn',
                parentKey: 'Purchase'
            },
        ]
    },
    {
        key: 'Sales',
        label: 'Sales',
        isTitle: false,
        Icon: Feather.ShoppingBag,
        children: [
            {
                key: 'salesMaster',
                label: 'Sales',
                isTitle: false,
                Icon: Feather.ShoppingBag,
                url: '/salesMaster',
                parentKey: 'Sales'
            },
            {
                key: 'salesReturnMaster',
                label: 'Sales Return',
                isTitle: false,
                Icon: Feather.ShoppingBag,
                url: '/salesReturnMaster',
                parentKey: 'Sales'
            },
        ]
    },

    {
        key: 'report',
        label: 'Report',
        isTitle: false,
        Icon: Feather.Package,
        // parentKey: 'master'
        children: [
            {
                key: 'purchaseReport',
                label: 'Purchase Report',
                isTitle: false,
                Icon: Feather.Users,
                url: '/purchaseReport',
                parentKey: 'report'

            },
            {
                key: 'salesReport',
                label: 'Sales Report',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/salesReport',
                parentKey: 'report'
            },
            {
                key: 'purchaseReturnReport',
                label: 'Purchase Return Report',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/purchaseReturnReport',
                parentKey: 'report'
            },
            {
                key: 'salesReturnReport',
                label: 'Sales Return Report',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/salesReturnReport',
                parentKey: 'report'
            },
            {
                key: 'purchaseCategoryReport',
                label: 'Purchase Category Report',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/purchaseCategoryReport',
                parentKey: 'report'
            },
            {
                key: 'salesCategoryReport',
                label: 'Sales Category Report',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/salesCategoryReport',
                parentKey: 'report'
            },
        ]

    },
    {
        key: 'barcodeSticker',
        label: 'Barcode',
        isTitle: false,
        Icon: Feather.Printer,
        url: '/barcodeSticker',

    },
    {
        key: 'transaction',
        label: 'Transaction',
        isTitle: false,
        Icon: Feather.DollarSign,
        url: '/transaction',

    },
    {
        key: 'user',
        label: 'User',
        isTitle: false,
        Icon: Feather.Users,
        url: '/userManagement',
        // parentKey: 'master'

    },
    {
        key: 'appSettings',
        label: 'App Settings',
        isTitle: false,
        Icon: Feather.ShoppingBag,
        url: '/appSettings',
        // parentKey: 'master'

    },
    {
        key: 'master',
        label: 'Master',
        isTitle: false,
        Icon: Feather.FolderPlus,
        children: [
            // {
            //     key: 'user',
            //     label: 'User',
            //     isTitle: false,
            //     Icon: Feather.MessageSquare,
            //     url: '/userManagement',
            //     parentKey: 'master'

            // },
            {
                key: 'roles',
                label: 'Roles',
                isTitle: false,
                Icon: Feather.Printer,
                url: '/roles',
                parentKey: 'master'
            },
            {
                key: 'groupMaster',
                label: 'Group',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/groupMaster',
                parentKey: 'master'
            },
            {
                key: 'branchMaster',
                label: 'Branch',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/branchMaster',
                parentKey: 'master'
            },
            {
                key: 'unit',
                label: 'Unit',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/unit',
                parentKey: 'master'
            },
            {
                key: 'division',
                label: 'Division',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/division',
                parentKey: 'master'
            },
            {
                key: 'place',
                label: 'Place',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/place',
                parentKey: 'master'
            },
            {
                key: 'supplierPlace',
                label: 'SupplierPlace',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/supplierPlace',
                parentKey: 'master'
            },
            {
                key: 'route',
                label: 'Route',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/route',
                parentKey: 'master'
            },
            {
                key: 'district',
                label: 'District',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/district',
                parentKey: 'master'
            },
            {
                key: 'state',
                label: 'State',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/state',
                parentKey: 'master'
            },
            {
                key: 'country',
                label: 'Country',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/country',
                parentKey: 'master'
            },
        ]

    }

]


const ADMIN_MENU_ITEMS: MenuItemTypes[] = [
    {
        key: 'company_management',
        label: 'Company Management',
        isTitle: false,
        Icon: Feather.Slack,
        url: '/admin/company_management',
    },
    {
        key: 'porfolio_management',
        label: 'Portfolio Management',
        isTitle: false,
        Icon: Feather.MessageSquare,
        url: '/admin/porfolio_management',
    },
    {
        key: 'site_management',
        label: 'Site Management',
        isTitle: false,
        Icon: Feather.BookOpen,
        url: '/admin/site_management',
    },
    {
        key: 'brand_management',
        label: 'Brand Management',
        isTitle: false,
        Icon: Feather.Sliders,
        children: [
            {
                key: 'brand_company',
                label: 'Brand Company',
                url: '/admin/brand_company',
                parentKey: 'brand_management',
            },
            {
                key: 'brand',
                label: 'Brand',
                url: '/admin/brand_management',
                parentKey: 'brand_management',
            },
        ],
    },
    {
        key: 'trade_management',
        label: 'Trade Management',
        isTitle: false,
        Icon: Feather.Sunset,
        children: [
            {
                key: 'trade_category',
                label: 'Trade Category',
                url: '/admin/trade_category',
                parentKey: 'trade_management',
            },
            {
                key: 'trade_sub_category',
                label: 'Trade Sub-Category',
                url: '/admin/trade_sub_category',
                parentKey: 'trade_management',
            },
        ],
    },
    {
        key: 'user_management',
        label: 'User Management',
        isTitle: false,
        Icon: Feather.UserCheck,
        url: '/admin/user_management',
    },
    {
        key: 'global',
        label: 'Global Setting',
        isTitle: false,
        Icon: Feather.ShoppingCart,
        children: [
            {
                key: 'notification_management',
                label: 'Notification/Alert Management',
                url: '/admin/notification_management',
                parentKey: 'global',
            },
        ],
    },
    {
        key: 'tenant_management',
        label: 'Tenant Management',
        isTitle: false,
        Icon: Feather.Star,
        children: [
            {
                key: 'tenant_category',
                label: 'Tenant Category',
                url: '/admin/tenant_category',
                parentKey: 'tenant_management',
            },
            {
                key: 'tenant_manager',
                label: 'Tenant Manager',
                url: '/admin/tenant_manager',
                parentKey: 'tenant_management',
            },
            {
                key: 'credential_manager',
                label: 'Credential Manager',
                url: '/admin/credential_manager',
                parentKey: 'tenant_management',
            },
            {
                key: 'agent_authentication',
                label: 'Agent Authentication',
                url: '/admin/agent_authentication',
                parentKey: 'tenant_management',
            },
            {
                key: 'activate_tenant',
                label: 'Activate Tenant',
                url: '/admin/activate_tenant',
                parentKey: 'tenant_management',
            },
            {
                key: 'deactivate_tenant',
                label: 'De-Activate Tenant',
                url: '/admin/deactivate_tenant',
                parentKey: 'tenant_management',
            },
        ],
    },
    {
        key: 'site_wise_setting',
        label: 'Site Wise Setting',
        isTitle: false,
        Icon: Feather.ShoppingCart,
        children: [
            {
                key: 'culture_setting',
                label: 'Culture Setting',
                url: '/admin/culture_setting',
                parentKey: 'site_wise_setting',
            },
            {
                key: 'holiday_setting',
                label: 'Holiday Setting',
                url: '/admin/holiday_setting',
                parentKey: 'site_wise_setting',
            },
        ],
    },
    {
        key: 'country_management',
        label: 'Country Management',
        isTitle: false,
        Icon: Feather.Truck,
        url: '/admin/country_management',
    },
    {
        key: 'role',
        label: 'Role',
        isTitle: false,
        Icon: Feather.Users,
        url: '/admin/role',
    },
]

const HORIZONTAL_MENU_ITEMS: MenuItemTypes[] = [
    {
        key: 'dashboard',
        Icon: Feather.Home,
        label: 'Dashboard',
        isTitle: true,
        children: [
            {
                key: 'ds-dashboard-1',
                label: 'Dashboard 1',
                url: '/dashboard-1',
                parentKey: 'dashboard',
            },
            {
                key: 'ds-dashboard-2',
                label: 'Dashboard 2',
                url: '/dashboard-2',
                parentKey: 'dashboard',
            },
            {
                key: 'ds-dashboard-3',
                label: 'Dashboard 3',
                url: '/dashboard-3',
                parentKey: 'dashboard',
            },
            {
                key: 'ds-dashboard-4',
                label: 'Dashboard 4',
                url: '/dashboard-4',
                parentKey: 'dashboard',
            },
        ],
    },
    {
        key: 'apps',
        Icon: Feather.Grid,
        label: 'Apps',
        isTitle: true,
        children: [
            {
                key: 'apps-calendar',
                label: 'Calendar',
                isTitle: false,
                Icon: Feather.Calendar,
                url: '/apps/calendar',
                parentKey: 'apps',
            },
            {
                key: 'apps-chat',
                label: 'Chat',
                isTitle: false,
                Icon: Feather.MessageSquare,
                url: '/apps/chat',
                parentKey: 'apps',
            },
        ],
    },
]

export { ADMIN_MENU_ITEMS, HORIZONTAL_MENU_ITEMS, MENU_ITEMS, USER_MENU_ITEMS }

