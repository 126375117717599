import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormInput } from "../components/custom/components"
import UserLayout from "../components/custom/layouts/UserLayout"
import { useToast } from "../components/toast/ToastProvider"
import { onlyUnique } from "../config/functions"
import { CategoryType, fetchCategoryAction } from "../redux/actions/categoryAction"
import { PurchaseCategoryType, fetchPurchaseCategoryAction } from "../redux/actions/purchaseCategoryAction"
import { AppDispatch, StoreState, } from "../redux/store"


interface TableBody {
    sno: number
    categoryName: string
    amount: number
}



const PurchaseCategoryReport = () => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const PurchaseCategoryList = useSelector<StoreState, PurchaseCategoryType[]>(state => state.purchaseCategory)
    const categoryList = useSelector<StoreState, CategoryType[]>(state => state.category)

    // console.log(PurchaseCategoryList);

    const [fromDate, setFromDate] = useState<string>('')
    const [fromDateError, setFromDateError] = useState<string>('')

    const [toDate, setToDate] = useState<string>('')
    const [toDateError, setToDateError] = useState<string>('')

    const [categoryId, setCategoryId] = useState<string>('')
    const [categoryIdError, setCategoryIdError] = useState<string>('')


    const [purchaseCategory, setPurchaseCategory] = useState<PurchaseCategoryType[]>(PurchaseCategoryList)

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }
    const handleCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategoryId(e.target.value)
        setCategoryIdError('')
    }



    const pdfGenerate = () => {
        // Create a new jsPDF instance
        // const doc = new jsPDF();

        // // Define the table headers and data
        const headers: any = [["S.No", "Category Name", "Amount"]];
        const data: any = PurchaseCategoryList.map((pl, i) => {
            return [i + 1, pl.categoryName, pl.amount]
        })
        // Define the table headers and data
        // const headers = ["S.No", "Finance Year", "Invoice Type","Invoice Type","Invoice Type","Invoice Type","Invoice Type"];
        // const data = [
        //   ["John Doe", "30", "USA","USA","USA","30","30"]
        // ];
        const title = "Purchase Category Report"
        const doc = new jsPDF('l', 'mm', [460, 210])
        doc.setFontSize(18)
        doc.text(title, 180, 13)
        autoTable(doc, {
            head: headers,
            body: data,
            startY: 20,
            didDrawPage: function () {
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 360, 10);
            }
        })
        doc.save(title + ".pdf")
    }
    useEffect(() => {
        dispatch(fetchCategoryAction())
    }, [])

    useEffect(() => {
        dispatch(fetchPurchaseCategoryAction(fromDate, toDate, categoryId))

    }, [fromDate, toDate, categoryId])


    // useEffect(() => {
    //     if(categoryName){
    //         const filterLists=PurchaseCategoryList.filter((pl)=>pl.categoryName===categoryName)
    //         setPurchaseCategory(filterLists)
    //     }else{
    //         setPurchaseCategory(PurchaseCategoryList)
    //     }
    // }, [categoryName,PurchaseCategoryList])


    return <>
        <UserLayout>
            <h4>Purchase Category Report</h4>
            <div className="row mb-2">
                <div className="col-3">
                    <FormInput
                        name='fromDate'
                        label='From Date'
                        labelClassName="required"
                        value={fromDate}
                        className="mt-2"
                        onChange={handleFromDate}
                        // errorText={tdateError}
                        type="date"
                    />
                </div>
                <div className="col-3">
                    <FormInput
                        name='toDate'
                        label='To Date'
                        labelClassName="required"
                        value={toDate}
                        className="mt-2"
                        onChange={handleToDate}
                        // errorText={tdateError}
                        type="date"
                    />
                </div>

                <div className="col-3">
                    <FormInput
                        name='category'
                        label='Category'
                        labelClassName="required mb-2"
                        value={categoryId}
                        className="mt-2"
                        onChange={handleCategory}
                        // errorText={tdateError}
                        type="select"
                    >
                        <option value="">All</option>
                        {
                            categoryList.filter(onlyUnique).map((cl) => {
                                return <option value={cl.id}>{cl.name}</option>
                            })
                        }
                    </FormInput>
                </div>



            </div>
            <div className="row">
                <div className="col">
                    <div className="card p-2 w-100">
                        <div className='table-wrapper'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='text-truncate align-middle'>S.No</th>
                                        <th className='text-truncate align-middle'>Category Name</th>
                                        <th className='text-truncate align-middle'>Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        PurchaseCategoryList.map((pl, i) => {
                                            console.log(pl);
                                            return <PurchaseCategoryReportBody
                                                sno={i + 1}
                                                categoryName={pl.categoryName}
                                                amount={pl.amount}
                                            />
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="text-end mt-1 mx-4">
                            <button className="btn btn-primary justify-content-end" onClick={pdfGenerate}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout >
    </>

}
const PurchaseCategoryReportBody = ({ sno, categoryName, amount }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle text-truncate'>{categoryName}</td>
        <td className='text-capitalize align-middle text-truncate'>{amount}</td>
    </tr>
}

export default PurchaseCategoryReport

