import { useEffect, useState } from "react";
import UserLayout from "../components/custom/layouts/UserLayout";
import { ToastTypes, useToast } from "../components/toast/ToastProvider";
import {
    AppSettings,
    editAppSettings,
    getSettings
} from "../services/appSetting.service";

const AppSetting = () => {
    const toast = useToast()
    const [setting, setSetting] = useState<AppSettings[]>([]);
    const [currentSettingValue, setCurrentSettingValue] = useState<string>('false');

    const fetchData = async () => {
        const settingList = await getSettings();
        console.log(settingList);
        setSetting(settingList);
        if (settingList.length > 0) {
            setCurrentSettingValue(settingList[0].setting_value);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);

    const handleToggleSetting = (settingId: number, newValue: string) => {
        setCurrentSettingValue(newValue);
    };

    const handleSave = () => {
        if (setting.length > 0 && setting[0].id) {
            const updatedSetting = {
                id: setting[0].id,
                setting_group: setting[0].setting_group,
                setting_key: setting[0].setting_key,
                setting_value: currentSettingValue,
            };


            editAppSettings(updatedSetting, setting[0].id)
                .then(() => {
                    toast("Setting saved successfully", ToastTypes.SUCCESS);
                })
                .catch(() => {
                    toast("Failed to save setting", ToastTypes.ERROR);
                });
        }
    };

    const handleReset = () => {
        if (setting.length > 0) {
            setCurrentSettingValue('false');

            toast("Click save button to update reset data", ToastTypes.SUCCESS);

        }
    };

    return (
        <>
            <UserLayout>
                <h4>App Settings</h4>
                <div className="container">
                    {setting.map((setting) => (
                        <div key={setting.id} className="card">
                            <div className="card-body">
                                <div className="hstack justify-content-between">
                                    <h5 className="card-title">{setting.setting_group}</h5>
                                </div>
                                <hr />
                                <div className="ps-5">
                                    <label className="ms-2 me-2">{setting.setting_key}</label>
                                    <input
                                        className="form-check-input ms-2 me-1"
                                        type="radio"
                                        name="radioNoLabel"
                                        value="true"
                                        checked={currentSettingValue === "true"}
                                        onChange={() => setting.id && handleToggleSetting(setting.id, "true")}
                                    />
                                    View
                                    <input
                                        className="form-check-input ms-2 me-1"
                                        type="radio"
                                        name="radioNoLabel"
                                        value="false"
                                        checked={currentSettingValue === "false"}
                                        onChange={() => setting.id && handleToggleSetting(setting.id, "false")}
                                    />
                                    Hide
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn btn-primary me-2" onClick={handleSave}>
                                        Save
                                    </button>
                                    <button className="btn btn-secondary" onClick={handleReset}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </UserLayout>
        </>
    );
};

export default AppSetting;
