import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';

interface dataSet {
    name: string,
    data: number[],
}

interface Iprops {
    datasets: number[]
    labels: string[]
    title: string
    colors: string[]
}

// simple donut chart
const DonutChart = (props: Iprops) => {
    // default options
    const apexDonutOpts: ApexOptions = {
        chart: {
            height: 320,
            type: 'donut',
        },
        labels: props.labels,
        colors: props.colors,
        // colors: ['#727cf5', '#6c757d', '#0acf97', '#fa5c7c', '#e3eaef'],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10,
        },
        dataLabels: {
            style: {
                fontSize: '13px',
                colors: ['#000'],
                fontWeight: 'bold'
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
        // plotOptions: {
        //     pie: {
        //         expandOnClick: true
        //     }
        // }
    };

    // chart data
    // const apexDonutData = [44, 55, 41, 17, 15];
    const apexDonutData = props.datasets;

    return (
        <Card>
            <Card.Body>
                <h4 className="header-title mb-3">{props.title}</h4>
                <Chart
                    options={apexDonutOpts}
                    series={apexDonutData}
                    type="donut"
                    height={320}
                    className="apex-charts"
                />
            </Card.Body>
        </Card>
    );
};

export default DonutChart;
