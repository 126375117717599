import { Dispatch } from "redux"
import { GetState } from "../store"
import { addGroup, deleteGroup, editGroup, getGroup } from "../../services/group.service"
import { camelize } from "../../config/functions"



export interface GroupMasterType {
    id?: number,
    Name?: string

}

export enum GroupActionsList {
    ADD_GROUP = 'ADD_GROUP',
    EDIT_GROUP = 'EDIT_GROUP',
    FETCH_GROUP = 'FETCH_GROUP',
    DELETE_GROUP = 'DELETE_GROUP'
}

export interface DeleteGroupMaster {
    id: number | undefined
}

export interface AddGroupAction {
    type: GroupActionsList.ADD_GROUP
    data: GroupMasterType
}

export interface EditGroupAction {
    type: GroupActionsList.EDIT_GROUP
    data: GroupMasterType
    id: number | undefined
}

export interface FetchGroupAction {
    type: GroupActionsList.FETCH_GROUP
    data: GroupMasterType[]

}

export interface DeleteGroupAction {
    type: GroupActionsList.DELETE_GROUP
    data: number|undefined
}

export type GroupActions = AddGroupAction | EditGroupAction | FetchGroupAction | DeleteGroupAction

export const addGroupMasterAction = (data: GroupMasterType) => {
    return async (dispatch: Dispatch) => {
       return addGroup(data).then(response => {
        if (response.status === 200 && response.data.data) {
            let addData = {}
            for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                const key = camelize(Object.keys(response.data.data)[i])
                const value = Object.values(response.data.data)[i]
                addData = { ...addData, [key]: value }
            }
            dispatch<AddGroupAction>({
                type: GroupActionsList.ADD_GROUP,
                data: addData
            })
            return Promise.resolve(response.data.message
                ? response.data.message
                : 'Group Master Added')
        } else {
            throw { response }
        }
    }).catch(error => {
        return Promise.reject(error.response
            ? error.response.data.message
                ? error.response.data.message
                : 'Unable to add'
            : 'Unable to add')
    })

    }
}

export const editGroupMasterAction = (data: GroupMasterType, id: number | undefined) => {
    return async (dispatch: Dispatch) => {
        return editGroup(data,id).then(response => {
            let editData={}
            if (response.status === 200 && response.data.data) {
                for (let i = 0; i < Object.keys(response.data.data).length; i++) {
                    const key = camelize(Object.keys(response.data.data)[i])
                    const value = Object.values(response.data.data)[i]
                    editData = { ...editData, [key]: value }
                }
                dispatch<EditGroupAction>({
            type: GroupActionsList.EDIT_GROUP,
            data: editData,
            id: id
        })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Group Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchGroupMasterAction = () => {
    
        return async (dispatch: Dispatch) => {
            const response=await getGroup()
            const data = response.data.data.map((res: any) => {
                let camelCase = {}
                for (let i = 0; i < Object.keys(res).length; i++) {
                    const key = camelize(Object.keys(res)[i])
                    const value = Object.values(res)[i]
                    camelCase = { ...camelCase, [key]: value }
                }
                return camelCase
            })
            dispatch<FetchGroupAction>({
                type: GroupActionsList.FETCH_GROUP,
                data: data
            })
        }
 }


export const DeleteGroupMasterAction = (id: number | undefined) => {
        return async (dispatch: Dispatch) => {
            return deleteGroup(id).then(response => {
                if (response.status === 200) {
                    return dispatch<DeleteGroupAction>({
                        type: GroupActionsList.DELETE_GROUP,
                        data: id
                    })
                    return Promise.resolve(response.data.message
                        ? response.data.message
                        : ' Acoount Deleted')
                } else {
                    throw { response }
                }
            }).catch(error => {
                return Promise.reject(error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to delete'
                    : 'Unable to delete')
            })
        }
      
    }
