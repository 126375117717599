import { GroupActionsList,GroupMasterType } from "../actions/groupMaster";
import { Action } from "../actions/types";

const initialValue: Array<GroupMasterType> = []

export const groupReducer = (state: GroupMasterType[] = initialValue, action: Action) => {

    switch (action.type) {

        case GroupActionsList.ADD_GROUP:

            return [...state, action.data]


        case GroupActionsList.EDIT_GROUP: {
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.data.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case GroupActionsList.FETCH_GROUP:

            return action.data



        case GroupActionsList.DELETE_GROUP: {
            return state.filter(i => i.id !== action.data)

        }

        default: return state
    }

}