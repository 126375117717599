import api from "../config/api"
import { LoginDataType } from "../redux/actions/authUserActions"
import { PasswordType, UsersType } from "../redux/actions/usersActions"

export const authLogin = async (data: LoginDataType) => {
    return api().post('/user/login/', data)
}

export const addUser = async (data: UsersType) => {
    return await api().post('/user/', data)
}

export const editUser = async (data: UsersType, id: number | undefined) => {
    // return await api().put(`/user/${id}`, data)
    return await api().put(`/user/?id=`+id, data)
}

export const editPassword = async (data: PasswordType, id: number | undefined) => {
    // return await api().put(`/user/reset-password/?id=${id}`, data)
    return await api().put(`/user/reset-password/?id=`+id, data)
}

export const getUser = async () => {
    return await api().get('/user/')
}

export const deleteUser = async (id: number | undefined) => {
    // return await api().delete(`/user/${id}`)
    return await api().delete(`/user/?id=`+id)

}

// export const updatePassword=(data:UsersType,id:number|undefined)=>{
//     return api().put()
// }