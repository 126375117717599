import { SalesItems } from "../actions/salesActions";
import { SalesItemActionList } from "../actions/salesItemAction";
import { Action } from "../actions/types";

const initialValue: Array<SalesItems> = []

export const salesItemReducer = (state: SalesItems[] = initialValue, action: Action) => {

    switch (action.type) {
        case SalesItemActionList.FETCH_SALES_ITEM:
            return action.data
        default:
            return state
    }
}