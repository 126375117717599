/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { camelize } from "../../config/functions"
import { getSalesCategory } from "../../services/salesCategory.service"

export enum SalesCategoryReportActionList {
    FETCH_SALESCATEGORY = 'FETCH_SALESCATEGORY',
}

export interface SalesCategoryType {
   categoryName:string,
   amount:number
}

export interface FetchSalesCategoryAction {
    type: SalesCategoryReportActionList.FETCH_SALESCATEGORY
    data: Array<SalesCategoryType>
}

export type SalesCategoryActions = FetchSalesCategoryAction

export const fetchSalesCategoryAction = (startDate:string,endDate:string,categoryId:string) => {
    return async (dispatch: Dispatch) => {
        const response = await getSalesCategory(startDate,endDate,categoryId)
        // console.log(response); 
        const data = response.data.data.map((res: any) => {
            // console.log(data);
            
            let camelCase = {}
            for (let i = 0; i < Object.keys(res).length; i++) {
                const key = camelize(Object.keys(res)[i])
                const value = Object.values(res)[i]
                camelCase = { ...camelCase, [key]: value }
            }
            return camelCase
        })
        dispatch<FetchSalesCategoryAction>({
            type: SalesCategoryReportActionList.FETCH_SALESCATEGORY,
            data: data
        })
    }
}



