import { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useSelector } from "react-redux"
import DonutChart from "../components/custom/components/charts/apex/DonutChart"
import LineChart from "../components/custom/components/charts/apex/LineChart"
import api, { APIResponse } from "../config/api"
import { CategoryType } from "../redux/actions/categoryAction"
import { SalesPaymentModeTypes } from "../redux/actions/salesActions"
import { StoreState } from "../redux/store"
import { ReportSalesReturnItemType } from "./SalesReturnYearlyReport"


interface MonthlySalesReturnData {
    id?: number
    // financeYear: string
    // branchId: number
    // invoiceType: string
    // invoiceTitle: string
    invoiceDate: string
    invoiceDay?: string
    // invoiceWeekNumber?: number
    // invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    packNo: string
    salesEntryNumber: number
    systemNo: string
    // repId?: number
    customerId: number
    // customerName: string
    // pointsEarned?: string
    // bankName: string
    // bankNumber: number
    paymentMode: SalesPaymentModeTypes
    billAmount?: string
    // disPercent?: string
    // disAmount?: string
    // add?: string
    // less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    salesReturnItems: ReportSalesReturnItemType[]
}

const SalesReturnMonthlyReport = () => {
    const categories = useSelector<StoreState, CategoryType[]>((state) => state.category)
    const [fromMonth, setFromMonth] = useState<Date>(new Date())
    // const [fromMonthError, setFromMonthError] = useState<string>('')
    const [toMonth, setToYear] = useState<Date>(new Date())
    const [year, setYear] = useState<Date>(new Date())

    const monthDaysArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const [monthwiseSalesReturnData, setMonthwiseSalesReturnData] = useState<MonthlySalesReturnData[]>([])

    const CategorywiseFromMonthSales = useMemo<{ cid: number, cname: string, sales: number }[]>(() => {
        let data: { cid: number, cname: string, sales: number }[] = [];
        const salesItemsData = monthwiseSalesReturnData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceMonth?.toString() === (fromMonth?.getMonth() + 1)?.toString())?.map(m => m.salesReturnItems).flat(1);
        // console.log(salesItemsData);

        categories?.forEach(c => {
            const catSales = salesItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                sales: catSales
            })
        })
        // console.log(data);
        return data

    }, [monthwiseSalesReturnData, fromMonth, categories, year])

    const CategorywiseToMonthSales = useMemo<{ cid: number, cname: string, sales: number }[]>(() => {
        let data: { cid: number, cname: string, sales: number }[] = [];
        const salesItemsData = monthwiseSalesReturnData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceMonth?.toString() === (toMonth?.getMonth() + 1)?.toString())?.map(m => m.salesReturnItems).flat(1);
        // console.log(salesItemsData);

        categories?.forEach(c => {
            const catSales = salesItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString())?.reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                sales: catSales
            })
        })
        // console.log(data);
        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [monthwiseSalesReturnData, toMonth, categories, year])

    useEffect(() => {

        if (fromMonth !== undefined && toMonth !== undefined && year !== undefined) {
            api().get<APIResponse<MonthlySalesReturnData[]>>(`salesReturn/dashboard/?fromMonth=${(fromMonth?.getMonth() + 1)?.toString()}&toMonth=${(toMonth?.getMonth() + 1)?.toString()}&year=${year?.getFullYear()?.toString()}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    // console.log(result);
                    setMonthwiseSalesReturnData(result)
                }
            }).catch((error) => { })
        } else {
            setMonthwiseSalesReturnData([])
        }
    }, [fromMonth, toMonth, year])

    // const colors = [COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3, COLORS.COLOR4, COLORS.COLOR5, COLORS.COLOR6, COLORS.COLOR7, COLORS.COLOR8]

    return <>
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Monthly Sales</h4>
                        </div>
                        <div className="col-lg-4 hstack gap-3 text-end">
                            <div className="col-lg-3 ">
                                <span className="dateInput">
                                    {/* <span><Feather.Calendar size={14} /></span> */}
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={fromMonth}
                                        onChange={(date: any) => setFromMonth(date)}
                                        showMonthYearPicker
                                        calendarClassName=""
                                        dateFormat="MM"
                                    // yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                                {/* <FormInput
                                name='fromMonth'
                                label='From Year'
                                labelClassName="required"
                                value={fromMonth}
                                className="mt-2 fs-5"
                                onChange={handleFromYear}
                                errorText={fromMonthError}
                                type="date"
                            /> */}
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={toMonth}
                                        onChange={(date: any) => setToYear(date)}
                                        showMonthYearPicker
                                        dateFormat="MM"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={year}
                                        onChange={(date: any) => setYear(date)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                // <BarChart
                                //     // datasets={[
                                //     //     { label: 'Portfolio 1', data: [11, 10, 34, 56, 12, 44, 55, 56, 89, 35, 78, 67], backgroundColor: COLORS.COLOR1, borderColor: COLORS.COLOR1 },
                                //     //     { label: 'Portfolio 2', data: [51, 70, 84, 16, 56, 78, 12, 45, 56, 89, 34, 56], backgroundColor: COLORS.COLOR2, borderColor: COLORS.COLOR2 }
                                //     // ]}
                                //     datasets={(() => {
                                //         let ydata: { name: string; data: number[] }[] = []

                                //         MonthlyFilteredData.forEach((y, i) => {
                                //             const index = portfolioOption.findIndex(p => p.id !== undefined && p.id.toString() === y.portfolio.toString())
                                //             const name = portfolioOption[index]?.name

                                //             // let data = y.record.map(d => parseFloat(d.sales))
                                //             // if (data.length > 0) {
                                //             //     ydata.push({
                                //             //         name: name,
                                //             //         data: data
                                //             //     })
                                //             // }
                                //             let data2: number[] = []
                                //             let d1 = y.record
                                //             yearFilter.map(f => f).sort().forEach(yr => {
                                //                 var yrs = d1.map(d => d.transyear)
                                //                 if (yrs.includes(yr)) {
                                //                     let obj = d1.find(f => f.transyear === yr)
                                //                     data2.push(obj?.sales ? parseFloat(obj.sales) : 0)
                                //                 } else data2.push(0)
                                //             })

                                //             if (data2.length > 0) {
                                //                 ydata.push({
                                //                     name: name,
                                //                     data: data2
                                //                 })
                                //             }
                                //         })
                                //         return ydata
                                //     })()
                                //         //     MonthlyFilteredData.map((y, i) => {
                                //         //     const index = portfolioOption.findIndex(p => p.id !== undefined && p.id.toString() === y.portfolio.toString())
                                //         //     const name = portfolioOption[index]?.name

                                //         //     return {
                                //         //         name: name,
                                //         //         data: y.record.map((r) => {
                                //         //             return parseFloat(r?.sales)
                                //         //         }),
                                //         //     }
                                //         // })
                                //     }
                                //     // labels={yearFilter.map(y => y.toString()).sort() || []}
                                //     labels={(() => {
                                //         const years: string[] = []
                                //         MonthlyFilteredData?.forEach((yr, i) => {
                                //             yr.record.forEach((r, i) => {
                                //                 years.push(r.transyear.toString())
                                //             })
                                //         })
                                //         return years.filter(onlyUnique)
                                //     })()}

                                //     // labels={MonthlyData.at(0)?.record.map(r => r.transyear.toString()) || []}
                                //     colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                //     title={''}
                                // />
                            }

                            <LineChart
                                // datasets={[
                                //     { name: months[fromMonth?.getMonth()], data: [54, 64, 52, 43, 22, 60, 32, 87, 13, 14, 34, 45, 54, 0, 0, 34, 34, 52, 43, 22, 20, 32, 87, 13, 14, 34, 45, 54, 0, 0] },
                                //     { name: months[toMonth?.getMonth()], data: [22, 20, 32, 87, 13, 14, 34, 45, 54, 0, 0, 4, 64, 52, 43, 22, 60, 32, 87, 13, 14, 34, 45, 13, 14, 34, 23, 11, 20] },

                                //     // { name: toMonth?.getFullYear()?.toString(), data: [ToYearSales] }
                                // ]}
                                datasets={(() => {
                                    let monthData: { name: string, data: number[] }[] = []
                                    let mons = [fromMonth, toMonth]
                                    mons.forEach(mon => {

                                        const fdata = monthwiseSalesReturnData?.filter(y => y.invoiceMonth?.toString() === (mon?.getMonth() + 1)?.toString())
                                        // console.log(fdata);
                                        let data: number[] = []
                                        monthDaysArray.forEach(md => {

                                            const val = fdata?.filter(f => f.invoiceDay?.toString() === md)?.reduce((p, c) => {
                                                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
                                            }, 0)
                                            data.push(val)
                                        })
                                        monthData.push({
                                            name: months[mon?.getMonth()],
                                            data: data
                                        })
                                    })

                                    return monthData
                                })()}

                                labels={monthDaysArray}
                                colors={['#00B9E3', '#FF4560']}
                                // colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title=""
                            />
                        </div>

                        <div className="col-lg-3">

                            {/* <PieChart
                                datasets={(() => {
                                    let ydata: { name: string, data: [] } = []

                                    return ydata
                                })()}
                                labels={[]}
                                colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title="" /> */}
                            <DonutChart
                                // datasets={[24, 45, 45, 56, 88]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseFromMonthSales.map(fm => fm.sales)}
                                labels={CategorywiseFromMonthSales.map(fm => fm.cname)}
                                colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#DC2AAC']}
                                title={months[fromMonth?.getMonth()] + ' ' + year?.getFullYear()?.toString()}
                            />
                        </div>
                        <div className="col-lg-3">
                            <DonutChart
                                // datasets={[65, 75, 72, 21, 89]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseToMonthSales.map(fm => fm.sales)}
                                labels={CategorywiseToMonthSales.map(fm => fm.cname)}
                                colors={['#FC89AC', '#7ED957', '#FF914D', '#00B9E3', '#E192FF', '#8CA5F7']}
                                title={months[toMonth?.getMonth()] + ' ' + year?.getFullYear()?.toString()}
                            />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="card">
                            <div className="card-body">

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >

    </>
}
export default SalesReturnMonthlyReport