import { PurchaseCategoryReportActionList, PurchaseCategoryType } from "../actions/purchaseCategoryAction";
import { Action } from "../actions/types";

const initialValue: Array<PurchaseCategoryType> = []

export const purchaseCategoryReducer = (state: PurchaseCategoryType[] = initialValue, action: Action) => {

    switch (action.type) { 
        case PurchaseCategoryReportActionList.FETCH_PURCHASECATEGORY:
            return action.data
        default :
            return state
    }
}