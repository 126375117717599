import { Action } from "../actions/types";
import { UsersActionsList, UsersType } from "../actions/usersActions";

const initialValue: Array<UsersType> = []

export const usersReducer = (state: UsersType[] = initialValue, action: Action) => {

    switch (action.type) {

        case UsersActionsList.ADD_USERS: {
            console.log(action.data);

            return [...state, action.data]
        }

        case UsersActionsList.EDIT_USERS: {
            const updated = [...state].slice()
            console.log(updated);
            const index = updated.findIndex(u => {
                return u.id === action.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case UsersActionsList.EDIT_PASSWORD: {
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.id
            })
            if (index > -1) {
                updated[index] = {
                    ...updated[index],
                    password: action.data.resetPassword
                }
            }

            return updated
        }

        case UsersActionsList.FETCH_USERS: {

            return action.data

        }

        case UsersActionsList.DELETE_USERS: {
            return state.filter(i => i.id !== action.data)

        }

        default: return state
    }

}