import { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useSelector } from "react-redux"
import { FormInput } from "../components/custom/components"
import BarChart from "../components/custom/components/charts/apex/BarChart"
import DonutChart from "../components/custom/components/charts/apex/DonutChart"
import { COLORS } from "../config"
import api, { APIResponse } from "../config/api"
import { CategoryType } from "../redux/actions/categoryAction"
import { PurchaseReturnPaymentModeTypes } from "../redux/actions/purchaseReturnAction"
import { StoreState } from "../redux/store"
import { ReportPurchaseReturnItemType } from "./PurchaseReturnYearlyReport"


interface WeeklyPurchaseReturnData {
    id?: number
    // financeYear: string
    // branchId: number
    // invoiceType: string
    // invoiceTitle: string
    invoiceDate: string
    // invoiceDay?: string
    invoiceWeekNumber?: number
    invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    // packNo: string
    purchaseReturnEntryNumber: number
    systemNo: string
    // repId?: number
    customerId: number
    // customerName: string
    // pointsEarned?: string
    // bankName: string
    // bankNumber: number
    paymentMode: PurchaseReturnPaymentModeTypes
    billAmount?: string
    // disPercent?: string
    // disAmount?: string
    // add?: string
    // less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    purchaseReturnItems: ReportPurchaseReturnItemType[]
}

const PurchaseReturnWeeklyReport = () => {
    // const d = new Date()
    // d.getDay()//gives week day (1-7)
    const categories = useSelector<StoreState, CategoryType[]>((state) => state.category)
    const [year, setYear] = useState<Date>(new Date())
    const [fromWeek, setFromWeek] = useState<string>('1')
    const [fromWeekError, setFromWeekError] = useState<string>('')
    const [toWeek, setToWeek] = useState<string>('1')
    const [toWeekError, setToWeekError] = useState<string>('')

    const weeksArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52']

    const weekFullDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const weekDays = ['1', '2', '3', '4', '5', '6', '7']

    const [weeklyPurchaseReturnData, setWeeklyPurchaseReturnData] = useState<WeeklyPurchaseReturnData[]>([])

    // const handleFromYear: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setFromWeek(e.target.value)
    //     setFromWeekError('')
    // }

    // const handleToYear: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setToWeek(e.target.value)
    //     setToWeekError('')
    // }


    const CategorywiseWeekOnePurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = weeklyPurchaseReturnData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceWeekNumber?.toString() === fromWeek?.toString())?.map(m => m.purchaseReturnItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        console.log(data);
        return data

    }, [weeklyPurchaseReturnData, fromWeek, categories, year])

    const CategorywiseWeekTwoPurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = weeklyPurchaseReturnData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceWeekNumber?.toString() === toWeek?.toString())?.map(m => m.purchaseReturnItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        console.log(data);
        return data

    }, [weeklyPurchaseReturnData, toWeek, categories, year])

    useEffect(() => {

        if (fromWeek !== undefined && toWeek !== undefined && year !== undefined) {
            api().get<APIResponse<WeeklyPurchaseReturnData[]>>(`purchaseReturn/dashboard/?weekOne=${fromWeek?.toString()}&weekTwo=${toWeek?.toString()}&year=${year?.getFullYear()?.toString()}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    console.log(result);
                    setWeeklyPurchaseReturnData(result)
                }
            }).catch((error) => { })
        } else {
            setWeeklyPurchaseReturnData([])
        }
    }, [fromWeek, toWeek, year])

    const colors = [COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3, COLORS.COLOR4, COLORS.COLOR5, COLORS.COLOR6, COLORS.COLOR7, COLORS.COLOR8]

    return <>
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Weekly Purchase</h4>
                        </div>
                        <div className="col-lg-4 hstack gap-3 text-end">
                            <div className="col-lg-3">
                                <FormInput
                                    name="Week"
                                    labelClassName="required"
                                    value={fromWeek}
                                    onChange={(e) => { setFromWeek(e.target.value) }}
                                    errorText={fromWeekError}
                                    containerClass="mb-2 w-100 h-100"
                                    // placeholder="Department"
                                    type="select"
                                >
                                    <option value="">Select week</option>
                                    {weeksArray.map((week) => {
                                        return <option value={week}>{week}</option>
                                    })
                                    }
                                </FormInput>
                                {/* <span className="dateInput">
                                    //<span><Feather.Calendar size={14} /></span>
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={fromWeek}
                                        onChange={(date: any) => setFromWeek(date)}
                                        showMonthYearPicker
                                        calendarClassName=""
                                        dateFormat="MM/yyyy"
                                    // yearItemNumber={4}
                                    //     required
                                    />
                                </span> */}
                            </div>
                            <div className="col-lg-3">
                                <FormInput
                                    name="Week"
                                    labelClassName="required"
                                    value={toWeek}
                                    onChange={(e) => { setToWeek(e.target.value) }}
                                    errorText={toWeekError}
                                    containerClass="mb-2"
                                    // placeholder="Department"
                                    type="select"
                                >
                                    <option value="">Select week</option>
                                    {weeksArray.map((week) => {
                                        return <option value={week}>{week}</option>
                                    })
                                    }
                                </FormInput>
                                {/* <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={toWeek}
                                        onChange={(date: any) => setToWeek(date)}
                                        sh
                                        dateFormat="MM/yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span> */}

                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={year}
                                        onChange={(date: any) => setYear(date)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">

                            <BarChart
                                // datasets={[
                                //     { name: 'Week ' + fromWeek, data: [54, 64, 52, 43, 22, 60, 32] },
                                //     { name: 'Week ' + toWeek, data: [22, 20, 32, 87, 13, 14, 34] },

                                //     // { name: toWeek?.getFullYear()?.toString(), data: [ToYearPurchase] }
                                // ]}

                                datasets={(() => {
                                    let weekData: { name: string, data: number[] }[] = []
                                    let weeks = [fromWeek, toWeek]
                                    weeks.forEach(weekk => {

                                        const fdata = weeklyPurchaseReturnData?.filter(y => y.invoiceWeekNumber?.toString() === weekk?.toString())
                                        console.log(fdata);
                                        let data: number[] = []
                                        weekDays.forEach(wd => {

                                            const val = fdata?.filter(f => f.invoiceWeekDay?.toString() === wd)?.reduce((p, c) => {
                                                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
                                            }, 0)
                                            data.push(val)
                                        })
                                        weekData.push({
                                            name: weekk,
                                            data: data
                                        })
                                    })

                                    return weekData
                                })()}
                                labels={weekFullDays}
                                colors={['#00B9E3', '#FF4560']}
                                // colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title=""
                            />
                        </div>

                        <div className="col-lg-3">

                            {/* <PieChart
                                datasets={(() => {
                                    let ydata: { name: string, data: [] } = []

                                    return ydata
                                })()}
                                labels={[]}
                                colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title="" /> */}
                            <DonutChart
                                // datasets={[24, 45, 45, 56, 88]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseWeekOnePurchase?.map(fm => fm.purchase)}
                                labels={CategorywiseWeekOnePurchase?.map(fm => fm.cname)}
                                colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#DC2AAC']}
                                title={fromWeek + '-' + year?.getFullYear()?.toString()}
                            />
                        </div>
                        <div className="col-lg-3">
                            <DonutChart
                                // datasets={[65, 75, 72, 21, 89]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseWeekTwoPurchase?.map(fm => fm.purchase)}
                                labels={CategorywiseWeekTwoPurchase?.map(fm => fm.cname)}
                                colors={['#FC89AC', '#7ED957', '#FF914D', '#00B9E3', '#E192FF', '#8CA5F7']}
                                title={toWeek + '-' + year?.getFullYear()?.toString()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >

    </>
}
export default PurchaseReturnWeeklyReport