import { CustomerActionsList, CustomerType } from "../actions/customerAction";
import { Action } from "../actions/types";

const initialValue: Array<CustomerType> = []

export const customerReducer = (state: CustomerType[] = initialValue, action: Action) => {

    switch (action.type) {

        case CustomerActionsList.ADD_CUSTOMER:

            return [...state, action.data]


        case CustomerActionsList.EDIT_CUSTOMER: {
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.accountNumber === action.data.accountNumber
            })
            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }
            return updated
        }

        case CustomerActionsList.FETCH_CUSTOMER:
            return action.data



        case CustomerActionsList.DELETE_CUSTOMER: {
            
            return state.filter(i => i.accountNumber !== action.data)

        }

        default: return state
    }

}