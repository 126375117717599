import api from "../config/api"

export const addProduct = async (data: FormData) => {
    return await api().post('/product/', data)
}

export const editProduct = async (data: FormData, id: number | undefined) => {
    // return await api().put(`/product/${id}`, data)
    return await api().post(`/product/?id=`+id, data)
}

export const getProduct = async () => {
    return await api().get('/product/')
}

export const deleteProduct = async (id: number | undefined) => {
    // return await api().delete(`/product/${id}`)
    return await api().delete(`/product/?id=`+id)
}