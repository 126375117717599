import { MouseEventHandler, useState } from "react"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from "react-redux"
import DeleteConfirmPurchase from "../components/DeleteConfirmPurchase"
import Modal from "../components/Modal"
import { FormInput } from "../components/custom/components"
import { useToast } from "../components/toast/ToastProvider"
import { FormType, FormTypeList } from "../config"
import { LocalFormatDate, isToday } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { PurchaseType } from "../redux/actions/purchaseActions"
import { AppDispatch, StoreState } from "../redux/store"


interface TableBody {
    sno: number
    invoiceDate: string
    invoiceNo: string
    purchaseEntryNo: string
    supplier: string
    finalAmount: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: PurchaseType) => void
type HandleDelete = (value: PurchaseType) => void

interface Props {
    setFormType: React.Dispatch<React.SetStateAction<FormType>>
    setId: React.Dispatch<React.SetStateAction<number | undefined>>
    onclose: () => void
}

const Purchase = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const toast = useToast()
    const purchaseList = useSelector<StoreState, PurchaseType[]>(state => state.purchase)
    console.log(purchaseList);

    // const supplierList = useSelector<StoreState, SupplierType[]>(state => state.supplier)
    const AccountLists = useSelector<StoreState, AccountHolderType[]>(state => state.account)

    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    // const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    // const [id, setId] = useState<number>()

    const [editData, setEditData] = useState<PurchaseType>()

    const [searchDate, setSearchDate] = useState<Date | null>(new Date());
    const [searchSupplier, setSearchSupplier] = useState<string>('');
    const [searchInvoiceNo, setSearchInvoiceNo] = useState<string>('');

    const handleEdit: HandleEdit = (value) => {
        props.setFormType(FormTypeList.EDIT)
        props.setId(value.id)
        setEditData(value)
        console.log('edit');
        props.onclose()
    }

    const handleDelete: HandleDelete = (value) => {
        props.setFormType(FormTypeList.DELETE)
        setEditData(value)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
    }

    // useEffect(() => {
    //     dispatch(fetchPurchaseAction())
    // }, [])

    const handleDateFilter = (date: Date | null) => {
        setSearchDate(date);
    };

    const handleSupplierFilter = (value: string) => {
        setSearchSupplier(value);
    };

    const handleInvoiceNoFilter = (value: string) => {
        setSearchInvoiceNo(value);
    };

    const filteredPurchaseList = purchaseList.filter((purchase) => {
        const formattedDate = LocalFormatDate(purchase.invoiceDate?.toString());

        const isDateMatch = searchDate ? formattedDate === LocalFormatDate(searchDate.toISOString()) : true;

        const normalizedSearchSupplier = searchSupplier.toLowerCase();
        const normalizedSupplierName = (AccountLists.find(s => s?.accountNumber === purchase?.supplierId)?.accountName || '').toLowerCase();

        return (
            isDateMatch &&
            purchase.invoiceNumber?.toString().includes(searchInvoiceNo) &&
            normalizedSupplierName.includes(normalizedSearchSupplier)
        );
    });

    console.log(filteredPurchaseList);


    return <>
        {/* Filter Inputs */}
        <div className="row">
            <div className="col-lg-12">
                <div className="hstack gap-4 mb-3">
                    <div>
                        <label htmlFor="searchDate">Date :</label>
                        <DatePicker
                            id="searchDate"
                            selected={searchDate}
                            onChange={(date) => handleDateFilter(date)}
                            // dateFormat="yyyy-MM-dd"
                            dateFormat="dd-MM-yyyy"
                        />
                    </div>
                    <FormInput
                        name='searchInvoiceNo'
                        label='Invoice No :'
                        value={searchInvoiceNo}
                        onChange={(e) => handleInvoiceNoFilter(e.target.value)}
                    />
                    <FormInput
                        name='searchSupplier'
                        label='Supplier :'
                        value={searchSupplier}
                        onChange={(e) => handleSupplierFilter(e.target.value)}
                    />
                </div>
            </div>
        </div>

        <Modal
            headerText={'Delete Purchase'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='sm'
        >
            <DeleteConfirmPurchase
                editData={editData}
                onClose={handleDeleteFormClose}
            />
        </Modal>
        <div className="row">
            <div className="col-lg-12">
                <div className="card shadow-lg">
                    {/* <div className="card-body"> */}
                    <div className='table-wrapper' style={{ zIndex: "0" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Purchase Entry No</th>
                                    <th className='text-truncate align-middle'>Invoice Date</th>
                                    <th className='text-truncate align-middle'>Invoice No</th>
                                    <th className='text-truncate align-middle'>Supplier</th>
                                    <th className='text-truncate align-middle'>Grand Total</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPurchaseList.map((tr, i) => {
                                    let sobj = AccountLists.find(s => s?.accountNumber === tr?.supplierId)
                                    let sname = sobj?.accountName ? sobj?.accountName : ''
                                    return <UserBody
                                        sno={i + 1}
                                        invoiceNo={tr.invoiceNumber?.toString()}
                                        invoiceDate={tr.invoiceDate?.toString()}
                                        purchaseEntryNo={tr.purchaseEntryNumber?.toString()}
                                        finalAmount={tr.grandTotal ? tr.grandTotal?.toString() : '0'}
                                        supplier={sname}
                                        onEdit={() => handleEdit(tr)}
                                        onDelete={() => handleDelete(tr)}
                                        key={i}

                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>
    </>
}

const UserBody = ({ sno, invoiceDate, finalAmount, invoiceNo, purchaseEntryNo, supplier, onEdit, onDelete }: TableBody) => {
    return <tr key={sno}>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{purchaseEntryNo}</td>
        <td className='text-capitalize align-middle'>{LocalFormatDate(invoiceDate)}</td>
        <td className='text-capitalize align-middle'>{invoiceNo}</td>
        <td className='text-capitalize align-middle'>{supplier}</td>
        <td className='text-capitalize align-middle'>{finalAmount}</td>
        {isToday(invoiceDate) && <td className="align-middle">
            <div className=' hstack gap-1  justify-content-center'>
                <button className='btn btn-sm fw-bold btn-info ' onClick={onEdit}><i className="fe-edit noti-icon"></i></button>
                <button className='btn btn-sm fw-bold btn-danger ' onClick={onDelete}><i className="fe-trash-2 noti-icon"></i></button>
            </div>
        </td>}
    </tr>
}
export default Purchase