import jsPDF from "jspdf"
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, useEffect, useMemo, useState } from "react"
import * as Feather from "react-feather"
import { useSelector } from "react-redux"
import { FromList, FromTypeForTransaction } from "../config"
import api, { APIResponse, } from "../config/api"
import { LocalFormatDate, currencyFormat, rupeeFormat, today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolder"
import { TransactionType } from "../redux/actions/transactionAction"
import { StoreState } from "../redux/store"
import Option from "./Option"
import { FormInput } from "./custom/components"

interface Props {
    formType: FromTypeForTransaction
    // currentBalance: string
    acNumber?: String
    acName?: String
    onSave?: () => void
}

interface openBalance {
    openingBalance: String
}

interface TableBody {
    sno: number
    // accountNumber: string
    // accountName: string
    // AccountHolderType: string
    date: string
    particulars: string
    paymentMode: string
    income: string
    expense: string
    balance?: string
    // billNo?: string
    openingBalance?: string
}

const TransactionPartyLedgerForm = ({ acName, onSave, acNumber, formType }: Props) => {
    // const toast = useToast()
    // const authUser = useSelector<StoreState, AuthUserType | null>(state => state.authUser)
    // const token = authUser!["auth-token"]!

    const accountHolder = useSelector<StoreState, Array<AccountHolderType>>(state => state.account)

    const [fromDate, setFromDate] = useState<string>(today())
    const [fromDateError, setFromDateError] = useState<string>('')
    const [toDate, setToDate] = useState<string>(today())
    const [toDateError, setToDateError] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountNumberError, setAccountNumberError] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')

    const [accountHead, setAccountHead] = useState<string>('')
    const [accountHeadError, setAccountHeadError] = useState<string>('')

    const [data, setData] = useState<TransactionType[]>([])

    const [openingBalance, setOpeningBalance] = useState<string>('')

    const AccountHeadList = useMemo<Array<string>>(() => {
        return accountHolder.map(ah => {
            return ah.groupName || ''
        })
    }, [accountHolder])

    const resetStates = () => {
        setFromDate('')
        setFromDateError('')
        setToDate('')
        setToDateError('')
    }

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }

    const handleAccountHead: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountHead(e.target.value)
    }

    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
        const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && ah.accountNumber.toString() === e.target.value)

        if (index > -1) {
            // setAccountGroupName(accountHolder[index].accountHead)
            setAccountName(accountHolder[index]?.accountName || '')
        }
        setAccountNumberError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const totalDebit = useMemo<number>(() => {
        if (data !== undefined) {
            return data?.reduce((previous, current) => {
                return previous + (current.debitAmount ? (current.debitAmount) : 0)
            }, 0)

        } else return 0
    }, [data])

    const totalCredit = useMemo<number>(() => {
        if (data !== undefined) {
            return data?.reduce((previous, current) => {
                return previous + (current.creditAmount ? (current.creditAmount) : 0)
            }, 0)

        } else return 0
    }, [data])

    console.log(totalDebit);

    const currentBalance = useMemo<number>(() => {
        if (openingBalance !== undefined && totalCredit !== undefined && totalDebit !== undefined) {

            return parseFloat(openingBalance) + totalDebit - totalCredit

        } else return 0
    }, [totalCredit, openingBalance, totalDebit])

    const totalDebitWithOpeningBalance = useMemo<number>(() => {
        return parseFloat(openingBalance) + (totalDebit !== undefined ? totalDebit : 0)
    }, [totalDebit, openingBalance])

    const handleSubmit = () => {
        if (data !== undefined) {
            const doc = new jsPDF('l', 'mm', 'a4')

            const head1 = [['S.No', 'Date', 'Particulars', 'Payment Mode', 'Debit', 'Credit', 'Balance']]

            let balance = Number(openingBalance)
            const body1 = [['1', '', 'Opening Balance', '', '', '', '', rupeeFormat(openingBalance)]]
            data.forEach((d, i) => {
                balance = (balance + Number(d.debitAmount)) - Number(d.creditAmount)

                body1.push([
                    String(i + 2),
                    String(d.date),
                    String(d.particulars),
                    String(d.paymentMode),
                    // String(d.billNo),
                    String((d.debitAmount) !== 0 ? rupeeFormat(d.debitAmount) : ''),
                    String((d.creditAmount) !== 0 ? rupeeFormat(d.creditAmount) : ''),
                    String(rupeeFormat(balance)),
                ])
            })

            const TotalHead = [['Opening Balance', 'Total Debit', 'Total Credit', 'Current Balance']]

            const TotalBody = [[(openingBalance ? rupeeFormat(openingBalance) : '0'), (totalDebit ? rupeeFormat(totalDebit) : '0'), (totalCredit ? rupeeFormat(totalCredit) : '0'), (currentBalance ? rupeeFormat(currentBalance) : '0')]]


            doc.setFontSize(18)
            doc.text('SUPREME BUS SERVICE', 100, 12)

            doc.setFontSize(12)
            doc.text('Transaction Date :- ', 13, 26)
            doc.text('From Date: ' + LocalFormatDate(fromDate), 65, 26)
            doc.text('To Date: ' + LocalFormatDate(toDate), 155, 26)

            if (formType === FromList.TRANSACTION) {
                doc.setFontSize(12)
                doc.text('Account Name :', 13, 34)
                doc.text(acName !== undefined ? acName.toString() : '', 52, 34)
                doc.text('Account Number :', 13, 42)
                doc.text(acNumber !== undefined ? acNumber.toString() : '', 52, 42)
            }

            if (formType === FromList.REPORT) {
                doc.setFontSize(12)
                doc.text('Account Name :', 13, 34)
                doc.text(accountName !== undefined ? accountName.toString() : '', 52, 34)
                doc.text('Account Number :', 13, 42)
                doc.text(accountNumber !== undefined ? accountNumber.toString() : '', 52, 42)
            }

            doc.setFontSize(13)
            // doc.text('Total', 15, 53)

            autoTable(doc, {
                head: TotalHead,
                body: TotalBody,
                startY: 56,
                didDrawPage: function () {

                    // // Header
                    var str = doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    // var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height
                    //     ? pageSize.height
                    //     : pageSize.getHeight();
                    doc.text(str.toString(), 260, 10);
                }
            })

            doc.setFontSize(13)
            doc.text('Transaction Details', 15, (doc as any).lastAutoTable.finalY + 10)
            autoTable(doc, {
                head: head1,
                body: body1,
                startY: (doc as any).lastAutoTable.finalY + 13,
                didDrawPage: function () {

                    // // Header
                    var str = doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    // var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height
                    //     ? pageSize.height
                    //     : pageSize.getHeight();
                    doc.text(str.toString(), 260, 10);
                }
            })

            // doc.setFontSize(14)
            // doc.text('Opening Balance :', 195, (doc as any).lastAutoTable.finalY + 10)
            // doc.text(openingBalance ? openingBalance?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 10)
            // doc.text('Total Debit :', 195, (doc as any).lastAutoTable.finalY + 18)
            // doc.text(totalDebit ? totalDebit?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 18)
            // doc.text('Total Credit :', 195, (doc as any).lastAutoTable.finalY + 26)
            // doc.text(totalCredit ? totalCredit?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 26)
            // doc.text('Current Balance :', 195, (doc as any).lastAutoTable.finalY + 34)
            // doc.text(currentBalance ? currentBalance.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 34)

            doc.save("Party Ledger.pdf")
            // onSave()
        }
    }

    useEffect(() => {
        if (formType === FromList.TRANSACTION && toDate !== undefined && fromDate !== undefined && acNumber !== '') {
            api().get<APIResponse<TransactionType[]>>(`transaction/filter/?from_date=${fromDate}&to_date=${toDate}&account_no=${acNumber}`).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setData(result)
                }
            }).catch(error => {

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, acNumber])

    useEffect(() => {
        if (formType === FromList.REPORT && toDate !== undefined && fromDate !== undefined && accountNumber !== '') {
            api().get<APIResponse<TransactionType[]>>(`transaction/filter/?from_date=${fromDate}&to_date=${toDate}&account_no=${accountNumber}`).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setData(result)
                }
            }).catch(error => {

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, accountNumber])

    useEffect(() => {
        if (formType === FromList.TRANSACTION && fromDate !== undefined && acNumber !== '') {
            api().get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${acNumber}&date=${fromDate}`).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);

                    setOpeningBalance((result.openingBalance).toString())
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, acNumber])

    useEffect(() => {
        if (formType === FromList.REPORT && fromDate !== undefined && accountNumber !== '') {
            api().get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${accountNumber}&date=${fromDate}`).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);

                    setOpeningBalance((result.openingBalance).toString())
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, accountNumber])

    let balance = Number(openingBalance)

    return <div className='container'>
        <div className="row pb-5">

            {formType === FromList.TRANSACTION && <div className='col-sm-12 col-lg-12 mb-2'>
                <label className="fs-5">Account :</label>
                <label className="fs-5 ms-2 badge bg-warning text-dark">{acName}</label>
            </div>}

            <div className='col-sm-6 col-lg-4'>
                <FormInput
                    name='From Date'
                    label='From Date'
                    labelClassName="required"
                    value={fromDate}
                    onChange={handleFromDate}
                    type='date'
                    errorText={fromDateError}
                    containerClass="mb-1"

                />
            </div>

            <div className='col-sm-6 col-lg-4'>
                <FormInput
                    name='To Date'
                    label='To Date'
                    labelClassName="required"
                    value={toDate}
                    onChange={handleToDate}
                    type='date'
                    errorText={toDateError}
                    containerClass="mb-1"

                />
            </div>

            {/* {formType === FromList.REPORT && <div className="col-sm-6 col-md-6 col-lg-4 mb-2">
                <FormInput
                    value={accountHead}
                    onChange={handleAccountHead}
                    labelClassName="required"
                    errorText={accountHeadError}
                    type='select'
                    label='Account Group'
                >
                    <option value="">Select</option> */}
            {/* .filter((c, index) => {
                                 return chars.indexOf(c) === index;
                                }); */}
            {/* {AccountHeadList.filter((a, i) => {
                        return AccountHeadList.indexOf(a) === i;
                    }).map((ah, i) => {
                        return <Option value={ah} key={i}>{ah}</Option>
                    })}
                </FormInput>
            </div>} */}

            {formType === FromList.REPORT && <div className='col-sm-6 col-md-6 col-lg-4'>
                <FormInput
                    name='Account'
                    label='Account'
                    labelClassName="required"
                    type='select'
                    value={accountNumber}
                    onChange={handleAccountNumber}
                    errorText={accountNumberError}
                    containerClass="mb-1"
                >
                    <Option value=''>Select</Option>
                    {accountHolder.filter((a, i) => {
                        return a.groupName === 'supplier';
                    }).filter(bl => {
                        return bl.accountName !== 'Main Account' && bl.accountName !== 'Advance Account' && bl.accountName !== 'Purchase Account' && bl.accountName !== 'Purchase Return Account' && bl.accountName !== 'Sales Account'
                    }).map(ah => {
                        return <Option value={ah.accountNumber} key={ah.accountNumber}>{ah.accountName}</Option>
                    })}
                </FormInput>
            </div>}


            {data.length > 0 && <div className="my-0">
                <h4>Transaction Details</h4>

                <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                    <div className='card-body px-0 pt-1 pb-0'>
                        <div className='container-fluid'>
                            <div className="row p-0">

                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className='rounded  p-1 m-1 yellow-box row'>
                                        <div className="col-sm-4 text-white p-1">
                                            <Feather.CheckSquare
                                                color='white'
                                                size={40}
                                            />
                                        </div>
                                        <div className="col-sm-8" >
                                            <h4 className='text-white'>Opening:<br /> {currencyFormat(openingBalance)}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className='rounded  p-1 m-1 blue-box row'>
                                        <div className="col-sm-4 text-white p-1">
                                            <Feather.PlusSquare
                                                color='white'
                                                size={40}
                                            />
                                        </div>
                                        <div className="col-sm-8" >
                                            {/* <h4 className='text-white'>Debit:<br /> {currencyFormat(totalDebit)}</h4> */}
                                            <h4 className='text-white'>Debit:<br /> {currencyFormat(totalDebit)}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className='rounded  p-1 m-1 pink-box row'>
                                        <div className="col-sm-4 text-white p-1">
                                            <Feather.MinusSquare
                                                color='white'
                                                size={40}
                                            />
                                        </div>
                                        <div className="col-sm-8" >
                                            <h4 className='text-white'>Credit:<br /> {currencyFormat(totalCredit)}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-3 col-lg-3">
                                    <div className='rounded  p-1 m-1 green-box row'>
                                        <div className="col-sm-4 text-white p-1">
                                            <Feather.Pocket
                                                color='white'
                                                size={40}
                                            />
                                        </div>
                                        <div className="col-sm-8" >
                                            <h4 className='text-white'>Balance:<br /> {currencyFormat(currentBalance)}</h4>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="text-center col-sm-6 col-md-3 col-lg-3 p-0">
                                <h4>Opening Balance: </h4>
                                <h4>{rupeeFormat(openingBalance)}</h4>
                            </div>
                            <div className="text-center col-sm-6 col-md-3 col-lg-3">
                                <h4>Total Debit:</h4>
                                <h4>{rupeeFormat(totalDebit)}</h4>
                            </div>
                            <div className="text-center col-sm-6 col-md-3 col-lg-3">
                                <h4>Total Credit:</h4>
                                <h4>{rupeeFormat(totalCredit)}</h4>
                            </div>
                            <div className="text-center col-sm-6 col-md-3 col-lg-3">
                                <h4>Current Balance:</h4>
                                <h4>{rupeeFormat(currentBalance)}</h4>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='table-wrapper'>
                    <table className="table colored">
                        <thead>
                            <tr>
                                <th className='text-truncate align-middle'>S.No.</th>
                                <th className='text-truncate align-middle'>Date</th>
                                <th className='text-truncate align-middle'>Particulars</th>
                                <th className='text-truncate align-middle'>Payment mode</th>
                                {/* <th className='text-truncate align-middle'>Bill No</th> */}
                                <th className='text-truncate align-middle text-end'>Debit</th>
                                <th className='text-truncate align-middle text-end'>Credit</th>
                                <th className='text-truncate align-middle text-end'>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {data.map((d, i) => {
                        balance = (balance + Number(d.debitAmount)) - Number(d.creditAmount)
                        return <PartyLedgerRow {...d} sno={i + 1} key={d.id} />
                    })} */}
                            {data.length > 0 && <tr>
                                <td>1</td>
                                <td className='text-truncate'>{LocalFormatDate(fromDate)}</td>
                                <td>Opening Balance</td>
                                {/* <td></td> */}
                                {/* <td className='text-end'>{parseFloat(openingBalance) !== 0 ? rupeeFormat(openingBalance) : ''}</td> */}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                            </tr>}
                            {data?.map((d, i) => {
                                balance = (balance + Number(d.debitAmount)) - Number(d.creditAmount)
                                console.log(d.debitAmount);

                                return <PartyLedgerRow
                                    sno={i + 2}
                                    date={d.date}
                                    particulars={d.particulars}
                                    paymentMode={d.paymentMode}
                                    income={d.debitAmount ? d.debitAmount?.toString() : ''}
                                    expense={d.creditAmount ? d.creditAmount?.toString() : ''}
                                    // billNo={d.billNo}
                                    balance={String(balance)}
                                    key={d.id}

                                />
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(totalDebit)}</span>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(totalCredit)}</span>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(currentBalance)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>}
            <div className="d-flex justify-content-end hstack gap-1 mt-1">
                <button className="btn btn-primary" onClick={handleClear}>Add New</button>
                {data.length > 0 && <button className="btn btn-success" onClick={handleSubmit}> <i className='fe-download'></i>Get Report</button>}
            </div>
        </div>

    </div>
}
const PartyLedgerRow = ({ sno, date, particulars, paymentMode, income, expense, balance }: TableBody) => {
    console.log(income);

    return <tr>
        <td>{sno}</td>
        <td>{date}</td>
        <td>{particulars}</td>
        <td>{paymentMode}</td>
        {/* <td>{billNo}</td> */}
        <td className="text-end" >{parseFloat(income) !== 0 ? rupeeFormat(income) : ''}</td>
        <td className="text-end">{parseFloat(expense) !== 0 ? rupeeFormat(expense) : ''}</td>
        <td className="text-end">{rupeeFormat(balance)}</td>
    </tr>
}
// const PartyLedgerRow = (props: TransactionType & { sno: number }) => {
//     return <tr>
//         <td>{props.sno}</td>
//         <td>{props.date}</td>
//         {/* <td>{props.transactionFor}</td> */}
//         <td>{props.paymentMode}</td>
//         {/* <td>{props.voucherType}</td> */}
//         <td>{rupeeFormat(props.debitAmount)}</td>
//         <td>{rupeeFormat(props.creditAmount)}</td>
//     </tr>
// }

export default TransactionPartyLedgerForm