import { useEffect, useMemo, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { useSelector } from "react-redux"
import DonutChart from "../components/custom/components/charts/apex/DonutChart"
import LineChart from "../components/custom/components/charts/apex/LineChart"
import api, { APIResponse } from "../config/api"
import { CategoryType } from "../redux/actions/categoryAction"
import { PurchasePaymentModeTypes } from "../redux/actions/purchaseActions"
import { StoreState } from "../redux/store"
import { ReportPurchaseItemType } from "./PurchaseYearlyReport"

interface MonthlyPurchaseData {
    id?: number
    // financeYear: string
    // branchId: number
    // invoiceType: string
    // invoiceTitle: string
    invoiceDate: string
    invoiceDay?: string
    // invoiceWeekNumber?: number
    // invoiceWeekDay?: number
    invoiceMonth?: number
    invoiceYear?: number
    invoiceNumber: number
    packNo: string
    purchaseEntryNumber: number
    systemNo: string
    // repId?: number
    customerId: number
    // customerName: string
    // pointsEarned?: string
    // bankName: string
    // bankNumber: number
    paymentMode: PurchasePaymentModeTypes
    billAmount?: string
    // disPercent?: string
    // disAmount?: string
    // add?: string
    // less?: string
    grandTotal?: string
    amtInWords?: string
    particulars?: string
    amtPaid?: string
    purchaseItems: ReportPurchaseItemType[]
}

const PurchaseMonthlyReport = () => {
    const categories = useSelector<StoreState, CategoryType[]>((state) => state.category)
    const [fromMonth, setFromMonth] = useState<Date>(new Date())
    // const [fromMonthError, setFromMonthError] = useState<string>('')
    const [toMonth, setToYear] = useState<Date>(new Date())
    const [year, setYear] = useState<Date>(new Date())

    const monthDaysArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const [monthwisePurchaseData, setMonthwisePurchaseData] = useState<MonthlyPurchaseData[]>([])

    const CategorywiseFromMonthPurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = monthwisePurchaseData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceMonth?.toString() === (fromMonth?.getMonth() + 1)?.toString())?.map(m => m.purchaseItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString()).reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        console.log(data);
        return data

    }, [monthwisePurchaseData, fromMonth, categories, year])

    const CategorywiseToMonthPurchase = useMemo<{ cid: number, cname: string, purchase: number }[]>(() => {
        let data: { cid: number, cname: string, purchase: number }[] = [];
        const purchaseItemsData = monthwisePurchaseData?.filter(y => y.invoiceYear?.toString() === year?.getFullYear()?.toString() && y.invoiceMonth?.toString() === (toMonth?.getMonth() + 1)?.toString())?.map(m => m.purchaseItems).flat(1);
        console.log(purchaseItemsData);

        categories?.forEach(c => {
            const catPurchase = purchaseItemsData?.filter(si => si.categoryId?.toString() === c.id?.toString())?.reduce((prev, curr) => {
                return prev + parseFloat(curr.totalAmount)

            }, 0)
            data.push({
                cid: c?.id ? c?.id : 0,
                cname: c?.name ? c?.name : '',
                purchase: catPurchase
            })
        })
        console.log(data);
        return data
        // return fdata.reduce((p, c) => {
        //     return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
        // }, 0)

    }, [monthwisePurchaseData, toMonth, categories, year])

    useEffect(() => {

        if (fromMonth !== undefined && toMonth !== undefined && year !== undefined) {
            api().get<APIResponse<MonthlyPurchaseData[]>>(`purchase/dashboard/?fromMonth=${(fromMonth?.getMonth() + 1)?.toString()}&toMonth=${(toMonth?.getMonth() + 1)?.toString()}&year=${year?.getFullYear()?.toString()}`).then((response) => {
                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    console.log(result);
                    setMonthwisePurchaseData(result)
                }
            }).catch((error) => { })
        } else {
            setMonthwisePurchaseData([])
        }
    }, [fromMonth, toMonth, year])

    // const colors = [COLORS.COLOR1, COLORS.COLOR2, COLORS.COLOR3, COLORS.COLOR4, COLORS.COLOR5, COLORS.COLOR6, COLORS.COLOR7, COLORS.COLOR8]

    return <>
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Monthly Purchase</h4>
                        </div>
                        <div className="col-lg-4 hstack gap-3 text-end">
                            <div className="col-lg-3 ">
                                <span className="dateInput">
                                    {/* <span><Feather.Calendar size={14} /></span> */}
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={fromMonth}
                                        onChange={(date: any) => setFromMonth(date)}
                                        showMonthYearPicker
                                        calendarClassName=""
                                        dateFormat="MM"
                                    // yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                                {/* <FormInput
                                name='fromMonth'
                                label='From Year'
                                labelClassName="required"
                                value={fromMonth}
                                className="mt-2 fs-5"
                                onChange={handleFromYear}
                                errorText={fromMonthError}
                                type="date"
                            /> */}
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={toMonth}
                                        onChange={(date: any) => setToYear(date)}
                                        showMonthYearPicker
                                        dateFormat="MM"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>
                            </div>
                            <div className="col-lg-3">
                                <span className="dateInput">
                                    <ReactDatePicker
                                        id="DatePicker"
                                        // type="string"
                                        className="text-center fw-bold w-100"
                                        selected={year}
                                        onChange={(date: any) => setYear(date)}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        yearItemNumber={4}
                                    //     required
                                    />
                                </span>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">

                            <LineChart
                                // datasets={[
                                //     { name: months[fromMonth?.getMonth()], data: [54, 64, 52, 43, 22, 60, 32, 87, 13, 14, 34, 45, 54, 0, 0, 34, 34, 52, 43, 22, 20, 32, 87, 13, 14, 34, 45, 54, 0, 0] },
                                //     { name: months[toMonth?.getMonth()], data: [22, 20, 32, 87, 13, 14, 34, 45, 54, 0, 0, 4, 64, 52, 43, 22, 60, 32, 87, 13, 14, 34, 45, 13, 14, 34, 23, 11, 20] },

                                //     // { name: toMonth?.getFullYear()?.toString(), data: [ToYearPurchase] }
                                // ]}
                                datasets={(() => {
                                    let monthData: { name: string, data: number[] }[] = []
                                    let mons = [fromMonth, toMonth]
                                    mons.forEach(mon => {

                                        const fdata = monthwisePurchaseData?.filter(y => y.invoiceMonth?.toString() === (mon?.getMonth() + 1)?.toString())
                                        console.log(fdata);
                                        let data: number[] = []
                                        monthDaysArray.forEach(md => {

                                            const val = fdata?.filter(f => f.invoiceDay?.toString() === md)?.reduce((p, c) => {
                                                return p + (c?.grandTotal ? parseFloat(c?.grandTotal) : 0)
                                            }, 0)
                                            data.push(val)
                                        })
                                        monthData.push({
                                            name: months[mon?.getMonth()],
                                            data: data
                                        })
                                    })

                                    return monthData
                                })()}

                                labels={monthDaysArray}
                                colors={['#00B9E3', '#FF4560']}
                                // colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title=""
                            />
                        </div>

                        <div className="col-lg-3">

                            {/* <PieChart
                                datasets={(() => {
                                    let ydata: { name: string, data: [] } = []

                                    return ydata
                                })()}
                                labels={[]}
                                colors={[colors[0], colors[1], colors[2], colors[3], colors[4], colors[5], colors[6], colors[7]]}
                                title="" /> */}
                            <DonutChart
                                // datasets={[24, 45, 45, 56, 88]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseFromMonthPurchase.map(fm => fm.purchase)}
                                labels={CategorywiseFromMonthPurchase.map(fm => fm.cname)}
                                colors={['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#DC2AAC']}
                                title={months[fromMonth?.getMonth()] + ' ' + year?.getFullYear()?.toString()}
                            />
                        </div>
                        <div className="col-lg-3">
                            <DonutChart
                                // datasets={[65, 75, 72, 21, 89]}
                                // labels={["Men's wear", "Women's wear", "Kid's wear", "Dhotis", "Toys"]}
                                datasets={CategorywiseToMonthPurchase.map(fm => fm.purchase)}
                                labels={CategorywiseToMonthPurchase.map(fm => fm.cname)}
                                colors={['#FC89AC', '#7ED957', '#FF914D', '#00B9E3', '#E192FF', '#8CA5F7']}
                                title={months[toMonth?.getMonth()] + ' ' + year?.getFullYear()?.toString()}
                            />
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="card">
                            <div className="card-body">

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div >

    </>
}
export default PurchaseMonthlyReport