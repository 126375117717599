import { BrandActionList, BrandType } from "../actions/brandAction";
import { Action } from "../actions/types";

const initialValue: Array<BrandType> = []

export const brandReducer = (state: BrandType[] = initialValue, action: Action) => {

    switch (action.type) {
        case BrandActionList.ADD_BRAND:
            return [...state, action.data];
        case BrandActionList.EDIT_BRAND: {
            const updated = [...state].slice()
            const index = updated.findIndex(u => {
                return u.id === action.id
            })

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated
        }

        case BrandActionList.FETCH_BRAND:
            return action.data
        case BrandActionList.DELETE_BRAND: {
            return state.filter(i => i.id !== action.data)

        }
        default:
            return state;
    }
}